import React from 'react';

import { Sites } from '@rvpower/constants';

import infoSoeIcon from '../../../images/icon-info-soe.svg';

const icon = {
  src: infoSoeIcon,
  alt: 'Info Icon'
};

function RateBanner(props) {
  const {
    title,
    description,
    fuseData,
    site
  } = props;

  const { permalease } = fuseData;
  const format = '###-###-####';

  const bannerText = () => {
    switch (site) {
      case Sites.CHOOSE:
        return (
          <div className="rate-banner__text">
            Questions about energy rates?  Ask our knowledgeable team. Call:
            {' '}
            <strong>
              <a
                className="rate-banner__number"
                href={`tel:${permalease}`}
                data-fuse
                data-fuse-format={format}
                data-fuse-name="ES-CHOOSE-FUSE-PERMA-BANNER"
              >
                {permalease}
              </a>
            </strong>
          </div>
        );
      case Sites.NEW_TXER:
        return (
          <>
            <strong className="rate-banner__bold-copy">{title}</strong>
            {' '}
            <span className="rate-banner__copy">{description}</span>
            {' '}
            <a
              className="rate-banner__number"
              href={`tel:${permalease}`}
              data-fuse
              data-fuse-format={format}
              data-fuse-name="ES-TXER-FUSE-PERMA-BANNER"
            >
              {permalease}
            </a>
          </>
        );
      case Sites.NEW_CTXP:
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: description }} />
            {' '}
            <a
              className="rate-banner__number"
              href={`tel:${permalease}`}
              data-fuse
              data-fuse-format={format}
              data-fuse-name="ES-CTXP-FUSE-PERMA-BANNER"
            >
              {permalease}
            </a>
          </>
        );
      default:
        return (
          <>
            <strong className="rate-banner__bold-copy">{title}</strong>
            {' '}
            <span className="rate-banner__copy">{description}</span>
            {' '}
            <a
              className="rate-banner__number"
              href={`tel:${permalease}`}
              data-fuse
              data-fuse-format={format}
              data-fuse-name="ES-SOE-FUSE-PERMA-BANNER"
            >
              {permalease}
            </a>
          </>
        );
    }
  };
  return (
    <section className="rate-banner soe-rate-banner">
      <img src={icon.src} alt={icon.alt} className="rate-banner__icon" />
      <p className="rate-banner__text">{bannerText()}</p>
    </section>
  );
}

export default RateBanner;

RateBanner.defaultProps = {
  title: 'No hidden fees here: ',
  description:
    'Our rates include all monthly supplier and utility charges, except taxes.'
};
