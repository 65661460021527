import React from 'react';

import CaretDown from '../../../svg/caret-down.svg';

const CaretDownIcon = () => (
  <img
    className="grid-v2-pills__pill-icon-caret"
    src={CaretDown}
    alt="Open Filter Menu"
  />
);

export default CaretDownIcon;
