import React from 'react';
import Tippy from '@tippy.js/react';

import lightningIcon from '../../../images/lightning-icon.svg';
import infoIcon from '../../../images/info-icon.svg';
import houseIcon from '../../../images/simple-home-icon.svg';
import moneyIcon from '../../../images/money-icon.svg';
import recommendBadge from '../../../images/recommend-badge.svg';

const tippyContent = [
  {
    id: 'best value',
    img: houseIcon,
    text: 'Best value for your home size'
  },
  {
    id: 'savings',
    img: moneyIcon,
    text: 'Lowest estimated monthly cost'
  },
  {
    id: 'recommend',
    img: recommendBadge,
    text: 'High rated supplier'
  }
];

const popperOptions = {
  modifiers: {
    preventOverflow: { enabled: false },
    hide: { enabled: false }
  }
};

const HmcRecommendBanner = () => {
  const outerDivElement = document.getElementById('grid');

  return (
    <div className="hmc-recommend-banner">
      <img
        src={lightningIcon}
        alt="lightning icon"
        className="hmc-recommend-banner__lightning-icon"
      />
      <p className="hmc-recommend-banner__text">We recommend</p>
      <Tippy
        content={(
          <div className="hmc-recommend-banner__tippy">
            <span className="hmc-recommend-banner__tippy-title">Why we recommend this plan:</span>
            <div className="hmc-recommend-banner__tippy-content">
              {tippyContent.map((item) => (
                <div key={item.id} className="hmc-recommend-banner__tippy-content-item">
                  <img
                    src={item.img}
                    alt={item.id}
                    className="hmc-recommend-banner__tippy-content-icon"
                  />
                  <p className="hmc-recommend-banner__tippy-content-text">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
            )}
        appendTo={outerDivElement}
        theme="hmc-recommend"
        arrow
        placement="bottom"
        trigger="mouseenter"
        popperOptions={popperOptions}
        interactive
      >
        <img
          src={infoIcon}
          alt="info icon"
          className="hmc-recommend-banner__info-icon"
        />
      </Tippy>
    </div>
  );
};

export default HmcRecommendBanner;
