import React from 'react';
import { PropTypes } from 'prop-types';
import tracking from '../../js/project/tagular/tracking';

import BadgeBox from './BadgeBox';
import openIcon from '../../svg/open.svg';
import useBreakpoints from '../../js/hooks/useBreakPoints';

export default function NTXPlanDetails({
  additionalClasses,
  plan,
  site,
  featured = true,
}) {
  let renewableBadge = false;
  let lowCostBadge = false;

  const { isSm } = useBreakpoints();
  const eflDoc = plan?.EFLDoc?.url;

  if (plan.badges) {
    plan.badges.forEach((badge) => {
      if (badge.name.includes('renewable')) renewableBadge = badge;
      else if (badge.name.includes('low-cost')) lowCostBadge = badge;
    });
  }

  const trackDocumentClicked = (documentType) => {
    tracking.elementClicked({
      webElement: {
        location: featured ? 'FEATURED' : 'GRID',
        elementType: 'Link',
        text: documentType
      },
      actionOutcome: 'Plan docs opened'
    });
  };

  return (
    <>
      <div className={`plan-details ntx ${additionalClasses || ''}`}>
        <div className="plan-details__content ntx-plan-details__content">
          <div
            className="plan-details__description"
            id="plan-details__description--ntx"
          >
            {!isSm && !featured && (
              <p className="plan-details__document-label">Plan details</p>
            )}
            {plan?.description
              || 'This provider has great energy plans at competitive rates.'}
          </div>
          {/* NTX Plan documents */}
          <div className="plan-details__content-container-trio--ntx">
            <div className="plan-details__document plan-details__document--ntx">
              <p className="plan-details__document-label">
                Terms of Service (TOS)
              </p>
              <p className="plan-details__document-content plan-details__document-content--ntx">
                The TOS explains the terms and conditions of your electric
                service with the provider.
              </p>
              <a
                onClick={() => trackDocumentClicked('TOS')}
                className="plan-details__document-cta ntx"
                href={plan?.TermsDoc?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Access the TOS
                <img src={openIcon} alt="Open Icon" />
              </a>
            </div>
            {!featured && eflDoc && (
              <div className="plan-details__document plan-details__document--ntx">
                <p className="plan-details__document-label">Document Summary</p>
                <p className="plan-details__document-content plan-details__document-content--ntx">
                  {/* eslint-disable-next-line */}
                  This document breaks down plan info in more detail to
                  understand a plan’s energy rate, fees, terms and conditions
                  and more.
                  {' '}
                </p>
                <a
                  onClick={() => trackDocumentClicked('EFL')}
                  className="plan-details__document-cta ntx"
                  href={plan?.EFLDoc?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Access the Summary
                  <img src={openIcon} alt="Open Icon" />
                </a>
              </div>
            )}
          </div>
          {/* NTX Plan badges */}
          {(renewableBadge || lowCostBadge) && (
            <div className="plan-details__first-page-badges plan-details__first-page-badges-ntx">
              {lowCostBadge && (
                <BadgeBox
                  type="low-cost-choice"
                  plan={plan}
                  badge={lowCostBadge}
                  isNTX
                  site={site}
                />
              )}
              {renewableBadge && (
                <BadgeBox
                  type="renewable"
                  plan={plan}
                  badge={renewableBadge}
                  isNTX
                  site={site}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

NTXPlanDetails.defaultProps = {
  additionalClasses: ''
};

NTXPlanDetails.propTypes = {
  additionalClasses: PropTypes.string,
  plan: PropTypes.shape({}).isRequired,
};
