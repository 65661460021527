import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Sites } from '@rvpower/constants';
import tracking from '../../../../js/project/tagular/tracking';

const options = [
  { value: '50', label: '$50' },
  { value: '100', label: '$100' },
  { value: '150', label: '$150' },
  { value: '200', label: '$200' },
  { value: '250', label: '$250' },
  { value: '300', label: '$300' },
];

const BillPrice = ({ filters, setFilters, site }) => {
  const isCtxp = site === Sites.NEW_CTXP;
  const { billPrice } = filters;

  const handleOnChange = (e, name) => {
    setFilters({
      ...filters,
      billPrice: { ...billPrice, [name]: e.value }
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'DROPDOWN',
        location: 'MONTHLY BILL PRICE FILTER',
        name: 'FILTER DROPDOWNS',
        position: '1',
        text: `${name}: ${e.value}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const dropdownIconColor = isCtxp ? '#E7666A' : '#0071CD';

  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: dropdownIconColor
    })
  };

  return (
    <div className="grid-v2-bill-price">
      <p className="grid-v2-bill-price__content">Select your minimum and maximum price.</p>
      <div className="grid-v2-bill-price__grid">
        <div className="grid-v2-bill-price__dropdown">
          <p className="grid-v2-bill-price__label">Minimum price</p>
          <Select
            classNamePrefix="grid-v2-bill-price-dropdown"
            className="grid-v2-bill-price-dropdown-container"
            name="minBillPrice"
            placeholder="No min"
            isSearchable={false}
            onChange={(e) => handleOnChange(e, 'minBillPrice')}
            options={[{ value: '0', label: 'No min' }, ...options]}
            value={options.filter((option) => billPrice.minBillPrice === option.value)}
            styles={customStyles}
          />
        </div>
        <div className="grid-v2-bill-price-dropdown">
          <p className="grid-v2-bill-price__label">Maximum price</p>
          <Select
            classNamePrefix="grid-v2-bill-price-dropdown"
            className="grid-v2-bill-price-dropdown-container"
            name="maxBillPrice"
            placeholder="No max"
            isSearchable={false}
            onChange={(e) => handleOnChange(e, 'maxBillPrice')}
            options={options}
            value={options.filter((option) => billPrice.maxBillPrice === option.value)}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default BillPrice;

BillPrice.propTypes = {
  filters: PropTypes.shape({
    billPrice: PropTypes.shape({
      minBillPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
      ]),
      maxBillPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
      ])
    }).isRequired
  }).isRequired,
  site: PropTypes.string
};

BillPrice.defaultProps = {
  site: ''
};
