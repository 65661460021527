import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';

import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import tracking from '../../js/project/tagular/tracking';

import soeLogo from '../../../images/logo-soe.svg';
import ctxpLogo from '../../../images/logo-ctxp.svg';
import iconArrowWhite from '../../../images/long-arrow-right.svg';
import iconPhoneWhite from '../../../images/icon-phone-white.svg';

function ProductDetailStickyHeader(props) {
  const { plan, site } = props;

  const [isSticky, setIsSticky] = useState(false);
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;

  const generateCtaButtonCopy = () => {
    const { ctaButtonCopy } = pdpTestActive;
    const defaultCopy = 'Order now';

    return pdpTestActive && ctaButtonCopy ? ctaButtonCopy : defaultCopy;
  };

  const isNEWCTXP = site === Sites.NEW_CTXP;
  const businessLogo = site === Sites.NEW_CTXP ? ctxpLogo : soeLogo;

  const handleClickCTA = () => {
    tracking.elementClicked({
      webElement: {
        location: 'PDP Sticky Nav',
        elementType: 'Button',
        text: 'Order now'
      },
      actionOutcome: 'Enter cart'
    });

    setTimeout(() => {
      // Allow time for tagular event to fire
      window.location = plan.cartUrl;
    }, 500);
  };

  const handleClickPhone = () => {
    tracking.elementClicked({
      webElement: {
        location: 'PDP Sticky Nav',
        elementType: 'Phone CTA',
        text: 'Phone number'
      },
      actionOutcome: 'Call'
    });
  };

  useEffect(() => {
    // Used to prevent memory leak error from async request
    let isSubscribed = true;

    const handleSticky = () => {
      const options = {
        threshold: [1],
        rootMargin: '380px'
      };

      const onIntersect = (entries) => {
        entries.forEach((entry) => {
          if (isSubscribed) {
            setIsSticky(!entry.isIntersecting);
          }
        });
      };

      const observer = new IntersectionObserver(onIntersect, options);

      observer.observe(document.body);
    };

    setTimeout(() => handleSticky(), 150);

    // eslint-disable-next-line
    return () => (isSubscribed = false)
  }, []);

  return (
    <div className={`pdp-sticky-header
      ${isSticky ? 'pdp-sticky-header--sticky' : ''}`}
    >
      <div className="pdp-sticky-header__banner">
        <div className="pdp-sticky-header__banner-inner pdp-sticky-header__banner-inner--mobile">
          <span className="pdp-sticky-header__banner-title">Call now</span>
          <img className="pdp-sticky-header__banner-icon" src={iconPhoneWhite} alt="Phone banner" />
          {/* eslint-disable-next-line */}
          <div className="pdp-sticky-header__phone" onClick={() => handleClickPhone()}>
            <a
              href={`tel:${isNEWCTXP ? '8442383028' : '8448515993'}`}
              data-fuse
              data-fuse-format="###-###-####"
              data-fuse-name={isNEWCTXP ? 'ES-CTXP-FUSE-PERMA-PDP' : 'ES-SOE-FUSE-PERMA-PDP'}
            >
              {isNEWCTXP ? '(844) 238-3028' : '(844) 851-5993'}
            </a>
          </div>
        </div>
        <div className="pdp-sticky-header__banner-inner pdp-sticky-header__banner-inner--desktop">
          <img className="pdp-sticky-header__banner-icon" src={iconPhoneWhite} alt="Phone banner" />
          <span className="pdp-sticky-header__banner-title">
            Have questions or need help?
            <strong> Give us a call:</strong>
          </span>
          {/* eslint-disable-next-line */}
          <div className="pdp-sticky-header__phone" onClick={() => handleClickPhone()}>
            <a
              href={`tel:${isNEWCTXP ? '8442383028' : '8448515993'}`}
              data-fuse
              data-fuse-format="###-###-####"
              data-fuse-name={isNEWCTXP ? 'ES-CTXP-FUSE-PERMA-PDP' : 'ES-SOE-FUSE-PERMA-PDP'}
            >
              {isNEWCTXP ? '(844) 238-3028' : '(844) 851-5993'}
            </a>
          </div>
        </div>
      </div>
      <div className="pdp-sticky-header__inner">
        <div className="pdp-sticky-header__container-left">
          <img className="pdp-sticky-header__logo" src={businessLogo} alt="Logo" />
        </div>
        <div className="pdp-sticky-header__container-right">
          <div className="pdp-sticky-header__kwh-container">
            <span className="pdp-sticky-header__kwh-copy">
              <strong>{`${plan?.price}¢ `}</strong>
              per kWh
            </span>
            <span className="pdp-sticky-header__kwh-subcopy">
              {`based on ${plan?.usage} kWh usage`}
            </span>
          </div>
          <button
            className="pdp-sticky-header__cta"
            type="button"
            onClick={handleClickCTA}
          >
            {generateCtaButtonCopy()}
            {!isNEWCTXP && (
              <img src={iconArrowWhite} alt="Order now" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailStickyHeader;

ProductDetailStickyHeader.propTypes = {
  plan: PropTypes.shape().isRequired,
};
