import React from 'react';
import { Sites } from '@rvpower/constants';
import PropTypes from 'prop-types';
import GridTrustBuildingIcon from './GridTrustBuildingIcon';

const siteColors = {
  [Sites.SOE]: '#003F73',
  [Sites.CHOOSE]: '#3b4da0',
  [Sites.TXER]: '#0160d4',
  [Sites.NEW_TXER]: '#0160d4',
  [Sites.CTXP]: '#e85053',
  [Sites.NEW_CTXP]: '#e85053'
};

const assetNames = {
  'Trust Building Banner - Fixed Rates': {
    icon: 'shield',
    headline: 'Plan for the season',
    copy:
      'We update our fixed rates daily to offer you the best available options as temperatures and rates rise.'
  },
  'Trust Building Banner - Best Energy Rates': {
    icon: 'magnifier',
    headline: 'Plan for the season',
    copy:
      'We always aim to find you the best energy rate for your needs as the market shifts in the changing season.'
  }
};

const GridTrustBuildingBanner = (props) => {
  const {
    site,
    assetName,
  } = props;

  if (!siteColors[site] || !assetNames[assetName]) {
    return null;
  }

  const iconColor = siteColors[site];
  const { icon, headline, copy } = assetNames[assetName];

  return (
    <div className="trust-building">
      <GridTrustBuildingIcon
        iconColor={iconColor}
        icon={icon}
      />
      <div className="trust-building__content">
        <p className="trust-building__headline">
          {headline}
        </p>
        <p className="trust-building__copy">
          {copy}
        </p>
      </div>
    </div>
  );
};

GridTrustBuildingBanner.defaultProps = {
  assetName: 'Trust Building Banner - Fixed Rates',
};

GridTrustBuildingBanner.propTypes = {
  assetName: PropTypes.string,
  site: PropTypes.string.isRequired
};
export default GridTrustBuildingBanner;
