import React from 'react';
import PropTypes from 'prop-types';

import StarSVG from '../../../svg/star.svg';

const RadioButton = ({
  checked,
  children,
  id,
  label,
  name,
  onChange,
  starSVG,
  value
}) => (
  <label htmlFor={id} className="grid-v2-radio-button">
    {(starSVG && label !== 'Any') && (
      <img
        className="grid-v2-radio-button__star"
        src={StarSVG}
        alt="Star Rating"
      />
    )}
    {label}
    {Boolean(children) && children}
    <input
      checked={checked}
      className="grid-v2-radio-button__input"
      type="radio"
      value={value}
      id={id}
      name={name}
      onChange={onChange}
    />
  </label>
);

export default RadioButton;

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  starSVG: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

RadioButton.defaultProps = {
  starSVG: false
};
