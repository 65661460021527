const eventMap = {
  ProductPriceViewed: 'energy.ProductPriceViewed.v1',
};

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0; var v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}

const listId = uuid();

const beam = (eventName, data) => {
  if (window.tagular) {
    window.tagular('beam', eventName, {
      '@type': eventMap[eventName],
      listId,
      ...data
    });
  }
};

const sanitizeSingleProduct = (product) => (
  { ...product, pointInTimeId: Number(product.pointInTimeId) }
);

const productPriceViewed = (product) => {
  const sanitizedProduct = sanitizeSingleProduct(product);

  beam('ProductPriceViewed', {
    product: sanitizedProduct
  });
};

export default productPriceViewed;
