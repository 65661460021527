import React from 'react';
import PropTypes from 'prop-types';
import buildClassList from '../../../js/project/buildClassList';

const Radio = ({
  checked,
  className,
  id,
  label,
  name,
  onChange,
  value
}) => {
  const classList = buildClassList('grid-v2-radio', className);
  return (
    <label
      htmlFor={id}
      className={classList}
    >
      <input
        checked={checked}
        className="grid-v2-radio__input"
        type="radio"
        value={value}
        id={id}
        name={name}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

export default Radio;

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

Radio.defaultProps = {
  className: ''
};
