import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from './CloseIcon';
import CaretDownIcon from './CaretDownIcon';
import buildClassList from '../../../js/project/buildClassList';

const PillChildren = ({ hasPlus, number, onClick }) => {
  const buttonWrapper = buildClassList(
    'grid-v2-pills__pill-children-button-wrapper',
    hasPlus ? 'grid-v2-pills__pill-children-button-wrapper--no-margin' : '',
  );

  return (
    <div className="grid-v2-pills__pill-children">
      {number === 0 && <CaretDownIcon />}
      {number > 0 && (
        <div className={buttonWrapper}>
          {hasPlus && '+'}
          {number > 1 && `+${number - 1}`}
          <button
            className="grid-v2-pills__pill-children-button"
            type="button"
            onClick={onClick}
          >
            <CloseIcon className="grid-v2-pills__pill-children-close" type="close-pill" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PillChildren;

PillChildren.propTypes = {
  hasPlus: PropTypes.bool,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

PillChildren.defaultProps = {
  hasPlus: false
};
