/* eslint-disable max-len */
import React, { useState } from 'react';
import accordionTxuContent from '../../js/project/faqs-txu-accordion-text-new';
import { CustomFuseText } from '../FuseNumbers';
import tracking from '../../js/project/tagular/tracking';

const Item = ({
  title, copy, usesFuse, onClick, index, isOpen, pdpTestActive, isOnPDP
}) => {
  const handleOnClick = (selectedIndex) => {
    tracking.elementClicked({
      webElement: {
        location: pdpTestActive && isOnPDP ? 'PDP' : 'GRID',
        elementType: 'Button',
        text: title,
        position: `FAQ ${index + 1}`
      },
      actionOutcome: isOpen ? 'COLLAPSE' : 'EXPAND'
    });

    if (onClick) {
      onClick(selectedIndex);
    }
  };

  const height = isOpen ? 'auto' : 0;
  const className = isOpen ? 'accordion-item--wrapper is-active' : 'accordion-item--wrapper';

  return (
    <div
      className="accordion-item"
      key={title}
    >
      <div
        onClick={() => handleOnClick(index)}
        className="accordion-item__title"
        aria-label="Toggle item"
        onKeyPress={() => handleOnClick(index)}
        role="button"
        tabIndex="0"
      >
        <span className="accordion-title">
          {title}
        </span>
        <div
          className={`accordion-item__cross ${isOpen ? 'is-active' : ''}`}
          role="button"
          aria-label="accordion toggle button"
        >
          <span />
          <span />
        </div>
      </div>
      { usesFuse ? (
        <div style={{ height }} className={className}>
          <p className="accordion-item__copy">
            On average, Texans use about 1100 kWh each month, but you may use more or less depending on the size and energy efficiency of your home, as well as your lifestyle.
            <sup>2</sup>
            {' '}
            You’re also likely to see higher usage in the summer and winter months, when your A/C and heater are working harder. You can always
            {' '}
            <CustomFuseText text="call us" />
            {' '}
            to get expert advice and help choosing a plan that will fit you and your home best.
          </p>
        </div>
      ) : (
        <div
          style={{ height }}
          className={className}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      ) }
    </div>
  );
};

function TXUAccordion(props) {
  const { pdpTestActive = false, isOnPDP = false } = props;
  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleClick = (index) => {
    setCurrentIndex((currentValue) => (currentValue !== index ? index : -1));
  };

  const faqItems = () => accordionTxuContent.faqItems;

  const accordionContentSelector = faqItems().map((data, index) => Item({
    ...data, index, isOpen: index === currentIndex, onClick: handleClick, pdpTestActive, isOnPDP
  }));

  const getTitle = () => {
    if (pdpTestActive) return 'Frequently Asked Questions';
    return 'FAQs';
  };

  return (
    <div
      id="accordion"
      className="accordion accordion--txu txu-redesign"
    >
      <span id="accordion__anchor" className="accordion__anchor" />
      <h3 className="accordion__title">{getTitle()}</h3>
      <div className="accordion__item-container">
        {accordionContentSelector}
      </div>
    </div>
  );
}

export default TXUAccordion;
