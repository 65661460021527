/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import editIcon from '../../../images/edit-icon-ctxp.svg';

const InlineZipcodeEntry = ({
  placeholder,
  buttonLabel,
  value,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [zipCode, setZipCode] = useState('');

  const handleOnOpen = () => {
    setIsOpen(true);
  };

  const handleOnChange = (event) => {
    const digits = event.target.value.replace(/\D/g, '');
    setZipCode(digits);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setIsOpen(false);
    if (onSubmit && zipCode.length === 5) {
      onSubmit(zipCode);
    }
  };

  const handleClickOutside = (event) => {
    if (event.target.closest('.inline-zipcode')) return;
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <>
      {isOpen ? (
        <form className="inline-zipcode" onSubmit={handleOnSubmit}>
          <input
            type="text"
            placeholder={placeholder}
            value={zipCode}
            onChange={handleOnChange}
            autoFocus
            maxLength={5}
          />
          <button type="submit">{buttonLabel}</button>
        </form>
      ) : (
        <button
          type="button"
          className="inline-zipcode__value"
          onClick={handleOnOpen}
        >
          <span>{value}</span>
          <img src={editIcon} alt="" />
        </button>
      )}
    </>
  );
};

InlineZipcodeEntry.propType = {
  buttonLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onsubmit: PropTypes.func.isRequired,
};

InlineZipcodeEntry.defaultProps = {
  buttonLabel: 'Update',
  placeholder: '',
  value: '',
};

export default InlineZipcodeEntry;
