import React, { useEffect } from 'react';
import { UsageAmounts, cartUrl } from '@rvpower/constants';
import tracking from '../../js/project/tagular/tracking';

import FeaturedCardLabel from '../grid-v2/FeaturedCardLabel';

// Icons
import iconPhoneFilled from '../../../images/icon-phone-black.svg';
import iconArrowDown from '../../svg/arrow-down--black.svg';

function PlanCardsWidgetPlanCard({
  dropdownId,
  site,
  fuseData,
  plan,
  activeDropdown,
  handleDropdownToggle,
  zipCode,
  externalGrid = false,
}) {
  const isDropdownActive = activeDropdown === dropdownId;
  const {
    permalease,
    dataFuseName = 'ES-SOE-FUSE-PERMA-ROG'
  } = fuseData;

  const planTermText = plan.term.type.replace(/FixedMonths/g, 'mos.').replace(/MonthToMonth/g, 'Monthly');
  const planPricePerKwh = (plan?.price * 100) ? (plan?.price * 100).toFixed(1) : '0';
  const usageLabel = UsageAmounts[plan.usageId].label;

  const trackElementClicked = (type, event) => {
    const isPhone = type === 'phone';

    tracking.elementClicked({
      actionOutcome: isPhone ? 'Call' : 'Enter Cart',
      webElement: {
        location: 'ROG',
        elementType: isPhone ? 'Phone CTA' : 'Cart CTA',
        text: event.target.innerText,
      },
    });
  };

  const handleCartClick = (event) => {
    trackElementClicked('cart', event);

    setTimeout(() => {
      let url = cartUrl(site, plan.id, plan.utility.id, zipCode || '79707', usageLabel);

      if (externalGrid && site) {
        const siteUrl = site.includes('choosetexaspower') ? 'choosetexaspower.org' : 'saveonenergy.com';
        url = process.env.APP_ENV === 'production'
          ? `https://www.${siteUrl}${url}`
          : `https://staging.${siteUrl}${url}`;
      }

      if (url) {
        window.postMessage(
          { type: 'widget-cart-redirect', url: `${url}&zipEntryModal=true` },
          '*'
        );
      }
    }, 500);
  };

  useEffect(() => {
    if (window.fuse) {
      window.fuse('placeNumbers');
    }
  }, []);

  return (
    <div className={`plans-widget-card ${isDropdownActive ? 'plans-widget-card--active' : ''}`}>
      <FeaturedCardLabel plan={plan} />
      <div className="plans-widget-card__logo-container">
        <img className="plans-widget-card__logo" alt="logo" src={plan.supplier.logo} />
      </div>
      <p className="plans-widget-card__plan-name">{plan.name}</p>
      <div className="plans-widget-card__highlights-container">
        <div className="plans-widget-card__highlight price">
          <div>
            <span className="price__value">
              {planPricePerKwh}
            </span>
            <sup className="price__symbol">¢</sup>
            <span className="price__unit">/kWh</span>
          </div>
          <p className="price__label">{`at ${usageLabel} kWh`}</p>
        </div>
        <div className="plans-widget-card__highlight term">
          <div>
            {plan.term.length && (
              <span className="term__length">
                {plan.term.length}
              </span>
            )}
            <span className="term__type">
              {planTermText}
            </span>
          </div>
          <p className="term__rate-type">
            {`${plan.type} rate`}
          </p>
        </div>
      </div>
      <div className="plans-widget-card__cta-container">
        <button
          className="plans-widget-card__cta plans-widget-card__cta--cart"
          type="button"
          onClick={(event) => handleCartClick(event)}
        >
          Check Availability
        </button>
        <a
          onClick={(event) => trackElementClicked('phone', event)}
          className="plans-widget-card__cta plans-widget-card__cta--phone"
          href={`tel:${permalease}`}
          data-fuse
          data-fuse-format="Call Now"
          data-fuse-name={dataFuseName}
        >
          Call Now
        </a>
      </div>
      <hr className="plans-widget-card__hr" />
      <div className="plans-widget-card__details-section">
        <div className="plans-widget-card__details-phone">
          <img className="plans-widget-card__phone-icon" alt="phone" src={iconPhoneFilled} />
          <a
            onClick={(event) => trackElementClicked('phone', event)}
            className="plans-widget-card__phone-number"
            href={`tel:${permalease}`}
            data-fuse
            data-fuse-format="(###) ###-####"
            data-fuse-name={dataFuseName}
          >
            {permalease}
          </a>
        </div>
        <button
          type="button"
          className="plans-widget-card__details-toggle"
          onClick={() => handleDropdownToggle(dropdownId, isDropdownActive)}
        >
          {isDropdownActive ? 'Less details' : 'More details'}
          <img
            className={`
              plans-widget-card__details-toggle-icon
              ${isDropdownActive && 'plans-widget-card__details-toggle-icon--active'}
            `}
            alt="arrow-down"
            src={iconArrowDown}
          />
        </button>
      </div>
    </div>
  );
}

export default PlanCardsWidgetPlanCard;
