import React, { useState } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { ProductClicked } from '@rvpower/track';
import PlanInfo from './PlanInfo';
import SavingsGraph from './SavingsGraph';
import RateBanner from '../../RateBanner';
import { FuseNumbersEP } from '../../FuseNumbers';
import infoBubble from '../../../svg/ico-thumbs-up.svg';
import { getPlan } from '../../../js/project/tracking-helper';

export default function GraphCard(props) {
  const {
    electricPhoenixToggleActive,
    electricPhoenixInput,
    toggleEP,
    plan,
    epData,
    site
  } = props;

  /* eslint-disable-next-line max-len */
  let cartUrl = `${plan?.cartUrl}&electricPhoenixActive=true&electricPhoenixToggleActive=${electricPhoenixToggleActive}`;
  if (electricPhoenixInput) {
    Object.entries(electricPhoenixInput).forEach(([k, v]) => {
      cartUrl += `&${k}=${v}`;
    });
  }

  const [overlayOpen, setOverlayOpen] = useState(false);

  const triggerOverlay = () => {
    setOverlayOpen(!overlayOpen);
  };

  const productClickedHandler = () => {
    const productInfo = getPlan(plan, 0, 'EP FEATURED');
    ProductClicked(productInfo);
  };

  return (
    <section className="graph-card-wrapper u-full-width">
      <RateBanner />
      <h1 className="graph-card-wrapper__heading">
        <span className="graph-card-wrapper__heading--block">
          Recommended plan&nbsp;
        </span>
        for highest savings
      </h1>
      {electricPhoenixToggleActive && (
        <div className="ep-toggle">
          <div className="ep-toggle__text">
            Customize results for savings
          </div>
          <Toggle
            defaultChecked
            onChange={toggleEP}
            className="ep-toggle__slider"
            aria-label="Toggle"
          />
        </div>
      )}

      <div className="graph-card">
        <div className="graph-card__savings-components">
          <PlanInfo
            triggerOverlay={triggerOverlay}
            newPlan={plan}
            epData={epData}
          />
          <SavingsGraph
            triggerOverlay={triggerOverlay}
            overlayOpen={overlayOpen}
            newPlan={plan}
            epData={epData}
          />
        </div>
        <div className="graph-card__cta-wrapper">
          {/* eslint-disable-next-line max-len */}
          <FuseNumbersEP className="graph-card__phone graph-card__button" site={site} phoneDesktop="Grid Desktop - Call + Phone" />
          <a
            className="graph-card__button"
            href={cartUrl}
            onClick={productClickedHandler}
          >
            CHECK AVAILABILITY
          </a>
        </div>
      </div>
      <div className="plan-recommendation__wrapper">
        <div className="icon-wrapper">
          <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
        </div>
        <div className="text-content">
          <div className="headline">
            Why we recommend this plan
          </div>
          <div className="description">
            {/* eslint-disable max-len */}
            This plan offers the highest savings for the whole term length, with fees factored in. We base this recommendation on any usage and bill data you entered, plus rate averages calculated from the Public Utility Commission of Texas (PUCT) Residential Rate Comparison.
          </div>
          <div className="description">
            If you didn&#39;t enter bill or usage data, we used our custom algorithm to estimate your current rate. For more accurate savings, please enter your bill information.
          </div>
        </div>
      </div>
    </section>
  );
}
