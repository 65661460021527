import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  termTypeToText,
  cartUrl as buildCartUrl,
  Sites
} from '@rvpower/constants';
import Spinner from '../Spinner';
import ProductDetailHero from './ProductDetailHero';
import ProductDetailBenefits from './ProductDetailBenefits';
import ProductDetailStickyHeader from './ProductDetailStickyHeader';
import ProductDetailAccordions from './ProductDetailAccordions';
import ProductDetailSectionInfo from './ProductDetailSectionInfo';
import ProductDetailSectionFAQ from './ProductDetailSectionFAQ';
import ProductDetailSectionWhyShop from './ProductDetailSectionWhyShop';

const usageLegend = {
  500: 'FiveHundredKwh',
  1000: 'OneThousandKwh',
  2000: 'TwoThousandKwh',
};

const query = gql`
  query plan($planID: ID!, $utilityID: ID!, $usage: [Int]!) {
    plan(planId:$planID, utilityId: $utilityID, usage: $usage){
      id
      name
      type
      pricePerKwh
      rates {
        price
        type
      }
      term {
        length
        type
      }
      supplier {
        id
        name
        logo
        ratings {
          customerService
          onlineAccessibility
          businessHistory
          overallScore
          planOptions
        }
        whatToKnow
      }
      documents {
        type
        url
      }
      badges {
        id
        name
        tooltip
        source
        attributeValue
      }
      billCredits {
        amount
        startKwh
        endKwh
      }
    }
  }
`;

function ProductDetailPage(props) {
  const { site, zipCode } = props;

  const isNEWCTXP = site === Sites.NEW_CTXP;

  /** Attempt to pull plan data from local storage
   * Otherwise query for plan data using planId and utilityId query params
   */
  const localStoragePlan = JSON.parse(window.localStorage.getItem('planData'));
  const params = new URLSearchParams(window.location.search);
  const planId = params.get('planId');
  const utilityId = params.get('utilityId');
  const usage = localStoragePlan?.usage || 2000;

  const {
    data: queryData = {},
    loading,
  } = useQuery(query, {
    errorPolicy: 'all',
    variables: {
      planID: planId,
      utilityID: utilityId,
      usage,
    },
    fetchPolicy: 'cache-and-network',
    skip: localStoragePlan?.rates?.find((rate) => rate?.type === usageLegend[usage])
  });

  if (loading) {
    return (
      <>
        <Spinner />
        <div className="column-content-placeholder" />
      </>
    );
  }

  const plan = localStoragePlan || queryData.plan;

  if (!plan) {
    return (
      <div>Error: Plan data not found</div>
    );
  }

  if (localStoragePlan) {
    const matchedRate = plan?.rates?.find((rate) => rate?.type === usageLegend[usage]);
    plan.price = matchedRate ? matchedRate.price : plan.price;
  } else {
    /** Add missing properties when plan is queried through graphql */
    const moverSwitcher = '';
    const dwellingType = '';

    plan.cartUrl = buildCartUrl(
      site,
      planId,
      utilityId,
      zipCode,
      usage,
      moverSwitcher,
      dwellingType,
    );
    plan.termText = plan?.term ? termTypeToText(plan.term) : '';
    plan.price = plan?.rates ? plan.rates.find((rate) => rate?.type === usageLegend[usage]).price : plan.pricePerKwh;
    plan.usage = usage;
  }

  /** Add plan data formatting */
  plan.price = plan.price ? (plan.price * 100).toFixed(1) : '0';
  if (plan.termText === 'Month to Month') plan.termText = 'Monthly';

  // This will override the following styles and elements display coming from the CTXP repo
  if (isNEWCTXP) {
    document.querySelector('.header__container').style.cssText += 'position:absolute;';
    document.querySelector('.menu-button--desktop').style.cssText += 'display:none;';
    document.querySelector('.hamburger-button--mobile').style.cssText += 'display:none;';
  }

  return (
    <div className="pdp">
      <ProductDetailStickyHeader site={site} plan={plan} />
      <ProductDetailHero site={site} plan={plan} zipCode={zipCode} />
      <ProductDetailBenefits site={site} noBackgroundImages={isNEWCTXP} plan={plan} />
      <ProductDetailAccordions site={site} plan={plan} />
      <ProductDetailSectionInfo site={site} noBackgroundImages={isNEWCTXP} />
      <ProductDetailSectionFAQ site={site} useChevronItem={isNEWCTXP} />
      <ProductDetailSectionWhyShop site={site} noBackgroundImages={isNEWCTXP} />
    </div>
  );
}

export default ProductDetailPage;
