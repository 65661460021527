export default function newrelic() {
  return window.newrelic || window.NREUM || {};
}

export function interaction() {
  const nr = newrelic();
  return nr && nr.interaction ? nr.interaction() : null;
}

export function noticeError(...args) {
  const nr = newrelic();

  if (nr && nr.noticeError) {
    nr.noticeError(...args);
  }
}

export function setAttribute(key, value) {
  const browserInteraction = interaction();

  if (browserInteraction) {
    browserInteraction.setAttribute(key, value).save();
  }
}
