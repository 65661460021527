import React, { useState } from 'react';
import TxuFooter from './TxuFooter';
import TXUAccordion from '../grid-faqs/TXUAccordion';

const TxuUnservePage = () => {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const zipCode = queryParams.get('zipCode');
  const [zipCodeValue, setZipCodeValue] = useState(zipCode);

  const handleZipCodeChange = (e) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
    setZipCodeValue(onlyNumbers);
  };

  const handleZipCodeSubmit = (e) => {
    e.preventDefault();
    const currentQueryParams = new URLSearchParams(window.location.search);
    currentQueryParams.set('zipCode', zipCodeValue);
    window.location.href = `${window.location.pathname}?${currentQueryParams.toString()}`;
  };

  return (
    <div className="txu-redesign unserve">
      <div className="txu-title-container">
        <h1>We don&rsquo;t currently have electricity plans available at&nbsp;</h1>
        <form onSubmit={handleZipCodeSubmit}>
          <input
            name="zipCode"
            type="text"
            value={zipCodeValue}
            onChange={handleZipCodeChange}
            className="simplified-hero-zipcode"
            maxLength="5"
            required
            pattern="[0-9]{5}"
          />
        </form>
      </div>

      <TXUAccordion />

      <TxuFooter />
    </div>
  );
};

export default TxuUnservePage;
