import React, { useState, useEffect } from 'react';

import starIcon from '../../images/red-star-hollow.svg';
import trustPilotLogo from '../../images/trustpilot-logo-dark.svg';
import noFeesIcon from '../../images/no-fees-icon-red.svg';
import experienceIcon from '../../images/experience-icon.svg';

const SocialProofBanner = (props) => {
  const {
    socialBanner
  } = props;

  const [reviewData, setReviewData] = useState({ numberOfReviews: { total: 2622 } });

  useEffect(() => {
    // Used to prevent memory leak error from async request
    let isSubscribed = true;

    /** Hit api to get review counts from trustpilot */
    const getReviewData = async () => {
      const response = await fetch(
        'https://api.development.saveonenergy.cloud/trustpilot/choosetexaspower.org',
      );

      const data = await response.json();

      if (data && isSubscribed) {
        setReviewData(data);
      }
    };

    getReviewData();

    // eslint-disable-next-line
    return () => (isSubscribed = false)
  }, []);

  let preampPlacementSpacing;
  if (socialBanner?.bannerPlacement === 'above') {
    preampPlacementSpacing = 'social-proofing__top';
  } else if (socialBanner?.bannerPlacement === 'below') {
    preampPlacementSpacing = 'social-proofing__bottom';
  }

  const headerOption1 = (
    <>
    Join over&nbsp;
      <span className="social-proofing__header-text-highlighted">
        7 million
      </span>
    &nbsp;Texans who trust us with their energy shopping
    </>
  );

  const headerOption2 = (
    <>
    Helping over&nbsp;
      <span className="social-proofing__header-text-highlighted">
        7 million
      </span>
    &nbsp;Texans shop for energy
    </>
  );

  let headerText;
  if (socialBanner?.headerText === 'option1') {
    headerText = headerOption1;
  } else if (socialBanner?.headerText === 'option2') {
    headerText = headerOption2;
  }

  return (
    <div className="grid-v2__column">
      <div className={`social-proofing ${preampPlacementSpacing}`}>
        <div className="social-proofing__header">
          <p className="social-proofing__header-text">
            {headerText}
          </p>
        </div>
        <hr className="social-proofing-divider" />
        <div className="social-proofing__content">
          <a
            href="https://www.trustpilot.com/review/choosetexaspower.org"
            target="_blank"
            rel="noopener noreferrer"
            className="social-proofing__content-container social-proofing__content-container--trustpilot"
          >
            <img
              src={starIcon}
              alt="Rating Star"
              className="social-proofing__content-star-icon"
            />
            <div className="social-proofing__content-text">
              <p className="social-proofing__content-heading">
                {reviewData.score?.trustScore}
                /5 stars
              </p>
              <img
                src={trustPilotLogo}
                alt="Trustpilot Logo"
                className="social-proofing__content-trustpilot-logo"
              />
            </div>
          </a>
          <div className="social-proofing__content-container">
            <img
              src={noFeesIcon}
              alt="Crossed Dollar Sign"
              className="social-proofing__content-dollar-icon"
            />
            <div className="social-proofing__content-text">
              <p className="social-proofing__content-heading">No</p>
              <p className="social-proofing__content-subheading">hidden Fees</p>
            </div>
          </div>
          <div className="social-proofing__content-container">
            <img
              src={experienceIcon}
              alt="Achievement Ribbon"
              className="social-proofing__content-experience-icon"
            />
            <div className="social-proofing__content-text">
              <p className="social-proofing__content-heading">8+ years</p>
              <p className="social-proofing__content-subheading">in business</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProofBanner;
