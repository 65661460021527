import React from 'react';

import { Sites } from '@rvpower/constants';

import SeasonalMessagingImage from '../../../images/seasonal-messaging.svg';
import SeasonalMessagingImageCtxp from '../../../images/seasonal-messaging-ctxp.svg';
import SeasonalMessagingImageTxer from '../../../images/seasonal-messaging-txer.svg';
import SeasonalMessagingImageChoose from '../../../images/seasonal-messaging-choose.svg';

function SeasonalMessaging(props) {
  const {
    currentIndex,
    insertionIndex,
    active,
    title,
    description,
    site
  } = props;

  if (!active || currentIndex !== insertionIndex) {
    return null;
  }

  const generateImage = () => {
    switch (site) {
      case Sites.NEW_TXER:
        return SeasonalMessagingImageTxer;
      case Sites.NEW_CTXP:
        return SeasonalMessagingImageCtxp;
      case Sites.CHOOSE:
        return SeasonalMessagingImageChoose;
      default:
        return SeasonalMessagingImage;
    }
  };

  return (
    <div className="seasonal-messaging">
      <div className="seasonal-messaging__inner">
        <div className="seasonal-messaging__content">
          <p className="seasonal-messaging__title">{title}</p>
          <p className="seasonal-messaging__subtitle">
            {description}
          </p>
        </div>
        <div className="seasonal-messaging__image-container">
          <img className="seasonal-messaging__image" src={generateImage()} alt="Seasonal Messaging" />
        </div>
      </div>
    </div>
  );
}

export default SeasonalMessaging;

SeasonalMessaging.defaultProps = {
  title: 'Plan for the season',
  description:
    'We always aim to find you the best energy rate for your needs as the market shifts in the changing season.'
};
