import React from 'react';
import ServiceCard from './ServiceCard';

export default function MaintenanceModeSOE() {
  return (
    <div className="maintenance-soe-hero">
      <div className="maintenance-soe-hero--main-div">
        <div className="maintenance-soe-hero--inner-container">
          <div className="maintenance-soe-hero--background-class" />
          <div className="maintenance-soe-hero--text-container">
            <p className="maintenance-soe-hero--header">
              We&apos;re doing a bit of maintenance to improve your experience.
            </p>
            <p className="maintenance-soe-hero--subtitle">
              Please check back shortly to find a great energy plan.
            </p>
          </div>
        </div>
      </div>
      <div className="maintenance-soe-services">
        <div className="maintenance-soe-services--main-div">
          <div className="maintenance-soe-services--wrapper">
            <ServiceCard
              comment="Calculate your energy usage based on your home needs"
              href="/resources/energy-consumption"
              linkText="Learn how"
              title="Energy usage calculator"
            />
            <ServiceCard
              comment="Your right to compare providers and choose from your electricity options"
              href="/resources/deregulated-energy-guide"
              linkText="Read more"
              title="Deregulated energy"
            />
            <ServiceCard
              comment="Everything you need to know about solar energy"
              href="/solar-energy"
              linkText="Read more"
              title="Solar energy"
            />
          </div>
        </div>
        <div className="soe-services-link-container">
          <a href="/" className="soe-services-link-container--text">
            Go to home
          </a>
        </div>
      </div>
    </div>
  );
}
