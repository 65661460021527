import React from 'react';
import PropTypes from 'prop-types';
import rightArrowIcon from '../../images/icon-right-arrow.svg';
import lightbulbIcon from '../../images/lightbulb-icon.svg';
import calculatorIcon from '../../images/calculator-icon.svg';
import solarIcon from '../../images/solar-icon.svg';

function ServiceCard({
  comment,
  href,
  linkText,
  title
}) {
  const icons = {
    'Energy usage calculator': calculatorIcon,
    'Deregulated energy': lightbulbIcon,
    'Solar energy': solarIcon,
  };

  return (
    <div className="soe-service-card">
      <a
        className="soe-service-card--child-link"
        href={href}
      >
        <div className="soe-service-card--main-div">
          <div className="soe-service-card--image">
            <img
              className="soe-service-card--icon"
              src={icons[title]}
              alt="serviceIcon"
            />
          </div>
          <div className="soe-service-card--content">
            <div className="soe-service-card--text">
              <p className="soe-service-card--title">{title}</p>
              <p className="soe-service-card--comment">{comment}</p>
            </div>
          </div>
          <div className="soe-service-card--anchor">
            <span className="soe-service-card--anchor--text">{linkText}</span>
            <div className="soe-anchor--arrow">
              <img
                src={rightArrowIcon}
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
export default ServiceCard;

ServiceCard.propTypes = {
  comment: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
