import React from 'react';

import ovalDark from '../svg/oval-dark.svg';
import blueString from '../svg/blue-string-pattern-cards.svg';
import dottedCircle from '../svg/dotted-pattern.svg';
import sectionCurve from '../svg/white-curve-footer-thick.svg';

function TXUShapes() {
  return (
    <div className="featured-cards__txu-shapes">
      <img className="svg--blue-string" src={blueString} alt="" />
      <img className="svg--oval-dark" src={ovalDark} alt="" />
      <img className="svg--section-curve" src={sectionCurve} alt="" />
      <div className="column-container">
        <img className="svg--dotted-circle" src={dottedCircle} alt="" />
      </div>
    </div>
  );
}

export default TXUShapes;
