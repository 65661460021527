import React from 'react';

function DisplayPlans(props) {
  const {
    paginationStartIndex,
    paginationIncrement,
    sortedPlans,
    paginationActive,
    paginationToggle,
    isAtBottom
  } = props;

  const planStartIndex = paginationStartIndex + 1;
  const currentPageIndex = Math.floor(paginationStartIndex / paginationIncrement) + 1;
  const planIncrementIndent = paginationIncrement * currentPageIndex;

  const planMaxIndent = planIncrementIndent <= sortedPlans ? planIncrementIndent : sortedPlans;

  const generateDisplayPlans = () => (
    <div>
      Showing plans&nbsp;
      {planStartIndex}
        -
      { planMaxIndent }
        &nbsp;of&nbsp;
      {sortedPlans}
    </div>
  );

  const generateShowFewerPlansButton = () => (
    <>
      <div onClick={paginationToggle} className="pagination-toggle__button">Show fewer plans</div>
    </>
  );

  const classNameValue = isAtBottom ? 'display-plans-container bottom' : 'display-plans-container';

  return (
    <div className={classNameValue}>
      {paginationActive && generateDisplayPlans()}
      {!paginationActive && generateShowFewerPlansButton()}
    </div>
  );
}

export default DisplayPlans;
