import React from 'react';
import { SupplierIds } from '@rvpower/constants';
import tracking from '../../js/project/tagular/tracking';
import PdfIcon from '../../../images/txu-pdf-list.svg';

const paylessDoc = {
  type: 'PDS',
  url: 'https://paylesspower.com/files/PrePaid_Disclosure_Statement_English.pdf'
};

function mapAbbreviations(text) {
  const abbreviations = {
    efl: 'Electricity Facts Label',
    tos: 'Terms Of Service',
    yrac: 'Your Rights As a Customer'
  };

  return text.replace(/\b(efl|tos|yrac)\b/gi, (match) => abbreviations[match.toLowerCase()]);
}

function TxuPDFLink(document, isFeaturedCard) {
  if (!document) {
    return null;
  }

  const { type, url } = document;
  const text = type === 'Terms' ? 'TOS' : type;

  const track = (textData) => {
    tracking.elementClicked({
      webElement: {
        location: isFeaturedCard ? 'FEATURED' : 'GRID',
        elementType: 'Link',
        text: textData
      },
      actionOutcome: 'Plan docs opened'
    });
  };

  return (
    <li key={type}>
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => track(text.toUpperCase())}>
        {`${mapAbbreviations(text)} (${text.toUpperCase()})`}
        {' '}
        <img src={PdfIcon} alt="doc" />
      </a>
    </li>
  );
}

export default function TxuPDFLinks({ plan, isFeaturedCard }) {
  const {
    EFLDoc, TermsDoc, YRACDoc, supplier
  } = plan;
  const { id: supplierId } = supplier;

  const docs = [EFLDoc, TermsDoc, YRACDoc, supplierId === SupplierIds.Payless ? paylessDoc : null];
  return docs.map((doc) => TxuPDFLink(doc, isFeaturedCard));
}

TxuPDFLinks.defaultProps = {
  isFeaturedCard: false
};
