import React from 'react';
import PropTypes from 'prop-types';
import errorCircle from '../../images/error-circle.svg';

export default function CustomTooltip(props) {
  // We gather the necessary props from the parent component
  const {
    centered,
    config:
    {
      text, visible, elementId
    },
    id
  } = props;

  // We check if the tooltip should be visible and if the elementId matches the tooltip id
  if (!visible || elementId !== id) {
    return null;
  }

  return (
    <div
      className={`custom-tooltip ${centered ? 'custom-tooltip--centered' : ''}`}
    >
      <img src={errorCircle} alt="errorCircle" />
      <span className="custom-tooltip-text">{text}</span>
    </div>
  );
}

CustomTooltip.propTypes = {
  centered: PropTypes.bool,
  config: PropTypes.symbol.isRequired,
  id: PropTypes.string.isRequired
};

CustomTooltip.defaultProps = {
  centered: false
};
