/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Sites } from '@rvpower/constants';
import tracking from '../../js/project/tagular/tracking';

import getPerMonthVerbage from '../../js/project/termination-fee';
import CardDetailsTable from '../grid-v2/CardDetailsTable';
import BadgeBox from '../grid-v2/BadgeBox';

import close from '../../svg/close-black-x.svg';
import openIcon from '../../svg/open.svg';

function PlanDetails({
  desktop = false,
  site,
  fuseData,
  activeDropdown,
  carouselButton,
  plan,
  handleDropdownToggle
}) {
  const { permalease } = fuseData;
  const isTXU = site === Sites.TXU;

  // Plan badges
  let satisfactionBadge = false;
  let renewableBadge = false;
  let betterBusinessBadge = false;

  if (plan) {
    plan.badges.forEach((badge) => {
      if (badge.name.includes('satisfaction')) satisfactionBadge = badge;
      else if (badge.name.includes('renewable')) renewableBadge = badge;
      else if (badge.name.includes('bbb')) betterBusinessBadge = badge;
    });
  }

  plan.fee = plan.fees?.find((fee) => fee.type === 'EarlyTerminationFee');

  const perMonthVerbage = getPerMonthVerbage(plan.fee?.monthly);
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderTabs = ['Plan details', 'Price breakdown', 'Plan documents', 'About the provider'];

  // Used for arrow placement above the plan details section
  const arrowStyles = () => {
    if (desktop) {
      switch (activeDropdown) {
        case 0:
          return 'plan-details--15';
        case 1:
          return 'plan-details--50';
        case 2:
          return 'plan-details--85';
        default:
          return '';
      }
    }

    if (carouselButton === 0) {
      return activeDropdown === 0
        ? 'plan-details--25'
        : 'plan-details--75';
    }

    return activeDropdown === 1
      ? 'plan-details--25'
      : 'plan-details--75';
  };

  const handleSliderClick = (i) => {
    tracking.elementClicked({
      actionOutcome: 'Expanded details',
      webElement: {
        location: 'ROG',
        elementType: 'Button',
        text: sliderTabs[i],
      }
    });

    setActiveSlide(i);
  };

  const trackDocumentClicked = (docType) => {
    tracking.elementClicked({
      actionOutcome: 'Open plan docs',
      webElement: {
        location: 'Plan Documents',
        elementType: 'Hyperlink',
        text: docType,
      }
    });
  };

  const trackElementClicked = () => {
    tracking.elementClicked({
      actionOutcome: 'Call',
      webElement: {
        location: 'ROG',
        elementType: 'Phone CTA',
        text: 'Phone Number'
      },
    });
  };

  useEffect(() => {
    if (window.fuse) {
      window.fuse('placeNumbers');
    }
  }, []);

  return (
    <div className={`plan-details ${arrowStyles()}`}>
      <div className="plan-details__details-header">
        <button
          className="plan-details__details-close"
          type="button"
          onClick={() => handleDropdownToggle(null)}
        >
          <img src={close} alt="Close" />
        </button>
        <p className="plan-details__details-title">{plan.name}</p>
        <p className="plan-details__details-subtitle">{plan.supplier.name}</p>
      </div>
      <div className="plan-details__slider">
        {sliderTabs.map((item, i) => (
          <button
            onClick={() => handleSliderClick(i)}
            type="button"
            key={item}
            className={`plan-details__slider-tab${activeSlide === i ? ' active' : ''}`}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="plan-details__content">
        { activeSlide === 0 && (
          <>
            <p className="plan-details__description">{plan.description}</p>
            <div className="plan-details__first-page-badges">
              { satisfactionBadge && (
                <BadgeBox type="satisfaction" plan={plan} badge={satisfactionBadge} site={site} />
              )}
              { renewableBadge && (
                <BadgeBox type="renewable" plan={plan} badge={renewableBadge} site={site} />
              )}
            </div>
          </>
        )}
        { activeSlide === 1 && (
          <>
            <p className="plan-details__content-title">Understanding your usage</p>
            <p className="plan-details__content-subtitle">based on your home type</p>
            <div className="plan-details__content-container">
              <div className="plan-details__content-table">
                <CardDetailsTable
                  plan={plan}
                  electricPhoenixActive={false}
                />
              </div>
              <div className="plan-details__legal-container">
                {plan.fee && (
                  <div className="plan-details__fee">
                    <div className="plan-details__fee-amount">
                      {`Early termination fee${perMonthVerbage}: `}
                      <strong>{`$${plan.fee.amount}`}</strong>
                    </div>
                    <div className="plan-details__fee-note">
                      (Note: This fee will
                      <strong> not be charged </strong>
                      if you end your contract early because you are moving out.)
                    </div>
                  </div>
                )}
                {!isTXU && (
                  <p className="plan-details__content-legal">
                    *Includes recurring charges excluding government fees and taxes.
                    Confirm this estimate with Plan documents. Bills vary according to actual usage.
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        { activeSlide === 2 && (
          <div className="plan-details__content-container-trio">
            <div className="plan-details__document">
              <p className="plan-details__document-label">Electricity Facts Label (EFL)</p>
              <p className="plan-details__document-content">
                An EFL breaks down plan info in more detail to understand a plan’s energy rate, fees, terms
                and conditions and more.
              </p>
              <a
                onClick={() => trackDocumentClicked('EFL')}
                className="plan-details__document-cta"
                href={plan?.documents?.find((doc) => doc.type === 'Efl')?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Access the EFL
                <img src={openIcon} alt="Open Icon" />
              </a>
            </div>
            <div className="plan-details__document">
              <p className="plan-details__document-label">Terms of Service (TOS)</p>
              <p className="plan-details__document-content">
                The TOS explains the terms and conditions of your electric service with the provider.
              </p>
              <a
                onClick={() => trackDocumentClicked('TOS')}
                className="plan-details__document-cta"
                href={plan?.documents?.find((doc) => doc.type === 'Terms')?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Access the TOS
                <img src={openIcon} alt="Open Icon" />
              </a>
            </div>
            <div className="plan-details__document">
              <p className="plan-details__document-label">Your Rights As a Consumer (YRAC)</p>
              <p className="plan-details__document-content">
                YRAC protects your rights by detailing all the info you should know about dealing with the provider.
              </p>
              <a
                onClick={() => trackDocumentClicked('YRAC')}
                className="plan-details__document-cta"
                href={plan?.documents?.find((doc) => doc.type === 'Yrac')?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Access the YRAC
                <img src={openIcon} alt="Open Icon" />
              </a>
            </div>
          </div>
        )}
        { activeSlide === 3 && (
          <div
            className={
              betterBusinessBadge
                ? 'plan-details__content-container-trio'
                : 'plan-details__content-container-double'
            }
          >
            <div>
              <p className="plan-details__content-know-title">What to know</p>
              <p className="plan-details__content-subtitle">
                {plan?.supplier?.whatToKnow || 'This provider has great energy plans at competitive rates.'}
              </p>
            </div>
            <BadgeBox type="rating" plan={plan} site={site} />
            { betterBusinessBadge && (
              <BadgeBox type="bbb" plan={plan} badge={betterBusinessBadge} site={site} />
            )}
          </div>
        )}
      </div>
      <div className="plan-details__mobile-ctas">
        <a
          onClick={trackElementClicked}
          className="plans-widget-card__cta plans-widget-card__cta--phone"
          href={`tel:${permalease}`}
          data-fuse
          data-fuse-format="Call Now"
          data-fuse-name="ES-CTXP-FUSE-PERMA-ROG"
        >
          Call Now
        </a>
        <button type="button" className="plans-widget-card__cta plans-widget-card__cta--cart">
          Check Availability
        </button>
      </div>
    </div>
  );
}

export default PlanDetails;
