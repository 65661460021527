/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import FeaturedCardLabel from '../../grid-v2/FeaturedCardLabel';
import NTXCardBadges from '../sub-components/NTXCardBadges';

import buildClassList from '../../../js/project/buildClassList';

function NTXFeaturedCard({
  plan,
  onOrderOnline,
  handleDetailsToggle,
  disableCartLinks,
  detailsOpen,
  selected,
  index,
  ntxP2CSavingsAcrossPrice,
}) {
  const ctaURL = plan.cartLink;
  const { name } = plan;
  const detailsAreOpened = detailsOpen && selected;

  const unitOfMeasure = Object.keys(plan.unitOfMeasure).length ? plan.unitOfMeasure.description : plan.unitOfMeasure;

  const handleOpenDetails = () => {
    // Lock mobile viewport to prevent background from scrolling
    if (detailsAreOpened) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');

    handleDetailsToggle(plan, !detailsOpen);
  };

  const { price } = plan.rates.find((rate) => rate.type === 'EnergyCharge');

  const planTermText = plan.termText.replace(/Months/g, 'mos.').replace(/Month to Month/g, 'Monthly');
  const planPricePerUnit = price ? (price * 100).toFixed(2) : '0';

  let planUnit = unitOfMeasure?.toUpperCase();
  if (planUnit?.includes('THM')) planUnit = 'THERM';
  if (planUnit?.length === 0 || planUnit === null) planUnit = 'KWH';

  const planType = plan.type.toUpperCase();
  const supplierPhone = plan.supplier.phone;
  const FCBaseClass = 'featured-plan-card-v2-vertical';
  const articleClass = buildClassList(
    FCBaseClass,
    'ntx-featured-card'
  );

  // ensure all phone numbers are formatted like 1-888-888-8888
  // assumes all phone numbers will come back either as 888-888-8888 or 1-888-888-8888
  const formatSupplierPhone = (num) => {
    const firstTwoNumbers = num.substring(0, 2);

    if (firstTwoNumbers !== '1-') {
      const newSupplierNumber = `1-${num}`;
      return newSupplierNumber;
    }
    return num;
  };

  const showCartCTA = plan.cartActive && !disableCartLinks && !plan?.supplier?.controls?.disableCart;
  const showPhoneCTA = plan?.supplier?.phone?.length > 0;

  // Filter out supplier and plan badges
  const supplierBadges = plan.badges.filter((badge) => {
    if (badge.name.includes('gas') || badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const planBadgesFiltered = plan.badges.filter((badge) => {
    if (!badge.name.includes('gas') && !badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const hasBadges = !!plan.badges.length;

  return (
    <article className={articleClass}>
      <FeaturedCardLabel isNTX plan={plan} index={index} />
      <div className={`${FCBaseClass}__top-split`}>
        <div className={`${FCBaseClass}__top-medium`}>
          <div className={`${FCBaseClass}__top-section`}>
            <div className={`${FCBaseClass}-ntx__top-container`}>
              <div className={`${FCBaseClass}__title-container`}>
                <div className={`${FCBaseClass}__title-row ntx`}>
                  {/* Title */}
                  <div
                    className={`${FCBaseClass}__title ntx ${
                      ntxP2CSavingsAcrossPrice ? 'ntx--title-adjustment' : ''
                    }`}
                  >
                    {name}
                  </div>
                  {/* Supplier Badge */}
                  <div>
                    <NTXCardBadges
                      badges={hasBadges ? supplierBadges : null}
                      supplierBadges
                    />
                  </div>
                </div>
                {/* Logo & Rating */}
                <div className={`${FCBaseClass}__provider-logo-ntx`}>
                  <img
                    className={`${FCBaseClass}__logo`}
                    src={plan.supplier.logo}
                    alt={plan.supplier.name}
                  />
                </div>
              </div>
              <div className={`${FCBaseClass}__price-badge-container`}>
                {/* Price per Unit & Term Length */}
                <div className={`${FCBaseClass}__price-container`}>
                  <div className={`${FCBaseClass}__price-kwh`}>
                    <p className={`${FCBaseClass}__price-value-ntx`}>
                      {`${planPricePerUnit}¢`}
                    </p>
                    <p className={`${FCBaseClass}__price-label ntx`}>
                      Price per
                    </p>
                    <p className={`${FCBaseClass}__price-label-secondary ntx`}>
                      {planUnit}
                    </p>
                  </div>
                  <div className={`${FCBaseClass}__term-length`}>
                    <p className={`${FCBaseClass}__price-value-ntx`}>
                      {planTermText}
                    </p>
                    <p className={`${FCBaseClass}__price-label ntx`}>
                      Term length
                    </p>
                    <p className={`${FCBaseClass}__price-label-secondary ntx`}>
                      {planType}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${FCBaseClass}__cta-container ntx`}>
            {/* Savings Across Price Featured Card */}
            {ntxP2CSavingsAcrossPrice
              && plan.priceToComparePercentage !== null
              && plan.priceToComparePercentage !== 0 && (
                <div
                  className={`${FCBaseClass}__p2c-sac-fc-banner`}
                >
                  <span className="percentage">
                    {plan.priceToComparePercentage}
%
                  </span>
                  {' '}
                  <span className="savings-text">est. savings**</span>
                </div>
            )}

            <div className={`${FCBaseClass}__cta-container-inner--ntx`}>
              <a
                className={`${FCBaseClass}__cta ${FCBaseClass}__cta--ntx ${!showPhoneCTA
                  && 'ntx-center-cartCTA'}`}
                style={!showCartCTA ? { visibility: 'hidden' } : {}}
                href={ctaURL}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                onClick={(e) => onOrderOnline(e, ctaURL, plan)}
              >
                Check availability
              </a>
              <div
                className="button--phone u-display-flex@lg u-justify-content-center@lg"
              >
                <a
                  className={`${FCBaseClass}__cta ${FCBaseClass}__cta--phone-ntx ${FCBaseClass}__cta--ntx phone--with-icon ${!showCartCTA
                    && 'ntx-center-fuse'}`}
                  style={!showPhoneCTA ? { visibility: 'hidden' } : {}}
                  id="ntx-phone-cta"
                  href={`tel:${formatSupplierPhone(supplierPhone)}`}
                >
                  <span className="ntx-phone-number-cta">
                    {formatSupplierPhone(supplierPhone)}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* Plan Badges */}
          <div className={`${FCBaseClass}__ntx-plan-badge-container`}>
            <NTXCardBadges badges={planBadgesFiltered} planType={planType} />
          </div>
          {/* Details trigger */}
          <div
            className={`plan-card-v2__details-trigger-container${
              detailsAreOpened
                ? ''
                : ' plan-card-v2__details-trigger-container--borderless'
            }`}
          >
            <button
              className={`${FCBaseClass}__details-trigger-ntx ${
                ntxP2CSavingsAcrossPrice
                  ? `${FCBaseClass}__details-trigger-ntx--p2c-trigger-adjustment`
                  : ''
              }`}
              type="button"
              onClick={() => handleOpenDetails()}
            >
              <span className={`${FCBaseClass}__details-trigger-copy`}>
                More details
              </span>
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

NTXFeaturedCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
};

export default NTXFeaturedCard;
