import React, { useEffect, useState } from 'react';
import { Sites } from '@rvpower/constants';

import tracking from '../../js/project/tagular/tracking';

import iconTransparentPricing from '../../../images/icon-transparent-pricing.svg';
import iconQuickEasyCheckout from '../../../images/icon-quick-easy-checkout.svg';
import iconUniversalCreditCheck from '../../../images/icon-universal-credit-check.svg';
import iconQualityCustomerSupport from '../../../images/icon-quality-customer-support.svg';
import iconTransparentPricingNoBack from '../../../images/icon-transparent-pricing-no-back.svg';
import iconQuickEasyCheckoutNoBack from '../../../images/icon-quick-easy-checkout-no-back.svg';
import iconUniversalCreditCheckNoBack from '../../../images/icon-universal-credit-check-no-back.svg';
import iconQualityCustomerSupportNoBack from '../../../images/icon-quality-customer-support-no-back.svg';
import iconTrophy from '../../../images/icon-trophy.svg';
import iconUsers from '../../../images/icon-users.svg';
import trustpilotGreatStars from '../../../images/trustpilot/4-star.svg';

function ProductDetailSectionWhyShop(props) {
  const { site, noBackgroundImages = false } = props;

  const [reviewData, setReviewData] = useState({ numberOfReviews: { total: 2622 } });

  const footerContent = [
    {
      site: Sites.NEW_CTXP,
      apiTrustSufix: 'choosetexaspower.org',
      siteName: 'Choose Texas Power',
      experience: '8+ years of experience',
      customers: '1 million+ customers helped',
      reviewLink: 'https://www.trustpilot.com/review/choosetexaspower.org',
      rated: 'great'
    },
    {
      site: Sites.SOE,
      apiTrustSufix: 'saveonenergy.com',
      siteName: 'SaveOnEnergy',
      experience: '18+ years of experience',
      customers: '1 million+ customers helped',
      reviewLink: 'https://www.trustpilot.com/review/saveonenergy.com',
      rated: 'great'
    }
  ];

  const content = [
    {
      title: 'Transparent pricing',
      subtitle: 'We’re clear and upfront about all charges associated with plans.',
      icon: noBackgroundImages ? iconTransparentPricingNoBack : iconTransparentPricing,
    },
    {
      title: 'Quick & easy checkout',
      subtitle: 'Secure your energy plan in a matter of minutes.',
      icon: noBackgroundImages ? iconQuickEasyCheckoutNoBack : iconQuickEasyCheckout,
    },
    {
      title: 'Universal credit check',
      subtitle: 'We run one seamless credit check to prequalify you for our partners.',
      icon: noBackgroundImages ? iconUniversalCreditCheckNoBack : iconUniversalCreditCheck,
    },
    {
      title: 'Quality customer support',
      subtitle: 'Questions about providers? Our team of experts has the answers.',
      icon: noBackgroundImages ? iconQualityCustomerSupportNoBack : iconQualityCustomerSupport,
    },
  ];

  const {
    apiTrustSufix,
    siteName,
    experience,
    customers,
    reviewLink,
    rated
  } = (function () {
    return footerContent?.find((name) => name.site === site) || {};
  }());

  const handleClickReviews = () => {
    tracking.elementClicked({
      webElement: {
        location: 'Redirect link',
        elementType: 'Trustpilot',
        text: 'Trustpilot link'
      },
      actionOutcome: 'Opens Trustpilot page'
    });
  };

  useEffect(() => {
    // Used to prevent memory leak error from async request
    let isSubscribed = true;

    /** Hit api to get review counts from trustpilot */
    const getReviewData = async () => {
      const response = await fetch(
        `https://api.development.saveonenergy.cloud/trustpilot/${apiTrustSufix}`,
      );

      const data = await response.json();

      if (data && isSubscribed) {
        setReviewData(data);
      }
    };

    getReviewData();

    // eslint-disable-next-line
    return () => (isSubscribed = false)
  }, []);

  return (
    <div className="pdp-section-why-shop">
      <div className="pdp-section-why-shop__inner">
        <p className="pdp-section-why-shop__title">{`Why shop with ${siteName}`}</p>
        <div className="pdp-section-why-shop__boxes">
          {
            content.map((box) => (
              <div className="pdp-section-why-shop__box" key={box.title}>
                <img className="pdp-section-why-shop__box-icon" src={box.icon} alt={box.title} />
                <div className="pdp-section-why-shop-box-content">
                  <p className="pdp-section-why-shop__box-title">{box.title}</p>
                  <p className="pdp-section-why-shop__box-subtitle">{box.subtitle}</p>
                </div>
              </div>
            ))
          }
        </div>
        <div className="pdp-section-why-shop__trustpilot">
          <div className="pdp-section-why-shop__trustpilot-header">
            <div className="pdp-section-why-shop__trustpilot-top">
              <span>
                Rated&nbsp;
                {rated}
              </span>
              <img
                className="pdp-section-why-shop__trustpilot-image"
                alt="Star rating"
                src={trustpilotGreatStars}
              />
            </div>
            <span className="pdp-section-why-shop__trustpilot-middle">
              Based on
              <a
                href={reviewLink}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleClickReviews}
              >
                {`${reviewData.numberOfReviews.total.toLocaleString()} reviews`}
              </a>
            </span>
          </div>
          <div className="pdp-section-why-shop__trustpilot-body">
            <p className="pdp-section-why-shop__trustpilot-copy">
              <img
                alt="Trophy icon"
                src={iconTrophy}
              />
              {experience}
            </p>
            <p className="pdp-section-why-shop__trustpilot-copy">
              <img
                alt="Users icon"
                src={iconUsers}
              />
              {customers}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailSectionWhyShop;
