/* eslint-disable max-len */
import React from 'react';
import {
  Sites
} from '@rvpower/constants';

// React Components
import UsageNewKwh from './kwh-calculator/UsageKwh';
import Usage from './Usage';
import CheckboxesSections from './CheckboxesSections';
import UtilityDropdown from './UtilityDropdown';

function Filter(props) {
  const {
    hideUsage,
    isOpen,
    originalFilters,
    scrollToTopOfList,
    setFilters,
    setIsOpen,
    setNewAmount,
    setSelectedUtility,
    site,
    utilities,
    utility,
    isNTX
  } = props;

  const resetFilters = () => setFilters(originalFilters);

  const closeFilterModal = () => {
    setIsOpen(!isOpen);
    document.body.classList.remove('no-scroll');
  };

  const showKwh = site !== Sites.TXU;

  return (
    <div className={`filter ${showKwh ? 'filter--kwh' : ''} ${isNTX ? 'filter--ntx' : ''}`}>
      <strong className="filter__label u-display-none u-display-block@xl">Filter</strong>
      { isNTX && (
        <UtilityDropdown
          setSelectedUtility={setSelectedUtility}
          utilities={utilities}
          utility={utility}
        />
      )}
      {/* eslint-disable react/jsx-props-no-spreading */}
      {!hideUsage && showKwh && (
        <UsageNewKwh setNewAmount={setNewAmount} device="desktop" scrollToTopOfList={scrollToTopOfList} {...props} />
      )}
      {!showKwh && (
        <Usage scrollToTopOfList={scrollToTopOfList} {...props} />
      )}
      <CheckboxesSections scrollToTopOfList={scrollToTopOfList} {...props} />
      <button
        type="submit"
        className={`button--filter-reset u-display-none u-display-block@xl ${hideUsage && 'kwh-hidden'}`}
        onClick={() => {
          resetFilters();
        }}
      >
        <span>Reset</span>
      </button>
      <div className="filter__bottom u-display-none@xl">
        <button
          type="submit"
          className={`button--filter-reset ${hideUsage && 'kwh-hidden'}`}
          onClick={() => {
            resetFilters();
            closeFilterModal();
          }}
        >
          <span>Reset</span>
        </button>
        <button
          type="submit"
          className="button button__apply-filter"
          onClick={() => {
            closeFilterModal();
          }}
        >
          <span>Apply Filters</span>
        </button>
      </div>
    </div>
  );
}

export default Filter;
