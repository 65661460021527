const text = [
  {
    title: 'How do you get paid?',
    copy: `<p class="accordion-item__copy">This service is free for you to use. We are paid by our providers when you begin service. For most plans, we also receive a monthly payment from providers for customers who stay in the plan. That means it’s important to us to make sure we offer plans that work well for your household.</p>` //eslint-disable-line
  },
  {
    title: 'How soon can I start service?',
    copy: `<p class="accordion-item__copy">Many customers can begin service the same day they sign up for a plan. However, switching providers can take up to five business days.</p>` //eslint-disable-line
  },
  {
    title: 'Can I cancel after I sign up?',
    copy: `<p class="accordion-item__copy">If you’re switching providers, you have three federal business days in which you can cancel your new service without a fee. If you’re moving, you are not subject to an early termination fee – just provide proof of your move such as a forwarding address.</p>` //eslint-disable-line
  },
  {
    title: 'What happens if I move?',
    copy: `<p class="accordion-item__copy">No worries! If you move, you can take your plan with you if it’s available at your new location. Or, you can cancel your plan without an early termination fee – the choice is yours.</p>` //eslint-disable-line
  },
  {
    title: 'Do I need to cancel my current provider when I switch?',
    copy: `<p class="accordion-item__copy">Nope!  When you switch providers, everything is handled for you by your new provider with no lapse in service.  All you’ll need to do for your former provider is pay your final bill when it arrives.</p>` //eslint-disable-line
  },
];

export default text;
