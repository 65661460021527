import React, { useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';

function VisibleTracker(props) {
  const {
    children, className, id, onVisible = () => { }, registerIsVisibileCheck = () => { }
  } = props;
  const inputEl = useRef(null);
  const fired = useRef(false);

  useEffect(() => {
    const isVisible = throttle(() => {
      if (inputEl && inputEl.current) {
        const { bottom, right } = inputEl.current.getBoundingClientRect();
        if (bottom <= window.innerHeight && right <= window.innerWidth && !fired.current) {
          onVisible();
          fired.current = true;
        }
      }
    }, 500);

    setTimeout(isVisible, 1000);
    window.addEventListener('scroll', isVisible);
    window.addEventListener('resize', isVisible);

    registerIsVisibileCheck(isVisible);

    return () => {
      // clean up spot
      // i.e. remove event listener
      window.removeEventListener('scroll', isVisible);
      window.removeEventListener('resize', isVisible);
    };
  }, [onVisible, registerIsVisibileCheck]);

  return (
    <div ref={inputEl} className={className} id={id}>
      {children}
    </div>
  );
}

export default VisibleTracker;
