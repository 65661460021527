import { Sites } from '@rvpower/constants';

const phoneBannerPlacements = {
  [Sites.SOE]: {
    getHelp: 'Phone Banner Get Help',
    talk: 'Phone Banner Talk Through',
    questions: 'Phone Banner Questions'
  },
  [Sites.CTXP]: {
    getHelp: 'Phone Banner Get Help',
    talk: 'Phone Banner Talk Through',
    questions: 'Phone Banner Questions'
  },
  [Sites.TXER]: {
    getHelp: 'Phone Banner Get Help',
    talk: 'Phone Banner Talk Through',
    questions: 'Phone Banner Questions'
  },
  [Sites.CHOOSE]: {
    getHelp: 'Phone Banner Get Help',
    talk: 'Phone Banner Talk Through',
    questions: 'Phone Banner Questions'
  },
  default: {
    getHelp: 'Phone Banner Get Help',
    talk: 'Phone Banner Talk Through',
    questions: 'Phone Banner Questions'
  }
};

export default phoneBannerPlacements;
