import React from 'react';

import logError from '../js/project/log-error';

export default function Error(props) {
  const { error, fuseData } = props;
  logError(error);
  return (
    <>
      <span>Error loading plans. Please try again or contact us at </span>
      <a
        href={`tel:${fuseData.permalease}`}
        data-fuse
        data-fuse-format="###-###-####"
        data-fuse-name={fuseData.dataFuseName}
      >
        {fuseData.permalease}
      </a>
    </>
  );
}
