import React, { useState, useRef } from 'react';

import IconEmailCapture from '../../../images/icon-email-capture.svg';
import IconToggleArrow from '../../../images/icon-toggle-arrow.svg';
import IconErrorYield from '../../../images/icon-yield.svg';


function EmailCapture() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [inputInteracted, setInputInteracted] = useState(false);
  const showError = inputInteracted && !emailIsValid;
  const emailAddressInput = useRef(null);

  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleChange = (event) => {
    const emailAddress = event.target.value;
    const valid = emailRegex.test(emailAddress);
    setEmailIsValid(valid);
    setInputInteracted(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailAddress = emailAddressInput?.current?.value;

    if (emailIsValid) {
      setIsSubmitted(true);

      if (window.tagular) {
        window.tagular('beam', 'FormSubmitted', {
          '@type': 'redventures.usertracking.v3.FormSubmitted',
          field: {
            fieldType: 'Grid Email Capture',
            fieldName: 'Email Address',
            fieldValue: emailAddress,
          }
        });
      }
    } else {
      setEmailIsValid(false);
      setInputInteracted(true);
    }
  };

  return (
    <div className="email-capture">
      <div className="email-capture__header" onClick={() => setIsOpen(!isOpen)}>
        <div className="email-capture__header-inner">
          <img
            className="email-capture__header-icon"
            src={IconEmailCapture}
            alt="Email capture icon"
          />
          <p className="email-capture__header-title">Stay up-to-date on the changing market</p>
        </div>
        <button
          className={`email-capture__toggle${isOpen ? ' email-capture__toggle--is-active' : ''}`}
          type="button"
        >
          <img className="email-capture__toggle-icon" src={IconToggleArrow} alt="Toggle Email Capture" />
          Toggle
        </button>
      </div>
      {isOpen && !isSubmitted && (
        <div className="email-capture__content">
          <p className="email-capture__copy">
            Get price alert updates and learn why energy rates are rising and how to plan for them.
          </p>
          <p className="email-capture__copy">
            Don’t worry — we won’t overload your inbox with emails.
          </p>
          <form className="email-capture__input-container">
            <input
              className={`email-capture__input${showError ? ' email-capture__input--error' : ''}`}
              type="email"
              placeholder="Enter your email address"
              ref={emailAddressInput}
              onChange={(e) => handleChange(e)}
            />
            <button
              className="email-capture__submit"
              type="submit"
              disabled={showError}
              onClick={(e) => handleSubmit(e)}
            >
              Subscribe
            </button>
          </form>
          {showError && (
            <span className="email-capture__error-text">
              <img
                className="email-capture__error-icon"
                src={IconErrorYield}
                alt="Email Validation Error"
              />
              Please enter a valid email
            </span>
          )}
          <p className="email-capture__legal">
            By subscribing, you agree to receive SaveOnEnergy promotional emails. Your&nbsp;
            <a
              href="https://www.saveonenergy.com/privacy/"
              rel="noopener noreferrer"
              target="_blank"
              className="email-capture__link"
            >
              privacy
            </a>
            &nbsp;is important to us.
          </p>
        </div>
      )}
      {isOpen && isSubmitted && (
        <div className="email-capture__content">
          <p className="email-capture__copy">
          Thanks for subscribing to SaveOnEnergy emails.
          </p>
        </div>
      )}
    </div>
  );
}

export default EmailCapture;
