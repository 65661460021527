/* eslint-disable max-len */
import React, { useState } from 'react';
import { Sites } from '@rvpower/constants';
import accordionContent from '../../js/project/faqs-accordion-text';
import accordionTxuContent from '../../js/project/faqs-txu-accordion-text';
import accordionPDPContent from '../../js/project/faqs-pdp-accordion-text';
import { CustomFuseText } from '../FuseNumbers';
import tracking from '../../js/project/tagular/tracking';

import chevronUp from '../../svg/chevron-up-white.svg';
import chevronDown from '../../svg/chevron-down-white.svg';

const Item = ({
  title, copy, usesFuse, onClick, index, isOpen, pdpTestActive, isOnPDP, useChevronItem
}) => {
  const handleOnClick = (selectedIndex) => {
    tracking.elementClicked({
      webElement: {
        location: pdpTestActive && isOnPDP ? 'PDP' : 'GRID',
        elementType: 'Button',
        text: title,
        position: `FAQ ${index + 1}`
      },
      actionOutcome: isOpen ? 'COLLAPSE' : 'EXPAND'
    });

    if (onClick) {
      onClick(selectedIndex);
    }
  };

  const height = isOpen ? 'auto' : 0;
  const className = isOpen ? 'accordion-item--wrapper is-active' : 'accordion-item--wrapper';

  return (
    <div
      className="accordion-item"
      key={title}
    >
      <div
        onClick={() => handleOnClick(index)}
        className="accordion-item__title"
        aria-label="Toggle item"
        onKeyPress={() => handleOnClick(index)}
        role="button"
        tabIndex="0"
      >
        <span className="accordion-title">
          {title}
        </span>
        { useChevronItem ? (
          <div
            className="accordion-item__chevron"
            role="button"
            aria-label="accordion toggle button"
          >
            <img src={chevronUp} alt="chevron up" className={`accordion-item__chevron-up ${isOpen ? 'is-active' : ''}`} />
            <img src={chevronDown} alt="chevron down" className={`accordion-item__chevron-down ${!isOpen ? 'is-active' : ''}`} />
          </div>
        ) : (
          <div
            className={`accordion-item__cross ${isOpen ? 'is-active' : ''}`}
            role="button"
            aria-label="accordion toggle button"
          >
            <span />
            <span />
          </div>
        )}
      </div>
      { usesFuse ? (
        <div style={{ height }} className={className}>
          <p className="accordion-item__copy">
            On average, Texans use about 1100 kWh each month, but you may use more or less depending on the size and energy efficiency of your home, as well as your lifestyle.
            <sup>4</sup>
            {' '}
            You’re also likely to see higher usage in the summer and winter months, when your A/C and heater are working harder. You can always
            {' '}
            <CustomFuseText text="call us" />
            {' '}
            to get expert advice and help choosing a plan that will fit you and your home best.
          </p>
        </div>
      ) : (
        <div
          style={{ height }}
          className={className}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      ) }
    </div>
  );
};

function Accordion(props) {
  const {
    site, pdpTestActive = false, isOnPDP = false, useChevronItem = false
  } = props;
  const isTxu = site === Sites.TXU;
  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleClick = (index) => {
    setCurrentIndex((currentValue) => (currentValue !== index ? index : -1));
  };

  const faqItems = () => {
    if (pdpTestActive) return accordionPDPContent;
    return isTxu ? accordionTxuContent.faqItems : accordionContent;
  };

  const accordionContentSelector = faqItems() ? faqItems().map((data, index) => Item({
    ...data, index, isOpen: index === currentIndex, onClick: handleClick, pdpTestActive, isOnPDP, useChevronItem
  })) : [];

  const getTitle = (currentSite) => {
    if (pdpTestActive) return 'Frequently Asked Questions';
    return [Sites.TXU, Sites.SOE, Sites.CHOOSE].includes(currentSite) ? 'FAQs' : 'FAQ\'s';
  };

  return (
    <div
      id="accordion"
      className={isTxu ? 'accordion accordion--txu txu-redesign' : 'accordion'}
    >
      <span id="accordion__anchor" className="accordion__anchor" />
      <h3 className="accordion__title">{getTitle(site)}</h3>
      <div className="accordion__item-container">
        {accordionContentSelector}
      </div>
    </div>
  );
}

export default Accordion;
