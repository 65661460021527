/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePreamp } from '../../preamp';
import { useMonarch } from '../../monarch';
import tracking from '../../../js/project/tagular/tracking';
import Ratings from '../../Ratings';
import FuseNumbers from '../../FuseNumbers';
import PlanDetails from '../../grid-v2/PlanDetails';
import CardBadges from '../../grid-v2/CardBadges';

import close from '../../../svg/close.svg';
import arrowDetail from '../../../svg/detail-arrow-down.svg';
import HmcRecommendBanner from '../../help-me-choose/HmcRecommendBanner';

function CardV2({
  plan,
  site,
  disableCartLinks,
  onOrderOnline,
  planIndex,
  hmcData,
  hasDefaultFilters,
  sort,
}) {
  const { name } = plan;

  const [detailsOpen, setDetailsOpen] = useState(false);
  let satisfactionBadge = false;
  let renewableBadge = false;
  let betterBusinessBadge = false;

  if (plan.badges) {
    plan.badges.forEach((badge) => {
      if (badge.name.includes('satisfaction')) satisfactionBadge = badge;
      else if (badge.name.includes('renewable')) renewableBadge = badge;
      else if (badge.name.includes('bbb')) betterBusinessBadge = badge;
    });
  }

  const planTermText = plan.termText.replace(/Months/g, 'mos.').replace(/Month to Month/g, 'Monthly');
  const planMonthlyBillEstimate = Math.round(plan.price * plan.usage) || '0';
  const planPricePerKwh = (plan.price * 100) ? (plan.price * 100).toFixed(1) : '0';
  const showCartCTA = plan.cartActive && !disableCartLinks && !plan?.supplier?.controls?.disableCart;
  const { ctaText } = usePreamp('Cart - CTA', true) || '';
  const ctaSwap = usePreamp('CTA-Phone Swap');
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;
  const { cartUrl } = plan;
  const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;

  const handleOpenDetails = () => {
    setDetailsOpen(!detailsOpen);

    // Lock mobile viewport to prevent background from scrolling
    if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'MORE DETAILS'
      },
      actionOutcome: `Plan details ${detailsOpen ? 'closed' : 'expanded'}`
    });
  };

  const handleCartClick = (e, targetUrl, targetPlan) => {
    e.preventDefault();

    const esiid = localStorage.getItem('esiid');
    const url = esiid ? `${targetUrl}&esiid=${esiid}` : targetUrl;

    onOrderOnline(e, url, targetPlan);
  };

  const hmcToggle = planIndex === 0
    && hmcData !== null
    && hmcData?.estimatedUsage !== null
    && !hasDefaultFilters
    && sort === 'rate-low';

  return (
    <article className={`plan-card-v2 ${hmcToggle ? 'plan-card-v2--hmc' : ''}`}>
      {hmcToggle && (
        <HmcRecommendBanner />
      )}
      <div className="plan-card-v2__top-split">
        <div className="plan-card-v2__top-medium">
          <div className="plan-card-v2__top-section">
            <div className="plan-card-v2__top-container">
              <div className="plan-card-v2__title-container">
                {/* Title */}
                <div className="plan-card-v2__title">{name}</div>
                {/* Logo & Rating */}
                <div className="plan-card-v2__logo-reviews">
                  <img className="plan-card-v2__logo" src={plan.supplier.logo} alt={plan.supplier.name} />
                  <Ratings
                    ratings={plan.supplier.ratings}
                    boundary="scrollParent"
                    tippyClass="ratings-tippy--card"
                    supplierName={plan.supplier.name}
                    site={site}
                    newGridEnabled
                  />
                </div>
              </div>
              <div className="plan-card-v2__price-badge-container">
                {/* Monthly Bill Estimate & Price per Kwh @ 1000kwh & Term Length */}
                <div className="plan-card-v2__price-container">
                  <div className="plan-card-v2__price-kwh">
                    <p className="plan-card-v2__price-label">Price per kWh</p>
                    <p className="plan-card-v2__price-label-secondary">{`at ${plan.usage} kWh`}</p>
                    <strong className="plan-card-v2__price-value plan-card-v2__price-value--bill">{`${planPricePerKwh}¢`}</strong>
                  </div>
                  <div className="plan-card-v2__term-length">
                    <p className="plan-card-v2__price-label">Term length</p>
                    <p className="plan-card-v2__price-label-secondary">{`${plan.type} rate`}</p>
                    <strong className="plan-card-v2__price-value">{planTermText}</strong>
                  </div>
                  <div className="plan-card-v2__monthly-bill">
                    <p className="plan-card-v2__price-label">Monthly bill</p>
                    <p className="plan-card-v2__price-label">estimate*</p>
                    <strong className="plan-card-v2__price-value">{`$${planMonthlyBillEstimate}`}</strong>
                  </div>
                </div>
                {/* Badges Medium Viewport */}
                <div className="plan-card-v2__badges-medium">
                  <CardBadges
                    satisfactionBadge={satisfactionBadge}
                    renewableBadge={renewableBadge}
                    betterBusinessBadge={betterBusinessBadge}
                  />
                </div>
              </div>
              {/* Badges Small Viewport */}
              <div className="plan-card-v2__badges-small">
                <CardBadges
                  satisfactionBadge={satisfactionBadge}
                  renewableBadge={renewableBadge}
                  betterBusinessBadge={betterBusinessBadge}
                />
              </div>
            </div>
          </div>
          {/* CTAs Small Breakpoint */}
          <div className="plan-card-v2__cta-container">
            <div className="plan-card-v2__cta-container-inner">
              {
                showCartCTA && (
                  <a
                    className={`plan-card-v2__cta plan-card-v2__cta--cart ${ctaSwap ? 'plan-card-v2__cta-cart-swap' : ''}`}
                    href={pdpTestActive ? pdpUrl : cartUrl}
                    onClick={(e) => handleCartClick(e, cartUrl, plan)}
                  >
                    { ctaText || 'Check availability' }
                  </a>
                )
              }
              <FuseNumbers
                className={`plan-card-v2__cta plan-card-v2__cta--phone ${!showCartCTA ? 'plan-card-v2__cta--phone-primary' : ''}`}
                site={site}
                newGridEnabled
                showCartCTA={showCartCTA}
              />
            </div>
          </div>
          {/* Details trigger */}
          <div
            className={`plan-card-v2__details-trigger-container${detailsOpen ? '' : ' plan-card-v2__details-trigger-container--borderless'}`}
          >
            <button
              className="plan-card-v2__details-trigger"
              type="button"
              onClick={() => handleOpenDetails()}
            >
              <span className="plan-card-v2__details-trigger-copy">
                {detailsOpen ? 'Less details' : 'More details'}
              </span>
              <img
                src={arrowDetail}
                className={`plan-card-v2__details-trigger-icon${detailsOpen ? ' plan-card-v2__details-trigger-icon--triggered' : ''}`}
                alt="Trigger details"
              />
            </button>
          </div>
        </div>
        {/* CTAs Medium Breakpoint */}
        <div className={`plan-card-v2__cta-container-medium${detailsOpen ? ' plan-card-v2__cta-container-medium--open' : ''}`}>
          {
            showCartCTA && (
              <a
                className={`plan-card-v2__cta plan-card-v2__cta--cart ${ctaSwap ? 'plan-card-v2__cta-cart-swap' : ''}`}
                href={pdpTestActive ? pdpUrl : cartUrl}
                onClick={(e) => handleCartClick(e, cartUrl, plan)}
              >
                { ctaText || 'Check availability' }
              </a>
            )
          }
          <div className="plan-card-v2__cta-medium">
            <FuseNumbers
              className="plan-card-v2__cta plan-card-v2__cta--phone"
              site={site}
              newGridEnabled
              showCartCTA={showCartCTA}
            />
          </div>
        </div>
      </div>
      {/* Details container */}
      { detailsOpen && (
        <div className="plan-card-v2__details">
          <div className="plan-card-v2__details-header">
            {/* eslint-disable-next-line */}
            <img
              className="plan-card-v2__details-close"
              src={close}
              alt="Close"
              onClick={() => handleOpenDetails()}
            />
            <div className="plan-card-v2__details-title">{name}</div>
            <div className="plan-card-v2__details-subtitle">{plan.supplier.name}</div>
          </div>
          <div className="plan-card-v2__details-content">
            <PlanDetails
              plan={plan}
              site={site}
            />
          </div>
        </div>
      )}
    </article>
  );
}

CardV2.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  // onOrderOnline: PropTypes.func.isRequired,
};

export default CardV2;
