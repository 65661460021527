import React from 'react';
import PropTypes from 'prop-types';
import arcadiaLogotype from '../../svg/arcadia-logotype.svg';
import tracking from '../../js/project/tagular/tracking';

const assets = {
  purpleOptionOne: 'Choose Grid Banner Arcadia Purple',
  purpleOptionTwo: 'Choose Grid Banner Arcadia Purple Option 2',
  yellowOptionOne: 'Choose Grid Banner Arcadia Yellow',
  yellowOptionTwo: 'Choose Grid Banner Arcadia Yellow Option 2',
};

const styles = {
  purple: 'choose-grid-banner-arcadia--purple',
  yellow: 'choose-grid-banner-arcadia--yellow'
};

const options = {
  one: {
    headline: 'Get energy that helps the planet',
    subheadline: `Want solar without the rooftop committment? Arcadia helps you
    explore your community solar options for cleaner energy savings.`,
    ctaLabel: 'Check availability'
  },
  two: {
    headline: 'Save with community solar energy',
    subheadline: `Help protect the planet, enjoy cleaner energy and save on
    your bill with Arcadia community solar options. `,
    ctaLabel: 'Check availability'
  }
};

const ChooseGridBannerArcadia = ({ assetName, className }) => {
  const track = (key) => {
    tracking.elementClicked({
      webElement: {
        location: 'Choose Grid',
        elementType: 'Choose Grid Banner Arcadia',
        text: key
      }
    });
  };

  const getData = (asset, assetsData, optionsData, stylesData) => {
    const data = {
      content: optionsData.one,
      style: stylesData.purple
    };

    if ([assetsData.purpleOptionTwo, assetsData.yellowOptionTwo].includes(asset)) {
      data.content = optionsData.two;
    }

    if ([assetsData.yellowOptionOne, assetsData.yellowOptionTwo].includes(asset)) {
      data.style = stylesData.yellow;
    }

    return data;
  };

  const { content, style } = getData(assetName, assets, options, styles);

  return (
    <section className={`choose-grid-banner-arcadia ${style} ${className}`}>
      <a
        className="choose-grid-banner-arcadia__link"
        // eslint-disable-next-line max-len
        href="https://www.arcadia.com/lp-chooseenergy?utm_source=chooseenergy&utm_medium=marketplace&promo=choose0energy"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => track(assetName)}
      >
        <div className="choose-grid-banner-arcadia__container">
          <div className="choose-grid-banner-arcadia__title">
            <img src={arcadiaLogotype} alt="Arcadia Logotype" className="choose-grid-banner-arcadia__logotype" />
          </div>
          <div className="choose-grid-banner-arcadia__text">
            <h1 className="choose-grid-banner-arcadia__headline">{content.headline}</h1>
            <h2 className="choose-grid-banner-arcadia__subheadline">{content.subheadline}</h2>
          </div>
          <div className="choose-grid-banner-arcadia__action">
            <span className="choose-grid-banner-arcadia__cta">{content.ctaLabel}</span>
          </div>
        </div>
      </a>
    </section>
  );
};

ChooseGridBannerArcadia.defaultProps = {
  assetName: assets.purpleOptionOne,
};

ChooseGridBannerArcadia.propTypes = {
  assetName: PropTypes.oneOf(Object.values(assets)),
};

export default ChooseGridBannerArcadia;
