import React, { useState, useRef, useEffect } from 'react';
import { updateZip } from '../js/project/get-query-params';
import tracking from '../js/project/tagular/tracking';

const ZipSearch = (props) => {
  const { plansCount, zipCode, site } = props;
  const [buttonName, setButtonName] = useState('Edit');
  const [localZipCode, setLocalZipCode] = useState(zipCode);

  const inputEl = useRef(null);

  const focusZip = (origin = 'field') => {
    setButtonName('Go');
    inputEl.current.select();

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: origin === 'button' ? 'Edit' : null
      },
      actionOutcome: 'Edit ZIP'
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (buttonName === 'Edit') {
      focusZip('button');
    } else {
      tracking.elementClicked({
        webElement: {
          location: 'GRID',
          elementType: 'Button',
          text: 'Go'
        },
        actionOutcome: 'Submit new ZIP'
      });

      const newZip = inputEl.current.value;
      setLocalZipCode(newZip);
      updateZip(site, newZip);
    }
  };

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  // Updates the zip in the middle of the grid page after the browser back button is clicked
  useEffect(() => {
    if (zipCode !== localZipCode) {
      setLocalZipCode(zipCode);
      updateZip(site, zipCode);
    }
  }, []);

  return (
    <section className="zip-search">
      <form className="zip-search__form" onSubmit={submit}>
        <div className="zip-search__text-wrapper">
          We found
          <strong>{` ${plansCount} plans `}</strong>
          for
          <input
            id="zip-code"
            className="zip-search__input"
            ref={inputEl}
            value={localZipCode}
            onChange={(e) => handleChange(e)}
            onClick={focusZip}
            type="text"
            inputMode="numeric"
            maxLength="5"
            pattern="[0-9]{5}"
            title="Please enter a valid zip code"
            required
            aria-label="zip code search"
          />
          {' '}
          ZIP Code
          <button
            id="zip-search-submit-button"
            className="zip-search__button"
            type="submit"
          >
            {buttonName}
          </button>
        </div>
      </form>
    </section>
  );
};

export default ZipSearch;
