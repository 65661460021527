import React from 'react';

export default function ArrowRight(color, clickHandler, classNameValue) {
  /* eslint-disable max-len */
  return (
    <svg className={classNameValue} onClick={clickHandler} width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>arrow-right</title>
      <g id="PD-120-Paginate-Grid" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="D-Pagination" transform="translate(-659.000000, -158.000000)" fill={color} fillRule="nonzero">
          <g id={classNameValue} transform="translate(659.000000, 158.000000)">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,2 C8.2680135,2 2,8.2680135 2,16 C2,23.7319865 8.2680135,30 16,30 C23.7319865,30 30,23.7319865 30,16 C30,8.2680135 23.7319865,2 16,2 Z" id="Rectangle" />
            <path d="M17.0786198,24.0195288 C17.1958068,24.0195288 17.2895568,23.9843727 17.3598691,23.9140603 L17.3598691,23.9140603 L24.7075078,16.5312654 C24.8012578,16.4609531 24.8481325,16.3672031 24.8481325,16.2500161 C24.8481325,16.1328291 24.8012578,16.0390791 24.7075078,15.9687668 L24.7075078,15.9687668 L17.3598691,8.58597187 C17.2895568,8.51565953 17.1958068,8.48050337 17.0786198,8.48050337 C16.9614327,8.48050337 16.8559642,8.51565953 16.7622143,8.58597187 L16.7622143,8.58597187 L16.059091,9.28909519 C15.9887786,9.35940752 15.9536225,9.45315748 15.9536225,9.57034452 C15.9536225,9.68753156 15.9887786,9.79300006 16.059091,9.88675001 L16.059091,9.88675001 L21.5082967,15.3359558 L9.52004406,15.3359558 C9.40285702,15.3359558 9.30324752,15.3769715 9.22121665,15.4590024 C9.13918577,15.5410332 9.09817006,15.6406427 9.09817006,15.7578298 L9.09817006,15.7578298 L9.09817006,16.7422024 C9.09817006,16.8593895 9.13918577,16.958999 9.22121665,17.0410298 C9.30324752,17.1230607 9.40285702,17.1640764 9.52004406,17.1640764 L9.52004406,17.1640764 L21.5082967,17.1640764 L16.059091,22.6132822 C15.9887786,22.7070321 15.9536225,22.8125006 15.9536225,22.9296877 C15.9536225,23.0468747 15.9887786,23.1406247 16.059091,23.210937 L16.059091,23.210937 L16.7622143,23.9140603 C16.8559642,23.9843727 16.9614327,24.0195288 17.0786198,24.0195288 Z" id="arrow" />
          </g>
        </g>
      </g>
    </svg>
  );
}
