import React from 'react';
import PropTypes from 'prop-types';

export const config = {
  placement: 'Non-Gimmicky Banner',
  assets: {
    one: 'Non-Gimmicky Option 1',
    two: 'Non-Gimmicky Option 2'
  }
};

const content = {
  [config.assets.one]: {
    text: 'All plans shown are flat rate, so no need to worry about gimmicky bill surprises.'
  },
  [config.assets.two]: {
    text: 'The plans shown are flat rates and have no hidden fees.'
  }
};

const NonGimmickyBanner = ({ assetName }) => {
  if (!content[assetName]?.text) {
    return null;
  }

  return (
    <div className="non-gimmicky-banner">
      <div className="non-gimmicky-banner__container">{content[assetName].text}</div>
    </div>
  );
};

NonGimmickyBanner.defaultProps = {
  assetName: config.assets.one,
};

NonGimmickyBanner.propTypes = {
  assetName: PropTypes.oneOf(Object.values(config.assets)),
};

export default NonGimmickyBanner;
