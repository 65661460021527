import React, { useState, useEffect } from 'react';

import ratingIcon from '../../../images/rating-icon.svg';
import trustpilotStarsFourAndHalf from '../../../images/trustpilot/4.5-star.svg';
import trustpilotLogo from '../../../images/trustpilot-logo.svg';
import achievementIcon from '../../../images/achievement-icon.svg';
import noFeesIcon from '../../../images/no-fees-icon.svg';

const SplashTrustPilot = () => {
  const [reviewData, setReviewData] = useState({ numberOfReviews: { total: 2622 } });

  useEffect(() => {
    // Used to prevent memory leak error from async request
    let isSubscribed = true;

    /** Hit api to get review counts from trustpilot */
    const getReviewData = async () => {
      const response = await fetch(
        'https://api.development.saveonenergy.cloud/trustpilot/choosetexaspower.org',
      );

      const data = await response.json();

      if (data && isSubscribed) {
        setReviewData(data);
      }
    };

    getReviewData();

    // eslint-disable-next-line
    return () => (isSubscribed = false)
  }, []);

  const totalReviews = reviewData.numberOfReviews.total.toLocaleString();

  return (
    <div className="hero-trustpilot">
      <a
        href="https://www.trustpilot.com/review/choosetexaspower.org"
        rel="noopener noreferrer"
        target="_blank"
        className="hero-trustpilot__top"
      >
        <img className="hero-trustpilot__icon" alt="rating icon" src={ratingIcon} />
        <div className="hero-trustpilot__rating">
          <div className="hero-trustpilot__rating-heading">
            <p className="hero-trustpilot__rating-heading-score">
              Excellent
              <span>{` ${reviewData.score?.trustScore}/5`}</span>
            </p>
            <img className="hero-trustpilot__rating-heading-stars" alt="Star rating" src={trustpilotStarsFourAndHalf} />
          </div>
          <div className="hero-trustpilot__rating-subheading">
            <p className="hero-trustpilot__rating-subheading-reviews">
              Based on&nbsp;
              <span className="hero-trustpilot__rating-subheading-reviews-total">
                {`${totalReviews} reviews`}
              </span>
              &nbsp;on&nbsp;
            </p>
            <img className="hero-trustpilot__rating-subheading-logo" alt="Trustpilot logo" src={trustpilotLogo} />
          </div>
        </div>
      </a>
      <div className="hero-trustpilot__content hero-trustpilot__middle">
        <img className="hero-trustpilot__icon" alt="Achievement icon" src={achievementIcon} />
        <p className="hero-trustpilot__content-text">8+ Years of experience</p>
      </div>
      <div className="hero-trustpilot__content hero-trustpilot__bottom">
        <img className="hero-trustpilot__icon" alt="No fees icon" src={noFeesIcon} />
        <p className="hero-trustpilot__content-text">All-inclusive rates and no hidden fees</p>
      </div>
    </div>
  );
};

export default SplashTrustPilot;
