import React, { useRef } from 'react';
import Tippy from '@tippy.js/react';
import Ratings from '../../Ratings';
import infoBubble from '../../../svg/info-bubble.svg';

export default function PlanInfo(props) {
  const { triggerOverlay, newPlan, epData } = props;
  const newPlanAverageCost = Math.round(newPlan.electricPhoenixData.averageCost);
  const ratingScore = newPlan.supplier.ratings;
  const annualSavings = Math.round(epData.annualCost - newPlan.electricPhoenixData.annualCost);
  const savingsNumber = annualSavings > 0 ? annualSavings : 0;
  const ratingScoreBoundryRef = useRef(null);

  return (
    <div className="plan-info">
      <img className="plan-info__logo" src={newPlan.supplier.logo} alt="Provider Logo" />
      <p className="plan-info__title">{newPlan.name}</p>
      <span className="plan-info__subtitle">{newPlan?.supplier.name}</span>
      <div className="plan-info__rating">
        {
          ratingScore && (
            <Ratings
              ratings={ratingScore}
              supplierName={newPlan.supplier.name}
              boundary={ratingScoreBoundryRef}
            />
          )
        }
      </div>
      <div className="plan-info__estimates">
        <div className="plan-info__estimate">
          <span className="plan-info__estimate-amount">
            $
            {savingsNumber}
          </span>
          <div className="plan-info__estimate-type">
            <span className="plan-info__estimate-text">estimated annual savings</span>
            {/* eslint-disable max-len */}
            <Tippy
              content="Estimated annual savings is based on your new estimated bill compared with any bill data you provided, plus bill averages for your home profile."
              className="savings-graph__tippy"
              placement="top"
              arrow
            >
              {/* eslint-enable max-len */}
              <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
            </Tippy>
          </div>
        </div>
        <div className="plan-info__estimate">
          <span className="plan-info__estimate-amount">
            $
            {newPlanAverageCost}
          </span>
          <div className="plan-info__estimate-type">
            <span>estimated average bill</span>
            {/* eslint-disable max-len */}
            <Tippy
              content="Estimated average bill is based on any usage data you entered, plus usage averages for your home profile."
              className="savings-graph__tippy"
              placement="top"
              arrow
            >
              {/* eslint-enable max-len */}
              <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
            </Tippy>
          </div>
        </div>
      </div>
      {/* eslint-disable */}
      <p className="plan-info__details" type="button" tabIndex="0" onClick={triggerOverlay}>
        Plan details
      </p>
      {/* eslint-enable */}
    </div>
  );
}
