import React, { useState } from 'react';

import TXUGoogleReview from './TXUGoogleReview';

export default function TXUHeroSectionSimplified() {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const zipCode = queryParams.get('zipCode');
  const [zipCodeValue, setZipCodeValue] = useState(zipCode);

  const handleZipCodeChange = (e) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
    setZipCodeValue(onlyNumbers);
  };

  const handleZipCodeSubmit = (e) => {
    e.preventDefault();
    const currentQueryParams = new URLSearchParams(window.location.search);
    currentQueryParams.set('zipCode', zipCodeValue);
    window.location.href = `${window.location.pathname}?${currentQueryParams.toString()}`;
  };

  return (
    <div className="simplified-hero-wrapper">
      <div className="hero-container simplified-hero">
        <div className="hero-text-container">
          <div className="location">
            <p>Shop plans in</p>
            <form onSubmit={handleZipCodeSubmit}>
              <input
                name="zipCode"
                type="text"
                value={zipCodeValue}
                onChange={handleZipCodeChange}
                className="simplified-hero-zipcode"
                maxLength="5"
                required
                pattern="[0-9]{5}"
              />
            </form>
          </div>
          <TXUGoogleReview />
        </div>
      </div>
    </div>
  );
}
