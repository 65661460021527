import React from 'react';

import buildClassList from '../../js/project/buildClassList';
import iconCheck from '../../../images/icon-check-default.svg';
import iconLeaf from '../../../images/icon-leaf-default.svg';
import iconThumbsUp from '../../../images/icon-thumbs-up.svg';

function CardBadges(props) {
  const {
    satisfactionBadge,
    renewableBadge,
    betterBusinessBadge,
    featured = false,
  } = props;

  const featuredClassName = buildClassList(
    featured && 'featured-plan-card-v2-vertical',
    !featured && 'plan-card-v2',
  );

  return (
    <div className={`${featuredClassName}__badges`}>
      <div
        className={`${featuredClassName}__badge ${featuredClassName}__badge--satisfaction-guarantee`}
      >
        <p className={`${featuredClassName}__badge-title`}>
          <img src={iconCheck} alt="Satisfaction Guarantee" />
          Satisfaction guarantee
        </p>
        <p className={`${featuredClassName}__badge-value`}>
          {satisfactionBadge?.attributeValue ? `${satisfactionBadge.attributeValue} days` : '--'}
        </p>
      </div>
      <div className={`${featuredClassName}__badge ${featuredClassName}__badge--renewable`}>
        <p className={`${featuredClassName}__badge-title`}>
          <img src={iconLeaf} alt="Percent Renewable" />
          % Renewable
        </p>
        <p className={`${featuredClassName}__badge-value`}>
          {renewableBadge?.attributeValue ? `${renewableBadge.attributeValue}%` : '0%'}
        </p>
      </div>
      <div className={`${featuredClassName}__badge ${featuredClassName}__badge--bbb-rating`}>
        <p className={`${featuredClassName}__badge-title`}>
          <img src={iconThumbsUp} alt="BBB rating" />
          BBB rating
        </p>
        <p className={`${featuredClassName}__badge-value`}>
          {betterBusinessBadge ? 'A+' : '--'}
        </p>
      </div>
    </div>
  );
}

export default CardBadges;
