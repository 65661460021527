/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ButtonResponseWrapper from './ButtonResponseWrapper';
import phoneIcon from '../../images/icon-phone-classic.svg';
import txuLogo from '../../images/txu-logo-white.svg';

if (document.querySelector('#js-grid')) {
  Modal.setAppElement('#js-grid');
}

const txuExistingCustomerLink = 'https://www.txu.com/myaccount/residential/plans-products/change-service.aspx';
const txuSignInLink = 'https://www.txu.com/signin';

const ZipModal = ({ fuseData }) => {
  const { bannerFuseData = {} } = fuseData;
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [existingCustomer, setExistingCustomer] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [isApartment, setIsApartment] = useState(null);
  const [localZipCode, setLocalZipCode] = useState('');
  const [toggle, setToggle] = useState(false);
  const [continueAttempt, setContinueAttempt] = useState(false);
  const [showError, setShowError] = useState(false);

  const initialValidation = {
    existing: false,
    zip: false,
    address: false,
    apartment: false,
  };

  const [errorConfig, setErrorConfig] = useState(initialValidation);

  const checkValid = (...responses) => {
    const invalid = [];
    responses.forEach((response) => {
      if (!errorConfig[response]) {
        invalid.push(errorConfig[response]);
      }
    });
    if (invalid.length) {
      return false;
    }
    return true;
  };

  const zipRegex = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/g;
  const validateZip = (input) => {
    if (zipRegex.test(input)) {
      setErrorConfig({ ...errorConfig, zip: true });
    } else {
      setErrorConfig({ ...errorConfig, zip: false });
    }
  };

  const beam = (eventName, data) => {
    if (window.tagular) {
      window.tagular('beam', eventName, {
        '@type': `redventures.usertracking.v3.${eventName}`,
        ...data,
      });
    }
  };

  const submitTagularBeam = () => {
    const tagularTrackingData = {
      field: [{
        fieldType: 'Zip Entry',
        fieldName: 'Zip',
        fieldValue: localZipCode.toString(),
      }],
    };
    beam('FormSubmitted', tagularTrackingData);
  };

  const signInTagularBeam = () => {
    const payload = {
      webElement: {
        location: 'Zip Modal',
        elementType: 'signInLink',
        text: 'Sign In'
      },
      outboundUrl: txuSignInLink
    };
    beam('ElementClicked', payload);
  };

  const continueTagularBeam = () => {
    if (existingCustomer === null) {
      return;
    }
    const payload = {
      webElement: {
        location: localZipCode.toString(),
        position: 'zipEntry',
        elementType: 'cta Button',
        text: existingCustomer ? 'CONTINUE (Existing Customer)' : 'CONTINUE (Not Existing Customer)'
      },
      outboundUrl: existingCustomer ? txuExistingCustomerLink : 'shop.txu.com'
    };
    beam('ElementClicked', payload);
  };

  useEffect(() => {
    if (continueAttempt && !toggle) {
      setShowError(!checkValid('existing', 'zip'));
    } else if (continueAttempt && toggle) {
      setShowError(!checkValid('address', 'apartment'));
    }
  });

  const handleZipInput = (e) => {
    setLocalZipCode(e.target.value);
    validateZip(e.target.value);
  };

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleContinue = () => {
    if (existingCustomer) {
      continueTagularBeam();
      window.location.href = txuExistingCustomerLink;
    } else if (checkValid('existing', 'zip')) {
      continueTagularBeam();
      setContinueAttempt(false);
      setShowError(false);
      setToggle(true);
    } else {
      setContinueAttempt(true);
    }
  };

  const handleZipKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleContinue();
    }
  };

  const handleSubmit = () => {
    if (checkValid('address', 'apartment')) {
      submitTagularBeam();
      pause(500).then(() => {
        window.location = `/plans/?zipcode=${localZipCode}&m=move${newAddress ? 'y' : 'n'}&a=apartment${isApartment ? 'y' : 'n'}`;
      });
      setmodalIsOpen(false);
    } else {
      setContinueAttempt(true);
    }
  };

  const { dataFuseName, permalease } = bannerFuseData;
  const zipError = continueAttempt && !checkValid('zip');
  const format = '###-###-####';

  useEffect(() => {
    if (window.fuse) {
      window.fuse('placeNumbers');
    }
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      overlayClassName="grid-modal-overlay"
      className="grid-modal-content entry-modal"
    >
      <div className="entry-modal-phone">
        <img
          className="entry-modal-phone__image"
          src={phoneIcon}
          alt="Telephone indicator"
        />
        <a
          className="entry-modal-phone__number"
          href={`tel:${permalease}`}
          data-fuse
          data-fuse-format={format}
          data-fuse-name={dataFuseName}
        >
          {permalease}
        </a>
      </div>
      <div className="entry-modal__content">
        <h3 className={`entry-modal__headline ${toggle ? 'entry-modal__headline--toggle' : ''}`}>
          Enter your ZIP Code to get the
          <br />
          result of your location.
        </h3>
        <form
          id="entry-modal"
          className="entry-modal__form"
        >
          {!toggle && (
            <div>
              <p className="entry-modal__title">
                Are you a current
                <span className="u-white-space-nowrap"> TXU Energy </span>
                customer?
              </p>
              <ButtonResponseWrapper
                answerState={existingCustomer}
                setAnswerState={setExistingCustomer}
                continueAttempt={continueAttempt}
                errorConfig={errorConfig}
                setErrorConfig={setErrorConfig}
                errorKey="existing"
              />
              <div className="entry-modal__cta-group">
                <input
                  className={`entry-modal__zip ${zipError ? 'entry-modal__zip--errored' : ''}`}
                  type="tel"
                  maxLength="5"
                  pattern="[0-9]{5}"
                  placeholder="ZIP CODE"
                  name="zip"
                  required
                  onChange={(e) => handleZipInput(e)}
                  onKeyDown={handleZipKeyDown}
                  value={localZipCode}
                />
                <button
                  type="button"
                  className="entry-modal__cta-button"
                  onClick={() => handleContinue()}
                  disabled={showError}
                >
                  CONTINUE
                </button>
                {showError && (
                  <span className="entry-modal__error">Please address required fields.</span>
                )}
              </div>
            </div>
          )}

          {toggle && (
            <div className="entry-modal__new-customer">
              <ButtonResponseWrapper
                answerState={newAddress}
                setAnswerState={setNewAddress}
                continueAttempt={continueAttempt}
                errorConfig={errorConfig}
                setErrorConfig={setErrorConfig}
                errorKey="address"
                title="Are you moving to a new address?"
              />
              <ButtonResponseWrapper
                answerState={isApartment}
                setAnswerState={setIsApartment}
                continueAttempt={continueAttempt}
                errorConfig={errorConfig}
                setErrorConfig={setErrorConfig}
                errorKey="apartment"
                title="Is this an apartment?"
              />
              <div className={`entry-modal__cta-group ${toggle ? 'entry-modal__cta-group--toggle' : ''}`}>
                <button
                  type="button"
                  className="entry-modal__cta-button"
                  onClick={() => handleSubmit()}
                  disabled={showError}
                >
                  SHOP
                </button>
                {showError && (
                  <span className="entry-modal__error">Please address required fields.</span>
                )}
              </div>
            </div>
          )}
        </form>
        <p className="entry-modal__title entry-modal__title--sign-in">
          Already have an account?
          {' '}
          <a
            href="https://www.txu.com/signin"
            onClick={signInTagularBeam}
            className="entry-modal__sign-in"
          >
            Sign in
          </a>
        </p>
      </div>
      <div className="entry-modal__logo-container">
        <a href="/">
          <img
            src={txuLogo}
            alt="TXU Energy Logo"
            className="entry-modal__logo-container"
          />
        </a>
      </div>
    </Modal>
  );
};

export default ZipModal;
