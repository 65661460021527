import React from 'react';
import PropTypes from 'prop-types';

import savignsBannerIcon from '../../images/savings-banner-icon.svg';

function SavingsBanner({ bannerText }) {
  return (
    <section className="savings-banner">
      <img
        src={savignsBannerIcon}
        alt="Savings Banner Icon"
        className="savings-banner__icon"
      />
      <span>
        {bannerText}
      </span>
    </section>
  );
}

export default SavingsBanner;

SavingsBanner.propType = {
  bannerText: PropTypes.string
};

SavingsBanner.defaultProps = {
  bannerText: 'As prices often change, we always help find the best energy rates for your needs.',
};
