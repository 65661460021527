import React from 'react';
import PropTypes from 'prop-types';

import PlusIcon from '../../../../svg/plus-black.svg';
import MinusIcon from '../../../../svg/minus-black.svg';

const CheckboxMessaging = ({ isHidden }) => {
  if (isHidden) {
    return (
      <div className="grid-v2-providers__checkbox-messaging">
        Less Providers
        <img className="grid-v2-providers__checkbox-messaging-svg" src={MinusIcon} alt="Minimize" />
      </div>
    );
  }

  return (
    <div className="grid-v2-providers__checkbox-messaging">
      More Providers
      <img className="grid-v2-providers__checkbox-messaging-svg" src={PlusIcon} alt="Expand" />
    </div>
  );
};

export default CheckboxMessaging;

CheckboxMessaging.propTypes = {
  isHidden: PropTypes.bool.isRequired
};
