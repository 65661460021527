import React from 'react';
import iconSmartMeter from '../../../images/icon-smart-meter.svg';
import iconSwitchArrows from '../../../images/icon-switch-arrows.svg';
import iconSmartMeterNoBack from '../../../images/icon-smart-meter-no-back.svg';
import iconSwitchArrowsNoBack from '../../../images/icon-switch-arrows-no-back.svg';

function ProductDetailSectionInfo(props) {
  const { noBackgroundImages = false } = props;

  const content = [
    {
      title: 'You may have a smart meter',
      subtitle: 'Most homes do and can start service without a technician visit.',
      icon: noBackgroundImages ? iconSmartMeterNoBack : iconSmartMeter,
    },
    {
      title: 'Switching providers?',
      subtitle: 'Your new provider will cancel your old service for you after you order.',
      icon: noBackgroundImages ? iconSwitchArrowsNoBack : iconSwitchArrows,
    }
  ];
  return (
    <div className="pdp-section-info">
      <div className="pdp-section-info__inner">
        {
          content.map((item) => (
            <div className="pdp-section-info__info-box" key={item.title}>
              <img className="pdp-section-info__info-box-icon" src={item.icon} alt="Smart meter" />
              <p className="pdp-section-info__info-box-title">{item.title}</p>
              <p className="pdp-section-info__info-box-subtitle">{item.subtitle}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default ProductDetailSectionInfo;
