import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DefaultFilters, Sites } from '@rvpower/constants';
import buildClassList from '../../../js/project/buildClassList';

import Providers from '../filters/providers';
import Features from '../filters/features';
import TermLength from '../filters/term-length';
import RateType from '../filters/rate-type';
import Rating from '../filters/rating';
import HomeSize from '../filters/home-size';
import BillPrice from '../filters/bill-price';
import Utility from '../filters/utility';

import Sort from '../sort';
import Tabs from '../tabs';

import ArrowLeft from '../../../svg/arrow-left.svg';

const FilterMenuMobile = ({
  allPlans,
  filters,
  filtersActiveSection,
  isNTX,
  isOpen,
  options,
  plans,
  providers,
  rates,
  setFilters,
  setIsOpen,
  setFiltersActiveSection,
  setSelectedUtility,
  setSort,
  setUsage,
  sort,
  terms,
  utilities,
  utility,
  site
}) => {
  const wrapperRefUsage = useRef(null);
  const wrapperRefBillPrice = useRef(null);
  const wrapperRefFeatures = useRef(null);
  const wrapperRefProviders = useRef(null);
  const wrapperRefRatings = useRef(null);
  const wrapperRefTerms = useRef(null);
  const wrapperRefRateTypes = useRef(null);
  const wrapperRefUtilities = useRef(null);
  const isTxu = site === Sites.TXU;

  const refKey = {
    usage: wrapperRefUsage,
    billPrice: wrapperRefBillPrice,
    features: wrapperRefFeatures,
    providers: wrapperRefProviders,
    ratings: wrapperRefRatings,
    terms: wrapperRefTerms,
    rateTypes: wrapperRefRateTypes,
    utilities: wrapperRefUtilities
  };

  const classList = buildClassList(
    'grid-v2-filter-menu-mobile',
    isOpen ? 'u-display-block' : 'u-display-none'
  );
  const buildWrapperClassList = buildClassList(
    'grid-v2-filter-menu-mobile__wrapper',
    refKey[filtersActiveSection]
      ? refKey[filtersActiveSection].current?.classList.add('grid-v2-filter-menu-mobile__wrapper--active')
      : ''
  );

  const handleClearAllFilters = () => {
    setFilters({
      ...DefaultFilters
    });
  };

  const handleCloseMenu = () => {
    if (filtersActiveSection) {
      // remove the active class state from the section
      refKey[filtersActiveSection].current.classList.remove('grid-v2-filter-menu-mobile__wrapper--active');
      setFiltersActiveSection('');
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (refKey[filtersActiveSection] && isOpen) {
      const hiddenScrollTo = refKey[filtersActiveSection]
        .current
        ?.querySelector('.grid-v2-filter-menu-mobile__hidden-element');
      hiddenScrollTo.scrollIntoView({ behavior: 'smooth' });
    }
  }, [refKey[filtersActiveSection], isOpen]);

  return (
    <div className={classList}>
      <div className="grid-v2-filter-menu-mobile-nav">
        <button
          className="grid-v2-filter-menu-mobile-nav__button"
          type="button"
          onClick={handleCloseMenu}
        >
          <img
            className="grid-v2-filter-menu-mobile-nav__arrow"
            src={ArrowLeft}
            alt="Go Back"
          />
        </button>
        <h3 className="grid-v2-filter-menu-mobile-nav__heading">Filters</h3>
      </div>
      <Tabs fullWidth>
        <div title="Filter">
          {isNTX ? (
            <>
              <div className={buildWrapperClassList} ref={wrapperRefUtilities}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Utility</h4>
                <Utility
                  setSelectedUtility={setSelectedUtility}
                  utilities={utilities}
                  utility={utility}
                />
              </div>
              <div className={buildWrapperClassList} ref={wrapperRefTerms}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Term length</h4>
                <TermLength
                  filters={filters}
                  plans={plans}
                  setFilters={setFilters}
                  terms={terms}
                />
              </div>
              <div className={buildWrapperClassList} ref={wrapperRefProviders}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Providers</h4>
                <Providers
                  filters={filters}
                  isNTX={isNTX}
                  plans={plans}
                  providers={providers}
                  setFilters={setFilters}
                />
              </div>
              <div className={buildWrapperClassList} ref={wrapperRefFeatures}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Features</h4>
                <Features
                  filters={filters}
                  plans={plans}
                  setFilters={setFilters}
                  isNTX={isNTX}
                />
              </div>
            </>
          ) : (
            <>
              <div className={buildWrapperClassList} ref={wrapperRefUsage}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Home size</h4>
                <HomeSize
                  filters={filters}
                  setFilters={setFilters}
                  setUsage={setUsage}
                  isTxu={isTxu}
                />
              </div>
              {!isTxu && (
                <div className={buildWrapperClassList} ref={wrapperRefBillPrice}>
                  <div className="grid-v2-filter-menu-mobile__hidden-element" />
                  <h4 className="grid-v2-filter-menu-mobile__heading">Monthly bill price</h4>
                  <BillPrice
                    site={site}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </div>
              )}
              {!isTxu && (
                <div className={buildWrapperClassList} ref={wrapperRefFeatures}>
                  <div className="grid-v2-filter-menu-mobile__hidden-element" />
                  <h4 className="grid-v2-filter-menu-mobile__heading">Features</h4>
                  <Features
                    filters={filters}
                    plans={plans}
                    setFilters={setFilters}
                    isNTX={isNTX}
                  />
                </div>
              )}
              {!isTxu && (
                <>
                  <div className={buildWrapperClassList} ref={wrapperRefProviders}>
                    <div className="grid-v2-filter-menu-mobile__hidden-element" />
                    <h4 className="grid-v2-filter-menu-mobile__heading">Providers</h4>
                    <Providers
                      filters={filters}
                      isNTX={isNTX}
                      plans={plans}
                      providers={providers}
                      setFilters={setFilters}
                    />
                  </div>
                  <div className={buildWrapperClassList} ref={wrapperRefRatings}>
                    <div className="grid-v2-filter-menu-mobile__hidden-element" />
                    <h4 className="grid-v2-filter-menu-mobile__heading">Provider rating</h4>
                    <Rating
                      allPlans={allPlans}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                </>
              )}
              <div className={buildWrapperClassList} ref={wrapperRefTerms}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Term length</h4>
                <TermLength
                  filters={filters}
                  plans={plans}
                  setFilters={setFilters}
                  terms={terms}
                />
              </div>
              <div className={buildWrapperClassList} ref={wrapperRefRateTypes}>
                <div className="grid-v2-filter-menu-mobile__hidden-element" />
                <h4 className="grid-v2-filter-menu-mobile__heading">Rate type</h4>
                <RateType
                  filters={filters}
                  rates={rates}
                  setFilters={setFilters}
                />
              </div>
            </>
          )}
        </div>
        <div title="Sort">
          <div className="grid-v2-filter-menu-mobile__wrapper">
            <Sort
              options={options}
              sort={sort}
              setSort={setSort}
              isNTX={isNTX}
              isTxu={isTxu}
            />
          </div>
        </div>
      </Tabs>
      <div className="grid-v2-filter-menu-mobile-footer">
        <button
          type="button"
          className="grid-v2-filter-menu-mobile-footer__clear-all"
          onClick={handleClearAllFilters}
        >
          Clear All
        </button>
        <button
          className="grid-v2-filter-menu-mobile-footer__button"
          type="button"
          onClick={handleCloseMenu}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterMenuMobile;

FilterMenuMobile.propTypes = {
  allPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  filtersActiveSection: PropTypes.string.isRequired,
  isNTX: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.shape({}).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rates: PropTypes.arrayOf(PropTypes.oneOf(['Fixed', 'Variable', 'Indexed'])).isRequired,
  setFilters: PropTypes.func.isRequired,
  setFiltersActiveSection: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setUsage: PropTypes.func.isRequired,
  sort: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  site: PropTypes.string
};

FilterMenuMobile.defaultProps = {
  sort: null, // default value type
  site: ''
};
