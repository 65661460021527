import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import infoBubble from '../../svg/info-bubble.svg';

export default function EpCardBillAmount(props) {
  const {
    number,
    text,
    infoText,
    first
  } = props;

  return (
    <div
      className={`
      card__section-item
      average-total
      u-display-flex
      u-flex-direction-column
      ${first ? 'average-total--first' : ''}
      `}
    >
      <div className="average-total__digits--container">
        <span className="average-total__digits">
          $
          {Math.round(number) < 0 ? 0 : Math.round(number)}
        </span>
      </div>
      <div className="price__settings">
        <span className="price-text">{text}</span>
        <Tippy
          content={infoText}
          className="disclaimer"
          arrow
          placement="top-start"
        >
          <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
        </Tippy>
      </div>
    </div>
  );
}

EpCardBillAmount.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  first: PropTypes.bool,
};

EpCardBillAmount.defaultProps = {
  first: false,
};
