import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ label }) => {
  const filterLabel = label.split(':');

  if (!filterLabel[1]) {
    return (<div>{label}</div>);
  }

  return (
    <div>
      <strong>
        {filterLabel[0]}
        :
      </strong>
      {filterLabel[1]}
    </div>
  );
};

export default Label;

Label.prototype = {
  label: PropTypes.string.isRequired
};
