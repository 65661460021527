/* eslint-disable max-len */
import React from 'react';
import { Sites } from '@rvpower/constants';
import Tip from './Tip';

function Tips(props) {
  const { params, site, className } = props;

  const config = [
    {
      param: 'movey',
      headline: 'Tips for moving',
      icon: site === Sites.SOE ? 'package' : '',
      tips: [
        'Secure a rate now! Delay starting service at the new address for up to 90 days.',
        'Provide proof of your move and change providers <b>without an early termination fee.</b>',
        'Most homes don\'t require an electrician to activate service.',
      ]
    },
    {
      param: 'moven',
      headline: 'Tips for switching',
      icon: site === Sites.SOE ? 'arrowsSwitcher' : '',
      tips: [
        'Secure a rate now and you can delay the start date for up to 90 days.',
        'Cancel your switch to a new provider with <b>no early termination fee</b> if you take action within 3 days of receiving your Terms of Service agreement.',
        '<b>No early termination fee</b> for changing providers if your contract has 14 or fewer days left.',
      ]
    },
  ];

  const usesPathname = site === Sites.TXER;

  let moverSwitcher;

  // For sites that use pathnames to determine mover/switcher instead of query params
  if (usesPathname) {
    const pathnames = window.location.pathname.split('/');
    config.forEach((tip) => {
      if (pathnames.indexOf(tip.param) !== -1) {
        moverSwitcher = tip;
      }
    });
  } else {
    moverSwitcher = config.find((item) => item.param === params.get('m'));
  }

  const sections = moverSwitcher ? [moverSwitcher] : config;
  const hasCollapsibles = sections === config;

  const moveOnlyCheck = sections
    .map((item) => item.param)
    .find((moveParam) => moveParam === 'movey');

  if (moveOnlyCheck && !hasCollapsibles) {
    return null;
  }

  return (
    <section className={`tips ${className}`}>
      {sections.map((section) => (
        <Tip
          key={section.param}
          section={section}
          hasCollapsibles={hasCollapsibles}
        />
      ))}
    </section>
  );
}

export default Tips;
