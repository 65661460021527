import React, { useEffect, useRef, useState } from 'react';

const HmcTextLoader = () => {
  const [activeRef, setActiveRef] = useState(null);

  const titles = [
    {
      id: 1,
      name: 'Compiling your data',
      ref: useRef(null)
    },
    {
      id: 2,
      name: 'Analyzing your data',
      ref: useRef(null)
    },
    {
      id: 3,
      name: 'Shopping for the best plan',
      ref: useRef(null)
    },
    {
      id: 4,
      name: 'Searching for exclusive rates',
      ref: useRef(null),
    },
  ];

  const titleSize = titles.length;

  const onFocusRef = (ref) => (
    ref?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  );

  const handleOnFocus = (title) => {
    if (title?.onFocus) {
      setTimeout(title.onFocus, title.time || 1000);
    }
  };

  useEffect(() => {
    setActiveRef(1);
  }, []);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setActiveRef((t) => (t < titleSize ? t + 1 : t));
    }, 1500);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  useEffect(() => {
    const title = titles.find((t) => t.id === activeRef);
    onFocusRef(title?.ref);
    handleOnFocus(title);
  }, [activeRef]);

  return (
    <div className="text-loading-container">
      <div className="text-loading-content">
        {titles.map((title) => {
          const isActive = title.id === activeRef;
          const difference = title.id - activeRef;
          const suffix = !isActive && (difference < 0 ? 'top' : 'down');
          return (
            <div className="text-loading-item">
              {suffix && <div className={`text-loading-blur-${suffix}`} />}
              <h3
                className={`
                  text-loading-title
                  ${isActive && 'text-loading-title--active'}
                `}
                key={title.id}
                ref={title.ref}
              >
                {title.name}
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HmcTextLoader;
