import React from 'react';
import saveIcon from '../../images/save-icon.svg';
import cancelIcon from '../../images/cancel-icon.svg';
import billIcon from '../../images/bill-icon.svg';
import friendlyIcon from '../../images/friendly-icon.svg';

export default function Badge({ type, children }) {
  function getBadgeClassName(badgeType) {
    let className = 'txu_badge ';

    if (badgeType === 'savings') {
      className += 'saving_badge';
    } else if (badgeType === 'no-cancellation') {
      className += 'cancellation_badge';
    } else if (badgeType === 'friendly') {
      className += 'friendly_badge';
    } else {
      className += 'bill_badge';
    }

    return className;
  }

  function getIconSrc(badgeType) {
    let iconSrc;

    if (badgeType === 'savings') {
      iconSrc = saveIcon;
    } else if (badgeType === 'no-cancellation') {
      iconSrc = cancelIcon;
    } else if (badgeType === 'friendly') {
      iconSrc = friendlyIcon;
    } else {
      iconSrc = billIcon;
    }

    return iconSrc;
  }

  const badgeClassName = getBadgeClassName(type);
  const iconSrc = getIconSrc(type);

  return (
    <div
      className={badgeClassName}
    >
      <img
        src={iconSrc}
        alt="icon"
      />
      {children}
    </div>
  );
}
