import React, { useState, useEffect } from 'react';
import { RatingSites } from '@rvpower/constants';
import GctToolbar from './toolbar/GctToolbar';

export default function GridComparisonTool(
  {
    comparedPlans,
    setComparedPlans,
    site,
    usage
  }
) {
  const [triggerModal, setTriggerModal] = useState(false);
  const planList = Object.assign([], comparedPlans);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ratingsEnabled = RatingSites.includes(site);
  const plansSelected = comparedPlans.length;

  if (plansSelected > 3) {
    planList.splice(3, 1);
    setComparedPlans([...planList]);
  }

  useEffect(() => {
    document.body.classList.add('gct--filo');
  }, []);

  const generateFee = (plan) => {
    if (plan && plan.fee) {
      const perMonthVerbage = plan.fee.monthly ? '/month' : ' one time fee';
      const earlyTermination = plan.fee.type === 'EarlyTerminationFee' ? plan.fee.amount : null;
      const earlyTerminationFee = earlyTermination + perMonthVerbage;
      return (
        <span>
          $
          {earlyTerminationFee}
        </span>
      );
    }
    const eflUrl = plan?.EFLDoc?.url;
    if (eflUrl) {
      return (
        <a href={eflUrl}>
          Unavailable
        </a>
      );
    }
    return (
      <span>
        Unavailable
      </span>
    );
  };

  return (
    <GctToolbar
      comparedPlans={comparedPlans}
      triggerModal={triggerModal}
      plansSelected={plansSelected}
      setComparedPlans={setComparedPlans}
      setTriggerModal={setTriggerModal}
      site={site}
      ratingsEnabled={ratingsEnabled}
      usage={usage}
      generateFee={generateFee}
    />
  );
}
