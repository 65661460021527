import React from 'react';
import { PropTypes } from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Sites, siteCta } from '@rvpower/constants';
import generateClasses from '../utils';
import { usePreamp } from '../../preamp';
import { useMonarch } from '../../monarch';
import FuseNumbers, { FuseNumbersEP } from '../../FuseNumbers';

export default function Cta({
  ESIID,
  plan,
  site,
  onOrderOnline,
  className,
  electricPhoenixActive,
  electricPhoenixToggleActive,
  electricPhoenixInput,
  featuredCard,
  supplierPhone,
  disableCartLinks
}) {
  const {
    cartActive,
    cartUrl,
    cartLink,
    phoneActive,
    supplier,
    isNTX
  } = plan;

  const { disableCart } = supplier.controls;
  const cartCtaCopy = usePreamp('Cart CTA') || siteCta(site, isMobile);
  const isSOE = site === Sites.SOE;
  const { ctaText } = usePreamp('Cart - CTA', true) || '';
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;
  const isCart3 = rules?.source?.cartVersion?.value?.cartVersion === '3.0';

  const txuCartCta = (ctaText || rules?.source?.cartCta?.value) ?? 'Check Availability';

  let updatedCartUrl = !isCart3 ? cartUrl : cartUrl.replace('cart', 'shop');
  const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;

  if (electricPhoenixActive) {
    updatedCartUrl += '&electricPhoenixActive=true';
    updatedCartUrl += `&electricPhoenixToggleActive=${electricPhoenixToggleActive}`;
  }

  // Logic for NTX Cart link offs
  if (isNTX) {
    if (cartLink) {
      updatedCartUrl = cartLink;
    } else {
      updatedCartUrl += '&isNTX=true';
    }
  }

  if (ESIID && isSOE) {
    updatedCartUrl += `&esiid=${ESIID}`;
  }

  if (Object.keys(electricPhoenixInput).length) {
    Object.entries(electricPhoenixInput).forEach(([k, v]) => {
      updatedCartUrl += `&${k}=${v}`;
    });
  }

  const isTxu = site === Sites.TXU;


  const classString = generateClasses(
    className,
    `card__section-item order
      u-display-flex u-align-items-center
      ${featuredCard ? 'u-flex-direction-column' : 'u-flex-direction-column@md u-justify-content-space-around@md'}
      ${cartActive ? 'u-justify-content-space-between' : 'u-justify-content-flex-end'}
      ${isNTX ? 'card__section-item--ntx-cta' : ''}`
  );

  // ensure all phone numbers are formatted like 1-888-888-8888
  // assumes all phone numbers will come back either as 888-888-8888 or 1-888-888-8888
  const formatSupplierPhone = (num) => {
    const firstTwoNumbers = num.substring(0, 2);

    if (firstTwoNumbers !== '1-') {
      const newSupplierNumber = `1-${num}`;
      return newSupplierNumber;
    }

    return num;
  };

  return (
    <div className={classString}>
      {featuredCard && phoneActive && !isNTX && (
        <FuseNumbers
          className={`grid-button button--phone u-display-flex@lg u-justify-content-center@lg
            ${cartActive ? '' : 'button--only'}`}
          site={site}
        />
      )}
      {!featuredCard && phoneActive && !isNTX && (
        <>
            {electricPhoenixActive ? (
              <FuseNumbersEP
                className="grid-button button--phone u-display-flex@lg u-justify-content-center@lg"
                site={site}
              />
            ) : (
              <FuseNumbers
                className="grid-button button--phone u-display-flex@lg u-justify-content-center@lg"
                site={site}
              />
            )}
        </>
      )}
      {isNTX && supplierPhone && (
        <div
          className="grid-button button--phone u-display-flex@lg u-justify-content-center@lg grid-button--ntx"
        >
          <button type="submit" className="button--phone u-display-flex@lg u-justify-content-center@lg">
            <a
              className="ntx-phone-cta"
              id="ntx-phone-cta"
              href={`tel:${formatSupplierPhone(supplierPhone)}`}
            >
              Call now:
              {' '}
              <span className="ntx-phone-number-cta">{formatSupplierPhone(supplierPhone)}</span>
            </a>
          </button>
        </div>
      )}
      {cartActive && !disableCartLinks && !disableCart && (
        <a
          href={pdpTestActive ? pdpUrl : updatedCartUrl}
          className={`grid-button button--cart grid-button--no-arrow ${isNTX ? 'button--cart-ntx' : ''}`}
          target={isNTX ? '_blank' : '_self'}
          onClick={(e) => onOrderOnline(e, updatedCartUrl, plan)}
        >
          { ctaText || (isTxu ? txuCartCta : cartCtaCopy) }
        </a>
      )}
    </div>
  );
}

Cta.propTypes = {
  plan: PropTypes.shape({
    cartActive: PropTypes.bool.isRequired,
    cartUrl: PropTypes.string.isRequired,
    isNTX: PropTypes.bool.isRequired,
    supplier: PropTypes.shape({
      controls: PropTypes.shape({
        disableCart: PropTypes.bool.isRequired,
        disableCartLinks: PropTypes.bool.isRequired,
      })
    })
  }).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  className: PropTypes.string,
  site: PropTypes.string.isRequired,
  electricPhoenixActive: PropTypes.bool,
  electricPhoenixToggleActive: PropTypes.bool,
  electricPhoenixInput: PropTypes.shape({}),
  featuredCard: PropTypes.bool,
  supplierPhone: PropTypes.string,
  disableCartLinks: PropTypes.bool
};

Cta.defaultProps = {
  className: '',
  electricPhoenixActive: false,
  electricPhoenixToggleActive: false,
  electricPhoenixInput: {},
  featuredCard: false,
  supplierPhone: '',
  disableCartLinks: false
};
