import React from 'react';
import { Sites } from '@rvpower/constants';
import logoTXU from '../../images/txu-logo.png';
import image from '../../images/404.png';
import MaintenanceModeSOE from './MaintenanceModeSOE';

export default function MaintenanceMode({ site, fuseData }) {
  if (site === 'shop.txu.com') {
    return (
      <section className="maintenance maintenance--txu u-full-width">
        <div className="maintenance__banner">
          <div className="maintenance__banner-text">
            <p>Try us for 60 days with no strings</p>
            <p><strong>Total Satisfaction Guarantee*</strong></p>
          </div>
        </div>
        <div className="maintenance__container">
          <div className="maintenance__logo">
            <img src={logoTXU} alt="txu logo" className="maintenance__image" />
          </div>
          <div className="maintenance__text-wrapper">
            <p className="maintenance__text u-text-align-center">
              Our site is undergoing a bit of maintenance currently.
              <br />
              <strong>
                Give us a call at&nbsp;
                { fuseData.permalease }
                ,
              </strong>
              &nbsp;and our experts will help you find the right plan for your home.
            </p>
          </div>
        </div>
      </section>
    );
  }

  if (site === Sites.SOE) {
    return (
      <MaintenanceModeSOE />
    );
  }

  return (
    <section className="maintenance u-full-width">
      <img src={image} alt="maintenance" className="maintenance__image" />

      <p className="maintenance__text u-text-align-center">
        We&apos;re doing a bit of maintenance to improve your experience.
        <br />
        Please check back shortly to find a great energy plan.
      </p>
    </section>
  );
}
