/* eslint-disable max-len */
import React from 'react';
import Tippy from '@tippy.js/react';

// React Components
import CardDetailsTrigger from './CardDetailsTrigger';

// SVGs
import infoBubble from '../svg/info-bubble.svg';

function ComparisonCard(props) {
  const {
    filters,
    plan
  } = props;

  const {
    name,
    supplier,
    termText,
    type,
    price,
    estimate,
    usage
  } = plan;

  return (
    <article className="card card--comparison">
      <div className="card__section card__section--name u-display-flex@md u-justify-content-space-between@md u-align-items-center@md">
        <div className="name">
          <strong>{`${name} - `}</strong>
          {supplier.name}
        </div>
        <div className="term u-display-none u-display-block@md">
          <strong>{`Term: ${termText} | ${type}`}</strong>
        </div>
      </div>
      <div
        className="card__section
          u-display-flex
          u-justify-content-space-between
          u-flex-wrap-wrap
          u-justify-content-flex-start@md
          u-align-items-center@md"
      >
        <div
          className="card__section-item price
          u-display-flex
          u-flex-direction-column
          u-align-items-center"
        >
          <div>
            <span className="price__digits">{price}</span>
            <sup className="price__symbol">¢</sup>
          </div>
          <div className="price__settings">
            <strong>PER KWH</strong>
            <br />
            {`AT ${usage}KWH`}
          </div>
        </div>
        <div
          className="card__section-item estimate
          u-display-none u-display-flex@lg
          u-align-items-center u-justify-content-center"
        >
          <strong>$</strong>
          <strong>{estimate}</strong>
          <div className="estimate__details">
            monthly bill
            <br />
            estimate for
            <br />
            {`${usage} kWh*`}
            <Tippy
              content="Includes recurring charges excluding government fees and taxes.
                Confirm this estimate with Plan Documents. Bills vary according to actual usage."
              className="disclaimer"
              arrow
              placement="top-start"
            >
              <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
            </Tippy>
          </div>
        </div>
        <div className="
          card__section-item logo__container
          u-display-flex u-flex-direction-column u-align-items-flex-end u-justify-content-flex-end
          u-align-items-center@md u-justify-content-center@md"
        >
          <div className="term u-align-self-flex-end u-display-none@md">
            <strong>{`Term: ${termText} | ${type}`}</strong>
          </div>
        </div>
      </div>
      <CardDetailsTrigger plan={plan} filters={filters} />
    </article>
  );
}

export default ComparisonCard;
