const useEmailTracking = () => {
  const endpointURL = `${process.env.API_URL}/email/lookup`;

  const initialize = () => {
    const { _Cohesion: cohesion } = window;
    const urlParams = new URLSearchParams(window.location.search);
    const kuuid = urlParams.get('kuid');
    let params = {};
    if (cohesion && kuuid) {
      params = {
        uuid: kuuid,
        cohesion: {
          anonymousId: cohesion.anonymousId,
          sessionId: cohesion.sessionId,
          instanceId: cohesion.instanceId,
          tenantId: cohesion.tenantId,
          userAgent: navigator.userAgent,
          writeKey: cohesion.tagular.writeKey
        }

      };
    }

    fetch(endpointURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    });
  };

  return {
    initialize
  };
};

export default useEmailTracking;
