import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import DragPlanCards from './DragPlanCards';

export default function DragPlansContext(props) {
  const {
    setComparedPlans,
    comparedPlans,
    closeModal,
    ratingsEnabled,
    generateFee,
    site,
    cartCta
  } = props;
  const planList = Object.assign([], comparedPlans);

  const onDragEnd = (result) => {
    const { destination, source, reason } = result;
    const invalidMovement = destination?.droppableId === source?.droppableId && destination.index === source.index;
    const droppedOutsideOfBoard = !destination || reason === 'CANCELED';
    if (droppedOutsideOfBoard || invalidMovement) {
      return;
    }

    const droppedCard = comparedPlans[source.index];

    planList.splice(source.index, 1);
    planList.splice(destination.index, 0, droppedCard);
    setComparedPlans([...planList]);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <DragPlanCards
          comparedPlans={comparedPlans}
          setComparedPlans={setComparedPlans}
          closeModal={closeModal}
          ratingsEnabled={ratingsEnabled}
          generateFee={generateFee}
          site={site}
          cartCta={cartCta}
        />
      </DragDropContext>
    </>
  );
}
