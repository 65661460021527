import React from 'react';
import PropTypes from 'prop-types';

import buildClassList from '../../../js/project/buildClassList';

import Star from '../../../svg/star.svg';
import StarPill from '../../../svg/star-pill.svg';

const StarIcon = ({ className, type }) => (
  <img
    className={buildClassList('grid-v2-pills__pill-icon-star', className)}
    src={type === 'star-pill' ? StarPill : Star}
    alt="Stars"
  />
);

StarIcon.propTypes = {
  className: PropTypes.string,
};

StarIcon.defaultProps = {
  className: ''
};

export default StarIcon;
