import React, { useState, useEffect } from 'react';
import Radio from '../../radio';

const CreditScore = (props) => {
  const {
    filters,
    setFilters,
  } = props;

  const [selectedFilter, setSelectedFilter] = useState('Any');

  const creditBuckets = ['Any', 'Poor', 'Good', 'Excellent'];

  const handleChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  useEffect(() => {
    // Reset credit score filter when 'Any' is selected, otherwise set the selected filter
    // Context: the default value needs to be an empty array- see PillsDropdownObject.js (set up)
    // and see plan-filter.js (filtering logic)
    setFilters({
      ...filters,
      creditScore: selectedFilter === 'Any' ? [] : [selectedFilter?.toLowerCase()],
    });
  }, [selectedFilter]);

  // Reset selected filter when all filters are cleared
  useEffect(() => {
    if (filters.creditScore?.length === 0) {
      setSelectedFilter('Any');
    }
  }, [filters.creditScore]);

  return (
    <div className="grid-v2-credit-score">
      <p className="grid-v2-credit-score__content">
        Providers check credit to qualify you for certain types of plans, and each provider has different requirements.
      </p>
      <form
        className="grid-v2-credit-score__form"
        aria-labelledby="credit-score-filters"
      >
        {creditBuckets.map((bucket) => (
          <Radio
            checked={(filters?.creditScore && filters?.creditScore.length > 0) ? (
              filters?.creditScore[0] === bucket.toLowerCase()
            ) : (
              (!filters?.creditScore || filters?.creditScore.length === 0) && bucket === 'Any'
            )}
            className="grid-v2-credit-score__form--radio"
            key={bucket}
            id={`credit-score-${bucket.toLowerCase()}`}
            label={bucket}
            name={`grid-v2-credit-score-${bucket.toLowerCase()}`}
            value={bucket}
            onChange={(e) => handleChange(e)}
          />
        ))}
      </form>
    </div>
  );
};

export default CreditScore;
