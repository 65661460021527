/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UsageAmounts } from '@rvpower/constants';

import { usePreamp } from '../../preamp';
import { useMonarch } from '../../monarch';
import tracking from '../../../js/project/tagular/tracking';
import Ratings from '../../Ratings';
import FuseNumbers from '../../FuseNumbers';
import CardBadges from '../../grid-v2/CardBadges';

import arrowDetail from '../../../svg/detail-arrow-down.svg';
import FeaturedCardLabel from '../../grid-v2/FeaturedCardLabel';
import buildClassList from '../../../js/project/buildClassList';

function FeaturedCardV2({
  ESIID,
  plan,
  site,
  onOrderOnline,
  handleDetailsToggle,
  disableCartLinks,
  detailsOpen,
  selected,
  index,
}) {
  const {
    name,
    usageId,
    prices,
  } = plan;

  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;
  const [usageDisplayed, setUsageDisplayed] = useState('1000');
  const [kwhPrice, setKwhPrice] = useState('0');
  useEffect(() => {
    if (usageId && usageId !== '') {
      setUsageDisplayed(UsageAmounts[usageId].label);
      setKwhPrice(prices[UsageAmounts[usageId].label]);
    }
  }, [usageId]);

  let newCartUrl = plan.cartUrl;
  let cardPrice = plan.price;
  let estimateMultiplier = plan.usage;
  if (plan.usage === 1000) {
    cardPrice = kwhPrice;
    estimateMultiplier = usageDisplayed;
    const regex = /[?&]rateType=([^&]*)/;
    newCartUrl = plan.cartUrl.replace(regex, `&rateType=${usageDisplayed}`);
  }

  const ctaURL = ESIID ? `${newCartUrl}&esiid=${ESIID}` : newCartUrl;
  const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;

  const detailsAreOpened = detailsOpen && selected;

  let satisfactionBadge = false;
  let renewableBadge = false;
  let betterBusinessBadge = false;

  plan.badges.forEach((badge) => {
    if (badge.name.includes('satisfaction')) satisfactionBadge = badge;
    else if (badge.name.includes('renewable')) renewableBadge = badge;
    else if (badge.name.includes('bbb')) betterBusinessBadge = badge;
  });

  const handleOpenDetails = () => {
    // Lock mobile viewport to prevent background from scrolling
    if (detailsAreOpened) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');

    tracking.elementClicked({
      webElement: {
        location: 'FEATURED',
        elementType: 'Button',
        text: 'MORE DETAILS'
      },
      actionOutcome: `Plan details ${detailsAreOpened ? 'closed' : 'expanded'}`
    });
    handleDetailsToggle(plan, !detailsOpen);
  };

  const planTermText = plan.termText.replace(/Months/g, 'mos.').replace(/Month to Month/g, 'Monthly');
  const planMonthlyBillEstimate = Math.round(cardPrice * estimateMultiplier) || '0';
  const planPricePerKwh = (cardPrice * 100) ? (cardPrice * 100).toFixed(1) : '0';

  const { ctaText } = usePreamp('Cart - CTA', true) || '';

  const FCBaseClass = 'featured-plan-card-v2-vertical';
  const articleClass = buildClassList(
    FCBaseClass,
    plan.distinction ? `${FCBaseClass}__${plan.distinction}` : '',
  );

  const showCartCTA = plan.cartActive && !disableCartLinks && !plan?.supplier?.controls?.disableCart;
  const ctaSwap = usePreamp('CTA-Phone Swap');

  const handleCartClick = (e, targetUrl, targetPlan) => {
    e.preventDefault();

    const esiid = localStorage.getItem('esiid');
    const url = esiid ? `${targetUrl}&esiid=${esiid}` : targetUrl;

    onOrderOnline(e, url, targetPlan);
  };

  const kwhDisplayed = plan.usage === 1000 ? usageDisplayed : plan.usage;

  return (
    <article
      className={articleClass}
    >
      {plan.distinction === 'sponsored' && (
        <p className={`${FCBaseClass}__distinction-label`}>Promoted plan</p>
      )}
      <FeaturedCardLabel index={index} plan={plan} />
      <div className={`${FCBaseClass}__top-split`}>
        <div className={`${FCBaseClass}__top-medium`}>
          <div className={`${FCBaseClass}__top-section`}>
            <div className={`${FCBaseClass}__top-container`}>
              <div className={`${FCBaseClass}__title-container`}>
                {/* Title */}
                <div className={`${FCBaseClass}__title`}>{name}</div>
                {/* Logo & Rating */}
                <div className={`${FCBaseClass}__logo-reviews`}>
                  <img className={`${FCBaseClass}__logo`} src={plan.supplier.logo} alt={plan.supplier.name} />
                  <Ratings
                    ratings={plan.supplier.ratings}
                    boundary="scrollParent"
                    tippyClass="ratings-tippy--card"
                    supplierName={plan.supplier.name}
                    site={site}
                    newGridEnabled
                  />
                </div>
              </div>
              <div className={`${FCBaseClass}__price-badge-container`}>
                {/* Monthly Bill Estimate & Price per Kwh @ 1000kwh & Term Length */}
                <div className={`${FCBaseClass}__price-container`}>
                  <div className={`${FCBaseClass}__price-kwh`}>
                    <p className={`${FCBaseClass}__price-label`}>Price per kWh</p>
                    <p className={`${FCBaseClass}__price-label-secondary`}>{`at ${kwhDisplayed} kWh`}</p>
                    <strong className={`${FCBaseClass}__price-value`}>{`${planPricePerKwh}¢`}</strong>
                  </div>
                  <div className={`${FCBaseClass}__term-length`}>
                    <p className={`${FCBaseClass}__price-label`}>Term length</p>
                    <p className={`${FCBaseClass}__price-label-secondary`}>{`${plan.type} rate`}</p>
                    <strong className={`${FCBaseClass}__price-value`}>{planTermText}</strong>
                  </div>
                  <div className={`${FCBaseClass}__monthly-bill`}>
                    <p className={`${FCBaseClass}__price-label`}>Monthly bill</p>
                    <p className={`${FCBaseClass}__price-label`}>estimate*</p>
                    <strong className={`${FCBaseClass}__price-value`}>{`$${planMonthlyBillEstimate}`}</strong>
                  </div>
                </div>
                {/* Badges Medium Viewport */}
                <div className={`${FCBaseClass}__badges-medium`}>
                  <CardBadges
                    satisfactionBadge={satisfactionBadge}
                    renewableBadge={renewableBadge}
                    betterBusinessBadge={betterBusinessBadge}
                    featured
                  />
                </div>
              </div>
              {/* Badges Small Viewport */}
              <div className={`${FCBaseClass}__badges-small`}>
                <CardBadges
                  satisfactionBadge={satisfactionBadge}
                  renewableBadge={renewableBadge}
                  betterBusinessBadge={betterBusinessBadge}
                  featured
                />
              </div>
            </div>
          </div>
          {/* CTAs Small Breakpoint */}
          <div className={`${FCBaseClass}__cta-container`}>
            <div className={`${FCBaseClass}__cta-container-inner`}>
              {
                showCartCTA && (
                  <a
                    className={`${FCBaseClass}__cta ${FCBaseClass}__cta--cart ${ctaSwap ? `${FCBaseClass}__cta-cart-swap` : ''}`}
                    href={pdpTestActive ? pdpUrl : ctaURL}
                    onClick={(e) => handleCartClick(e, ctaURL, plan)}
                  >
                    { ctaText || 'Check availability' }
                  </a>
                )
              }
              <FuseNumbers
                className={`${FCBaseClass}__cta ${FCBaseClass}__cta--phone`}
                site={site}
                showCartCTA={showCartCTA}
                newGridEnabled
              />
            </div>
          </div>
          {/* Details trigger */}
          <div className={`plan-card-v2__details-trigger-container${detailsAreOpened ? '' : ' plan-card-v2__details-trigger-container--borderless'}`}>
            <button
              className={`${FCBaseClass}__details-trigger`}
              type="button"
              onClick={() => handleOpenDetails()}
            >
              <span className={`${FCBaseClass}__details-trigger-copy`}>
                {detailsAreOpened ? 'Less details' : 'More details'}
              </span>
              <img
                src={arrowDetail}
                className={`${FCBaseClass}__details-trigger-icon ${detailsAreOpened ? `${FCBaseClass}__details-trigger-icon--triggered` : ''}`}
                alt="Trigger details"
              />
            </button>
          </div>
        </div>
        {/* CTAs Medium Breakpoint */}
        <div className={`${FCBaseClass}__cta-container-medium ${detailsAreOpened ? `${FCBaseClass}__cta-container-medium--open` : ''}`}>
          {
            showCartCTA && (
              <a
                className={`${FCBaseClass}__cta ${FCBaseClass}__cta--cart ${ctaSwap ? `${FCBaseClass}__cta-cart-swap` : ''}`}
                href={pdpTestActive ? pdpUrl : ctaURL}
                onClick={(e) => handleCartClick(e, ctaURL, plan)}
              >
                { ctaText || 'Check availability' }
              </a>
            )
          }
          <div className={`${FCBaseClass}__cta-medium`}>
            <FuseNumbers
              className={`${FCBaseClass}__cta ${FCBaseClass}__cta--phone`}
              site={site}
              showCartCTA={showCartCTA}
              newGridEnabled
            />
          </div>
        </div>
      </div>
    </article>
  );
}

FeaturedCardV2.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
};

export default FeaturedCardV2;
