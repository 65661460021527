/* eslint-disable max-len */
const text = {
  faqItems: [
    {
      title: 'How soon can I start service?',
      copy: `
        <p class="accordion-item__copy">
          As soon as today – when you sign up by 6:30 p.m., your lights can be on tonight.<sup>1</sup>
        </p>
      `
    },
    {
      title: 'Can I change my plan at any time?',
      copy: `
        <p class="accordion-item__copy">
          We want you to be happy with your plan and confident that it’s the right fit. With our Total Satisfaction Guarantee, you can try us for 60 days with no strings attached. If your plan’s not the right fit, we’ll help you find one that is – or you can switch for free.<sup>**</sup>
        </p>
      `
    },
    {
      title: 'Will I need to pay a deposit?',
      copy: `
        <p class="accordion-item__copy">
          We’ll let you know if a refundable deposit is due after you select a plan and we run a soft credit check. If a deposit is due, we offer flexible payment options to help you get your lights on fast. Our team can tell you about additional options and help you find out if you’re eligible to have a deposit waived.
        </p>
      `
    },
    {
      title: 'What are the benefits of choosing TXU Energy?',
      copy: `
        <ul>
          <li>
            Plans that fit the way you live. From Free Nights to Free Pass to cash back rewards, we’ll help you save on electricity with plans that provide bill relief when you need it most.
          </li>
          <li>
            Straightforward pricing you can trust. We keep things simple with transparent plans and no hidden fees, ever. All our term plans come with our exclusive <b>Price Protect Promise℠</b>, which keeps you safe from fluctuating wholesale energy rates.<sup>*</sup>
          </li>
          <li>
            Best-in-class service. We’re here for you 24/7 to help with any questions about your electricity.
          </li>
          <li>
            Tools to help keep your life simple. Use our mobile app to pay your bill, check your usage and more.
          </li>
        </ul>
      `
    },
    {
      title: 'How do I know how much electricity I’ll use?',
      copy: '',
      usesFuse: true,
    },
  ],
  disclaimers: [
    {
      order: 1,
      text: 'Same-day service is available for customers with AMS meters who sign up before 6:30 p.m., Monday through Saturday. Sundays, holidays and Lubbock customers excepted.'
    },
    {
      order: 2,
      text: 'Just send us your final bill and we’ll cover the fee up to $150 with a credit on your TXU Energy bill.'
    },
    {
      order: 3,
      text: 'Price Protect Promise℠ applies to residential term contracted customers on fixed rate products. The only circumstance in which the price could change during the term is to reflect actual changes in law or regulatory charges as described in the Electricity Facts Label (EFL).'
    },
    {
      order: 4,
      text: 'Average based on official EIA research.'
    }
  ]
};

export default text;
