import React, { useState, useEffect } from 'react';
import { prodEnv, devEnv } from '../../js/config/config';

// Icons
import soeLogo from '../../../images/soe-logo.svg';
import closeIcon from '../../svg/close.svg';

const env = process.env.APP_ENV === 'production' ? prodEnv : devEnv;
const zipcodeEndpoint = env.ZIPCODE;
const texasRegex = /7([5-9]\d{3}|33\d{2})|885\d{2}/;

const getStateByZip = (zipCode, retryCount = 3) => fetch(`${zipcodeEndpoint}/${zipCode}`, {
  method: 'GET',
}).then((results) => results.json()).then(
  (data) => data.state
).catch(() => {
  if (retryCount === 1) {
    if (texasRegex.test(zipCode)) return 'TX';
    return null;
  }

  return getStateByZip(zipCode, retryCount - 1);
});

const submitZip = async (zipCode, clickId, site) => {
  const state = await getStateByZip(zipCode, 3);

  let gridUrl;

  if (state && state !== 'TX') {
    gridUrl = `${process.env.CHOOSE_URL}/shop/residential/electricity/${state}/${zipCode}?isNTX=true`;
  } else {
    gridUrl = env.SOE_URL;
    gridUrl += `/plans/?zipCode=${zipCode}`;
  }

  gridUrl += `&utm_source=AFFL&clickId=${clickId}`;

  if (site) {
    gridUrl += `&utm_campaign=${site}`;
  }

  window.postMessage(
    { type: 'affiliate-redirect', height: gridUrl },
    '*'
  );
};

const AffiliateZip = ({ clickId, site = '' }) => {
  const [localZipCode, setLocalZipCode] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const themeBuilder = (themeName) => {
    const grid = document.querySelector('#soe-affiliate-zip');

    if (!grid || !themeName) return;

    grid.classList.add(`theme-${themeName}`);
  };

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitZip(localZipCode, clickId, site);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    themeBuilder();
  }, []);

  const containerObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect.height && window) {
        window.postMessage(
          { type: 'affiliate-resize', height: entry.contentRect.height },
          '*'
        );
      }
    });
  });

  useEffect(() => {
    const widgetContainer = document.querySelector('.widget-container');
    if (widgetContainer) containerObserver.observe(widgetContainer);

    return () => {
      containerObserver.unobserve(widgetContainer);
    };
  }, []);

  return (
    <div className="widget-container">
      {modalIsOpen ? (
        <div className="ad-disclosure-modal-overlay">
          <div className="ad-disclosure-modal">
            <div className="ad-disclosure-modal__header">
              <h3 className="ad-disclosure-modal__headline">Advertising disclosure</h3>
              <button className="ad-disclosure-modal__close-icon" onClick={closeModal} type="button">
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
            <div className="ad-disclosure-modal__content">
              {/* eslint-disable-next-line max-len */}
              <p className="ad-disclosure-modal__copy">This advertising widget is powered by SaveOnEnergy and contains advertisements that SaveOnEnergy may be paid for in different ways. You will not be charged for engaging with this advertisement. While we strive to provide a wide range of offers, this advertising widget does not include information about every product or service that may be available to you. We make reasonable efforts to ensure that information in the featured advertisements is up to date, each advertiser featured in this widget is responsible for the accuracy and availability of its offer details. It is possible that your actual offer terms from an advertiser may be different than the offer terms in this advertising widget and the advertised offers may be subject to additional terms and conditions of the advertiser which will be presented to you prior to making a purchase. All information is presented without any warranty or guarantee to you.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="affiliate-zip">
          <div className="affiliate-zip__main">
            <p className="affiliate-zip__heading">
              Find energy plans
              {' '}
              <span>in your area</span>
            </p>
            <p className="affiliate-zip__subheading">What is your service location?</p>
            <form className="affiliate-zip__input-section" onSubmit={handleSubmit}>
              <input
                className="affiliate-zip__input"
                type="text"
                inputMode="numeric"
                placeholder="ZIP code"
                value={localZipCode}
                onChange={(e) => handleChange(e)}
                maxLength={5}
                pattern="[0-9]{5}"
                title="Please enter a valid zip code"
                required
              />
              <button type="submit" className="affiliate-zip__cta">
                Check availability
              </button>
            </form>
          </div>
          <div className="affiliate-zip__footer">
            <div className="affiliate-zip__logo-container">
              <span>Powered by</span>
              <img
                className="affiliate-zip__logo"
                src={soeLogo}
                alt="soe-logo"
              />
            </div>
            <div className="affiliate-zip__links-container">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="affiliate-zip__link"
                href="https://www.saveonenergy.com/privacy/"
              >
                Privacy Policy
              </a>
              <span>|</span>
              <button
                className="affiliate-zip__link"
                type="button"
                onClick={() => setModalIsOpen(true)}
              >
                Advertiser Disclosure
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateZip;
