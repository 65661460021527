import React from 'react';
import PropTypes from 'prop-types';

import { CustomFuseLink } from './FuseNumbers';

function GridPhoneBanner(props) {
  const { text, site } = props;
  let fuseData;
  try {
    fuseData = JSON.parse(window.fuseJson);
  } catch (error) {
    console.error(error);
  }

  return (
    <div className="grid-phone-banner">
      <span>{text}</span>
      <CustomFuseLink site={site} fuseData={fuseData?.banner} />
    </div>
  );
}

GridPhoneBanner.propTypes = {
  text: PropTypes.string,
  site: PropTypes.string,
};

GridPhoneBanner.defaultProps = {
  text: '',
  site: '',
};

export default GridPhoneBanner;
