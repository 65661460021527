import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';

import tracking from '../../../js/project/tagular/tracking';
import CloseIcon from './CloseIcon';

const popperOptions = {
  modifiers: {
    preventOverflow: { enabled: false },
    hide: { enabled: false }
  }
};

const TippyInstance = ({
  children,
  content,
  placement,
  resetFilters,
  resetText,
  setInstance,
  setIsTippyOpen,
  title,
  index
}) => {
  const [resetTrigger, setResetTrigger] = useState(0);
  const clonedElement = React.cloneElement(content, { resetTrigger });
  const outerDivElement = document.getElementById('grid');

  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX');

  const formattedTitle = (filterTitle) => {
    if (filterTitle === 'Rating') return 'Provider rating';
    if (filterTitle === 'Price') return 'Monthly bill price';
    return filterTitle;
  };

  const handleClose = () => {
    tracking.elementClicked({
      webElement: {
        elementType: 'DROPDOWN',
        location: 'GRID HERO',
        name: 'FILTER DROPDOWNS',
        text: title,
        position: index.toString(),
      },
      actionOutcome: 'COLLAPSE'
    });
  };

  const footerClass = `grid-v2-pills__tooltip-instance-footer grid-v2-pills__tooltip-instance-footer__${title}`;

  const handleReset = () => {
    setResetTrigger(resetTrigger + 1);
    resetFilters();
  };

  return (
    <Tippy
      className={`${isNTX ? 'ntx' : ''}`}
      content={
        (
          <div className={`grid-v2-pills__tooltip-instance ${isNTX ? 'ntx' : ''}`}>
            <div className="grid-v2-pills__tooltip-instance-header">
              <h4 className="grid-v2-pills__tooltip-instance-title">
                { formattedTitle(title) }
              </h4>
              <button
                data-close-popper
                type="button"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>
            {clonedElement}
            <div
              className={footerClass}
            >
              <button
                className="grid-v2-pills__tooltip-instance-footer-button"
                data-close-popper
                type="button"
                onClick={handleReset}
              >
                {resetText}
              </button>
            </div>
          </div>
        )
      }
      placement={placement}
      flip={false}
      trigger="click"
      theme={title === 'Utility' ? 'desktop-pills-ntx-utility' : 'desktop-pills'}
      appendTo={outerDivElement}
      popperOptions={popperOptions}
      interactive
      onShow={(instance) => {
        const closeButton = instance.popperChildren.tooltip.querySelectorAll('[data-close-popper]');
        setInstance(instance);
        setIsTippyOpen(true);
        closeButton.forEach((inst) => inst.addEventListener('click', () => {
          instance.hide();
        }));
      }}
      onHide={(instance) => {
        const closeButton = instance.popperChildren.tooltip.querySelectorAll('[data-close-popper]');
        setInstance(instance);
        setIsTippyOpen(false);
        closeButton.forEach((inst) => inst.removeEventListener('click', () => {
          instance.hide();
        }));
      }}
    >
      {children}
    </Tippy>
  );
};

export default TippyInstance;

TippyInstance.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  placement: PropTypes.oneOf(['bottom-start', 'bottom-end', 'bottom']),
  resetFilters: PropTypes.func.isRequired,
  resetText: PropTypes.string,
  setInstance: PropTypes.func.isRequired,
  setIsTippyOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number,
};

TippyInstance.defaultProps = {
  resetText: 'Reset',
  placement: 'bottom-start',
  index: 0,
};
