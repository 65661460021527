import React, { useRef } from 'react';
import { ProductViewed, ProductPriceViewed } from '@rvpower/track';
import { getPlan } from '../js/project/tracking-helper';

// React Components
import ComparisonCard from './ComparisonCard';
import VisibleTracker from './VisibleTracker';

function ComparisonCards(props) {
  const { plans } = props;
  const date = new Date();

  const productsViewed = useRef({});
  const productViewed = (p) => {
    if (!productsViewed.current[p.productId]) {
      productsViewed.current[p.productId] = true;
      ProductViewed(p);
      ProductPriceViewed(p);
    }
  };

  return (
    <section className="cards">
      <h2 className="cards__heading">
        Compare to&nbsp;
        {plans[0].supplier.name}
      </h2>
      <div className="cards__cards">
        {plans.map((plan, i) => {
          const productInfo = getPlan(plan, i + 1, 'GRID');
          return (
            <VisibleTracker onVisible={() => productViewed(productInfo)} key={plan.id}>
              <ComparisonCard key={plan.id} plan={plan} date={date} />
            </VisibleTracker>
          );
        })}
      </div>
      <div className="legal-disclaimer">
        **Comparison products and rates for&nbsp;
        {plans[0].supplier.name}
        &nbsp;listed on powertochoose.org as of&nbsp;
        {date.toLocaleString()}
      </div>
    </section>
  );
}

export default ComparisonCards;
