import React from 'react';

import lightningIcon from '../../../images/lightning-icon.svg';
import closeIcon from '../../../images/close-icon.svg';

const HmcSuccessBanner = (props) => {
  const {
    updateHmcBannerToggle,
    hmcData,
  } = props;

  return (
    <div className="grid-v2__column">
      <div className="hmc-grid-banner">
        <div className="hmc-grid-banner__content">
          <img
            src={lightningIcon}
            alt="lightning icon"
            className="hmc-grid-banner__lightning-icon"
          />
          <p className="hmc-grid-banner__text">
          Your home details helped us determine your average monthly energy usage of
            <strong className="hmc-grid-banner__kwh">
            &nbsp;
              {hmcData?.estimatedUsage}
            &nbsp;kWh
            </strong>
          .
          </p>
        </div>
        <div className="hmc-grid-banner__button-wrapper">
          <button
            type="button"
            className="hmc-grid-banner__close-button"
            onClick={() => updateHmcBannerToggle(false)}
          >
            <img
              src={closeIcon}
              alt="close icon"
              className="hmc-grid-banner__close-icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HmcSuccessBanner;
