import { createElement } from 'react';
import ReactDOM from 'react-dom';
import beam from '../../../js/project/tagular/beam';

import SolarCardGeneric1 from './SolarCardGeneric1';
import SolarCardGeneric2 from './SolarCardGeneric2';
import SolarCardGeneric3 from './SolarCardGeneric3';
import SolarBrandedCard from './SolarBrandedCard';
import SolarNonBrandCard from './SolarNonBrandCard';

const SolarCardComponents = {
  'generic option 1': SolarCardGeneric1,
  'generic option 2': SolarCardGeneric2,
  'generic option 3': SolarCardGeneric3,
  'solar branded card': SolarBrandedCard,
  'solar nonbrand card': SolarNonBrandCard,
};

function trackElementViewed(component) {
  const elementViewedPayload = {
    webElement: {
      elementType: component,
      location: 'NTX Grid',
    }
  };
  beam.userTrackingV3('ElementViewed', elementViewedPayload);
}

// The placement of the solar card is an editable "asset" for this rule.
// The below functions handles proper placement of the components based on the input
export function handleMonarchPlacement(position, componentName, props) {
  const propData = props;
  propData.trackElementViewed = trackElementViewed;
  const component = SolarCardComponents[componentName];
  const parentNode = document.querySelector(
    '#grid > div > div.grid-v2__column.ntx > section.cards > div > div:nth-child(1)'
  );
  const monarchElement = document.createElement('div');
  monarchElement.setAttribute('id', 'monarch-placement');
  ReactDOM.render(createElement(component, propData), monarchElement);

  if (parentNode) {
    parentNode.setAttribute('id', 'cards-parent-node');
    const target = document.querySelector(`#cards-parent-node > div:nth-child(${position})`);

    if (target) {
      parentNode.insertBefore(monarchElement, target);
    }
  }
}

export function returnComponent(result) {
  const componentName = result.componentName?.toLowerCase();
  const { position } = result;

  if (SolarCardComponents[componentName] !== undefined) {
    handleMonarchPlacement(position, componentName, result);
  }
}
