import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import tracking from '../js/project/tagular/tracking';

if (document.querySelector('#js-grid')) {
  Modal.setAppElement('#js-grid');
}

export default function UtilitySelectModal({
  utilities,
  setSelectedUtility,
  selectedUtility
}) {
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const openModal = () => {
    setmodalIsOpen(true);
  };

  const closeModal = () => {
    setmodalIsOpen(false);
  };

  if (utilities.length > 1 && !selectedUtility.id && !modalIsOpen) {
    openModal();
  }

  const handleSubmit = (utility) => {
    tracking.elementClicked({
      webElement: {
        location: 'MODAL',
        elementType: 'LINK',
        name: 'NTX Utility Selection',
        text: utility.name,
      },
      actionOutcome: 'INTERNALLINK',
    });
    setSelectedUtility(utility.id);
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="grid-modal-overlay"
        className="grid-modal-content utility-select-modal"
      >
        <h3 className="utility-select-modal__title">Select your utility</h3>
        <p className="utility-select-modal__sub-title">Select your electric utility:</p>
        {utilities.map((utility) => (
          <button
            key={uuid()}
            className="utility-select-modal__item"
            type="button"
            value={utility.id}
            onClick={() => handleSubmit(utility)}
          >
            {utility.name}
          </button>
        ))}
      </Modal>
    </div>
  );
}

UtilitySelectModal.propTypes = {
  utilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedUtility: PropTypes.shape().isRequired,
  setSelectedUtility: PropTypes.func.isRequired
};
