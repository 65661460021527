import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';
import GctModal from '../modal/GctModal';
import GctToolbarCard from './GctToolbarCard';
import useClickOutside from '../../custom-hooks/useClickOutside';

const tagularGroupIds = {};

function GctToolbar(props) {
  const {
    plansSelected,
    comparedPlans,
    setComparedPlans,
    setTriggerModal,
    triggerModal,
    site,
    ratingsEnabled,
    usage,
    generateFee,
  } = props;
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const plansTooltipRef = useRef();

  // determines whether we show the full slide or buttons only
  useEffect(() => {
    if (comparedPlans.length === 0) { setIsTooltipActive(false); }
  }, [comparedPlans.length]);

  const track = () => {
    const groupHash = [];
    const selectedPlans = () => comparedPlans.map((plan, index) => {
      groupHash.push(plan.id);
      const { name, prices, roundedPricePerKwh } = plan;
      const position = index + 1;
      const variant = plan.term.length?.toString() || '0';
      const price = Number(roundedPricePerKwh) || Number(prices[usage]);
      const sku = plan.utility.name;
      const brand = plan.supplier.name;
      const location = 'Comparison Tool';
      return {
        name,
        position,
        variant,
        price,
        sku,
        location,
        brand
      };
    });
    const plans = selectedPlans();
    let listId;
    const haskKey = groupHash.sort().join('');
    if (tagularGroupIds[haskKey]) {
      listId = tagularGroupIds[haskKey];
    } else {
      listId = uuidV4();
      tagularGroupIds[haskKey] = listId;
    }

    if (window.tagular) {
      window.tagular('beam', 'ProductListFiltered', {
        '@type': 'redventures.ecommerce.v1.ProductListFiltered',
        listId,
        product: [...plans]
      });
    }
  };

  const openModal = () => {
    track();
    setTriggerModal(true);
  };

  const [animation, setAnimation] = useState('');

  useEffect(() => {
    setAnimation(' has-animation');

    setTimeout(() => {
      setAnimation('');
    }, 2000);
  }, [plansSelected]);

  const ignoreClickClasses = ['checkbox', 'checkbox__label', 'checkbox__span',
    'gct-toolbar__plans-selected', 'gct-toolbar__content'];
  useClickOutside(plansTooltipRef, () => setIsTooltipActive(false), ignoreClickClasses);

  return (
    <>
      { triggerModal && comparedPlans.length && (
      <GctModal
        triggerModal={triggerModal}
        setTriggerModal={setTriggerModal}
        comparedPlans={comparedPlans}
        setComparedPlans={setComparedPlans}
        site={site}
        ratingsEnabled={ratingsEnabled}
        usage={usage}
        generateFee={generateFee}
      />
      )}
      <div className="gct-toolbar">
        <div className="gct-toolbar__elements-wrapper">
          <div
            ref={plansTooltipRef}
            className={`gct-toolbar__plans-tooltip${isTooltipActive ? '' : ' is-hidden'}`}
          >
            {comparedPlans.map((item, i) => (
              <GctToolbarCard
                comparedPlans={comparedPlans}
                setComparedPlans={setComparedPlans}
                item={item}
                i={i}
                key={item.id}
                usage={usage}
              />
            ))}
            <button
              className="gct-toolbar__clear-plans"
              type="button"
              onClick={() => setComparedPlans([])}
            >
              Clear all plans
            </button>
          </div>
          <button
            onClick={() => {
              setIsTooltipActive(!isTooltipActive);
            }}
            onKeyDown={(e) => e.keyCode === 13 && setIsTooltipActive(!isTooltipActive)}
            className="gct-toolbar__content"
            type="button"
            disabled={plansSelected < 1}
          >
            <span
              className={`gct-toolbar__plans-selected
              ${plansSelected > 0 ? animation : ''}`}
            >
              {plansSelected}
            </span>
            {` plan${plansSelected === 1 ? '' : 's'} selected`}
          </button>
          <button
            className="gct-toolbar__compare grid-button"
            type="button"
            onClick={openModal}
          >
            compare
          </button>
        </div>
      </div>
    </>
  );
}

export default GctToolbar;
