const getIncentivePerSize = (incentives) => {
  const params = new URLSearchParams(window.location.search);
  let incentiveType = '';
  if (params.get('a') === 'apartmenty') {
    incentiveType = 'multiFamilyIncentive';
  }

  if (params.get('a') === 'apartmentn') {
    incentiveType = 'singleFamilyIncentive';
  }

  if (!incentives) {
    return null;
  }

  if (!incentives[incentiveType]?.showIncentive) {
    return null;
  }

  if (!incentives[incentiveType]?.offerText) {
    return null;
  }

  return {
    ...incentives[incentiveType],
    // replace the first <br> at the beginning of the string
    disclaimer: incentives[incentiveType].disclaimer.replace(/^<br>/, ''),
  };
};

export default getIncentivePerSize;
