import React from 'react';
import { Sites } from '@rvpower/constants';

import buildClassList from '../../js/project/buildClassList';
import iconCheck from '../../../images/icon-check-simple.svg';

function CardBadgesSimplified(props) {
  const {
    satisfactionBadge,
    renewableBadge,
    betterBusinessBadge,
    featured = false,
    site,
  } = props;

  const simplifiedFCsActive = site === Sites.SOE;
  // betterBusinessBadge is a constant
  let betterBusinessBadgeBool = betterBusinessBadge;

  // Only 2 badges are allowed on the simplified FCs
  if (simplifiedFCsActive && satisfactionBadge && satisfactionBadge.attributeValue && renewableBadge) {
    betterBusinessBadgeBool = false;
  }

  const featuredClassName = buildClassList(
    featured && 'featured-plan-card-v2-vertical',
    !featured && 'plan-card-v2',
  );

  return (
    <div className={`${featuredClassName}__badges`}>
      {satisfactionBadge && satisfactionBadge.attributeValue
        && (
        <div
          className={`${featuredClassName}__badge ${featuredClassName}__badge--satisfaction-guarantee`}
        >
          <p className={`${featuredClassName}__badge-title`}>
            <img src={iconCheck} alt="Satisfaction Guarantee" />
            {`${satisfactionBadge.attributeValue}-Day`}
            {' '}
satisfaction guarantee
          </p>
        </div>
        )}
      {renewableBadge?.attributeValue
        && (
        <div className={`${featuredClassName}__badge ${featuredClassName}__badge--renewable`}>
          <p className={`${featuredClassName}__badge-title`}>
            <img src={iconCheck} alt="Percent Renewable" />
            {`${renewableBadge.attributeValue}%`}
            {' '}
Renewable
          </p>
        </div>
)}
      {betterBusinessBadgeBool
        && (
        <div className={`${featuredClassName}__badge ${featuredClassName}__badge--bbb-rating`}>
          <p className={`${featuredClassName}__badge-title`}>
            <img src={iconCheck} alt="BBB rating" />
            A+ BBB rating
          </p>
        </div>
        )}
    </div>
  );
}

export default CardBadgesSimplified;
