/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import tracking from '../../../js/project/tagular/tracking';

import soeBrandLogo from '../../../../images/soe-logo-grey.svg';
import arrowIcon from '../../../../images/arrow-long-right.svg';
import VisibleTracker from '../../VisibleTracker';

const SolarCardGeneric2 = ({
  ctaText,
  header,
  subheader,
  trackElementViewed,
}) => {
  const ctaLink = 'https://www.saveonenergy.com/solar-energy/solar-estimator/';

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID - Solar Card Generic 2',
        elementType: 'Button',
        text: 'Get started',
      },
      actionOutcome: 'Navigate to solar lead form'
    });
  };

  return (
    <VisibleTracker onVisible={() => trackElementViewed('Solar card generic option 2')}>
      <div className="solar-generic-2__container">
        <div className="solar-generic-2__wrapper">
          <div className="solar-generic-2__content">
            <div className="solar-generic-2__content__headers">
              <div>{header}</div>
              <div>{subheader}</div>
            </div>
            <a
              className="solar-generic__cta-button"
              href={ctaLink}
              onClick={handleClick}
              target="_blank"
            >
              {ctaText}
              <img
                alt="Get started arrow icon"
                className="solar-generic__cta-button__icon"
                src={arrowIcon}
              />
            </a>
            <div className="solar-generic-2__content__logo-wrapper">
              <div>Powered by</div>
              <img
                alt="SOE logo"
                className="solar-generic__brand-logo"
                src={soeBrandLogo}
              />
            </div>
          </div>
        </div>
        <div className="solar-generic-2__background" />
      </div>
    </VisibleTracker>
  );
};

export default SolarCardGeneric2;
