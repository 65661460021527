function tagularBeam(eventName, data, webContext = true) {
  if (window.tagular) {
    // false flag is used to disable session renewal
    // webContext is used to attach webContext to event
    window.tagular('beam', eventName, data, false, webContext);
  }
}

function userTrackingV2(eventName, data = {}) {
  tagularBeam(eventName, {
    '@type': `redventures.usertracking.v2.${eventName}`,
    ...data
  });
}

function userTrackingV3(eventName, data = {}) {
  tagularBeam(eventName, {
    '@type': `redventures.usertracking.v3.${eventName}`,
    ...data
  });
}

export function energyTracking(eventName, data = {}, version = 'v1', webContext = true) {
  tagularBeam(eventName, {
    '@type': `energy.${eventName}.${version}`,
    ...data,
  }, webContext);
}

export default {
  userTrackingV2,
  userTrackingV3,
};
