import React from 'react';
import PropTypes from 'prop-types';
import tracking from '../../../../js/project/tagular/tracking';

import { compareTermText } from '../../../../js/project/mappers';

import Checkbox from '../../checkbox/index';

const formattedLabel = (label) => {
  // changed in termTypeToText originally
  if (label === 'Month to Month') {
    return 'Monthly';
  }

  return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
};

const TermLength = ({
  filters,
  plans,
  terms,
  setFilters
}) => {
  const termsCheckboxes = terms.sort(compareTermText).map((term) => ({
    name: term,
    value: term,
    label: formattedLabel(term),
    checked: filters.terms.includes(term),
    number: plans.filter((plan) => plan.termText === term).length
  }));

  const addFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: [...filters[filterType], filterValue]
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'CHECKBOX',
        location: 'TERM LENGTH FILTER',
        name: 'FILTER DROPDOWNS',
        position: '4',
        text: `${filterType}: ${filterValue}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const removeFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: filters[filterType].filter((f) => f !== filterValue)
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'CHECKBOX',
        location: 'TERM LENGTH FILTER',
        name: 'FILTER DROPDOWNS',
        position: '4',
        text: `${filterType}: ${filterValue}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const termsSection = {
    sectionLabel: 'Terms',
    checkboxes: termsCheckboxes,
    addFilter: addFilter('terms'),
    removeFilter: removeFilter('terms'),
  };

  return (
    <div className="grid-v2-term-length">
      {termsSection?.checkboxes.map((checkbox) => (
        <Checkbox
          key={checkbox.name}
          checkbox={checkbox}
          addFilter={termsSection.addFilter}
          removeFilter={termsSection.removeFilter}
        />
      ))}
    </div>
  );
};

export default TermLength;

PropTypes.propTypes = {
  filters: PropTypes.shape({
    customPlanOrder: PropTypes.array.isRequired,
    features: PropTypes.array.isRequired,
    providers: PropTypes.array.isRequired,
    rateTypes: PropTypes.array.isRequired,
    ratings: PropTypes.array.isRequired,
    terms: PropTypes.array.isRequired,
    usage: PropTypes.number.isRequired
  }).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({
    supplier: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    termText: PropTypes.string.isRequired,
  })).isRequired,
  setFilters: PropTypes.func.isRequired,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired
};
