import React from 'react';

function Checkbox(props) {
  const {
    checkbox, addFilter, removeFilter, isCheckboxHidden, isNTX
  } = props;
  const {
    checked, label, name, value, number
  } = checkbox;

  const check = () => {
    if (!checked) {
      addFilter(value);
    } else {
      removeFilter(value);
    }
  };

  const shownProviders = [
    '4Change Energy',
    'Constellation',
    'Direct Energy',
    'Reliant',
    'TXU Energy',
    'Payless Power'
  ];

  const displayClass = (shownProviders.includes(name) || isCheckboxHidden || isNTX)
    ? 'u-display-flex'
    : 'u-display-none';

  return (
    <div className={`checkbox__container u-align-items-center ${displayClass}`}>
      <div className="checkbox" onClick={check}>
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          readOnly
        />
        <span />
        <span className="checkbox__label">{label}</span>
        <span className="checkbox__number">{`(${number})`}</span>
      </div>
    </div>
  );
}

export default Checkbox;
