import React from 'react';
import { usePreamp } from '../preamp';
import txuLogo from '../../../images/txu-logo.png';
import { useMonarch } from '../monarch';

function GuaranteeBanner({ location }) {
  const { rules } = useMonarch();

  const heading = usePreamp('Grid-Guarantee-Banner-Middle-Editable-Headline')
    || rules?.source?.gridGuaranteeBannerMiddle?.value?.headline;

  const subheading = usePreamp('Grid-Guarantee-Banner-Middle-Editable-Subheadline')
    || rules?.source?.gridGuaranteeBannerMiddle?.value?.subheadline;

  return (
    <div className="txu-redesign guarantee-banner__container">
      <div className={`guarantee-banner ${location}-position`}>
        <img className="txu-logo" src={txuLogo} alt="txu logo" />
        <span className="try-us-span">
          {heading || 'Shop confidently with Texas’ most trusted provider.'}
        </span>
        <span className="total-satisfaction-span">
          {subheading || 'Total Satisfaction Guarantee*'}
        </span>
      </div>
    </div>
  );
}

export default GuaranteeBanner;
