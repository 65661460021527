import React, { createContext, useState } from 'react';

export const PlanContext = createContext();

export const PlanContextProvider = ({ children, value }) => {
  const [plan, setPlan] = useState(value);

  return (
    <PlanContext.Provider value={{ plan, setPlan }}>
      {children}
    </PlanContext.Provider>
  );
};

export const usePlanPlanContext = () => {
  const context = React.useContext(PlanContext);

  if (context === undefined) {
    throw new Error(
      'usePlanPlanContext must be used within a PlanContextProvider'
    );
  }

  return context;
};
