/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import txuLogo from '../../images/txu-logo.png';

const reviewsUrl = 'https://www.google.com/maps/place/TXU+Energy+Corporate+Office/@32.8984572,-96.9663083,17z/data=!4m7!3m6!1s0x0:0xf442d96f72986dfa!8m2!3d32.8984572!4d-96.9641196!9m1!1b1';

function GoogleReviewsBannerComments() {
  return (
    <div className="google-reviews-banner__container">
      <div className="google-reviews-banner__content">
        <div className="rate-content">
          <div className="google-reviews-banner logo-container">
            <img className="txu-logo" src={txuLogo} alt="txu logo" />
            <span className="banner-text">Most trusted provider by Texans</span>
          </div>
          <div className="google-reviews-banner rate-container">
            <div className="counter-container">
              <span>
                Rated
                <b> 4.6/5 </b>
                | Based on 2000+
              </span>
              <a href={reviewsUrl} target="_blank" rel="noopenner noreferrer"> Google reviews</a>
            </div>
          </div>
        </div>
        <div className="comments-container">
          <div className="single-comment">
            <div className="stars-container">
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--emptyStar" alt="emptyStar icon" />
            </div>
            <div className="comment-content">
              <span>Great experience with their online chat customer service, and great rates!</span>
              <span className="comment-name">Leah H</span>
            </div>
          </div>
          <div className="single-comment--second">
            <div className="stars-container">
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            </div>
            <div className="comment-content">
              <span>Love this company, great prices and very friendly service. I will never leave this company.</span>
              <span className="comment-name">Ashley O</span>
            </div>
          </div>
          <div className="single-comment--third">
            <div className="stars-container">
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
              <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            </div>
            <div className="comment-content">
              <span>Very helpful in helping select the best plan based on my consumption...They share with me ways to save even more.</span>
              <span className="comment-name">Brigitte P.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleReviewsBannerComments;
