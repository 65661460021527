import React, { useRef } from 'react';
import Toggle from 'react-toggle';
import Carousel from 'react-multi-carousel';
import { ProductViewed, ProductClicked } from '@rvpower/track';
import { Sites } from '@rvpower/constants';
import { isMobile } from 'react-device-detect';
import { getPlan } from '../js/project/tracking-helper';
import { usePreamp } from './preamp';
import { useMonarch } from './monarch';

import TemplateFeaturedCard from './cards/templates/TemplateFeaturedCard';
import TemplateTXUFeaturedCard from './cards/templates/TemplateTXUFeaturedCard';
import VisibleTracker from './VisibleTracker';
import TXUShapes from './TXUShapes';
import RateBanner from './RateBanner';
import GuaranteeBanner from './GuaranteeBanner';
import GridTrustBuildingBanner from './grid-trust-building-banner/GridTrustBuildingBanner';

function FeaturedCards(props) {
  const {
    ESIID,
    featuredPlans,
    site,
    shouldAbortLoadingPlans,
    gridComparisonToolActive,
    comparedPlans,
    setComparedPlans,
    disableCartLinks,
    electricPhoenixToggleActive,
    toggleEP,
    dwellingType,
    newGridEnabled = false,
  } = props;

  const funcs = useRef([]);
  const registerIsVisbileCheck = (f) => funcs.current.push(f);
  const dispatchIsVisibleChecks = () => funcs.current.forEach((f) => f());
  const isTXU = site === Sites.TXU;
  const isChoose = site === Sites.CHOOSE;
  const isSOE = site === Sites.SOE;
  const showRateBanner = isSOE || isChoose;
  const SOEesiid = isSOE ? ESIID : '';

  const gridTrustBuildingBanner = usePreamp('Trust Building - MP Grid');
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;

  const responsive = {
    desktop: {
      breakpoint: { max: 30000, min: 1025 },
      items: featuredPlans.length < 3 ? featuredPlans.length : 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: featuredPlans.length < 2 ? featuredPlans.length : 2,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 100
    },
    smallMobile: {
      breakpoint: { max: 374, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 75
    }
  };

  if (shouldAbortLoadingPlans(featuredPlans) === true) {
    return null;
  }

  const headerMessage = isTXU ? 'Recommended Plans' : 'Recommended plans in your area';
  const txuClass = isTXU ? ' featured-cards--txu' : '';

  return (
    <section className={`featured-cards u-full-width${txuClass}`}>
      {showRateBanner && <RateBanner site={site} />}
      {isTXU && isMobile && <GuaranteeBanner />}
      {isTXU && <TXUShapes />}
      {/* eslint-disable-next-line max-len */}
      <h1 className={`featured-cards__heading ${electricPhoenixToggleActive && 'ep-active'} u-text-align-center u-position-relative`}>{headerMessage}</h1>
      {electricPhoenixToggleActive && (
        <div className="ep-toggle">
          <div className="ep-toggle__text">
            Customize results for savings
          </div>
          <Toggle
            onChange={toggleEP}
            className="ep-toggle__slider"
            aria-label="Toggle"
          />
        </div>
      )}
      <div className="featured-cards__cards u-position-relative">
        <Carousel
          arrows={false}
          partialVisbile
          responsive={responsive}
          showDots
          afterChange={dispatchIsVisibleChecks}
        >
          {featuredPlans.map((plan, i) => {
            const productInfo = getPlan(plan, i + 1, 'FEATURED');
            const pdpProductInfo = { ...productInfo, actionOutcome: 'Entered PDP' };

            const onOrderOnline = (e, cartUrl, planObject) => {
              e.preventDefault();

              const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;
              window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
              ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);

              setTimeout(() => {
                window.location = pdpTestActive ? pdpUrl : cartUrl;
              }, 150);
            };

            return (
              <VisibleTracker
                onVisible={() => ProductViewed(productInfo)}
                registerIsVisbileCheck={registerIsVisbileCheck}
                key={plan.id}
              >
                {isTXU ? (
                  <TemplateTXUFeaturedCard
                    comparedPlans={comparedPlans}
                    disableCartLinks={disableCartLinks}
                    key={plan.id}
                    gridComparisonToolActive={gridComparisonToolActive}
                    onOrderOnline={onOrderOnline}
                    plan={plan}
                    site={site}
                    setComparedPlans={setComparedPlans}
                    dwellingType={dwellingType}
                  />
                ) : (
                  <TemplateFeaturedCard
                    ESIID={SOEesiid}
                    key={plan.name}
                    plan={plan}
                    onOrderOnline={onOrderOnline}
                    site={site}
                    gridComparisonToolActive={gridComparisonToolActive}
                    comparedPlans={comparedPlans}
                    setComparedPlans={setComparedPlans}
                    disableCartLinks={disableCartLinks}
                    newGridEnabled={newGridEnabled}
                  />
                )}
              </VisibleTracker>
            );
          })}
        </Carousel>
        { gridTrustBuildingBanner && (
          <GridTrustBuildingBanner site={site} assetName={gridTrustBuildingBanner} />
        )}
      </div>
    </section>
  );
}

export default FeaturedCards;
