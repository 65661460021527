import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import tracking from '../../../../js/project/tagular/tracking';

import RadioButton from '../../radio-button';

const RateType = ({
  filters,
  rates,
  setFilters
}) => {
  const [isRadio, setIsRadio] = useState('Any');

  const anyCheckbox = {
    name: 'Any',
    value: 'Any',
    label: 'Any',
    checked: filters.rateTypes.length === 0,
  };

  const ratesCheckboxes = rates.map((rate) => ({
    name: rate,
    value: rate,
    label: rate,
    checked: filters.rateTypes.includes(rate),
  }));

  // add anyCheckbox as the first element of ratesCheckbox
  ratesCheckboxes.unshift(anyCheckbox);

  const rateType = {
    sectionLabel: 'Rate type',
    checkboxes: ratesCheckboxes,
  };

  const handleChange = (e) => {
    setIsRadio(e.currentTarget.value);

    tracking.elementClicked({
      webElement: {
        elementType: 'BUTTON',
        location: 'RATE TYPE FILTER',
        name: 'FILTER DROPDOWNS',
        position: '6',
        text: e.currentTarget.value
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  useEffect(() => {
    if (isRadio === 'Any') {
      setFilters({
        ...filters,
        rateTypes: []
      });
      return;
    }

    setFilters({
      ...filters,
      rateTypes: [isRadio],
    });
  }, [isRadio]);

  const groupName = `rate-name-${uuidv4()}`;

  return (
    <div className="grid-v2-rate-type">
      {rateType.checkboxes.map((rate) => {
        const {
          label,
          name,
          value,
          checked
        } = rate;
        const id = `rate-type-${uuidv4()}`;
        return (
          <RadioButton
            key={name}
            checked={checked}
            id={id}
            label={label}
            name={groupName}
            onChange={handleChange}
            value={value}
          />
        );
      })}
    </div>
  );
};

export default RateType;

RateType.propTypes = {
  filters: PropTypes.shape({
    customPlanOrder: PropTypes.array.isRequired,
    features: PropTypes.array.isRequired,
    providers: PropTypes.array.isRequired,
    rateTypes: PropTypes.array.isRequired,
    ratings: PropTypes.array.isRequired,
    terms: PropTypes.array.isRequired,
    usage: PropTypes.number.isRequired
  }).isRequired,
  rates: PropTypes.arrayOf(PropTypes.oneOf(['Fixed', 'Variable', 'Indexed'])).isRequired,
  setFilters: PropTypes.func.isRequired
};
