import React, {
  createContext, useContext, useState, useEffect
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Loader from './Loader';
import Error from './Error';

const FeatureFlagContext = createContext({});
const featureFlagsQuery = gql`
{
  featureFlags {
    name
    enabled
  }
}
`;

export function useFeatureFlag(name = '') {
  const ctx = useContext(FeatureFlagContext);

  return ctx[name] || false;
}

export default function FeatureFlagsProvider({ children, fuseData }) {
  const { loading, error, data = {} } = useQuery(featureFlagsQuery);
  const [localFlags, setLocalFlags] = useState({});

  useEffect(() => {
    window.toggleGridFeatureFlag = (name, value = true) => {
      setLocalFlags({
        ...localFlags,
        [name]: value || false,
      });
    };
  }, [localFlags]);

  if (loading || !data) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} fuseData={fuseData} />;
  }

  const featureFlagList = data.featureFlags || [];
  const reduceFn = (obj, { name, enabled }) => ({ ...obj, [name]: enabled });
  const mappedFlags = featureFlagList.reduce(reduceFn, {});

  return (
    <FeatureFlagContext.Provider value={{ ...mappedFlags, ...localFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
