import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  addFilter,
  checkbox,
  className,
  removeFilter,
}) => {
  const {
    checked, label, name, value, number
  } = checkbox;

  const check = () => {
    if (!checked) {
      addFilter(value);
    } else {
      removeFilter(value);
    }
  };
  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX');

  return (
    <div className={`u-align-items-center ${className}`}>
      <div className={`grid-v2-checkbox ${isNTX ? 'ntx' : ''}`} onClick={check} aria-hidden="true">
        <div>
          <input
            className="grid-v2-checkbox__input"
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            readOnly
          />
          <span className="grid-v2-checkbox__pseudo-input" />
          <span className="grid-v2-checkbox__label">{label}</span>
        </div>
        <span className="grid-v2-checkbox__number">{number}</span>
      </div>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  checkbox: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    number: PropTypes.number
  }).isRequired,
  className: PropTypes.string,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  className: ''
};
