import React from 'react';
import PropTypes from 'prop-types';

import iconCheck from '../../svg/green-check.svg';
import iconInfo from '../../svg/orange-info.svg';
import iconClose from '../../svg/close.svg';

function PrefillBanner(props) {
  const { filters, setBannerOpen, setFilters } = props;

  const estimatedKWHUsage = Number(localStorage.getItem('estimatedKWHUsage'));
  const prefilledUsage = filters.usage === estimatedKWHUsage;
  const iconSrc = prefilledUsage ? iconCheck : iconInfo;

  return (
    <div
      className={
        `grid-v2__prefill-filter grid-v2__prefill-filter--desktop grid-v2__prefill-filter-banner
        ${prefilledUsage ? '' : ' grid-v2__prefill-filter--updated'}`
      }
    >
      <img className="grid-v2__prefill-filter-icon" src={iconSrc} alt="Icon" />
      {prefilledUsage ? (
        <span className="grid-v2__prefill-filter-copy">
          We’ve done the work for you!
          Providing your address helped us determine your monthly bill and your home energy usage of
          <strong>{` ${estimatedKWHUsage} kWh.`}</strong>
        </span>
      ) : (
        <span className="grid-v2__prefill-filter-copy">
          Updating your home size changes the accuracy of your monthly bill estimate.
          {' '}
          <button
            className="grid-v2__prefill-filter-button"
            type="button"
            onClick={() => setFilters({ ...filters, usage: estimatedKWHUsage })}
          >
            Clear the filter
          </button>
          {' '}
          to go back to your address-based pricing.
        </span>
      )}
      {/* eslint-disable-next-line */}
      <img
        className="grid-v2__prefill-filter-close"
        src={iconClose}
        alt="Close"
        onClick={() => setBannerOpen(false)}
      />
    </div>
  );
}

export default PrefillBanner;

PrefillBanner.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  setBannerOpen: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};
