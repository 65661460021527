import React from 'react';
import { isMobile } from 'react-device-detect';
import { usePreamp } from './preamp';
import txuLogo from '../../images/txu-logo.png';

let heading = 'Try us for 60 days with no strings';
let subheading = 'Total Satisfaction Guarantee*';
let testsActive = false;

function GuaranteeBanner({ location }) {
  const editableHeadline = usePreamp('Grid-Guarantee-Banner-Middle-Editable-Headline');
  const editableSubHeadline = usePreamp('Grid-Guarantee-Banner-Middle-Editable-Subheadline');

  if (editableHeadline) {
    heading = editableHeadline;
    testsActive = true;

    if (editableSubHeadline) {
      subheading = editableSubHeadline;
    }
  }

  return (
    <div className="guarantee-banner__container">
      <div className={`guarantee-banner ${location}-position`}>
        <span
          dangerouslySetInnerHTML={{ __html: heading }}
          className={`try-us-span ${!isMobile && testsActive ? 'try-us-span--desktop' : ''}`}
        />
        <span dangerouslySetInnerHTML={{ __html: subheading }} className="total-satisfaction-span" />
        <img className="txu-logo" src={txuLogo} alt="txu logo" />
      </div>
    </div>
  );
}

export default GuaranteeBanner;
