import React, { useState } from 'react';

import bannerCheck from '../../images/banner-check.svg';
import bannerX from '../../images/banner-x.svg';

const ZipSuccessBanner = () => {
  const [bannerClose, setBannerClose] = useState(false);

  let closeBanner = '';
  if (bannerClose) {
    closeBanner = 'zip-success-banner--close';
  }

  return (
    <div className={`grid-v2__column ${closeBanner}`}>
      <div className="zip-success-banner">
        <div className="zip-success-banner__left">
          <img alt="checkmark" src={bannerCheck} className="zip-success-banner__left-check" />
          <p className="zip-success-banner__left-message">
          We’ve done the work for you! Providing your ZIP code helped us
          find plans with more accurate pricing in your area.
          </p>
        </div>
        <button type="button" className="zip-success-banner__button" onClick={() => setBannerClose(true)}>
          <img alt="close button" src={bannerX} className="zip-success-banner__button-close" />
        </button>
      </div>
    </div>
  );
};

export default ZipSuccessBanner;
