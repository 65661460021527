import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Sites,
  UsageAmounts,
} from '@rvpower/constants';
import OverLayContent from './OverlayContent';
import OverLayContentTxu from './OverLayContentTxu';

export default function FeaturedCardOverLay(props) {
  const {
    site,
    plan,
    type,
    activeClass,
    incentiveActive,
  } = props;

  const {
    usageId,
    estimates,
  } = plan;

  const overlayContents = useRef(null);
  const [usage, setUsage] = useState('1000');

  const scrollOverlayContentsToTop = () => {
    overlayContents.current.scrollTop = 0;
  };

  const scrollOverlayContentsToBottom = () => {
    overlayContents.current.scrollTop = overlayContents.current.scrollHeight;
  };

  const handleUpScroll = (e) => {
    if (e.keyCode === 38) {
      scrollOverlayContentsToTop();
    }
  };

  const handleScrollDown = (e) => {
    if (e.keyCode === 40) {
      scrollOverlayContentsToBottom();
    }
  };

  useEffect(() => {
    if (usageId && usageId !== '') {
      setUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  const usageNum = usage ? parseInt(usage, 10) : 1;
  const estimate = estimates[usageNum];

  return (
    <>
      {
        site !== Sites.TXU ? (
          <OverLayContent
            site={site}
            plan={plan}
            type={type}
            activeClass={activeClass}
            overlayContents={overlayContents}
            scrollOverlayContentsToTop={scrollOverlayContentsToTop}
            scrollOverlayContentsToBottom={scrollOverlayContentsToBottom}
            handleUpScroll={handleUpScroll}
            handleScrollDown={handleScrollDown}
            estimate={estimate}
            usage={usage}
          />
        ) : (
          <OverLayContentTxu
            site={site}
            plan={plan}
            type={type}
            activeClass={activeClass}
            overlayContents={overlayContents}
            scrollOverlayContentsToTop={scrollOverlayContentsToTop}
            scrollOverlayContentsToBottom={scrollOverlayContentsToBottom}
            handleUpScroll={handleUpScroll}
            handleScrollDown={handleScrollDown}
            estimate={estimate}
            usage={usage}
            incentiveActive={incentiveActive}
          />
        )
      }
    </>
  );
}

FeaturedCardOverLay.propTypes = {
  site: PropTypes.string.isRequired,
  plan: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  activeClass: PropTypes.bool.isRequired,
  incentiveActive: PropTypes.bool,
};

FeaturedCardOverLay.defaultProps = {
  incentiveActive: false,
};
