import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import buildClassList from '../../../js/project/buildClassList';

const Pill = forwardRef(({
  icon,
  isActive,
  label,
  children,
  onClick,
}, ref) => (
  <div
    ref={ref}
    role="button"
    className={buildClassList(
      label === 'Filter & sort' && 'grid-v2-pills__filters-pill',
      'grid-v2-pills__pill',
      isActive && 'grid-v2-pills__pill--active',
      label === 'Filter' && 'grid-v2-pills__hmc-filter',
    )}
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={0}
  >
    {icon}
    {label?.length > 0 && (
      <p
        className={buildClassList('grid-v2-pills__pill-text', isActive && 'grid-v2-pills__pill-text--active', label)}
      >
        {label}
      </p>
    )}
    {children}
  </div>
));

export default Pill;

Pill.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func
};

Pill.defaultProps = {
  icon: <></>,
  isActive: false,
  label: '',
  onClick: () => null
};
