import React from 'react';

import { useMonarch } from '../monarch';

import rating from '../../../images/rating.svg';

export default function TXUGoogleReview() {
  const { rules } = useMonarch();
  const googleReviewData = rules?.source?.googleReviews?.value?.data || {
    linkText: 'Google Reviews',
    rating: 4.7,
    totalReviews: '8,000+',
  };
  const fullRating = `${googleReviewData?.rating}/5`;

  return (
    <p className="rating" data-preamp>
      <img src={rating} alt="rating" />
      <span>
        Rated
        {' '}
        <b>{fullRating}</b>
        {' '}
        | Based on
        {' '}
        {googleReviewData?.totalReviews}
        <a
          href={`${googleReviewData?.url}
                `}
          rel="noopener noreferrer"
          target="_blank"
        >
          {googleReviewData?.linkText}
        </a>
      </span>
    </p>
  );
}
