import React from 'react';

export default function ArrowLeft(color, clickHandler, classNameValue) {
  /* eslint-disable max-len */
  return (
    <svg className={classNameValue} onClick={clickHandler} width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>arrow-left</title>
      <g id="PD-120-Paginate-Grid" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="D-Pagination" transform="translate(-702.000000, -158.000000)" fill={color} fillRule="nonzero">
          <g id={classNameValue} transform="translate(702.000000, 158.000000)">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,2 C8.2680135,2 2,8.2680135 2,16 C2,23.7319865 8.2680135,30 16,30 C23.7319865,30 30,23.7319865 30,16 C30,8.2680135 23.7319865,2 16,2 Z" id="Rectangle" transform="translate(16.000000, 16.000000) rotate(-180.000000) translate(-16.000000, -16.000000) " />
            <path d="M15.1323172,23.5194966 C15.2495043,23.5194966 15.3432542,23.4843405 15.4135665,23.4140281 L15.4135665,23.4140281 L22.7612053,16.0312332 C22.8549552,15.9609209 22.9018299,15.8671709 22.9018299,15.7499839 C22.9018299,15.6327969 22.8549552,15.5390469 22.7612053,15.4687346 L22.7612053,15.4687346 L15.4135665,8.08593968 C15.3432542,8.01562735 15.2495043,7.98047118 15.1323172,7.98047118 C15.0151302,7.98047118 14.9096617,8.01562735 14.8159117,8.08593968 L14.8159117,8.08593968 L14.1127884,8.789063 C14.0424761,8.85937534 14.0073199,8.95312529 14.0073199,9.07031233 C14.0073199,9.18749937 14.0424761,9.29296787 14.1127884,9.38671783 L14.1127884,9.38671783 L19.5619941,14.8359236 L7.57374148,14.8359236 C7.45655444,14.8359236 7.35694494,14.8769393 7.27491407,14.9589702 C7.19288319,15.041001 7.15186749,15.1406105 7.15186749,15.2577976 L7.15186749,15.2577976 L7.15186749,16.2421702 C7.15186749,16.3593573 7.19288319,16.4589668 7.27491407,16.5409976 C7.35694494,16.6230285 7.45655444,16.6640442 7.57374148,16.6640442 L7.57374148,16.6640442 L19.5619941,16.6640442 L14.1127884,22.11325 C14.0424761,22.2069999 14.0073199,22.3124684 14.0073199,22.4296555 C14.0073199,22.5468425 14.0424761,22.6405925 14.1127884,22.7109048 L14.1127884,22.7109048 L14.8159117,23.4140281 C14.9096617,23.4843405 15.0151302,23.5194966 15.1323172,23.5194966 Z" id="arrow" transform="translate(15.026849, 15.749984) rotate(-180.000000) translate(-15.026849, -15.749984) " />
          </g>
        </g>
      </g>
    </svg>
  );
}
