import React, { useState } from 'react';

import HmcAddressCapture from './hmc-forms/HmcAddressCapture';
import HmcLoader from './HmcLoader';
import HmcSqftCapture from './hmc-forms/HmcSqftCapture';
import beam from '../../js/project/tagular/beam';

import backIcon from '../../../images/back-arrow.svg';
import closeIcon from '../../../images/close-icon.svg';

const HmcModal = (props) => {
  const {
    modalOpen,
    modalHandler,
    updateHmcData,
    setSort,
  } = props;

  const [currentForm, setCurrentForm] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [failedCorelogic, setFailedCorelogic] = useState(null);

  // utility functions for the experience
  const updateModalHandler = () => {
    modalHandler(!modalOpen);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('hmc-overlay')) {
      const overlayClickedPayload = {
        webElement: {
          location: 'Help Me Choose Form',
        },
        actionOutcome: 'Close Help Me Choose'
      };
      beam.userTrackingV3('ElementClicked', overlayClickedPayload);

      updateModalHandler();
    }
  };

  const skipHandler = (skipAmount = 1) => {
    // grid will break if currentForm is greater than formData.length - 1
    if (currentForm + skipAmount > 1) {
      return setCurrentForm(currentForm);
    }

    return setCurrentForm(currentForm + skipAmount);
  };

  const backHandler = (backAmount = 1) => {
    // grid will break if currentForm is less than 0
    if (currentForm - backAmount < 0) {
      updateModalHandler();
      return setCurrentForm(0);
    }

    return setCurrentForm(currentForm - backAmount);
  };

  const updateIsLoading = (loading) => {
    setIsLoading(loading);
  };

  const updateCorelogicFail = (data) => {
    setFailedCorelogic(data);
  };

  // event handler for the close buttons
  const closeButtonsClickHandler = () => {
    const closeButtonsPayload = {
      webElement: {
        location: `${currentForm === 0 ? 'Address' : 'Usage'} Entry`,
      },
      actionOutcome: 'Go back to Grid'
    };
    beam.userTrackingV3('ElementClicked', closeButtonsPayload);

    updateModalHandler();
  };

  // Controls the forms that will be rendered in the modal (can add more forms as needed)
  const formData = [
    {
      form: <HmcAddressCapture
        modalOpen={modalOpen}
        skipHandler={skipHandler}
        updateIsLoading={updateIsLoading}
        updateModalHandler={updateModalHandler}
        updateHmcData={updateHmcData}
        updateCorelogicFail={updateCorelogicFail}
        setSort={setSort}
      />,
    },
    {
      form: <HmcSqftCapture
        modalOpen={modalOpen}
        backHandler={backHandler}
        updateIsLoading={updateIsLoading}
        updateModalHandler={updateModalHandler}
        updateHmcData={updateHmcData}
        failedCorelogic={failedCorelogic}
        setSort={setSort}
      />,
    },
  ];

  return (
    <div
      className={`hmc-overlay ${modalOpen ? 'hmc-overlay--active' : ''}`}
      onClick={handleOverlayClick}
      role="presentation"
      aria-hidden={modalOpen ? 'false' : 'true'}
    >
      <div className={`hmc-drawer ${modalOpen ? 'hmc-drawer--active' : ''}`}>
        <div className="hmc-drawer__top">
          <button
            type="button"
            className="hmc-drawer__top-back-button"
            onClick={closeButtonsClickHandler}
            tabIndex={modalOpen ? '0' : '-1'}
          >
            <img
              src={backIcon}
              alt="back button"
              className="hmc-drawer__top-back-icon"
            />
          </button>
          <button
            type="button"
            className="hmc-drawer__top-close-button"
            onClick={closeButtonsClickHandler}
            tabIndex={modalOpen ? '0' : '-1'}
          >
            <img
              src={closeIcon}
              alt="close button"
              className="hmc-drawer__top-close-icon"
            />
          </button>
        </div>
        <div className="hmc-drawer__content">
          {isLoading ? (
            <HmcLoader />
          ) : (
            formData[currentForm].form
          )}
        </div>
      </div>
    </div>
  );
};

export default HmcModal;
