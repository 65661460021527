import React, { useState, useEffect, useRef } from 'react';
import tracking from '../js/project/tagular/tracking';

function UsageInput(props) {
  const {
    updateDisplay,
    updateFilter,
    shouldClearTextInput,
    setShouldClearTextInput,
    device,
    setMobileUsageOpen
  } = props;

  const [value, setValue] = useState('');

  const storeValue = (e) => {
    setShouldClearTextInput(false);
    setValue(e.target.value);
  };

  const applyUpdate = (e) => {
    e.preventDefault();
    if (device === 'mobile') {
      setMobileUsageOpen(false);
    }
    updateDisplay(parseInt(value, 10));
    updateFilter(parseInt(value, 10));

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'Apply',
        position: 'Usage sidebar'
      },
      actionOutcome: 'Submit exact usage',
    });
  };

  const clearTextInput = () => {
    setValue('');
  };

  useEffect(() => {
    if (shouldClearTextInput) {
      clearTextInput();
    }
  }, [shouldClearTextInput]);

  const getButtonText = () => {
    if (device === 'mobile') {
      return 'Update';
    }
    if (value) {
      return 'Apply';
    }
    return 'Edit';
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (device === 'mobile') {
      inputRef.current.focus();
    }
  }, []);

  const error = value && (value < 0 || value > 3500);

  return (
    <div className="usage-input">
      <label
        htmlFor="usage-input__input"
        className="usage-input__label"
      >
        <p className="usage-input__text">Enter exact kWh usage</p>
        <form className="usage-input__container" onSubmit={(e) => applyUpdate(e)}>
          <input
            ref={inputRef}
            type="number"
            id="usage-input__input"
            name="usage-input__input"
            className={`usage-input__input ${error && 'usage-input__input--error'}`}
            maxLength="4"
            value={value}
            onChange={storeValue}
            placeholder="kWh"
            pattern="[0-9]*"
            inputMode="numeric"
          />
          <button
            type="submit"
            className="button button--usage-input"
            disabled={!value || error}
          >
            {getButtonText()}
          </button>
        </form>
        {error && <span className="usage-input__error">Enter number between 0 and 3500</span>}
      </label>
    </div>
  );
}

export default UsageInput;
