import React from 'react';
import { SupplierIds } from '@rvpower/constants';
import tracking from '../js/project/tagular/tracking';

const paylessDoc = {
  type: 'PDS',
  url: 'https://paylesspower.com/files/PrePaid_Disclosure_Statement_English.pdf'
};

function PDFLink(document, isFeaturedCard) {
  if (!document) {
    return null;
  }

  const { type, url } = document;
  const text = type === 'Terms' ? 'TOS' : type;

  const track = (textData) => {
    tracking.elementClicked({
      webElement: {
        location: isFeaturedCard ? 'FEATURED' : 'GRID',
        elementType: 'Link',
        text: textData
      },
      actionOutcome: 'Plan docs opened'
    });
  };

  return (
    <li key={type}>
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => track(text.toUpperCase())}>
        {text.toUpperCase()}
      </a>
    </li>
  );
}

export default function PDFLinks({ plan, isFeaturedCard }) {
  const {
    EFLDoc, TermsDoc, YRACDoc, supplier
  } = plan;
  const { id: supplierId } = supplier;

  const docs = [EFLDoc, TermsDoc, YRACDoc, supplierId === SupplierIds.Payless ? paylessDoc : null];
  return docs.map((doc) => PDFLink(doc, isFeaturedCard));
}

PDFLinks.defaultProps = {
  isFeaturedCard: false
};
