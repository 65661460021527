import React from 'react';

const TXUPlanDisclaimers = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div className="txu-plan-disclaimers">
      {children}
    </div>
  );
};

export default TXUPlanDisclaimers;
