/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function GridTrustBuildingIcon(props) {
  const { iconColor, icon } = props;

  if (icon === 'shield') {
    return (
      <svg
        width="40"
        height="39"
        viewBox="0 0 40 39"
        fill={iconColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.66 4C15.07 9.71 4.19 10 4.19 10C5.69 28.66 17.31 33.78 17.31 33.78V38.16C-1.57 27.84 3.11434e-06 5.87 3.11434e-06 5.87C12.73 7.47 17.63 0 17.63 0H21.79C22.8073 1.39514 24.0979 2.56858 25.5833 3.44881C27.0687 4.32905 28.7178 4.89766 30.43 5.12L27.13 8.43C23.39 8 19.66 4 19.66 4ZM19.57 21.37L15.15 16.94L12.25 19.84L19.55 27.14L39.23 7.41L36.51 4.69L19.57 21.37ZM38.57 13.66L33.57 18.66C33.57 18.66 29.07 31.72 21.5 33.66V38.33C21.5 38.33 36.42 32.89 38.58 13.61L38.57 13.66Z"
          fill={iconColor}
        />
      </svg>
    );
  }
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={iconColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5923 32.4093C36.9166 30.4672 37.4735 28.0909 37.1525 25.7517C36.8314 23.4124 35.6559 21.281 33.8591 19.7801C32.0622 18.2792 29.7753 17.5184 27.4516 17.6486C25.128 17.7788 22.9374 18.7904 21.3142 20.4829C19.691 22.1753 18.7538 24.4251 18.6884 26.786C18.623 29.1469 19.4341 31.4465 20.961 33.2289C22.4879 35.0113 24.619 36.1462 26.9318 36.4087C29.2446 36.6712 31.5701 36.042 33.4472 34.646L37.9063 39.0373L40 36.9199L35.5923 32.4093ZM27.9155 33.3636C26.6541 33.3636 25.4209 32.9839 24.3722 32.2724C23.3234 31.561 22.5061 30.5499 22.0237 29.367C21.5413 28.184 21.4155 26.8825 21.6621 25.6269C21.9087 24.3714 22.5168 23.2183 23.4093 22.3136C24.3018 21.4088 25.4386 20.7931 26.676 20.5442C27.9134 20.2953 29.1958 20.4246 30.3608 20.9155C31.5258 21.4065 32.5212 22.2371 33.221 23.3023C33.9207 24.3675 34.2935 25.6194 34.292 26.8996C34.2901 28.6147 33.6174 30.2588 32.4218 31.4708C31.2262 32.6828 29.6054 33.3636 27.9155 33.3636Z"
        fill={iconColor}
      />
      <path
        d="M23.0377 14.4712C23.0315 14.535 23.0385 14.5993 23.0582 14.6601C23.0779 14.721 23.1098 14.777 23.1519 14.8247C23.1941 14.8723 23.2455 14.9106 23.303 14.937C23.3604 14.9634 23.4227 14.9775 23.4858 14.9782C23.5887 14.9455 23.6766 14.8763 23.7332 14.7831C23.7899 14.69 23.8116 14.5793 23.7943 14.4712V9.64749C23.8124 9.57052 23.8152 9.49068 23.8026 9.4126C23.79 9.33452 23.7622 9.25977 23.7208 9.1927C23.6538 9.10337 23.5623 9.03597 23.4578 8.99887C23.3534 8.96176 23.2405 8.95657 23.1331 8.98394H18.4904C18.4211 8.97124 18.35 8.97311 18.2815 8.98942C18.213 9.00573 18.1485 9.03615 18.092 9.07878C18.0355 9.12142 17.9883 9.17536 17.9532 9.23727C17.9181 9.29919 17.8959 9.36776 17.888 9.43873C17.9195 9.56987 17.9998 9.68352 18.1122 9.75581C18.2245 9.82809 18.36 9.8534 18.4904 9.82642H22.4573L17.7557 14.5831C17.6563 14.6898 17.5455 14.7848 17.4252 14.8664C17.2415 15.008 17.0799 15.1124 16.955 15.0677C16.713 14.9535 16.4995 14.7853 16.3306 14.5756L14.641 12.8832C14.4032 12.6254 14.083 12.4616 13.7374 12.421C13.526 12.4056 13.314 12.4423 13.1196 12.528C12.9252 12.6136 12.754 12.7457 12.6208 12.913L5.87695 19.7199C5.80327 19.78 5.74913 19.8612 5.72161 19.953C5.69409 20.0448 5.69447 20.1428 5.72268 20.2344C5.76483 20.29 5.81775 20.3363 5.87819 20.3704C5.93863 20.4046 6.00531 20.4258 6.07412 20.4327C6.14293 20.4397 6.21243 20.4323 6.27832 20.411C6.34422 20.3898 6.40513 20.355 6.4573 20.3089L12.8632 13.7853C13.0909 13.5169 13.4141 13.2112 13.7374 13.2709C14.0302 13.3741 14.2859 13.5636 14.472 13.8151C14.9862 14.3296 15.7649 15.1124 15.9927 15.3063C16.2208 15.6254 16.5641 15.8398 16.9477 15.9027C17.1987 15.9004 17.4457 15.8385 17.6689 15.722C17.8921 15.6055 18.0854 15.4376 18.2332 15.2317L23.045 10.3558L23.0377 14.4712Z"
        fill={iconColor}
      />
      <path
        d="M4.79706 5.54693C4.79711 5.76906 4.86233 5.98614 4.98441 6.17051C5.1065 6.35487 5.27991 6.49816 5.48255 6.5821C5.68519 6.66605 5.90787 6.68685 6.1222 6.64186C6.33653 6.59686 6.53281 6.48811 6.686 6.32946C6.8392 6.17081 6.94237 5.96946 6.98236 5.75107C7.02236 5.53269 6.99737 5.30716 6.91058 5.10324C6.82379 4.89932 6.67912 4.72625 6.49504 4.6061C6.31095 4.48595 6.09578 4.42416 5.87695 4.4286C5.58854 4.43445 5.31389 4.55486 5.11198 4.76396C4.91007 4.97305 4.797 5.25417 4.79706 5.54693Z"
        fill={iconColor}
      />
      <path
        d="M7.91919 5.54693C7.9192 5.76843 7.98401 5.98495 8.10542 6.16901C8.22683 6.35308 8.39937 6.49641 8.60114 6.58083C8.80292 6.66525 9.02486 6.68696 9.23881 6.6432C9.45276 6.59944 9.64909 6.49219 9.8029 6.33504C9.95671 6.17789 10.0611 5.97793 10.1028 5.7605C10.1444 5.54308 10.1216 5.31799 10.037 5.11377C9.95252 4.90956 9.81014 4.73542 9.62796 4.61344C9.44579 4.49145 9.23202 4.42712 9.01377 4.4286C8.7228 4.43057 8.4444 4.54926 8.23934 4.75878C8.03427 4.96829 7.91918 5.25162 7.91919 5.54693Z"
        fill={iconColor}
      />
      <path
        d="M3.0854 3.16115H27.8421V12.4806H31.2654V3.47429C31.2654 2.31868 31.2654 1.15561 31.2654 0H0V28.2789L2.78421 28.2118H12.4151V25.3862H3.0854V3.16115Z"
        fill={iconColor}
      />
    </svg>
  );
}

GridTrustBuildingIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default GridTrustBuildingIcon;
