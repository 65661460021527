import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import StarRatings from './StarRatings';
import getProviderLink from '../js/project/provider-links';
import simpleStar from '../../images/star-simple.svg';

function Ratings(props) {
  const {
    boundary,
    offset,
    ratings,
    site,
    supplierName,
    tippyClass,
    newGridEnabled,
    newGridSimplified,
  } = props;

  if (!ratings) return null;

  const {
    customerService,
    onlineAccessibility,
    overallScore,
    planOptions
  } = ratings;

  const rounded = (number) => Math.round(number * 2) / 2;

  const formattedScore = overallScore.toFixed(1);

  const { current } = boundary;

  const providerLink = getProviderLink(site, supplierName);

  const beam = (eventName, data) => {
    if (window.tagular) {
      window.tagular('beam', eventName, {
        '@type': `redventures.usertracking.v3.${eventName}`,
        ...data,
      });
    }
  };

  const handleButtonClick = () => {
    const payload = {
      webElement: {
        location: 'Grid',
        elementType: 'Link',
        text: formattedScore
      }
    };
    beam('ElementClicked', payload);
  };

  const handleLinkClick = () => {
    const payload = {
      webElement: {
        location: 'Rating Modal',
        elementType: 'Link',
        text: 'Click Here'
      },
      outboundUrl: `${window.location.host}${providerLink}`
    };
    beam('ElementClicked', payload);
  };

  return (
    <div className="ratings">
      { newGridSimplified
        ? (
          <img
            key={Math.random()}
            className="ratings__star"
            alt="rating"
            src={simpleStar}
          />
        )
        : (
          <StarRatings
            rating={rounded(overallScore).toFixed(1) ?? ''}
            totalStars={5}
            newGridEnabled={newGridEnabled}
            newGridSimplified={newGridSimplified}
          />
        )}
      <Tippy
        content={(
          <>
            {!newGridEnabled && (
              <h3 className="tippy-content__heading">
                Overall Score:
                {' '}
                {formattedScore}
              </h3>
            )}
            <div className="tippy-content__rating">
              <p className="tippy-content__text">Customer Service</p>
              <p className="tippy-content__score">
                {customerService.toFixed(1)}
                /5
              </p>
            </div>
            <div className="tippy-content__rating">
              <p className="tippy-content__text">Online Accessibility</p>
              <p className="tippy-content__score">
                {onlineAccessibility.toFixed(1)}
                /5
              </p>
            </div>
            <div className="tippy-content__rating">
              <p className="tippy-content__text">Plan Options</p>
              <p className="tippy-content__score">
                {planOptions.toFixed(1)}
                /5
              </p>
            </div>
            <p className="tippy-content__info">
              Want more information on this provider?
              {' '}
              <a
                href={providerLink}
                className="tippy-content__link"
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleLinkClick}
              >
                Click Here
              </a>
            </p>
          </>
        )}
        className={`ratings-tippy ${tippyClass}`}
        arrow
        boundary={current || ''}
        placement="top"
        offset={offset || 0}
        trigger="click"
      >
        <button
          type="button"
          className="ratings__button"
          onClick={handleButtonClick}
        >
          {formattedScore}
        </button>
      </Tippy>
    </div>
  );
}

Ratings.propTypes = {
  boundary: PropTypes.string,
  offset: PropTypes.oneOf(PropTypes.string, PropTypes.number),
  ratings: PropTypes.shape({}),
  site: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  tippyClass: PropTypes.string,
};

Ratings.defaultProps = {
  boundary: '',
  offset: 0,
  ratings: null,
  tippyClass: '',
};

export default Ratings;
