/**
 *
 * simple utility function to concatenate classNames together
 * example use:
 * const classNames = buildClassList(
 *   styles.label,
 *   size && styles.radio.textSize[size],
 *   condition ? style.condition : style.otherCondπition
 *   className,
 *  );
 */

const buildClassList = (...classes) => classes.filter(Boolean).join(' ');

export default buildClassList;
