import React, { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import UsageDisplay from '../UsageDisplay';
import UsageSlider from '../UsageSlider';
import UsageInput from '../UsageInput';
import editIcon from '../../../images/edit-icon.svg';

function Usage(props) {
  const { site, device } = props;
  const { filters, setFilters, setNewAmount } = props;
  const { usage } = filters;

  const [kwhAmount, setKwhAmount] = useState(0);
  const [shouldClearTextInput, setShouldClearTextInput] = useState(false);

  const [mobileUsageOpen, setMobileUsageOpen] = useState(false);

  useEffect(() => {
    setKwhAmount(usage);
  }, [usage]);

  const updateDisplay = (kwh) => {
    setNewAmount(kwh);
    setKwhAmount(kwh);
  };

  const updateFilter = (kwh) => {
    setFilters({
      ...filters,
      usage: kwh
    });
  };

  const titleCopy = {
    mobile: 'Enter your usage. We\'ll show you plans that fit.',
    desktop: 'Give us a few details and we\'ll find you a better price.'
  };

  return (
    <div className="slider slider--kwh">
      <p className="slider__description">
        {titleCopy[device]}
      </p>
      <div className="slider__container slider__container">
        <UsageDisplay
          kwh={kwhAmount}
          site={site}
        />
        <UsageSlider
          initialUsage={usage}
          updateDisplay={updateDisplay}
          updateFilter={updateFilter}
          setShouldClearTextInput={setShouldClearTextInput}
          kwh={kwhAmount}
        />
        {device === 'desktop' || mobileUsageOpen ? (
          <UsageInput
            updateDisplay={updateDisplay}
            updateFilter={updateFilter}
            shouldClearTextInput={shouldClearTextInput}
            setShouldClearTextInput={setShouldClearTextInput}
            device={device}
            setMobileUsageOpen={setMobileUsageOpen}
          />
        ) : (
          <button
            type="button"
            className="mobile-usage-button"
            onClick={() => setMobileUsageOpen(true)}
          >
            Enter exact kWh usage
            <img
              src={editIcon}
              alt="edit icon"
              className="usage-input__icon"
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default Usage;
