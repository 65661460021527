import React from 'react';

import Tippy from '@tippy.js/react';
import Sort from './sort';
import tracking from '../../js/project/tagular/tracking';

import SortIcon from '../../svg/new-sort-icon.svg';

function SortButton(props) {
  const {
    options,
    sort,
    setSort,
    isNTX,
    showHelpMeChooseCTA,
    displaySortLabel = false,
  } = props;

  const popperOptions = {
    modifiers: {
      preventOverflow: { enabled: false },
      hide: { enabled: false }
    }
  };

  const outerDivElement = document.getElementById('grid');

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        elementType: 'DROPDOWN',
        location: 'GRID',
        name: 'SORT FILTERS',
        text: 'SORT'
      },
      actionOutcome: 'Sort dropdown opened'
    });
  };

  return (
    <div className={`
      featured-plan-card-v2__tooltip-container
      ${showHelpMeChooseCTA ? 'featured-plan-card-v2__tooltip-container--hmc' : ''}
      `}
    >
      <Tippy
        content={
          (
            <div className="featured-plan-cards-v2__tooltip">
              <Sort options={options} sort={sort} setSort={setSort} isNTX={isNTX} />
            </div>
          )
        }
        placement="bottom-end"
        theme="desktop-pills"
        appendTo={outerDivElement}
        trigger="click"
        popperOptions={popperOptions}
        interactive
      >
        <button
          type="button"
          className="featured-plan-cards-v2__tooltip-button"
          onClick={() => handleClick()}
        >
          <img
            className="featured-plan-cards-v2__tooltip-icon"
            src={SortIcon}
            alt="Sort plans"
          />
          Sort by
          {' '}
          {displaySortLabel ? options[sort] : ''}
        </button>
      </Tippy>
    </div>
  );
}

export default SortButton;
