import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';

import ProductDetailBenefitCard from './ProductDetailBenefitCard';

import tracking from '../../js/project/tagular/tracking';

import iconSatisfactionGuarantee from '../../../images/icon-satisfaction-guarantee.svg';
import iconRenewableEnergy from '../../../images/icon-renewable-energy.svg';
import iconBillCredits from '../../../images/icon-bill-credits.svg';
import iconVariableRate from '../../../images/icon-variable-rate.svg';
import iconFixedRate from '../../../images/icon-fixed-rate.svg';
import iconCallUs from '../../../images/icon-call-us.svg';
import iconHassleFree from '../../../images/icon-hassle-free.svg';

import iconSatisfactionGuaranteeNoBack from '../../../images/icon-satisfaction-guarantee-no-back.svg';
import iconRenewableEnergyNoBack from '../../../images/icon-renewable-energy-no-back.svg';
import iconBillCreditsNoBack from '../../../images/icon-bill-credits-no-back.svg';
import iconVariableRateNoBack from '../../../images/icon-variable-rate-no-back.svg';
import iconFixedRateNoBack from '../../../images/icon-fixed-rate-no-back.svg';
import iconCallUsNoBack from '../../../images/icon-call-us-no-back.svg';
import iconHassleFreeNoBack from '../../../images/icon-hassle-free-no-back.svg';

function ProductDetailBenefits(props) {
  const {
    site,
    plan,
    noBackgroundImages = false
  } = props;

  const [billCreditData, setBillCreditData] = useState({});
  const [threeBenefits, setThreeBenefits] = useState([]);

  const isNEWCTXP = site === Sites.NEW_CTXP;

  const handleClickPhoneNumber = () => {
    tracking.elementClicked({
      webElement: {
        location: 'PDP',
        elementType: 'Phone CTA',
        text: 'Phone number'
      },
      actionOutcome: 'Call'
    });
  };

  /** All different possible benefits in priority order */
  const allBenefits = [
    {
      type: 'satisfactionGuarantee',
      icon: noBackgroundImages ? iconSatisfactionGuaranteeNoBack : iconSatisfactionGuarantee,
      title: 'Satisfaction guarantee',
      // eslint-disable-next-line max-len
      content: `New customers can switch to a different ${plan.supplier.name} plan or a new provider without paying an early cancellation fee within 60 days of enrollment.`
    },
    {
      type: 'renewableEnergy',
      icon: noBackgroundImages ? iconRenewableEnergyNoBack : iconRenewableEnergy,
      title: '100% renewable energy',
      // eslint-disable-next-line max-len
      content: 'This plan uses renewable energy sources like wind and solar to reduce the use of conventional fossil fuels.'
    },
    {
      type: 'billCredits',
      icon: noBackgroundImages ? iconBillCreditsNoBack : iconBillCredits,
      title: 'Bill credits',
      // eslint-disable-next-line max-len
      content: `Get an automatic $${billCreditData.amount} bill credit each month when you use ${billCreditData.startKwh} kWh or more.`
    },
    {
      type: 'variableRate',
      icon: noBackgroundImages ? iconVariableRateNoBack : iconVariableRate,
      title: 'Variable-rate plan',
      // eslint-disable-next-line max-len
      content: 'This plan doesn’t require a contract, and while the price fluctuates with market trends, it allows you to take advantage of market lows.'
    },
    {
      type: 'fixedRate',
      icon: noBackgroundImages ? iconFixedRateNoBack : iconFixedRate,
      title: 'Fixed-rate plan',
      content: 'This plan requires a contract but offers a stable price throughout the contract.'
    },
    {
      type: 'callUs',
      icon: noBackgroundImages ? iconCallUsNoBack : iconCallUs,
      title: 'Need help? Call us.',
      content: (
        <>
          Call our experts at
          {/* eslint-disable-next-line */}
          <span className="pdp-benefits__fuse-number" onClick={handleClickPhoneNumber}>
            <a
              href={`tel:${isNEWCTXP ? '8442383028' : '8448515993'}`}
              data-fuse
              data-fuse-format="###-###-####"
              data-fuse-name={isNEWCTXP ? 'ES-CTXP-FUSE-PERMA-PDP' : 'ES-SOE-FUSE-PERMA-PDP'}
            >
              {isNEWCTXP ? '(844) 238-3028' : '(844) 851-5993'}
            </a>
          </span>
          to get best-in-class customer service.
        </>
      )
    },
    {
      type: 'hassleFree',
      icon: noBackgroundImages ? iconHassleFreeNoBack : iconHassleFree,
      title: 'Hassle-free checkout',
      content: 'We’ve designed the checkout process to be quick and easy.'
    },
  ];

  useEffect(() => {
    const dynamicBenefits = [];

    /** Conditionally push items into benefits array based on plan data */
    plan.badges.forEach((badge) => {
      if (badge.name.includes('satisfaction')) dynamicBenefits.push('satisfactionGuarantee');
      if (badge.name.includes('renewable')) dynamicBenefits.push('renewableEnergy');
    });

    // eslint-disable-next-line max-len
    const matchedBillCredit = plan?.billCredits?.find((billCredit) => plan.usage >= billCredit.startKwh && plan.usage <= billCredit.endKwh);

    if (matchedBillCredit?.amount && matchedBillCredit?.startKwh) {
      setBillCreditData(matchedBillCredit);
      dynamicBenefits.push('billCredits');
    }

    if (plan.type === 'Fixed') dynamicBenefits.push('fixedRate');
    else if (plan.type === 'Variable') dynamicBenefits.push('variableRate');

    dynamicBenefits.push('callUs');
    dynamicBenefits.push('hassleFree');

    /** Display only the first three items from the list */
    setThreeBenefits(dynamicBenefits.splice(0, 3));
  }, []);

  return (
    <div className="pdp-benefits">
      <div className="pdp-benefits__inner">
        {threeBenefits.map((benefitType) => {
          const matchedBenefit = allBenefits.find((benefit) => benefit.type === benefitType);

          return (
            <ProductDetailBenefitCard benefit={matchedBenefit} key={matchedBenefit.type} />
          );
        })}
      </div>
    </div>
  );
}

export default ProductDetailBenefits;

ProductDetailBenefits.propTypes = {
  plan: PropTypes.shape().isRequired,
};
