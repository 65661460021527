import React, { useState } from 'react';
import PropTypes from 'prop-types';
import buildClassList from '../../../js/project/buildClassList';

const Tabs = ({ children, fullWidth }) => {
  const [currentTab, setCurrentTab] = useState(0);

  if (!children || children.length === 0) {
    console.error('Error in Tabs: Please add children props to the Tabs component');
    return null;
  }

  return (
    <div className="grid-v2-tabs">
      <div className="grid-v2-tabs__tabs">
        {children?.map((child, i) => {
          const { title } = child.props;

          if (!title) {
            console.error('Error in Tabs: Please add a prop type of "title" to the children props');
            return null;
          }

          const tabClassList = buildClassList(
            'grid-v2-tabs__tab',
            fullWidth ? 'grid-v2-tabs__tab--full-width' : '',
            currentTab === i ? 'grid-v2-tabs__tab--active' : ''
          );

          return (
            <button
              className={tabClassList}
              role="tab"
              type="button"
              key={title}
              onClick={() => setCurrentTab(i)}
            >
              {title}
            </button>
          );
        })}
      </div>
      <div>{children[currentTab].props.children}</div>
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  fullWidth: PropTypes.bool
};

Tabs.defaultProps = {
  fullWidth: false
};
