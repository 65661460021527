/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Sites, UsageAmounts } from '@rvpower/constants';
import generateClasses from '../utils';

export default function PricePerKwh({
  className,
  isTXU,
  plan,
  site,
  featuredCard,
  label,
}) {
  const {
    price,
    usage,
    usageId,
    prices,
    unitOfMeasure: { currency, description }
  } = plan;

  const [featuredUsage, setFeaturedUsage] = useState('1000');
  const updatedPrice = prices[featuredUsage];
  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;

  // NTX uses energyCharge instead
  const energyChargeRate = plan.rates.find((p) => p.type === 'EnergyCharge');
  const defaultClassName = `price u-display-flex u-align-items-center ${featuredCard ? 'u-justify-content-center' : 'u-flex-direction-column'}`;
  const classString = generateClasses(
    className,
    defaultClassName,
  );

  const ntxDisplayPrice = Number(energyChargeRate?.price * 100).toFixed(2);
  const displayPrice = Number((featuredCard ? updatedPrice : price) * 100).toFixed(1);

  useEffect(() => {
    if (usageId && usageId !== '') {
      setFeaturedUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  let fullDescription = description?.toUpperCase();
  if (fullDescription?.includes('THM')) {
    fullDescription = 'THERM';
  }

  return (
    <div
      className={classString}
    >
      <div>
        { /* NTX uses energyChargeRate while the rest use price */}
        {isNTX ? (
          <span className="price__digits">{ntxDisplayPrice || 'N/A'}</span>
        ) : (
          <span className="price__digits">{displayPrice}</span>
        )}
        <sup className="price__symbol">{currency || '¢'}</sup>
      </div>
      <div className="price__settings">
        <strong className={`uppercase ${isNTX ? 'per-kwh-text--ntx' : ''}`}>
          {label}
          {' '}
          <span>{fullDescription || 'kWh'}</span>
        </strong>
        {!isNTX && (
          <p>
            {`at ${featuredCard ? featuredUsage : usage} ${description || 'kWh'}${isTXU ? '/mo' : ''}`}
          </p>
        )}
      </div>
    </div>
  );
}

PricePerKwh.propTypes = {
  className: PropTypes.string,
  isTXU: PropTypes.bool,
  site: PropTypes.string.isRequired,
  plan: PropTypes.shape({}).isRequired,
  featuredCard: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.bool
  ]),
  label: PropTypes.string,
};

PricePerKwh.defaultProps = {
  className: '',
  isTXU: false,
  featuredCard: false,
  label: 'per',
};
