/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import txuLogo from '../../images/txu-logo.png';

const reviewsUrl = 'https://www.google.com/maps/place/TXU+Energy+Corporate+Office/@32.8984572,-96.9663083,17z/data=!4m7!3m6!1s0x0:0xf442d96f72986dfa!8m2!3d32.8984572!4d-96.9641196!9m1!1b1';

function GoogleReviewsBannerSimple() {
  return (
    <div className="google-reviews-banner__container">
      <div className="google-reviews-banner-simple__content">
        <div className="google-reviews-banner-simple logo-container">
          <img className="txu-logo" src={txuLogo} alt="txu logo" />
          <span className="banner-text">Most trusted provider by Texans</span>
        </div>
        <div className="google-reviews-banner-simple rate-container">
          <div className="stars-container">
            <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            <img className="google-reviews__star--fullStar" alt="fullStar icon" />
            <img className="google-reviews__star--halfStar" alt="halfStar icon" />
          </div>
          <div className="counter-container">
            <span>
              Rated
              <b> 4.6/5 </b>
               | Based on 2000+
            </span>
            <a href={reviewsUrl} target="_blank" rel="noopenner noreferrer"> Google reviews</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleReviewsBannerSimple;
