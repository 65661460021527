/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import useBreakpoints from '../../../js/hooks/useBreakPoints';
import tracking from '../../../js/project/tagular/tracking';

import arrowIcon from '../../../../images/arrow-long-right.svg';
import soeBrandLogo from '../../../../images/soe-logo-transparent.svg';
import providerLogos from '../../../../images/provider-logos.png';
import providerLogosMobile from '../../../../images/provider-logos_mobile.png';

import VisibleTracker from '../../VisibleTracker';

const SolarCardGeneric3 = ({
  header,
  subheader,
  ctaText,
  trackElementViewed,
}) => {
  const { isSm } = useBreakpoints();
  const ctaLink = 'https://www.saveonenergy.com/solar-energy/solar-estimator/';

  const providerLogo = isSm ? providerLogosMobile : providerLogos;

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID - Solar Card Generic 3',
        elementType: 'Button',
        text: 'Get started',
      },
      actionOutcome: 'Navigate to solar lead form'
    });
  };

  return (
    <VisibleTracker onVisible={() => trackElementViewed('Solar card generic option 3')}>
      <div className="solar-generic-3__wrapper">
        <div className="solar-generic-3__inner-wrapper">
          <div className="solar-generic-3__content">
            <div className="solar-generic-3__content__header">{header}</div>
            <div className="solar-generic-3__content__subheader">{subheader}</div>
            <a
              className="solar-generic__cta-button"
              href={ctaLink}
              onClick={handleClick}
              target="_blank"
            >
              {ctaText}
              <img
                alt="get started arrow icon"
                className="solar-generic__cta-button__icon"
                src={arrowIcon}
              />
            </a>
            <div className="solar-generic__content__logo-wrapper">
              <div>Powered by</div>
              <img
                alt="SOE logo"
                className="solar-generic__brand-logo"
                src={soeBrandLogo}
              />
            </div>
          </div>
          <div className="solar-generic-3__provider-logos-wrapper">
            <img
              alt="provider logos"
              className="solar-generic-3__provider-logos"
              src={providerLogo}
            />
          </div>
        </div>
      </div>
    </VisibleTracker>
  );
};

export default SolarCardGeneric3;
