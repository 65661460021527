import React from 'react';
import PropTypes from 'prop-types';
import buildClassList from '../../../../js/project/buildClassList';
import tracking from '../../../../js/project/tagular/tracking';

const Utility = ({
  setSelectedUtility,
  utilities,
  utility
}) => {
  const options = utilities?.map((util) => ({ value: util.id, label: util.name }));
  const defaultValue = options?.find((option) => option?.value === utility?.id);
  const checkboxSections = {
    sectionLabel: 'Utility',
    checkboxes: options,
  };

  const classList = buildClassList('grid-v2-utilities', 'grid-v2-utilities--pills-dropdown');

  function handleChange(checkbox) {
    tracking.elementClicked({
      webElement: {
        elementType: 'Radio Button',
        location: 'Grid Hero - Utility Filter',
        name: 'NTX Utility Selection',
        text: checkbox.label
      },
      actionOutcome: 'Switched Utility'
    });
    setSelectedUtility(checkbox.value);
  }

  return (
    <div className={classList}>
      {checkboxSections?.checkboxes?.map((checkbox) => (
        <div className="grid-v2-utilities__filter-options">
          <input
            id="utility-filter-radio"
            name="Utility"
            onChange={() => handleChange(checkbox)}
            type="radio"
            value={checkbox.label}
            defaultChecked={checkbox.label === defaultValue?.label}
          />
          <label
            className="grid-v2-utilities__label"
            htmlFor={checkbox.label}
          >
            {checkbox.label}
          </label>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Utility;

Utility.propTypes = {
  utilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  utility: PropTypes.shape().isRequired,
  setSelectedUtility: PropTypes.func.isRequired,
};
