const env = process.env.APP_ENV || 'development';

const productDisclosures = env === 'production' ? {
  'e-Saver 10': `*Rates shown include a discount of 0.8¢ per kWh applied to billing cycles for which
                you are enrolled in AutoPay. If you are not enrolled in AutoPay, your rate will be higher as shown
                in the Electricity Facts Label (EFL). Your actual average price per kWh for electricity service will
                depend on your usage. The only circumstance in which the price could change during the 10-month
                minimum term is to reflect actual changes in law or regulatory charges.`,
  'e-Saver 12': `*Rates shown include a discount of 0.8¢ per kWh applied to billing cycles for which
                you are enrolled in AutoPay. If you are not enrolled in AutoPay, your rate will be
                higher as shown in the Electricity Facts Label (EFL). Your actual average price per
                kWh for electricity service will depend on your usage. The only circumstance in which
                the price could change during the 12-month minimum term is to reflect actual changes
                in law or regulatory charges.`,
  'Flex Forward': `*Your actual average price per kWh for electricity service will
                  depend on your usage as shown in the Electricity Facts Label (EFL).
                  The only circumstance in which the price could change during the 12-month
                  minimum term is to reflect actual changes in law or regulatory charges.`,
  'Free Nights & Solar Days 12 (8 p.m.)': `¹TXU Energy Free Nights & Solar Days 12 (8 p.m.) is available
                                            to residential customers who have a properly functioning AMS-enabled
                                            smart meter. Minimum term of 12 months and an early cancellation fee 
                                            of $150 apply. The only circumstance in which the price could change 
                                            during the 12-month minimum term is to reflect actual changes in law 
                                            or regulatory charges as described in the Electricity Facts Label (EFL).
                                            <br><br>
                                            ²Energy Charges and TDU Delivery Charges per kWh are 100% free during
                                            night hours. TXU Energy purchases solar renewable energy credits equal
                                            to 100% of your daytime energy usage.`,
  'Free Nights & Solar Days 12 (9 p.m.)': `*TXU Energy Free Nights & Solar Days 12 is available to
                                          residential customers who have had an AMS-enabled smart
                                          meter installed and provisioned at their premise by their
                                          local Transmission and Distribution Utility (TDU).
                                          TXU Energy Free Nights & Solar Days 12 has a minimum term of
                                          12 months and an early cancellation fee of $150. The only
                                          circumstance in which the price could change during the
                                          12-month minimum term is to reflect actual changes in law
                                          or regulatory charges as described in the Electricity Facts
                                          Label (EFL). See the Terms of Service Agreement and
                                          Electricity Facts Label for more details. <br><br>
                                          **Free electricity at night applies to per kWh usage
                                          charges between 9 p.m. and 6 a.m. every day for 12 full
                                          months. Other recurring and nonrecurring charges,
                                          including monthly charges and state and local taxes,
                                          apply normally to all hours of usage. TXU Energy
                                          purchases solar renewable energy credits equal to 100%
                                          of your daytime energy usage. A provisioned smart meter
                                          is required.`,
  'Free Pass 12': `*Free days apply to energy charges and TDU delivery charges per kWh. Other billed
                  charges apply normally.`,
  'On Your Terms': `*TXU Energy On Your Terms is an variable month-to-month plan with no early
                    cancellation fee. See the Terms of Service Agreement and Electricity Facts
                    Label for more details.`,
  'Savers Choice 12': `*Your actual average price per kWh for electricity service will depend on
                        your usage as shown in the Electricity Facts Label(EFL). The only circumstance
                        in which the price could change during the 12-month minimum term is to reflect
                        actual changes in law or regulatory charges.`,
  'Season Pass 12': `*50% off energy charges applies to Dec/Jan/Feb and Jun/Jul/Aug bills. TDU and
                      other billed charges apply normally in all months.<br><br>
                      **Your actual average price per kWh for electricity service will depend on
                      your usage as shown in the Electricity Facts Label (EFL). The only circumstance
                      in which the price could change during the 12-month minimum term is to reflect
                      actual changes in law or regulatory charges as described in the Electricity
                      Facts Label (EFL). TXU Energy Season Pass 12SM is an indexed rate plan that
                      has a minimum term of 12 months and an early cancellation fee of $150. See the
                      Terms of Service Agreement and Electricity Facts Label for more details.`,
  'Simple Rate 12': `*Your actual average price per kWh for electricity service will depend on your
                      usage as shown in the Electricity Facts Label (EFL). The only circumstance in
                      which the price could change during the 12-month minimum term is to reflect
                      actual changes in law or regulatory charges.`,
  'Solar Saver 12': `TXU Energy Solar Saver 12SM is a fixed rate plan that has a minimum term of 12
                      months and an early cancellation fee of $150. The only circumstance in which
                      the price could change during the 12-month minimum term is to reflect actual
                      changes in law or regulatory charges as described in the
                      Electricity Facts Label (EFL). See the Terms of Service Agreement and
                      Electricity Facts Label for more details.<br><br>
                      *If you're not completely happy with your service, just call within 60 days
                      after your sign-up date to choose another plan or switch for free. New TXU
                      Energy residential customers only. Cancellation fee applies if you cancel
                      after 60 days. You remain responsible for any billed and unbilled charges.`,
  'Solar Value 12': `*If you're not completely happy with your service, just call within 60 days
                      after your sign-up date to choose another plan or switch for free. New TXU
                      Energy residential customers only. Cancellation fee applies if you cancel
                      after 60 days. You remain responsible for any billed and unbilled charges.`,
  'Texas Choice 12': `*Cash back loyalty reward is payable by the end of February for the previous
                      calendar year to eligible customers in good standing as of the payout date who
                      have remained a TXU Energy customer continuously from date of enrollment on an
                      eligible plan. Reward is based on 3% of electricity consumption actually billed
                      to and paid for by customer (excluding taxes and certain non-recurring fees and
                      charges) while the reward program remains in effect. Reward is fulfilled with a
                      Mastercard® prepaid card, subject to terms and conditions of card issuer.
                      TXU Energy reserves the right in its sole discretion to substitute a check of
                      equal value for the Mastercard® prepaid card or to otherwise modify or cancel
                      the program at any time. For reward balances of $5 and under, TXU Energy
                      reserves the right to issue a bill credit in lieu of a prepaid card.<br><br>
                      **Your actual average price per kWh for electricity service will depend on
                      your usage as shown in the Electricity Facts Label (EFL). The only
                      circumstance in which the price could change during the 12-month minimum term
                      is to reflect actual changes in law or regulatory charges.`,
  'Texas Choice 24': `*Cash back loyalty reward is payable by the end of February for the previous
                      calendar year to eligible customers in good standing as of the payout date who
                      have remained a TXU Energy customer continuously from date of enrollment on an
                      eligible plan. Reward is based on 3% of electricity consumption actually billed
                      to and paid for by customer (excluding taxes and certain non-recurring fees and
                      charges) while the reward program remains in effect. Reward is fulfilled with a
                      Mastercard® prepaid card, subject to terms and conditions of card issuer.
                      TXU Energy reserves the right in its sole discretion to substitute a check of
                      equal value for the Mastercard® prepaid card or to otherwise modify or cancel
                      the program at any time. For reward balances of $5 and under, TXU Energy
                      reserves the right to issue a bill credit in lieu of a prepaid card.<br><br>
                      **Your actual average price per kWh for electricity service will depend on
                      your usage as shown in the Electricity Facts Label (EFL). The only
                      circumstance in which the price could change during the 24-month minimum
                      term is to reflect actual changes in law or regulatory charges.`,
  'Value Edge 12': `*Your actual average price per kWh for electricity service will depend on your
                    usage as shown in the Electricity Facts Label (EFL). The only circumstance in
                    which the price could change during the 12-month minimum term is to reflect
                    actual changes in law or regulatory charges.`,
  'Freedom Rewards 12': `*TXU Energy Freedom Rewards 12SM is a fixed rate plan that has a
                         minimum term of 12 months and an early cancellation fee of $150.
                         The only circumstance in which the price could change during the 12-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Freedom Rewards 24': `*TXU Energy Freedom Rewards 24SM is a fixed rate plan that has a
                         minimum term of 24 months and an early cancellation fee of $295.
                         The only circumstance in which the price could change during the 24-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Freedom Rewards 36': `*TXU Energy Freedom Rewards 36SM is a fixed rate plan that has a
                         minimum term of 36 months and an early cancellation fee of $395.
                         The only circumstance in which the price could change during the 36-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Ultimate Summer Pass 12': `*Discounted energy charges apply from June through September and the 3
                    hottest days a month are measured by the nearest designated weather station to the customer.
                    TXU Energy Ultimate Summer Pass 12SM is a fixed rate plan that has a minimum term of 12 months
                    and an early cancellation fee of $150. The only circumstance in which the price could change 
                    during the 12-month minimum term is to reflect actual changes in law or regulatory charges 
                    as described in the Electricity Facts Label (EFL). See the Terms of Service Agreement and 
                    Electricity Facts Label for more details.`,
  'Ultimate Summer Pass 24': `*Discounted energy charges apply from June through September and the 3
                    hottest days a month are measured by the nearest designated weather station to the customer.
                    TXU Energy Ultimate Summer Pass 24SM is a fixed rate plan that has a minimum term of 24 months
                    and an early cancellation fee of $150. The only circumstance in which the price could change 
                    during the 24-month minimum term is to reflect actual changes in law or regulatory charges 
                    as described in the Electricity Facts Label (EFL). See the Terms of Service Agreement and 
                    Electricity Facts Label for more details.`,
  'Season Pass Plus A/C Care 12': `**50% off energy charges applies to Dec/Jan/Feb and Jun/Jul/Aug
                    bills. TDU and other billed charges apply normally in all months.<br><br>*TXU
                    Energy Season Pass Plus A/C Care features 12 months of Maintenance Plus from
                    Service Experts Heating and Air Conditioning. This service provides customers
                    with one heating or air conditioning tune-up every six months, 24/7 priority
                    service, 15% repair service discounts as well as other benefits. Please visit
                    txu.com/ACCare for product details and complete terms and conditions. Service
                    Experts, Service Experts Heating & Air Conditioning, and the Service Experts
                    logo and design are registered trademarks of Service Experts LLC and used under
                    license by SE Canada Inc. Offer not valid on prior purchases and cannot be
                    combined with any other offers. Some restrictions apply. License #: TACLA19815C,
                    M-9955; TACLA72534E, 37694; TACLA87496C, 42650; TACLB26365E.`,
  'Free Nights & Solar Days 12 (8 pm)': `TXU Energy Free Nights & Solar Days 12 (8 p.m.) is available to 
            residential customers who have a properly functioning AMS-enabled smart meter. Minimum term of 
            12 months and an early cancellation fee of $150 apply. The only circumstance in which the price 
            could change during the 12-month minimum term is to reflect actual changes in law or regulatory 
            charges as described in the Electricity Facts Label (EFL).<br><br>
            Energy Charges and TDU Delivery Charges per kWh are 100% free during night hours.
            TXU Energy purchases solar renewable energy credits equal to 100% of your daytime energy usage.`,
  'Free Nights & Solar Days 24 (8 pm)': `TXU Energy Free Nights & Solar Days 12 (8 p.m.) is available to 
            residential customers who have a properly functioning AMS-enabled smart meter. Minimum term of 
            12 months and an early cancellation fee of $150 apply. The only circumstance in which the price 
            could change during the 12-month minimum term is to reflect actual changes in law or regulatory 
            charges as described in the Electricity Facts Label (EFL).<br><br>
            Energy Charges and TDU Delivery Charges per kWh are 100% free during night hours.
            TXU Energy purchases solar renewable energy credits equal to 100% of your daytime energy usage.`,
} : {
  'Free Pass 36': `*TXU Energy Free Nights & Solar Days 12 (8 p.m.) is
                  available to residential customers who have had an
                  AMS-enabled smart meter installed and provisioned at
                  their premise by their local Transmission and Distribution
                  Utility (TDU). TXU Energy Free Nights &
                  Solar Days 12 (8 p.m.) has a minimum term of 12 months and
                  an early cancellation fee of $150. The only circumstance in
                  which the price could change during the 12-month minimum
                  term is to reflect actual changes in law or regulatory
                  charges as described in the Electricity Facts Label (EFL).
                  See the Terms of Service Agreement and Electricity Facts
                  Label for more details.<br><br>
                  **Free electricity at night applies to per kWh usage charges
                  between 8 p.m. and 5 a.m. every day for 12 full months.
                  Other recurring and nonrecurring charges, including monthly
                  charges and state and local taxes, apply normally to all
                  hours of usage. TXU Energy purchases solar renewable energy
                  credits equal to 100% of your daytime energy usage. A
                  provisioned smart meter is required. `,
  'Season Pass 24': `*TXU Energy Free Nights & Solar Days 12 is available to
                    residential customers who have had an AMS-enabled smart
                    meter installed and provisioned at their premise by their
                    local Transmission and Distribution Utility (TDU).
                    TXU Energy Free Nights & Solar Days 12 has a minimum term of
                    12 months and an early cancellation fee of $150. The only
                    circumstance in which the price could change during the
                    12-month minimum term is to reflect actual changes in law
                    or regulatory charges as described in the Electricity Facts
                    Label (EFL). See the Terms of Service Agreement and
                    Electricity Facts Label for more details. <br><br>
                    **Free electricity at night applies to per kWh usage
                    charges between 9 p.m. and 6 a.m. every day for 12 full
                    months. Other recurring and nonrecurring charges,
                    including monthly charges and state and local taxes,
                    apply normally to all hours of usage. TXU Energy
                    purchases solar renewable energy credits equal to 100%
                    of your daytime energy usage. A provisioned smart meter
                    is required.`,
  'Season Pass 36': `TXU Energy Solar Saver 12SM is a fixed rate plan that has a minimum term of 12
                    months and an early cancellation fee of $150. The only circumstance in which
                    the price could change during the 12-month minimum term is to reflect actual
                    changes in law or regulatory charges as described in the
                    Electricity Facts Label (EFL). See the Terms of Service Agreement and
                    Electricity Facts Label for more details.<br><br>
                    *If you're not completely happy with your service, just call within 60 days
                    after your sign-up date to choose another plan or switch for free. New TXU
                    Energy residential customers only. Cancellation fee applies if you cancel
                    after 60 days. You remain responsible for any billed and unbilled charges.`,
  'Summer Pass 24': `*Cash back loyalty reward is payable by the end of February for the previous
                    calendar year to eligible customers in good standing as of the payout date who
                    have remained a TXU Energy customer continuously from date of enrollment on an
                    eligible plan. Reward is based on 3% of electricity consumption actually billed
                    to and paid for by customer (excluding taxes and certain non-recurring fees and
                    charges) while the reward program remains in effect. Reward is fulfilled with a
                    Mastercard® prepaid card, subject to terms and conditions of card issuer.
                    TXU Energy reserves the right in its sole discretion to substitute a check of
                    equal value for the Mastercard® prepaid card or to otherwise modify or cancel
                    the program at any time. For reward balances of $5 and under, TXU Energy
                    reserves the right to issue a bill credit in lieu of a prepaid card.<br><br>
                    **Your actual average price per kWh for electricity service will depend on
                    your usage as shown in the Electricity Facts Label (EFL). The only
                    circumstance in which the price could change during the 12-month minimum term
                    is to reflect actual changes in law or regulatory charges.`,
  'Freedom Rewards 12': `*TXU Energy Freedom Rewards 12SM is a fixed rate plan that has a
                         minimum term of 12 months and an early cancellation fee of $150.
                         The only circumstance in which the price could change during the 12-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Freedom Rewards 24': `*TXU Energy Freedom Rewards 24SM is a fixed rate plan that has a
                         minimum term of 24 months and an early cancellation fee of $295.
                         The only circumstance in which the price could change during the 24-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Freedom Rewards 36': `*TXU Energy Freedom Rewards 36SM is a fixed rate plan that has a
                         minimum term of 36 months and an early cancellation fee of $395.
                         The only circumstance in which the price could change during the 36-month
                         minimum term is to reflect actual changes in law or regulatory charges as described
                         in the Electricity Facts Label (EFL). See the Terms of Service Agreement and
                         Electricity Facts Label for more details.<br><br>
                         **Energy Cash rewards are only earned on paid-for Energy Charges on your TXU Energy
                         bill and not on other charges, taxes or when you redeem Energy Cash. Energy Cash has
                         no cash, credit or other redemption value and cannot be transferred. Energy Cash will
                         be applied automatically to your final Freedom Rewards bill with any remaining, unused
                         Energy Cash eligible for use for 60 days after your term ends if you re-enroll on an
                         eligible plan.`,
  'Summer Pass 36': `*Cash back loyalty reward is payable by the end of February for the previous
                    calendar year to eligible customers in good standing as of the payout date who
                    have remained a TXU Energy customer continuously from date of enrollment on an
                    eligible plan. Reward is based on 3% of electricity consumption actually billed
                    to and paid for by customer (excluding taxes and certain non-recurring fees and
                    charges) while the reward program remains in effect. Reward is fulfilled with a
                    Mastercard® prepaid card, subject to terms and conditions of card issuer.
                    TXU Energy reserves the right in its sole discretion to substitute a check of
                    equal value for the Mastercard® prepaid card or to otherwise modify or cancel
                    the program at any time. For reward balances of $5 and under, TXU Energy
                    reserves the right to issue a bill credit in lieu of a prepaid card.<br><br>
                    **Your actual average price per kWh for electricity service will depend on
                    your usage as shown in the Electricity Facts Label (EFL). The only
                    circumstance in which the price could change during the 12-month minimum term
                    is to reflect actual changes in law or regulatory charges.`,
  'Season Pass Plus A/C Care 12': `**50% off energy charges applies to Dec/Jan/Feb and Jun/Jul/Aug
                    bills. TDU and other billed charges apply normally in all months.<br><br>*TXU
                    Energy Season Pass Plus A/C Care features 12 months of Maintenance Plus from
                    Service Experts Heating and Air Conditioning. This service provides customers
                    with one heating or air conditioning tune-up every six months, 24/7 priority
                    service, 15% repair service discounts as well as other benefits. Please visit
                    txu.com/ACCare for product details and complete terms and conditions. Service
                    Experts, Service Experts Heating & Air Conditioning, and the Service Experts
                    logo and design are registered trademarks of Service Experts LLC and used under
                    license by SE Canada Inc. Offer not valid on prior purchases and cannot be
                    combined with any other offers. Some restrictions apply. License #: TACLA19815C,
                    M-9955; TACLA72534E, 37694; TACLA87496C, 42650; TACLB26365E.`,
  'Ultimate Summer Pass 12': `*Discounted energy charges apply from June through September and the 3
                    hottest days a month are measured by the nearest designated weather station to the customer.
                    TXU Energy Ultimate Summer Pass 12SM is a fixed rate plan that has a minimum term of 12 months
                    and an early cancellation fee of $150. The only circumstance in which the price could change 
                    during the 12-month minimum term is to reflect actual changes in law or regulatory charges 
                    as described in the Electricity Facts Label (EFL). See the Terms of Service Agreement and 
                    Electricity Facts Label for more details.`,
  'Ultimate Summer Pass 24': `*Discounted energy charges apply from June through September and the 3
                    hottest days a month are measured by the nearest designated weather station to the customer.
                    TXU Energy Ultimate Summer Pass 24SM is a fixed rate plan that has a minimum term of 24 months
                    and an early cancellation fee of $150. The only circumstance in which the price could change 
                    during the 24-month minimum term is to reflect actual changes in law or regulatory charges 
                    as described in the Electricity Facts Label (EFL). See the Terms of Service Agreement and 
                    Electricity Facts Label for more details.`,
  'Free Nights & Solar Days 12 (8 pm)': `TXU Energy Free Nights & Solar Days 12 (8 p.m.) is available to 
            residential customers who have a properly functioning AMS-enabled smart meter. Minimum term of 
            12 months and an early cancellation fee of $150 apply. The only circumstance in which the price 
            could change during the 12-month minimum term is to reflect actual changes in law or regulatory 
            charges as described in the Electricity Facts Label (EFL).<br><br>
            Energy Charges and TDU Delivery Charges per kWh are 100% free during night hours.
            TXU Energy purchases solar renewable energy credits equal to 100% of your daytime energy usage.`,
  'Free Nights & Solar Days 24 (8 pm)': `TXU Energy Free Nights & Solar Days 12 (8 p.m.) is available to 
            residential customers who have a properly functioning AMS-enabled smart meter. Minimum term of 
            12 months and an early cancellation fee of $150 apply. The only circumstance in which the price 
            could change during the 12-month minimum term is to reflect actual changes in law or regulatory 
            charges as described in the Electricity Facts Label (EFL).<br><br>
            Energy Charges and TDU Delivery Charges per kWh are 100% free during night hours.
            TXU Energy purchases solar renewable energy credits equal to 100% of your daytime energy usage.`,
};

const productDisclosure = (name) => productDisclosures[name];

export default productDisclosure;
