import React from 'react';
import PropTypes from 'prop-types';
import CheckboxTooltip from './grid-comparison-tool/CheckboxTooltip';

function Checkbox(props) {
  const {
    checkbox,
    addItem,
    removeItem,
    gridComparisonToolActive,
    comparedPlans
  } = props;
  const {
    checked, label, name, value, number = null
  } = checkbox;

  const check = () => {
    if (!checked) {
      addItem(value);
    } else {
      removeItem(value);
    }
  };

  const GctDisableCheckbox = comparedPlans?.length === 3 && gridComparisonToolActive && !checked;

  return (
    <div className="checkbox__container u-display-flex u-align-items-center">
      {GctDisableCheckbox && (
        <CheckboxTooltip />
      )}
      <div className={`checkbox ${GctDisableCheckbox ? ' checkbox__label--disabled' : ''}`} onClick={check}>
        <input
          type="checkbox"
          readOnly
          name={name}
          value={value}
          checked={checked}
        />
        <span className="checkbox__span" />
        <span className={`checkbox__label ${gridComparisonToolActive ? 'checkbox__label--gct' : ''}`}>{label}</span>
        {typeof number === 'number' && (
          <span className="checkbox__number">{`(${number})`}</span>
        )}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  checkbox: PropTypes.shape({}).isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  gridComparisonToolActive: PropTypes.bool,
  comparedPlans: PropTypes.arrayOf(PropTypes.shape({})),
};

Checkbox.defaultProps = {
  gridComparisonToolActive: false,
  comparedPlans: []
};

export default Checkbox;
