/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { FeaturedCardLabels } from '@rvpower/constants';

// SVGs
import apartmentIcon from '../svg/1000-icon.svg';
import smallHouseIcon from '../svg/1500-icon.svg';
import largeHouseIcon from '../svg/2000-icon.svg';
import creditIcon from '../svg/no-credit-check.svg';
import billIcon from '../svg/ico-bill-credit.svg';
import freenightsIcon from '../svg/ico-free-nights.svg';
import freeweekendsIcon from '../svg/ico-free-weekends.svg';
import greatvalueIcon from '../svg/ico-great-value.svg';
import moversIcon from '../svg/ico-our-mover-special.svg';
import nodepositIcon from '../svg/ico-no-deposit.svg';
import nosuprisesIcon from '../svg/ico-no-surprises.svg';
import popularIcon from '../svg/ico-popular-plan.svg';
import earthIcon from '../svg/ico-save-the-planet.svg';
import seasonalIcon from '../svg/ico-seasonal-discount.svg';
import instantApproval from '../svg/ico-instant-approval.svg';
import highCustomerSatisfaction from '../svg/ico-customer-satisfaction-v2.svg';
import popularProvider from '../svg/ico-popular-provider.svg';
import lowEnergyCharge from '../svg/ico-low-energy-charge.svg';
import popularPlan from '../svg/ico-popular-plan-v2.svg';
import greenEnergy from '../svg/ico-green-energy-v2.svg';
import competitiveRate from '../svg/ico-competitive-rate.svg';
import acProtection from '../svg/ico-ac-protection.svg';
import flatEnergyRate from '../svg/ico-flat-energy-rate.svg';
import longerPriceGuarantee from '../svg/ico-longer-price-guarantee.svg';
import longerPriceSecurity from '../svg/ico-longer-price-security.svg';
import noGimmicks from '../svg/ico-no-gimmicks.svg';
import renewableLeaf from '../svg/ico-renewable-leaf.svg';
import renewableRecycle from '../svg/ico-renewable-recycle.svg';
import popularForMoving from '../svg/ico-popular-for-moving.svg';
import popularForSwitching from '../svg/ico-popular-for-switching.svg';
import exclusiveOfferBadge from '../svg/ico-exclusive-offer-badge.svg';
import giftCardIcon from '../svg/ico-giftcard.svg';
import cashBackIcon from '../svg/ico-cash-back.svg';
import smartHomeEquipmentIcon from '../svg/ico-smarthome-equipment.svg';

function FeaturedCardLabel(props) {
  const { plan } = props;
  const { labelId } = plan;

  const gridSvgOptions = {
    1: apartmentIcon,
    2: smallHouseIcon,
    3: largeHouseIcon,
    4: creditIcon,
    5: nodepositIcon,
    6: earthIcon,
    7: popularIcon,
    8: greatvalueIcon,
    9: nosuprisesIcon,
    10: freenightsIcon,
    11: freeweekendsIcon,
    12: billIcon,
    13: seasonalIcon,
    14: moversIcon,
    15: freeweekendsIcon,
    16: billIcon,
    17: popularIcon,
    18: billIcon,
    19: giftCardIcon,
    20: cashBackIcon,
    21: smartHomeEquipmentIcon,
    22: largeHouseIcon, // icon re-purposed for "great for work from home"
    23: instantApproval,
    24: highCustomerSatisfaction,
    25: popularProvider,
    26: lowEnergyCharge,
    27: popularPlan,
    28: greenEnergy,
    29: competitiveRate,
    30: acProtection,
    31: flatEnergyRate,
    32: longerPriceGuarantee,
    33: longerPriceSecurity,
    34: noGimmicks,
    35: renewableLeaf,
    36: renewableRecycle,
    37: popularForMoving,
    38: popularForSwitching,
    39: exclusiveOfferBadge,
    40: exclusiveOfferBadge,
  };

  let labelIcon = nosuprisesIcon;
  let labelText = FeaturedCardLabels[8].label;

  if (labelId && labelId !== '') {
    labelIcon = gridSvgOptions[labelId];
    labelText = FeaturedCardLabels[labelId - 1].label;
  }

  // Overriding labelText to allow us to use different label names
  if (labelId === 35 || labelId === 36) {
    labelText = '100% Renewable';
  }

  if (labelId === 39 || labelId === 40) {
    labelText = 'EXCLUSIVE OFFER';
  }

  return (
    <div className="featured-card__section label u-display-flex u-align-items-center">
      {labelId === 39 || labelId === 40 ? (
        <ReactSVG className="svg svg--featured-card-icon" src={labelIcon} />
      ) : <img className="svg svg--featured-card-icon" src={labelIcon} alt={labelText} />}
      <span className="label__text">{labelText}</span>
    </div>
  );
}

export default FeaturedCardLabel;

FeaturedCardLabel.propTypes = {
  plan: PropTypes.shape().isRequired,
};
