import React from 'react';
import PropTypes from 'prop-types';

import { Sites } from '@rvpower/constants';
import Providers from '../filters/providers';
import Features from '../filters/features';
import TermLength from '../filters/term-length';
import RateType from '../filters/rate-type';
import Rating from '../filters/rating';
import HomeSize from '../filters/home-size';
import BillPrice from '../filters/bill-price';
import Utility from '../filters/utility';
import CreditScore from '../filters/credit-score';

const PillAllFilters = ({
  allPlans,
  filters,
  isNTX,
  plans,
  providers,
  rates,
  setFilters,
  setUsage,
  setSelectedUtility,
  utilities,
  utility,
  terms,
  site,
  creditFilterToggle
}) => {
  const isTxu = site === Sites.TXU;

  return (
    <div className={`grid-v2-pills__all-filters ${isNTX ? 'ntx' : ''}`}>
      {isNTX ? (
        <>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Utility</h4>
            <Utility
              setSelectedUtility={setSelectedUtility}
              utilities={utilities}
              utility={utility}
            />
          </div>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Term length</h4>
            <TermLength
              filters={filters}
              plans={plans}
              setFilters={setFilters}
              terms={terms}
            />
          </div>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Providers</h4>
            <Providers
              filters={filters}
              isNTX={isNTX}
              plans={plans}
              providers={providers}
              setFilters={setFilters}
            />
          </div>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Features</h4>
            <Features
              filters={filters}
              plans={plans}
              setFilters={setFilters}
              isNTX
            />
          </div>
        </>
      ) : (
        <>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Home size</h4>
            <HomeSize
              filters={filters}
              setFilters={setFilters}
              setUsage={setUsage}
              isTxu={isTxu}
            />
          </div>
          {!isTxu && (
            <>
              {creditFilterToggle && (
              <div className="grid-v2-pills__all-filters-section">
                <h4 className="grid-v2-pills__all-filters-heading">Credit score</h4>
                <CreditScore
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
              )}
              <div className="grid-v2-pills__all-filters-section">
                <h4 className="grid-v2-pills__all-filters-heading">Monthly bill price</h4>
                <BillPrice
                  filters={filters}
                  setFilters={setFilters}
                  site={site}
                />
              </div>
              <div className="grid-v2-pills__all-filters-section">
                <h4 className="grid-v2-pills__all-filters-heading">Features</h4>
                <Features
                  filters={filters}
                  plans={plans}
                  setFilters={setFilters}
                  isNTX={false}
                />
              </div>
            </>
          )}
          {!isTxu && (
            <>
              <div className="grid-v2-pills__all-filters-section">
                <h4 className="grid-v2-pills__all-filters-heading">Providers</h4>
                <Providers
                  filters={filters}
                  isNTX={isNTX}
                  plans={plans}
                  providers={providers}
                  setFilters={setFilters}
                />
              </div>
              <div className="grid-v2-pills__all-filters-section">
                <h4 className="grid-v2-pills__all-filters-heading">Rating</h4>
                <Rating
                  allPlans={allPlans}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </>
          )}
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Term length</h4>
            <TermLength
              filters={filters}
              plans={plans}
              setFilters={setFilters}
              terms={terms}
            />
          </div>
          <div className="grid-v2-pills__all-filters-section">
            <h4 className="grid-v2-pills__all-filters-heading">Rate type</h4>
            <RateType
              filters={filters}
              rates={rates}
              setFilters={setFilters}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PillAllFilters;

PillAllFilters.propTypes = {
  allPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  isNTX: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rates: PropTypes.arrayOf(PropTypes.oneOf(['Fixed', 'Variable', 'Indexed'])).isRequired,
  setFilters: PropTypes.func.isRequired,
  setUsage: PropTypes.func.isRequired,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  site: PropTypes.string,
  creditFilterToggle: PropTypes.bool.isRequired,
};

PillAllFilters.defaultProps = {
  site: ''
};
