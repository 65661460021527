import React from 'react';
import { Sites } from '@rvpower/constants';

import ZipSearchInline from './ZipSearchInline';

import solarPanelIcon from '../../../images/solar-panel-icon.svg';
import plugIcon from '../../../images/plug-icon.svg';
import emailIcon from '../../../images/email-icon.svg';
import editIcon from '../../../images/edit-zip-icon.svg';

const UnserveHero = (props) => {
  const { zipCode, site, plansCount } = props;

  const isSOE = site === Sites.SOE;

  return (
    <div className="unserve-hero">
      <div className="unserve-hero__heading">
        <h1 className="unserve-hero__heading-title">
        We don&rsquo;t currently have electricity plans available at&nbsp;
          <ZipSearchInline
            zipCode={zipCode}
            site={site}
            plansCount={plansCount}
          />
          <div className="unserve-hero__heading-icon-container">
            <img alt="edit icon" src={editIcon} className="unserve-hero__heading-icon" />
          </div>
        </h1>
        <p className="unserve-hero__heading-subtitle">Explore other available services at your home.</p>
      </div>

      <div className="unserve-hero__quick-links">
        <a href="#slf" className="unserve-hero__quick-links-anchors">
          <img alt="solar panel icon" src={solarPanelIcon} className="unserve-hero__quick-links-anchors-icons" />
          Get free solar estimate
        </a>
        <a href="#smart-tech" className="unserve-hero__quick-links-anchors">
          <img alt="plug icon" src={plugIcon} className="unserve-hero__quick-links-anchors-icons" />
          Shop for smart tech
        </a>
        {isSOE && (
          <a href="#subscribe" className="unserve-hero__quick-links-anchors">
            <img alt="email icon" src={emailIcon} className="unserve-hero__quick-links-anchors-icons" />
            Stay connected
          </a>
        )}
      </div>
    </div>
  );
};

export default UnserveHero;
