import React, { useState, useRef, useEffect } from 'react';
import { updateZip } from '../../js/project/get-query-params';
import beam from '../../js/project/tagular/beam';

const ZipSearch = (props) => {
  const { zipCode, site } = props;
  const [localZipCode, setLocalZipCode] = useState(zipCode);

  const inputEl = useRef(null);

  const submit = (e) => {
    e.preventDefault();

    const newZip = inputEl.current.value;
    setLocalZipCode(newZip);
    updateZip(site, newZip);
  };

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  const clickHandler = () => {
    const payload = {
      webElement:
        {
          elementType: 'Button',
          location: 'UnservGrid',
        },
      actionOutcome: 'Edit Zip'
    };

    beam.userTrackingV3('ElementClicked', payload);
  };

  // Updates the zip in the middle of the grid page after the browser back button is clicked
  useEffect(() => {
    if (zipCode !== localZipCode) {
      setLocalZipCode(zipCode);
      updateZip(site, zipCode);
    }
  }, []);

  return (
    <form className="zip-search__form" onSubmit={submit}>
      <input
        id="zip-code"
        className="zip-search__input"
        ref={inputEl}
        value={localZipCode}
        onChange={(e) => handleChange(e)}
        onClick={clickHandler}
        type="text"
        inputMode="numeric"
        maxLength="5"
        pattern="[0-9]{5}"
        title="Please enter a valid zip code"
        required
        aria-label="zip code search"
      />
    </form>
  );
};

export default ZipSearch;
