import React, { useState, useEffect } from 'react';
import { ProductListViewed, ProductListFiltered } from '@rvpower/track';
import {
  GridSortOptions,
  termTypeToText,
  Sites,
  SupplierIds,
  RatingSites,
  supplierIdMap,
  GridNewSOESortOptions,
  TxuGridSortOptions,
  cartUrl as buildCartUrl
} from '@rvpower/constants';
import URLSearchParams from '@ungap/url-search-params';
import { isMobile } from 'react-device-detect';

import { getAllPlanList, getFilterFields } from '../../js/project/tracking-helper';
import getDefaultFeaturedPlans from '../../js/project/featured-plans-defaults';
import planFilterFn from '../../js/project/plan-filter';
import phoneBannerPlacements from '../../js/project/phone-banner-placements';
import { usePreamp } from '../preamp';

// React Components
import FeaturedCards from './FeaturedCards';
import Options from '../Options';
import Cards from '../Cards';
import SortButton from '../grid-v2/SortButton';
import CardsKwh from '../kwh-calculator/CardsKwh';
import ComparisonCards from '../ComparisonCards';
import Tips from '../Tips';
import ComparisonBanner from '../ComparisonBanner';
import UsageKwh from '../kwh-calculator/UsageKwh';
import GuaranteeBanner from './GuaranteeBanner';
import GridComparisonTool from '../grid-comparison-tool/GridComparisonTool';
import TXUAccordion from '../grid-faqs/TXUAccordion';
import GraphCard from '../electric-phoenix/graph-card';
import UtilitySelectModal from '../UtilitySelectModal';
import ChooseGridBannerArcadia from '../choose-grid-banner/ChooseGridBannerArcadia';
import GridPhoneBanner from '../GridPhoneBanner';
import NonGimmickyBanner, { config as NonGimmickyBannerConfig } from '../NonGimmickyBanner';
import useEmailTracking from '../useEmailTracking';
import ComparePriceBanner from '../ComparePriceBanner';
import GoogleReviewsBannerSimple from '../GoogleReviewsBannerSimple';
import GoogleReviewsBannerComments from '../GoogleReviewsBannerComments';
import Pills from '../grid-v2/pills';
import FilterMenuMobile from '../grid-v2/filter-menu/FilterMenuMobile';
import TxuFooter from './TxuFooter';
import TxUTerms from '../TxUTerms/TxUTerms';
import TXUHeroSectionSimplified from './TXUHeroSectionSimplified';
import TXUEditLocation from './TXUEditLocation';
import TxuUnservePage from './TxuUnservePage';

const usageLegend = {
  TwoThousandKwh: 2000,
  OneThousandKwh: 1000,
  FiveHundredKwh: 500
};

// pass over mover-switcher and dwelling-type to txu cartUrl
const params = new URLSearchParams(window.location.search);
let moverSwitcher = '';
let moverSwitcherTagValue = '';

if (params.get('m') === 'moven') {
  moverSwitcher = 'switch';
  moverSwitcherTagValue = 'Residential Enrollment Switch';
} else if (params.get('m') === 'movey') {
  moverSwitcher = 'mover';
  moverSwitcherTagValue = 'Residential Enrollment Move';
}

let dwellingType = '';
let dwellingTypeTagValue = '';
if (params.get('a') === 'apartmenty') {
  dwellingType = 'multi';
  dwellingTypeTagValue = '02';
} else if (params.get('a') === 'apartmentn') {
  dwellingType = 'single';
  dwellingTypeTagValue = '01';
}

let showGreenPlans = false;
if (params.get('showGreenPlans') === 'Yes') {
  showGreenPlans = true;
}

function getFeaturedPlans(plansById, utilityId, featuredConfig, site) {
  const { utilities } = featuredConfig;

  if (utilities) {
    const { featuredPlans = [] } = utilities.find((util) => util.utilityId === utilityId) || {};
    return featuredPlans.map((plan) => ({
      ...plansById[plan.planId],
      labelId: parseInt(plan.labelId, 10),
      usageId: plan.usageId || '1'
    })).filter((plan) => plan.name);
  }
  return getDefaultFeaturedPlans(site).map((plan) => ({ ...plansById[plan.planId], labelId: plan.labelId }))
    .filter((plan) => plan.name);
}

function getDefaultFilters(listConfig) {
  const { filters = {} } = listConfig;
  const {
    usage, features, providers, rateTypes, terms, customPlanOrder
  } = filters;

  const usageString = usage;

  return {
    usage: parseInt(usageString, 10) || 1000,
    features: showGreenPlans ? ['GreenEnergy'] : (features || []),
    providers: providers || [],
    rateTypes: rateTypes || [],
    customPlanOrder: customPlanOrder || [],
    terms: terms ? terms.map(termTypeToText) : [],
    ratings: [],
    billPrice: { minBillPrice: null, maxBillPrice: null }
  };
}

function editPlans(
  plans = [],
  zipCode,
  filters,
  utility,
  isCallCenterOpen,
  site,
  disablePhoneNumbers,
  isNTX
) {
  return plans.map((plan) => {
    const prices = {};
    const estimates = {};
    plan.rates.forEach((r) => {
      if (usageLegend[r.type]) {
        prices[usageLegend[r.type]] = r.price ? (parseFloat(r.price)) : 0;
        estimates[usageLegend[r.type]] = r.price ? (parseFloat(r.price) * usageLegend[r.type]).toFixed(0) : 0;
      }
    });

    const { usage } = filters;
    const cartUrl = buildCartUrl(site, plan.id, utility.id, zipCode, usage, moverSwitcher, dwellingType);

    const disableAllPhoneNumbers = disablePhoneNumbers || plan.supplier.controls?.disablePhoneNumbers;

    return {
      ...plan,
      TermsDoc: plan.documents.find((document) => document.type === 'Terms'),
      EFLDoc: plan.documents.find((document) => document.type === 'Efl'),
      YRACDoc: plan.documents.find((document) => document.type === 'Yrac'),
      fee: plan.fees?.find((fee) => fee.type === 'EarlyTerminationFee'),
      price: prices[usage],
      prices,
      estimate: estimates[usage],
      estimates,
      usage,
      utility,
      cartUrl,
      active: plan.active && !plan.supplier.controls?.disableGrid,
      phoneActive: isCallCenterOpen && !disableAllPhoneNumbers,
      isCallCenterOpen,
      cartActive: plan.cartActive,
      badges: plan.badges,
      termText: termTypeToText(plan.term),
      isNTX,
    };
  });
}

function TxuGrid(props) {
  const {
    ESIID,
    site,
    isCallCenterOpen,
    disableCartLinks,
    disablePhoneNumbers,
    utilities = [],
    zipCode,
    featuredConfig = {},
    listConfig = {},
    city,
    electricPhoenixInput,
    selectedUtility,
    setSelectedUtility,
    utility,
    fuseData,
  } = props;
  const isTXU = site === Sites.TXU;
  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';

  function removeOldFooter() {
    if (isTXU) {
      if (document.querySelector('.footer__container')) {
        const footer = document.querySelector('.footer__container');
        footer.remove();
      }

      if (
        document.querySelector('.footer-bluecurved-angle__container-svg--top')
      ) {
        const footer = document.querySelector(
          '.footer-bluecurved-angle__container-svg--top'
        );
        footer.remove();
      }
    }
  }
  removeOldFooter();

  function epDataValid(obj) {
    let isValid = true;
    if (!obj.annualCost || !obj.annualUsage || !obj.averageCost || !obj.monthlyUsage.length) {
      isValid = false;
    }
    return isValid;
  }

  const [sort, setSort] = useState(null);
  const {
    plans = [],
    comparisonPlans = [],
    epData = {},
    electricPhoenixData = {}
  } = utility;

  const originalFilters = getDefaultFilters(listConfig);
  const [filters, setFilters] = useState(originalFilters);
  const gridComparisonToolActive = usePreamp('Grid Comparison Tool');
  const chooseGridBannerArcadia = usePreamp('Choose Grid Banner Arcadia');
  const googleReviewsBannerSimple = usePreamp('Google Reviews Grid Banner Simple');
  const googleReviewsBannerComments = usePreamp('Google Reviews Grid Banner Comments');
  const nonGimmickyBannerAsset = usePreamp(NonGimmickyBannerConfig.placement);
  const faqsBannerActive = !isTXU;
  const [comparedPlans, setComparedPlans] = useState([]);
  const [enableCardAnimation, setEnableCardAnimation] = useState(false);
  const [kWhAmountUpdated, setKwHamountUpdated] = useState(0);
  const [kWhPlansWithPrice, setKhWPlansWithPrice] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [filtersActiveSection, setFiltersActiveSection] = useState('');
  const [kwhOrSqft, setKwhOrSqft] = useState('kwh');

  const handleSort = (sortType) => {
    setSort(sortType);
  };

  const chooseGridBannerArcadiaActive = site === Sites.CHOOSE && chooseGridBannerArcadia;
  const nonGimmickyBannerActive = site === Sites.NEW_TXER && nonGimmickyBannerAsset;

  const electricPhoenixToggleActive = params.get('electricPhoenixToggleActive') === 'true';
  // eslint-disable-next-line max-len
  const [electricPhoenixActive, setElectricPhoenixActive] = useState(params.get('electricPhoenixActive') === 'true' && epDataValid(electricPhoenixData));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ratingsEnabled = RatingSites.includes(site);

  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;

  // eslint-disable-next-line max-len
  const { usage } = filters;

  const placements = phoneBannerPlacements[site] || phoneBannerPlacements.default;

  const bannerGetHelp = usePreamp(placements.getHelp);
  const bannerCallNow = usePreamp(placements.talk);
  const bannerQuestion = usePreamp(placements.questions);
  const bannerComparePrice = usePreamp('Grid Compare Price Banner', true);
  const bannerText = bannerGetHelp || bannerCallNow || bannerQuestion;
  const emailTracking = useEmailTracking();

  useEffect(() => {
    document.documentElement.scrollIntoView();

    const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    if (scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (electricPhoenixActive) {
      setSort('bill-low');
    } else {
      if (!listConfig.filters) { return; }
      setSort(listConfig.sort || 'rate-low');
      setFilters(getDefaultFilters(listConfig));
    }
  }, [listConfig, electricPhoenixActive]);

  const editedComparisonPlans = editPlans(
    comparisonPlans,
    zipCode,
    filters,
    utility,
    isCallCenterOpen,
    site,
    disablePhoneNumbers,
    isNTX
  );

  const shouldAbortLoadingPlans = (txuPlans) => {
    if (!isTXU) {
      return false;
    }

    return txuPlans.some((plan) => plan.supplier.id !== SupplierIds.TxuBranded);
  };

  const setKwhPlansWithPrices = (plansWithPrice) => {
    const newPlan = plansWithPrice[0]?.price;
    const oldPlan = kWhPlansWithPrice[0]?.price;
    if (newPlan !== oldPlan) {
      setKhWPlansWithPrice([...plansWithPrice]);
    }
  };

  /** Check EP data object to ensure all properties have a value */
  const checkEPObject = (data) => {
    const properties = Object.entries(data);
    // eslint-disable-next-line
    return properties.map((property) => {
      const value = property[1];
      if (typeof value === 'string' && !value) {
        return false;
      }

      if (typeof value === 'number' && value <= 0) {
        return false;
      }

      if (Array.isArray(value) && !value.length) {
        return false;
      }

      if (typeof value === 'object') {
        return Object.keys.length > 0;
      }

      return true;
    }).every((v) => v === true);
  };

  const editedPlans = editPlans(
    plans,
    zipCode,
    filters,
    utility,
    isCallCenterOpen,
    site,
    disablePhoneNumbers,
    isNTX
  )
    .filter((p) => {
      let epActiveAndPlanIneligible = false;
      if (electricPhoenixActive) {
        // Check is plan is valid for electric phoenix
        if (!p.electricPhoenixActive || !checkEPObject(p.electricPhoenixData)) {
          epActiveAndPlanIneligible = true;
        }
        const supplierName = epData.input.lastBillSupplierName;
        // Remove all plans whose provider matches the electric phoenix questionnaire current provider response
        if (p.supplier.id === supplierIdMap[supplierName]) {
          epActiveAndPlanIneligible = true;
        }
      }
      return p.active && (p.phoneActive || p.cartActive) && !p.hideOnGrid && !epActiveAndPlanIneligible;
    });

  const editedPlansById = editedPlans.reduce((accum, curVal) => ({ ...accum, [curVal.id]: curVal }), {});

  const featuredPlans = getFeaturedPlans(editedPlansById, utility.id, featuredConfig, site);

  useEffect(() => {
    if (utilities.length === 1 || (utilities.length > 1 && selectedUtility)) {
      ProductListViewed(getAllPlanList(featuredPlans, editedPlans), zipCode);
    }
  }, [selectedUtility, utilities.length, zipCode]);

  // filter options based off plans
  const terms = [...new Set(editedPlans.map((p) => p.termText))];
  const providersById = editedPlans.reduce((accum, p) => {
    const { id, name } = p.supplier;
    if (!accum[id]) return { ...accum, [id]: { id, name } };
    return accum;
  }, {});

  const providers = Object.values(providersById).sort((a, b) => a.name.localeCompare(b.name));
  const rates = [...new Set(editedPlans.map((p) => p.type))].filter((el) => el != null);

  // remove any filters that don't have any plans associated with them
  if (filters.terms) filters.terms = filters.terms.filter((t) => terms.includes(t));
  if (filters.providers) filters.providers = filters.providers.filter((p) => !!providersById[p]);
  if (filters.rates) filters.rates = filters.rates.filter((r) => rates.includes(r));

  // filter the plans
  const filteredPlans = editedPlans.filter(planFilterFn(filters, ratingsEnabled));

  const [epPrimaryPlan, setEpPrimaryPlan] = useState(filteredPlans[0]);

  const updatePlanPrice = (planList) => {
    const plansWithPrice = planList.map((plan) => {
      const pricePerKwhMonth = plan?.price || [];
      if (!pricePerKwhMonth) {
        return null;
      }

      const roundedEstimate = Math.round(pricePerKwhMonth * usage);
      const roundedPricePerKwh = (pricePerKwhMonth * 100).toFixed(1);
      // eslint-disable-next-line no-param-reassign
      plan.roundedEstimate = roundedEstimate;
      // eslint-disable-next-line no-param-reassign
      plan.roundedPricePerKwh = roundedPricePerKwh;
      return plan;
    });
    return plansWithPrice;
  };

  useEffect(() => {
    if (kWhAmountUpdated) {
      const updatedPlanValues = kWhPlansWithPrice?.filter(
        (plan) => comparedPlans.some((planToMatch) => plan.name === planToMatch.name)
      );
      const latestWithPrice = updatePlanPrice(updatedPlanValues);
      setComparedPlans([...latestWithPrice]);
    }
  }, [kWhPlansWithPrice]);

  const updateSort = (s) => {
    setSort(s);
    ProductListFiltered(
      getAllPlanList(featuredPlans, editedPlans),
      getFilterFields(filters, providersById), GridSortOptions[s]
    );
  };

  const updateFilters = (f) => {
    setFilters(f);
    ProductListFiltered(
      getAllPlanList(featuredPlans, editedPlans),
      getFilterFields(f, providersById), GridSortOptions[sort]
    );
  };

  useEffect(() => {
    if (showGreenPlans) updateFilters(originalFilters);
  }, []);

  useEffect(() => {
    emailTracking.initialize();
  }, []);

  const scrollToTopOfList = () => {
    const zipSearchForm = document.querySelector('.zip-search__form');
    if (zipSearchForm) {
      zipSearchForm.scrollIntoView();
    }
  };

  const setNewAmount = (val) => {
    setKwHamountUpdated(val);
  };

  const beam = (eventName, data) => {
    if (window.tagular) {
      window.tagular('beam', eventName, {
        '@type': `redventures.usertracking.v3.${eventName}`,
        ...data,
      });
    }
  };

  function generateQuestionnaireUrlParams() {
    const zipcodeValue = params.get('zipcode');
    let movingParam = '';
    if (params.get('m')) {
      if (params.get('m') === 'movey') {
        movingParam = 'm=movey';
      } else {
        movingParam = 'm=moven';
      }
    }

    return `?zipcode=${zipcodeValue}${movingParam && '&'}${movingParam && movingParam}&option=true`;
  }

  const toggleEP = () => {
    const payload = {
      webElement: {
        elementType: 'Button',
        location: electricPhoenixActive ? 'EP Grid' : 'Control Grid',
        name: electricPhoenixActive ? 'Toggle to control grid' : 'Toggle to EP grid',
        text: 'Load page to show plans with highest savings first',
        position: 'Grid',
      }
    };

    beam('ElementClicked', payload);

    // If questionnaire was not filled out, redirect back to questionnaire page
    if (!electricPhoenixActive && (!epData.annualCost || !epData.annualUsage || !epData.averageCost)) {
      document.location.href = `/home-info${generateQuestionnaireUrlParams()}`;
    }
    setElectricPhoenixActive(!electricPhoenixActive);
  };

  const getPlansCount = (currentSite) => {
    if ([Sites.TXU].includes(currentSite)) {
      return filteredPlans.length;
    }

    return editedPlans.length;
  };

  if (getPlansCount(site) === 0 && utilities.length === 0) {
    return <TxuUnservePage />;
  }

  return (
    <div className="txu-redesign">
      <TXUHeroSectionSimplified />
      {bannerText && !isSOE && <GridPhoneBanner site={site} text={bannerText} />}
      {electricPhoenixActive && filteredPlans.length && (
        <GraphCard
          plan={epPrimaryPlan}
          electricPhoenixToggleActive={electricPhoenixToggleActive}
          toggleEP={toggleEP}
          epData={electricPhoenixData}
          site={site}
          electricPhoenixInput={electricPhoenixInput}
        />
      )}
      {nonGimmickyBannerActive && <NonGimmickyBanner assetName={nonGimmickyBannerAsset} />}
      {isNTX && bannerComparePrice
        && (
        <ComparePriceBanner
          title={bannerComparePrice.title}
          content={bannerComparePrice.content}
          protip={bannerComparePrice.protip}
        />
        )}
      { !isNTX && !electricPhoenixActive && (
      <FeaturedCards
        ESIID={SOEesiid}
        featuredPlans={featuredPlans}
        site={site}
        shouldAbortLoadingPlans={shouldAbortLoadingPlans}
        gridComparisonToolActive={gridComparisonToolActive}
        comparedPlans={comparedPlans}
        setComparedPlans={setComparedPlans}
        disableCartLinks={disableCartLinks}
        electricPhoenixToggleActive={electricPhoenixToggleActive}
        toggleEP={toggleEP}
        dwellingType={dwellingType}
      />
      )}
      <GuaranteeBanner />

      {!isNTX && <Tips params={params} site={site} />}
      { /* eslint-disable-next-line max-len */ }
      {gridComparisonToolActive && !isTXU && !isNTX && <ComparisonBanner />}

      {isTXU && !isMobile && googleReviewsBannerSimple && <GoogleReviewsBannerSimple />}
      {isTXU && !isMobile && googleReviewsBannerComments && <GoogleReviewsBannerComments />}
      {gridComparisonToolActive && !isTXU && (
        <GridComparisonTool
          comparedPlans={comparedPlans}
          setComparedPlans={setComparedPlans}
          site={site}
          usage={usage}
        />
      )}

      <div className="grid-v2">
        <div className="simplified-all-plans-header">
          <div className="all-plans-label">
            <span>Showing all plans for </span>
            {zipCode}
          </div>
          <TXUEditLocation />
        </div>
        <div className="txu-redesign filters-grid">
          <div className="txu-redesign txu-pills">
            <Pills
              allPlans={editedPlans}
              isCallCenterOpen={isCallCenterOpen}
              fuseData={fuseData}
              filters={filters}
              isOpen={toggleFilter}
              isNTX={isNTX}
              plans={filteredPlans}
              providers={providers}
              rates={rates}
              setIsOpen={setToggleFilter}
              setFilters={updateFilters}
              setFiltersActiveSection={setFiltersActiveSection}
              setUsage={setKwhOrSqft}
              terms={terms}
              usage={kwhOrSqft}
              site={site}
              className="grid-v2--filter"
            />
          </div>
          <div className="txu-redesign txu-sort">
            <SortButton
              options={TxuGridSortOptions}
              sort={sort}
              setSort={setSort}
              isNTX={isNTX}
              displaySortLabel
            />
          </div>
        </div>
        <div className="grid-v2__column grid-v2__column-extended">
          <FilterMenuMobile
            allPlans={editedPlans}
            filters={filters}
            filtersActiveSection={filtersActiveSection}
            isNTX={isNTX}
            isOpen={toggleFilter}
            options={GridNewSOESortOptions}
            plans={filteredPlans}
            providers={providers}
            rates={rates}
            setIsOpen={setToggleFilter}
            setFilters={updateFilters}
            setFiltersActiveSection={setFiltersActiveSection}
            setSort={handleSort}
            setUsage={setKwhOrSqft}
            sort={sort}
            terms={terms}
            site={site}
          />
        </div>
      </div>
      <div className="column-container u-display-flex@xl grid-v2">
        <div className={`column-right u-display-flex ${gridComparisonToolActive ? 'gct-active' : ''}`}>
          {!isTXU ? (
            <>
              <div className="mobile-filters">
                {!electricPhoenixActive && !isNTX && (
                  <UsageKwh
                    site={site}
                    plans={filteredPlans}
                    providers={providers}
                    rates={rates}
                    terms={terms}
                    filters={filters}
                    customPlanOrder={filters.customPlanOrder || []}
                    setFilters={updateFilters}
                    originalFilters={originalFilters}
                    device="mobile"
                    setNewAmount={setNewAmount}
                  />
                )}
                <Options
                  site={site}
                  electricPhoenixActive={electricPhoenixActive}
                  plans={filteredPlans}
                  allPlans={editedPlans}
                  providers={providers}
                  rates={rates}
                  terms={terms}
                  filters={filters}
                  setFilters={updateFilters}
                  originalFilters={originalFilters}
                  setSort={updateSort}
                  sort={sort}
                  scrollToTopOfList={scrollToTopOfList}
                  setSelectedUtility={setSelectedUtility}
                  utilities={utilities}
                  utility={utility}
                  isNTX={isNTX}
                />
              </div>
              <CardsKwh
                ESIID={SOEesiid}
                electricPhoenixActive={electricPhoenixActive}
                electricPhoenixToggleActive={electricPhoenixToggleActive}
                epPrimaryPlan={epPrimaryPlan}
                setEpPrimaryPlan={setEpPrimaryPlan}
                electricPhoenixInput={electricPhoenixInput}
                shouldAbortLoadingPlans={shouldAbortLoadingPlans}
                plans={filteredPlans}
                site={site}
                usage={usage}
                sort={sort}
                epData={electricPhoenixData}
                filters={filters}
                utility={utility?.id}
                enableCardAnimation={enableCardAnimation}
                setEnableCardAnimation={setEnableCardAnimation}
                comparedPlans={comparedPlans}
                setComparedPlans={setComparedPlans}
                gridComparisonToolActive={gridComparisonToolActive}
                setKwhPlansWithPrices={setKwhPlansWithPrices}
                kWhAmountUpdated={kWhAmountUpdated}
                faqsBannerActive={faqsBannerActive}
                disableCartLinks={disableCartLinks}
                isNTX={isNTX}
              />
            </>
          ) : (
            <>
              <Options
                site={site}
                electricPhoenixActive={electricPhoenixActive}
                plans={filteredPlans}
                allPlans={editedPlans}
                providers={providers}
                rates={rates}
                terms={terms}
                filters={filters}
                setFilters={updateFilters}
                originalFilters={originalFilters}
                setSort={updateSort}
                sort={sort}
                setSelectedUtility={setSelectedUtility}
                utilities={utilities}
                utility={utility}
                isNTX={isNTX}
              />
              <Cards
                shouldAbortLoadingPlans={shouldAbortLoadingPlans}
                gridComparisonToolActive={gridComparisonToolActive}
                plans={filteredPlans}
                site={site}
                sort={sort}
                filters={filters}
                enableCardAnimation={enableCardAnimation}
                setEnableCardAnimation={setEnableCardAnimation}
                comparedPlans={comparedPlans}
                setComparedPlans={setComparedPlans}
                faqsBannerActive={faqsBannerActive}
                disableCartLinks={disableCartLinks}
                dwellingType={dwellingType}
              />
            </>
          )}
          {editedComparisonPlans.length > 0
            && <ComparisonCards plans={editedComparisonPlans} />}

          {isNTX && site === Sites.CHOOSE && <Tips params={params} site={site} className="tips-below-grid" />}

          {chooseGridBannerArcadiaActive && (
            <ChooseGridBannerArcadia assetName={chooseGridBannerArcadia} className="arcadia-banner-below-grid" />
          )}

          <TXUAccordion />

          <TxUTerms />

          <UtilitySelectModal
            selectedUtility={utility}
            utilities={utilities}
            setSelectedUtility={setSelectedUtility}
            isNTX={isNTX}
          />
        </div>
      </div>
      {isTXU
        && (
          <>
            <span className="u-display-none" data-aa-dwelling-type={dwellingTypeTagValue} />
            <span className="u-display-none" data-aa-tdsp={utility.name} />
            <span className="u-display-none" data-aa-mover-switcher={moverSwitcherTagValue} />
            <span className="u-display-none" data-aa-city={city} />
          </>
        )}
      <TxuFooter />
    </div>
  );
}

export default TxuGrid;
