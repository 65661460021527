import React, { useState } from 'react';
import {
  GridSortOptions, GridSortOptionsEP, TxuGridSortOptions, Sites
} from '@rvpower/constants';

// React Components
import Sort from './Sort';
import Filter from './Filter';

// SVGs
import close from '../svg/close.svg';
import filter from '../svg/filter.svg';

const styles = {
  [Sites.SOE]: {
    container: 'options-container u-display-flex u-flex-direction-column u-justify-content-flex-start u-flex-1',
    filter: 'options-container__filter'
  },
  default: {
    container: 'options-container u-display-flex u-align-items-center u-justify-content-space-between u-flex-wrap-wrap',
    filter: ''
  }
};

const SortWithWrapper = ({ currentSite, children }) => {
  if (currentSite === Sites.SOE) {
    return (
      <div className="options-container__sort u-display-flex u-flex-direction-row u-justify-content-space-between">
        {children}
      </div>
    );
  }

  return children;
};

function Options(props) {
  const {
    providers,
    rates,
    terms,
    filters,
    setFilters,
    originalFilters,
    setSort,
    electricPhoenixActive,
    sort,
    plans,
    allPlans,
    site,
    customPlanOrder,
    scrollToTopOfList,
    setSelectedUtility,
    utilities,
    utility,
    isNTX,
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  const isTXU = (site === Sites.TXU);

  let sortKeys;
  if (isTXU) {
    sortKeys = Object.keys(TxuGridSortOptions);
  } else {
    sortKeys = electricPhoenixActive ? Object.keys(GridSortOptionsEP) : Object.keys(GridSortOptions);
  }

  if (customPlanOrder && customPlanOrder.length === 0) {
    sortKeys = sortKeys.filter((s) => s !== 'recommended');
  }
  /* eslint-disable max-len */
  const sortOptions = sortKeys.map((k) => ({ value: k, label: electricPhoenixActive ? GridSortOptionsEP[k] : GridSortOptions[k] }));
  /* eslint-enable max-len */

  const containerStyle = styles?.[site]?.container || styles.default.container;
  const filterStyle = styles?.[site]?.filter || styles.default.filter;

  return (
    <div
      className={`${containerStyle} ${!isOpen ? 'options-container--open' : ''}`}
    >
      <SortWithWrapper currentSite={site}>
        <>
          <Sort sortOptions={sortOptions} setSort={setSort} sort={sort} />
          <button
            className="button--filter u-display-flex u-align-items-center u-display-none@xl"
            type="submit"
            onClick={() => {
              setIsOpen(!isOpen);
              document.body.classList.toggle('no-scroll');
            }}
          >
            <span>Filter</span>
            {isOpen && <img className="svg svg--filter" src={filter} alt="Filter" />}
            {!isOpen && <img className="svg svg--info-close-modal" src={close} alt="Close" />}
          </button>
        </>
      </SortWithWrapper>
      <div
        className={`${filterStyle} filter__container u-display-none@xl
        ${!isOpen ? 'u-display-block' : 'u-display-none'}`}
      >
        <Filter
          hideUsage
          providers={providers}
          rates={rates}
          terms={terms}
          filters={filters}
          setFilters={setFilters}
          originalFilters={originalFilters}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          plans={plans}
          allPlans={allPlans}
          site={site}
          scrollToTopOfList={scrollToTopOfList}
          setSelectedUtility={setSelectedUtility}
          utilities={utilities}
          utility={utility}
          isNTX={isNTX}
        />
      </div>
    </div>
  );
}

export default Options;
