import React from 'react';
import { PropTypes } from 'prop-types';
import generateClasses from '../utils';

import Ratings from '../../Ratings';

export default function SupplierRatings({
  plan,
  site,
  enableRatings,
  className,
}) {
  const {
    logo: supplierLogo,
    name: supplierName,
    ratings: supplierRatings,
  } = plan.supplier;

  const classString = generateClasses(
    className,
    `logo__container
    u-display-flex u-flex-direction-column u-align-items-flex-end u-justify-content-flex-end
    u-align-items-center@md u-justify-content-center@md
    ep-card--hide`
  );

  return (
    <div className={classString}>
      <img className="logo__image" src={supplierLogo} alt={supplierName} />
      {enableRatings && (
        <Ratings
          ratings={supplierRatings}
          boundary="scrollParent"
          tippyClass="ratings-tippy--card"
          supplierName={supplierName}
          site={site}
        />
      )}
    </div>
  );
}

SupplierRatings.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  enableRatings: PropTypes.bool,
  className: PropTypes.string,
};

SupplierRatings.defaultProps = {
  className: '',
  enableRatings: false
};
