import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import { Sites } from '@rvpower/constants';
import PDFLinks from './PDFLinks';
import chevronUp from '../svg/ico-up-arrow.svg';
import chevronDown from '../svg/ico-down-arrow.svg';

import infoBubble from '../svg/info-bubble-white.svg';
import infoBubbleNewTXER from '../svg/icon-info-new-txer.svg';
import getPerMonthVerbage from '../js/project/termination-fee';

export default function OverlayContent({
  activeClass,
  type,
  plan,
  site,
  overlayContents,
  scrollOverlayContentsToTop,
  scrollOverlayContentsToBottom,
  handleUpScroll,
  handleScrollDown,
  estimate,
  usage,
}) {
  const {
    fee,
    termText,
  } = plan;

  const { name, supplier, description } = plan;
  const isCTXP = site === Sites.NEW_CTXP || site === Sites.CTXP;
  const isNewTXER = site === Sites.NEW_TXER;
  const isSOE = site === Sites.SOE;

  const domainRegex = /(.com|.org)/;
  const siteWithoutDomain = site.replace(domainRegex, '');

  return (
    <>
      {/* eslint-disable-next-line max-len */}
      <div className={`overlay__up-chevron ${activeClass ? 'overlay__up-chevron--active' : ''}`} onClick={scrollOverlayContentsToTop} onKeyUp={handleUpScroll} role="button" tabIndex={0}>
        <img src={chevronUp} alt="Up Arrow" />
      </div>
      <div className={activeClass ? 'grid-overlay active-overlay' : 'grid-overlay'}>
        <div ref={overlayContents} className="overlay__scrollable-container">
          <div className="overlay__headings">
            {!isCTXP && (
              <p className="overlay__plan">
                {`${name} -`}
                &nbsp;
                <span>
                  <strong>
                    {supplier.name}
                  </strong>
                </span>
              </p>
            )}
            {isCTXP && (
              <>
                <p className="overlay__plan--heading-block-style">
                  {name}
                  &nbsp;
                  <div className="overlay__plan__supplier-name">
                    <span>- </span>
                    <strong>
                      {supplier.name}
                    </strong>
                  </div>
                </p>
              </>
            )}
          </div>
          <div className="overlay__terms">
            <span className="overlay__heading--light">
              TERM:&nbsp;
            </span>
            {` ${termText} | ${type.toUpperCase()}`}
          </div>
          <div className="overlay__first-group">
            <div className="overlay__estimate-group">
              <p className="overlay__estimate-num">
                {`$${estimate}`}
                <span className="overlay__estimate-term">
                  /m
                </span>
              </p>
              <p className="overlay__estimate-paragraph">
                estimate for
              </p>
              <p className="overlay__usage">
                {`${usage}KWh*`}
                <Tippy
                  content="Includes recurring charges excluding government fees and taxes.
                    Confirm this estimate with Plan Documents. Bills vary according to actual usage."
                  className={isSOE ? 'disclaimer' : `tippy tippy--${siteWithoutDomain}`}
                  arrow
                  placement="top-start"
                >
                  <img
                    className="overlay__estimates-tippy svg svg--info-bubble"
                    src={isNewTXER ? infoBubbleNewTXER : infoBubble}
                    alt="Info"
                  />
                </Tippy>
              </p>
            </div>
            <div className="overlay__plan-docs">
              <div className="overlay__plan-tippy-container">
                <p className="overlay__doc-heading">
                  <span className="overlay__doc-heading--block">
                    Plan&nbsp;
                  </span>
                  Documents
                  <Tippy
                    content="EFL: Electricity Facts Label TOS: Terms of Service YRAC: Your Rights as a Customer"
                    className={isSOE ? 'disclaimer' : `tippy tippy--${siteWithoutDomain}`}
                    arrow
                    placement="top-start"
                  >
                    <img
                      className="overlay__plan-docs-tippy svg svg--info-bubble svg--overlay-bubble"
                      src={isNewTXER ? infoBubbleNewTXER : infoBubble}
                      alt="Info"
                    />
                  </Tippy>
                </p>
              </div>
              <ul className="overlay__doc-list">
                <PDFLinks plan={plan} isFeaturedCard />
              </ul>
            </div>
          </div>
          {!isCTXP && (
            <div>
              {fee && (
                <div className="overlay__standard-group">
                  <p className="overlay__standardard-group--bold">
                    {`Early Termination Fee${getPerMonthVerbage(fee.monthly)}: $${fee.amount}`}
                  </p>
                  <p>
                    (Note: This fee will not be charged if you end your contract early because you are moving out)
                  </p>
                </div>
              )}
              {description && (
                <div className="overlay__standard-group">
                  <p className="overlay__standardard-group--bold">
                    Plan Details:
                  </p>
                  <p className="overlay__standard-group--paragraph">
                    {description}
                  </p>
                </div>
              )}
            </div>
          )}
          {isCTXP && (
            <div>
              {description && (
                <div className="overlay__standard-group overlay__standard-group--plan-details">
                  <p className="overlay__standardard-group--bold">
                    Plan Details:
                  </p>
                  <p className="overlay__standard-group-copy">
                    {description}
                  </p>
                </div>
              )}
              {fee && (
                <div className="overlay__standard-group">
                  <p className="overlay__standardard-group--bold">
                    {`Early Termination Fee ${getPerMonthVerbage(fee.monthly)}: $${fee.amount}`}
                  </p>
                  <p className="overlay__standard-group--paragraph">
                    (Note: This fee will not be charged if you end your contract early because you are moving out)
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* eslint-disable-next-line max-len */}
      <div className={`overlay__down-chevron ${activeClass ? 'overlay__down-chevron--active' : ''}`} onKeyUp={handleScrollDown} onClick={scrollOverlayContentsToBottom} role="button" tabIndex={0}>
        <img src={chevronDown} alt="Scroll Down" />
      </div>
    </>
  );
}

OverlayContent.propTypes = {
  site: PropTypes.string.isRequired,
  plan: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  activeClass: PropTypes.bool.isRequired,
  overlayContents: PropTypes.shape({}).isRequired,
  scrollOverlayContentsToTop: PropTypes.func.isRequired,
  scrollOverlayContentsToBottom: PropTypes.func.isRequired,
  handleUpScroll: PropTypes.func.isRequired,
  handleScrollDown: PropTypes.func.isRequired,
  estimate: PropTypes.string.isRequired,
  usage: PropTypes.string.isRequired,
};
