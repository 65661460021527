import React, { useState } from 'react';

import beam from '../../../js/project/tagular/beam';

import infoIcon from '../../../../images/info-icon.svg';
import iconHome from '../../../../images/icon-home--hmc.svg';
import inputErrorIcon from '../../../../images/error-icon.svg';

const HmcSqftCapture = (props) => {
  const {
    modalOpen,
    backHandler,
    updateIsLoading,
    updateModalHandler,
    updateHmcData,
    failedCorelogic,
    setSort,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const backClickHandler = () => {
    const backButtonPayload = {
      webElement: {
        location: 'Usage Entry',
      },
      actionOutcome: 'Go back to Address'
    };
    beam.userTrackingV3('ElementClicked', backButtonPayload);

    backHandler();
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (inputValue !== '') {
      const inputFieldPayload = {
        formContext: {
          formName: 'Help Me Choose',
          formType: 'Questionnaire',
        },
        stepContext: {
          stepName: 'Home Type',
          stepNumber: 1,
        },
        userInputField: {
          fieldLabel: 'manual entry',
          fieldName: 'Home Type',
          fieldType: 'kwh value',
          fieldValue: inputValue,
        }
      };
      beam.userTrackingV3('FieldInputted', inputFieldPayload);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      !inputValue
      || Number.isNaN(inputValue)
      || inputValue < 120
    ) {
      setIsValid(false);
      return;
    }

    const continueButtonPayload = {
      formContext: {
        formName: 'Help Me Choose',
        formType: 'Questionnaire',
      },
      stepContext: {
        stepName: 'Usage Entry',
        stepNumber: 1,
      }
    };
    beam.userTrackingV3('FormContinued', continueButtonPayload);

    // unmount form and mount loader, reset fail states, kwh usage calculation and update hmcData
    updateIsLoading(true);
    setIsValid(true);
    const parsedSqft = parseInt(inputValue, 10);
    const kwhResult = Math.round(parsedSqft * (2 / 3));

    updateHmcData({
      form: 'HmcSqftCapture',
      estimatedUsage: kwhResult,
    });

    setSort('rate-low');

    // close drawer after loader animation
    setTimeout(() => {
      const cateredGridPayload = {
        pageName: 'Help Me Choose Grid'
      };
      beam.userTrackingV2('PageViewed', cateredGridPayload);

      updateModalHandler();
    }, 5500);

    // ensure unmounting of the loader after drawer close animation
    setTimeout(() => {
      updateIsLoading(false);
    }, 5800);
  };

  return (
    <form
      id="hmc-sqft-capture"
      className="hmc-capture"
      onSubmit={(e) => submitHandler(e)}
    >
      {failedCorelogic && (
        <div className="hmc-capture__banner">
          <img
            src={infoIcon}
            alt="information icon"
            className="hmc-capture__banner-icon"
          />
          <p className="hmc-capture__banner-content">Oops, we&lsquo;re having trouble finding your usage details.</p>
        </div>
      )}
      <div className="hmc-capture__title-container">
        <h2 className="hmc-capture__header">No worries, we can use your home&lsquo;s square footage instead</h2>
      </div>
      <div
        className={`
          hmc-capture__input
          ${!isValid ? 'hmc-capture__input--invalid' : ''}
        `}
      >
        <img
          src={iconHome}
          alt="house icon"
          className={`
            hmc-capture__input-home-icon
            ${!isValid ? 'hmc-capture__input-home-icon--invalid' : ''}
          `}
        />
        <input
          tabIndex={modalOpen ? '0' : '-1'}
          className={`
            hmc-capture__input-field
            ${!isValid ? 'hmc-capture__input-field--invalid' : ''}
          `}
          placeholder="Enter your square footage"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {!isValid && (
          <div className="hmc-capture-esiid-address-lookup__error-container">
            <img
              src={inputErrorIcon}
              className="hmc-capture-esiid-address-lookup__error-icon"
              alt="Error icon"
            />
            <p className="hmc-capture-esiid-address-lookup__error-message">Please enter a valid number.</p>
          </div>
        )}
      </div>
      <div className="hmc-drawer__bottom">
        <button
          type="button"
          className="hmc-drawer__bottom-modifier-button"
          onClick={backClickHandler}
          tabIndex={modalOpen ? '0' : '-1'}
        >
        Back
        </button>
        <button
          form="hmc-sqft-capture"
          type="submit"
          className="hmc-drawer__bottom-continue-button"
          tabIndex={modalOpen ? '0' : '-1'}
          disabled={!inputValue}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default HmcSqftCapture;
