function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0; var v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}

const listId = uuid();

const beam = (eventName, data) => {
  if (window.tagular) {
    window.tagular('beam', eventName, {
      '@type': `redventures.ecommerce.v1.${eventName}`,
      listId,
      ...data
    });
  }
};

// have to reduce the number of products we send
// because the browser can't handle a huge request
const sliceProducts = (products) => (products.length > 20 ? products.slice(0, 20) : products);

const sanitizeData = (productList) => {
  const sanitizedList = productList.map((item) => ({
    brand: item.brand,
    category: item.category,
    location: item.location,
    name: item.name,
    position: Number(item.position),
    quantity: item.quantity,
    sku: item.sku,
    price: Number(item.price),
    productId: item.productId,
    variant: item.variant.toString()
  }));

  return sanitizedList;
};

const sanitizefilters = (filterFields) => {
  const sanitizedList = filterFields.map((item) => (
    { ...item, fieldLabel: item.fieldLabel.toString(), fieldValue: item.fieldValue.toString() }
  ));
  return sanitizedList;
};

const sanitizeSingleProduct = (product) => (
  { ...product, price: Number(product.price), variant: product.variant.toString() }
);

const removeAllUnallowedProperties = (productList) => productList.map((product) => {
  const {
    pointInTimeId,
    fiveHundredKwh,
    oneThousandKwh,
    twoThousandKwh,
    variationId,
    lifetimeId,
    electricPhoenixData,
    ...allowedProperties
  } = product;
  return allowedProperties;
});

const productListViewed = (products, zip) => {
  const sanitizedProducts = sanitizeData(products);
  const sanitizedProductsWithAllowedProperties = removeAllUnallowedProperties(sanitizedProducts);

  beam('ProductListViewed', {
    product: sliceProducts(sanitizedProductsWithAllowedProperties),
    field: [{
      fieldType: 'Zip Entry',
      fieldName: 'Zip',
      fieldValue: zip,
    }],
  });
};

const productListFiltered = (products, filterFields, sort) => {
  const sanitizedProducts = sanitizeData(products);
  const sanitizedProductsWithAllowedProperties = removeAllUnallowedProperties(sanitizedProducts);
  const sanitizedFilterFields = sanitizefilters(filterFields);

  beam('ProductListFiltered', {
    product: sliceProducts(sanitizedProductsWithAllowedProperties),
    field: sanitizedFilterFields,
    sorts: [{
      type: 'Filter',
      value: sort,
    }],
  });
};

const productSearched = (zip) => {
  beam('ProductSearched', {
    field: [{
      fieldType: 'Zip Entry',
      fieldName: 'Zip',
      fieldValue: zip,
    }],
  });
};

const productClicked = (product, actionOutcome = 'Entered Cart') => {
  const sanitizedProduct = sanitizeSingleProduct(product);
  sanitizedProduct.variant = sanitizedProduct.variant.toString();
  beam('ProductClicked', {
    product: sanitizedProduct,
    actionOutcome,
  });
};

const productViewed = (product) => {
  const sanitizedProduct = sanitizeSingleProduct(product);

  beam('ProductViewed', {
    product: sanitizedProduct
  });
};

export {
  productClicked,
  productListFiltered,
  productListViewed,
  productSearched,
  productViewed,
};
