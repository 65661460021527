import beam from './beam';

const elementClicked = (data) => {
  beam.userTrackingV3('ElementClicked', data);
};

const fieldInputted = (data) => {
  beam.userTrackingV3('FieldInputted', data);
};

const elementViewed = (data) => {
  beam.userTrackingV3('ElementViewed', data);
};

export default {
  elementClicked,
  elementViewed,
  fieldInputted
};
