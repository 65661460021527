const { SupplierIds } = require('./id');

const EPQ_DATA = {
  hasCurrentBillInfo: {
    default: 'No',
    excludeFromRequest: true,
    options: [
      'Yes',
      'No'
    ],
    transform: null
  },
  lastBillMonth: {
    default: 'Jan',
    options: [
      'Jan',
      'Feb',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ],
    transform(value) { return this.options.indexOf(value) + 1; }
  },
  lastBillSupplierName: {
    default: 'Select your provider',
    options: [
      'Select your provider',
      '4Change Energy',
      'Cirro',
      'Constellation',
      'Direct Energy',
      'Discount Power',
      'Express Energy',
      'First Choice Power',
      'Frontier Utilities',
      'Gexa Energy',
      'Green Mountain',
      'Payless Power',
      'Pulse Power',
      'Reliant',
      'TriEagle Energy',
      'TXU Energy',
      'Other',
    ],
    transform(value) { return value; }
  },
  lastBillUsage: {
    default: '0',
    options: null,
    transform(value) { return parseFloat(value); }
  },
  lastBillCost: {
    default: '0',
    options: null,
    transform(value) { return parseFloat(value); }
  },
  sqft: {
    default: 1500,
    options: null,
    transform(value) { return parseFloat(value); }
  },
  residents: {
    default: '3',
    options: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6+',
    ],
    transform(value) { return parseInt(value, 10); }
  },
  heatingType: {
    default: 'Not sure',
    options: [
      'Electric',
      'Gas',
      'Not sure'
    ],
    transform(value) { return (value === 'Not sure' ? 'Default' : value); }
  },
  hasPool: {
    default: 'No',
    options: [
      'Yes',
      'No'
    ],
    transform(value) { return (value === 'Yes'); }
  },
  showGreenPlans: {
    default: 'No',
    excludeFromRequest: true,
    options: [
      'Yes',
      'No'
    ],
    transform(value) { return (value === 'Yes'); }
  },
};

const supplierIdMap = {
  '4Change Energy': SupplierIds.FourChange,
  Cirro: SupplierIds.Cirro,
  Constellation: SupplierIds.Constellation,
  'Direct Energy': SupplierIds.Direct,
  'Discount Power': SupplierIds.Discount,
  'Express Energy': SupplierIds.Express,
  'First Choice Power': SupplierIds.FirstChoice,
  'Frontier Utilities': SupplierIds.Frontier,
  'Gexa Energy': SupplierIds.Gexa,
  'Green Mountain': SupplierIds.GreenMountain,
  'Payless Power': SupplierIds.Payless,
  'Pulse Power': SupplierIds.Pulse,
  Reliant: SupplierIds.Reliant,
  'TriEagle Energy': SupplierIds.TriEagle,
  'TXU Energy': SupplierIds.Txu
};

const billKeyNames = [
  'lastBillCost',
  'lastBillMonth',
  'lastBillSupplierName',
  'lastBillUsage'
];

function assembleEPInput(paramsInstance) {
  const EPInputObject = {};

  // Add param values to EPInputObject
  Object.keys(EPQ_DATA).forEach((key) => {
    if (paramsInstance.get(key) && !EPQ_DATA[key].excludeFromRequest) {
      EPInputObject[key] = paramsInstance.get(key);
    }
  });

  // Transform param values if neccessary
  Object.keys(EPQ_DATA).forEach((key) => {
    if (EPInputObject[key] && EPQ_DATA[key].transform) {
      EPInputObject[key] = EPQ_DATA[key].transform(EPInputObject[key]);
    }
  });

  // Check if user said they had their bill information
  let billDataComplete = false;
  if (paramsInstance.get('hasCurrentBillInfo') === 'Yes') {
    billDataComplete = true;
  }

  // Check all bill-related values for validity
  billKeyNames.forEach((key) => {
    if (EPInputObject[key] === undefined
      || EPInputObject === null) {
      billDataComplete = false;
    }
  });

  // Remove all bill-related values if any fail validity
  if (!billDataComplete) {
    billKeyNames.forEach((key) => {
      delete EPInputObject[key];
    });
  }

  return EPInputObject;
}

module.exports = {
  EPQ_DATA,
  assembleEPInput,
  supplierIdMap
};
