import React from 'react';
import Tippy from '@tippy.js/react';

function RatingsUnavailToolTip() {
  return (
    <Tippy
      content={(
        <div className="tippy-content__rating">
          <p className="tippy-content__text">Not enough data to calculate rating</p>
        </div>
      )}
      className="tippy-tooltip modal-ratings-tippy"
      arrow
      placement="top-start"
      zIndex="9999"
    >
      <button
        type="button"
        className="ratings__button plan__tippy"
      >
        N/A
      </button>
    </Tippy>
  );
}

export default RatingsUnavailToolTip;
