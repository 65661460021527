import fetch from 'isomorphic-unfetch';
import jwtDecode from 'jwt-decode';
import ApolloClient, { InMemoryCache, defaultDataIdFromObject } from 'apollo-boost';
import { Observable } from 'apollo-link';
// eslint-disable-next-line import/extensions
import { noticeError } from '@rvpower/utils/newrelic.js';

const LOCAL_STORAGE_TOKEN_KEY = 'power/session-token';
const LOCAL_STORAGE_RO_TOKEN_KEY = 'power/session-ro-token';
const LOCAL_STORAGE_RO_TOKEN_SOURCE = 'power/session-ro-token-source';

/**
 * Checks if a token is still valid
 * @param {String} token the JWT token
 * @return {Boolean} True if the token is valid and not expired, otherwise false
 */
function tokenValid(token) {
  try {
    const { exp } = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return currentTime < exp;
  } catch (error) {
    return false;
  }
}

/**
 * Creates a session token given a base url, source, and optional additional
 * data
 *
 * @param {String} baseUrl Base URL for the session token endpoint
 * @param {Object} data Additional data to use for session creation
 */
export async function createSessionToken(baseUrl, data = {}, refresh = false) {
  const currentToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (!refresh && currentToken && tokenValid(currentToken)) {
    return;
  }

  if (!data || !data.source) {
    throw new Error('missing session source key');
  }

  /** Source tracking for ESIID Model */
  const isPaidSearch = localStorage.getItem('isPaidSearch') === 'true';

  const url = `${baseUrl.replace(/\/?$/, '')}/session/create`;
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data, isPaidSearch }),
  };

  const resp = await fetch(url, opts);
  if (!resp.ok) {
    throw new Error(`expected 201 status code, got ${resp.status}`);
  }

  const { token } = await resp.json();
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

/**
 * Creates a read-only token given a base url & source
 *
 * @param {String} baseUrl Base URL for the session token endpoint
 * @param {String} source Session source ID
 */
export async function createReadOnlyToken(baseUrl, source, refresh = false) {
  const currentToken = localStorage.getItem(LOCAL_STORAGE_RO_TOKEN_KEY);
  const currentSource = localStorage.getItem(LOCAL_STORAGE_RO_TOKEN_SOURCE);

  if (!refresh && currentToken && tokenValid(currentToken) && currentSource === source) {
    return;
  }

  if (!source) {
    throw new Error('missing session source key');
  }

  const url = `${baseUrl.replace(/\/?$/, '')}/session/read-only?source=${source}`;
  const resp = await fetch(url);
  if (!resp.ok) {
    throw new Error(`expected 200 status code, got ${resp.status}`);
  }

  const { token } = await resp.json();
  localStorage.setItem(LOCAL_STORAGE_RO_TOKEN_KEY, token);
  localStorage.setItem(LOCAL_STORAGE_RO_TOKEN_SOURCE, source);
}

/**
 * Clears the token from localstorage
 */
export function clearToken(readOnly = false) {
  localStorage.removeItem(readOnly ? LOCAL_STORAGE_RO_TOKEN_KEY : LOCAL_STORAGE_TOKEN_KEY);
}

/**
 * Handle expiration is called when an expired token error occurrs
 *
 * @param {Operation} operation Apollo operation
 * @param {Function} onExpiration callback to be run on expiration
 * @return {Observable} observable handling the refresh operation
 */
function handleExpiration(operation, onExpiration = () => {}) {
  return new Observable(async (subscriber) => {
    try {
      const retry = await onExpiration();
      if (retry) {
        subscriber.next(operation);
      }

      subscriber.complete();
    } catch (err) {
      subscriber.error(err);
    }
  });
}

/**
 * Creates an Apollo Client instance using the given urls/session data. Handles
 * token generation & management.
 *
 * @param {String} appsyncUrl URL of the AppSync endpoint
 * @param {String} tokenBaseUrl URL of the session creation endpoint
 * @param {Object} sessionData Data to use for session creation
 * @param {Function} onTokenExpiration Callback to be called when the token is expired
 * @return {ApolloClient} Apollo Client instance
 */
// eslint-disable-next-line max-len
export default function createClient(appsyncUrl, readOnly = false, onExpiration = () => {}) {
  return new ApolloClient({
    fetch,
    uri: appsyncUrl,
    request(operation) {
      const token = localStorage.getItem(readOnly ? LOCAL_STORAGE_RO_TOKEN_KEY : LOCAL_STORAGE_TOKEN_KEY);
      operation.setContext({ headers: { Authorization: token } });
    },
    onError(error) {
      const {
        networkError, operation, forward, graphQLErrors
      } = error;

      if (networkError && networkError.statusCode === 401) {
        return handleExpiration(operation, onExpiration).flatMap((op) => forward(op));
      }

      if (graphQLErrors) {
        graphQLErrors.forEach((e) => noticeError(e, { operation: operation.operationName }));
      } else if (networkError) {
        noticeError(networkError, { operation: operation.operationName });
      }

      return forward(operation);
    },
    cache: new InMemoryCache({
      /* eslint-disable no-underscore-dangle */
      dataIdFromObject(object) {
        if (object.__typename === 'Plan' && object.utility && object.utility.id) {
          return `${object.__typename}:${object.id}:${object.utility.id}`;
        }

        return defaultDataIdFromObject(object);
      }
      /* eslint-enable */
    })
  });
}
