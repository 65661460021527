export default function planFilterFn(filters = {}, ratingsEnabled = false) {
  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX');
  const prodLowDepositPlans = [
    'b81c75bb-0d17-4224-9180-5a25b9809bf3',
    '92bf15f0-737b-4a1e-9153-ab878d2ca41d',
    'dc6175b6-9a11-4856-928f-c6b93844b954',
    '24b802cf-c33b-46b8-a0f1-0980145c040c'
  ];

  return (plan) => {
    if (!isNTX && filters.features.length > 0 && !filters.features.every((f) => plan.features.includes(f))) {
      return false;
    }

    if (isNTX && filters.features.length > 0) {
      if (plan.badges.length === 0 && filters.features.includes('Fixed rate')) return true;

      if (plan.badges.length === 0 && !filters.features.includes('Fixed rate')) return false;

      if (plan.badges.length === 1 && filters.features.includes('Fixed rate')) {
        const hasFixedRateBadge = plan.badges.every((badge) => badge.type !== 'plan-badge');
        if (hasFixedRateBadge) {
          return true;
        }
      }

      if (plan.badges.length > 0) {
        const hasMatchingBadge = plan.badges.some((badge) => filters.features.includes(badge.text));

        if (hasMatchingBadge) {
          return true;
        }
      }
      return false;
    }

    if (filters.providers.length > 0 && !filters.providers.includes(plan.supplier.id)) {
      return false;
    }

    if (filters.rateTypes.length > 0 && plan.type && !filters.rateTypes.includes(plan.type)) {
      return false;
    }

    if (
      filters.creditScore?.length > 0
      && plan.supplier?.creditThreshold
      && !filters.creditScore?.includes(plan.supplier?.creditThreshold)
    ) {
      return false;
    }

    if (filters.terms.length > 0 && plan.termText && !filters.terms.includes(plan.termText)) {
      return false;
    }

    if (filters.customPlanOrder.length > 0 && !filters.customPlanOrder.includes(plan.id)) {
      return false;
    }

    if (filters?.billPrice?.minBillPrice !== null && Number(filters?.billPrice?.minBillPrice) > Number(plan.estimate)) {
      return false;
    }

    if (filters?.billPrice?.maxBillPrice !== null && Number(filters?.billPrice?.maxBillPrice) < Number(plan.estimate)) {
      return false;
    }

    if (
      ratingsEnabled
      && filters.ratings.length > 0
      && !filters.ratings.some((r) => (r <= (plan.supplier.ratings?.overallScore || 0)))
    ) {
      return false;
    }

    // TODO: Delete this when the proper solution is implemented
    // Jira: POW-8291
    if (prodLowDepositPlans.includes(plan.id)) {
      return false;
    }

    return true;
  };
}
