import { termTextToType } from '@rvpower/constants';
import { usageMapping } from './mappers';

/**
 * Converts a plan into tracking plan data we need
 * @param {Object} plan
 * @param {Integer} position
 * @param {String} location location of the plan: GRID or FEATURED
 */
const getPlan = (plan, position, location) => ({
  brand: plan.supplier.name,
  category: 'Electricity',
  location,
  name: plan.name,
  position,
  price: plan.price || 0,
  productId: plan.id,
  quantity: 1,
  sku: plan.utility.name,
  variant: plan.term.length ? plan.term.length : plan.term.type,
  pointInTimeId: plan.harbingerIdentifiers.pointInTimeId,
  lifetimeId: plan.harbingerIdentifiers.lifetimeId,
  variationId: plan.utility.id,
  fiveHundredKwh: plan.rates.find((r) => r.type === usageMapping[500])?.price || 0,
  oneThousandKwh: plan.rates.find((r) => r.type === usageMapping[1000])?.price || 0,
  twoThousandKwh: plan.rates.find((r) => r.type === usageMapping[2000])?.price || 0,
  electricPhoenixData: plan.electricPhoenixData,
});

/**
   * Converts array of plan objects into an array of plan objects with just the
   * tracking data that we need
   * @param {Array} plans
   * @param {String} location location of the plans. GRID or FEATURED
   */
const getPlanList = (plans, location) => plans.map((plan, i) => getPlan(plan, i + 1, location));

/**
 *
 * @param {Array} featuredPlans array of featured plans
 * @param {Array} listPlans array of list plans
 */
const getAllPlanList = (featuredPlans, listPlans) => getPlanList(featuredPlans, 'FEATURED')
  .concat(getPlanList(listPlans, 'GRID'));

const getFilterFields = (f, providersById) => {
  const filterFields = [];
  Object.keys(f).forEach((key) => {
    switch (key) {
      case 'usage':
        filterFields.push({ fieldName: 'usage', fieldValue: f[key], fieldLabel: f[key] });
        break;
      case 'terms':
        filterFields.push(...f[key].map((v) => {
          const term = termTextToType(v);
          return {
            fieldName: 'contractTerm',
            fieldValue: term.length ? term.length : 0,
            fieldLabel: v.toLowerCase()
          };
        }));
        break;
      case 'rateTypes':
        filterFields.push(...f[key].map(
          (v) => ({ fieldName: 'contractType', fieldValue: v.toLowerCase(), fieldLabel: v.toLowerCase() })
        ));
        break;
      case 'providers':
        filterFields.push(...f[key].map((v) => {
          const providerName = providersById[v] ? providersById[v].name : v;
          return { fieldName: key, fieldValue: providerName, fieldLabel: providerName };
        }));
        break;
      case 'billPrice':
        filterFields.push({ fieldName: 'billPrice', fieldValue: f[key], fieldLabel: f[key] });
        break;
      default:
        filterFields.push(...f[key].map(
          (v) => ({ fieldName: key, fieldValue: v, fieldLabel: v })
        ));
    }
  });
  return filterFields;
};

export { getAllPlanList, getPlan, getFilterFields };
