// Solar Lead Form Urls
export const devEnv = {
  ELF_URL: 'https://elf.qa.bankrate.com/embedded.umd.min.js',
  BRUNO_URL: 'https://offers.qa.bankrate.com/power/solar?question-set=solar-pii-full-page-results',
  ZIPCODE: 'https://api.staging.saveonenergy.cloud/zipcode',
  SOE_URL: 'https://staging.saveonenergy.com',
};

export const prodEnv = {
  ELF_URL: 'https://elf.bankrate.com/embedded.umd.min.js',
  BRUNO_URL: 'https://offers.bankrate.com/power/solar?question-set=solar-pii-full-page-results',
  ZIPCODE: 'https://api.saveonenergy.cloud/zipcode',
  SOE_URL: 'https://saveonenergy.com',
};
