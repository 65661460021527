import React, { useEffect, useState } from 'react';
import useBreakpoints from '../../../js/hooks/useBreakPoints';

import iconFlame from '../../../../images/icon-flame-purple.svg';
import iconLightningBolt from '../../../../images/icon-lightning-bolt-purple.svg';
import iconLeaf from '../../../../images/icon-leaf-green-ntx.svg';
import iconDollarSign from '../../../../images/icon-dollar-sign-blue.svg';
import iconClock from '../../../../images/icon-clock-green.svg';

function NTXCardBadges({
  badges,
  planType,
  supplierBadges = false,
  featuredCard = true,
}) {
  const { isXl } = useBreakpoints();
  const [lowCostExists, setLowCostExists] = useState(false);

  const supplierBadgeOptions = {
    background: '#F3E7F4',
    'natural-gas': {
      icon: iconFlame,
      text: 'Gas',
    },
    electric: {
      icon: iconLightningBolt,
      text: 'Electric',
    },
  };
  const planBadgeOptions = {
    'low-cost-choice': {
      background: '#DDE1F3',
      icon: iconDollarSign,
      text: 'Low-cost choice',
      order: isXl ? 2 : 1,
    },
    renewable: {
      background: '#D2F6CE',
      icon: iconLeaf,
      text: 'Renewable',
      order: isXl ? 1 : 2,
    },
    'variable-rate': {
      text: 'Variable rate'
    },
    default: { // Fixed rate
      background: '#D7F7E5',
      icon: iconClock,
      text: 'Fixed rate',
      order: 3,
    },
  };

  let badgesArray = badges?.length ? [...badges] : [];

  // Check if badges contain any badges. If no supplier badges, display nothing.
  // If no plan badges, display default badge
  let badgeName;
  if (badgesArray.length === 0) {
    if (supplierBadges) {
      badgesArray = [];
    } else {
      if (planType === 'variable') {
        badgeName = 'variable-rate';
      } else {
        badgeName = 'default';
      }
      badgesArray = [{ name: badgeName }];
    }
  }

  useEffect(() => {
    if (badgesArray) {
      badgesArray.forEach((badge) => {
        if (badge.name.includes('low-cost-choice')) setLowCostExists(true);
      });
    }
  }, []);

  badgesArray.forEach((filteredBadge) => {
    const badge = filteredBadge;
    const supplierBadge = badge.name.includes('gas') || badge.name.includes('electric');

    if (supplierBadge) {
      badge.background = supplierBadgeOptions.background;
      badge.icon = supplierBadgeOptions[badge.name].icon;
      badge.text = supplierBadgeOptions[badge.name].text;
      badge.type = 'supplier-badge';
      badge.order = 0;
    } else {
      badge.background = planBadgeOptions[badge.name]?.background || planBadgeOptions.default.background;
      badge.icon = planBadgeOptions[badge.name]?.icon || planBadgeOptions.default.icon;
      badge.text = planBadgeOptions[badge.name]?.text || planBadgeOptions.default.text;
      badge.type = 'plan-badge';
      badge.order = planBadgeOptions[badge.name]?.order || planBadgeOptions.default.order;
    }

    if (!lowCostExists && badge.name.includes('renewable')) badge.order = 1;
  });

  const baseClass = featuredCard ? 'ntx-featured-plan-card' : 'ntx-plan-card';

  return (
    <div className={`${baseClass}__badges-container`}>
      {badgesArray.map((badge) => {
        const badgeOrder = badge.order ? `ntx-card__badge-order--${badge.order}` : '';
        const divStyle = {
          backgroundColor: badge.background,
          borderColor: badge.background,
        };
        return (
          <div
            className={`ntx-card-badge--${badge.type} ${badgeOrder}`}
            id="ntx-card-badge-order"
            style={divStyle}
          >
            <img className="ntx-featured-plan-card__badge--icon" src={badge.icon} alt={`${badge.text} Badge`} />
            {badge.text}
          </div>
        );
      })}
    </div>
  );
}

export default NTXCardBadges;
