import { Sites } from '@rvpower/constants';

const env = process.env.APP_ENV || 'development';

function getTXUFeaturedPlans() {
  if (env === 'production') {
    return [{
      labelId: 2,
      planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
    },
    {
      labelId: 3,
      planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
    },
    {
      labelId: 1,
      planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
    }];
  }

  return [{
    labelId: 2,
    planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
  },
  {
    labelId: 3,
    planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
  },
  {
    labelId: 1,
    planId: '00265395-4b58-418a-891d-a9b2804ff2cd' // Free Nights & Solar Days 12
  }];
}

function getMarketplaceFeaturedPlans() {
  if (env === 'production') {
    return [{
      labelId: 2,
      planId: '7086fa41-7ead-474b-afa0-c5e78010f680' // TXU: Smart Edge 12
    },
    {
      labelId: 3,
      planId: 'bc6f4642-b642-43a7-b038-43124ad9617d' // Frontier: Straight Power 12
    },
    {
      labelId: 1,
      planId: 'cbf762aa-ca1e-466e-8ea9-f378581a61ce' // TriEagle: Green Eagle 12
    }];
  }

  return [{
    labelId: 2,
    planId: '77773a9f-dad6-4618-ae43-41e0e4248a08' // TXU: Smart Edge 12
  },
  {
    labelId: 3,
    planId: '6ecae096-9e0d-408f-a336-98c2d436d766' // Frontier: Straight Power 12
  },
  {
    labelId: 1,
    planId: '3b6f99fe-ec7e-490c-8b4b-1c420cf52f52' // TriEagle: Green Eagle 12
  }];
}

const getDefaultFeaturedPlans = (site) => {
  if (site === Sites.TXU) {
    return getTXUFeaturedPlans();
  }

  return getMarketplaceFeaturedPlans();
};

export default getDefaultFeaturedPlans;
