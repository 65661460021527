import useMediaQuery from './useMediaQuery';

const useBreakpoints = () => {
  const breakpoints = {
    isSm: useMediaQuery('(max-width: 767px'),
    isMd: useMediaQuery('(min-width: 768px) and (max-width: 949px)'),
    isLg: useMediaQuery('(min-width: 950px'),
    isXl: useMediaQuery('(min-width: 1024px)'),
  };

  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';
  if (breakpoints.isXl) breakpoints.active = 'xl';

  const windowWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const backupIsMobile = windowWidth < 768;

  /** iOS Compatibility: Check to make sure isSm has a proper boolean value */
  breakpoints.isSm = breakpoints.isSm !== null ? breakpoints.isSm : backupIsMobile;

  return breakpoints;
};

export default useBreakpoints;
