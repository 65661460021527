import React from 'react';
import PropTypes from 'prop-types';

const convertBullets = (text) => {
  if (!text) {
    return text;
  }

  // eslint-disable-next-line max-len
  return text.replace(/•/g, '<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi44NTQgNC4zNTRhLjUuNSAwIDEgMC0uNzA4LS43MDhMNC41IDUuMjkzbC0uNjQ2LS42NDdhLjUuNSAwIDEgMC0uNzA4LjcwOGwxIDFhLjUuNSAwIDAgMCAuNzA4IDB6IiBmaWxsPSIjMDBCNDc2Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDBhNSA1IDAgMSAwIDAgMTBBNSA1IDAgMCAwIDUgME0xIDVhNCA0IDAgMSAxIDggMCA0IDQgMCAwIDEtOCAwIiBmaWxsPSIjMDBCNDc2Ii8+PC9zdmc+" alt="Checkmark" />');
};

const hasBullets = (text) => text.includes('•');

const TXUWhatsIncluded = ({ secondaryDescription }) => {
  const description = convertBullets(secondaryDescription);
  const ithasBullets = hasBullets(secondaryDescription);
  return description ? (
    <div
      className={`txu-whats-included ${ithasBullets ? 'txu-whats-included--bullets' : ''}`}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  ) : null;
};

export default TXUWhatsIncluded;

TXUWhatsIncluded.propTypes = {
  secondaryDescription: PropTypes.string.isRequired,
};
