import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import PDFLinks from './PDFLinks';

import infoBubble from '../svg/info-bubble-white.svg';
import productDisclosure from '../js/project/product-disclaimers';

export default function OverlayContent({
  activeClass,
  type,
  plan,
  incentiveActive,
}) {
  const {
    secondaryDescription,
    fee,
    termText,
    name,
  } = plan;

  const variablePlan = type.toUpperCase() === 'VARIABLE';
  const planDisclosure = productDisclosure(name);

  return (
    <div className={activeClass ? 'overlay active-overlay' : 'overlay'}>
      <div className="overlay__heading">
        <span className="overlay__heading--light">
          TERM:
        </span>
        {` ${termText} ${variablePlan ? '' : `|  ${type.toUpperCase()}`}`}
      </div>
      <div
        className="overlay__html-content"
        dangerouslySetInnerHTML={{ __html: secondaryDescription }}
      />
      <div className="overlay__info-bubble-row overlay__info-bubble-row--first">
        <Tippy
          content="EFL: Electricity Facts Label TOS: Terms of Service YRAC: Your Rights as a Customer"
          className="tippy tippy--shop-txu"
          arrow
          placement="top-start"
        >
          <img className="svg svg--info-bubble svg--overlay-bubble" src={infoBubble} alt="Info" />
        </Tippy>
        <ul className="overlay__pdf-list u-display-flex u-justify-content-space-between">
          <li>Plan Documents</li>
          <PDFLinks plan={plan} isFeaturedCard />
        </ul>
      </div>
      <div className="overlay__info-bubble-row">
        <Tippy
          content="Includes recurring charges excluding government fees and taxes.
            Confirm this estimate with Plan Documents. Bills vary according to actual usage."
          className="tippy tippy--shop-txu"
          arrow
          placement="top-start"
        >
          <img className="svg svg--info-bubble svg--overlay-bubble" src={infoBubble} alt="Info" />
        </Tippy>
        {fee && (
          <span className="overlay__pdf-list">
            {`Early Termination Fee: $${fee.amount}`}
          </span>
        )}
      </div>
      <div
        className="overlay__html-disclosure"
        dangerouslySetInnerHTML={{ __html: planDisclosure }}
      />
      {incentiveActive && (
        <div className="incentive__disclaimer incentive__disclaimer--featured">
          {/* eslint-disable-next-line max-len */}
          * Redeem your bonus at the website or phone number within 90 days of the first day of electricity service on your new plan with TXU Energy. Once redeemed, you’ll receive your prepaid Mastercard within 2 – 4 weeks after your first bill on your new plan is paid in full. Your account must be in good standing and active on this plan at that time. Mastercard® prepaid card is valid for six months after issuance.
        </div>
      )}
    </div>
  );
}

OverlayContent.propTypes = {
  activeClass: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  plan: PropTypes.shape({}).isRequired,
};
