import React from 'react';
import PropTypes from 'prop-types';

import buildClassList from '../../../js/project/buildClassList';

import MenuIcon from '../../../svg/filter.svg';

const MenuDownIcon = ({ className }) => (
  <img
    className={buildClassList('grid-v2-pills__pill-icon-menu', className)}
    src={MenuIcon}
    alt="Open Filter Menu"
  />
);

MenuDownIcon.propTypes = {
  className: PropTypes.string,
};

MenuDownIcon.defaultProps = {
  className: ''
};

export default MenuDownIcon;
