import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePreamp } from '../../../preamp';
import { useMonarch } from '../../../monarch';
import tracking from '../../../../js/project/tagular/tracking';
import iconCheck from '../../../../svg/green-check.svg';
import Radio from '../../radio';
import RadioButton from '../../radio-button';
import HomeInput from './HomeInput';

const validate = {
  homeKwhUsage: (value) => {
    if (!value) return 'Please enter a numbered amount.';

    if (parseInt(value, 10) < 0 || parseInt(value, 10) > 3500) {
      return 'Enter number between 0 and 3500';
    }

    return '';
  },
  homeSqFtUsage: (value) => {
    if (!value) return 'Please enter a numbered amount.';

    if (parseInt(value, 10) < 0 || parseInt(value, 10) > 3500) {
      return 'Enter number between 0 and 3500';
    }

    return '';
  }
};

const HomeSize = ({
  filters, setFilters, setUsage, resetTrigger, isTxu
}) => {
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const { rules } = useMonarch();
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;
  const coreLogicBool = monarchCoreLogic || coreLogicPreamp;
  const [homeFilter, setHomeFilter] = useState('kwh');

  const [homeKwhUsage, setHomeKwhUsage] = useState('1000');
  const [homeSqFtUsage, setHomeSqFtUsage] = useState('');

  const [exactKwhUsage, setExactKwhUsage] = useState('');
  const [exactSqFtUsage, setExactSqFtUsage] = useState('');

  const [exactKwhUsageLazy, setExactKwhUsageLazy] = useState(false);
  const exactKwhUsageError = validate.homeKwhUsage(exactKwhUsage);

  const [exactSqFtUsageLazy, setExactSqFtUsageLazy] = useState(false);
  const exactSqFtUsageError = validate.homeSqFtUsage(exactSqFtUsage);

  // append an unique id to every instance of this component
  const uniqueId = uuidv4();

  const estimatedKWHUsage = Number(localStorage.getItem('estimatedKWHUsage'));
  const clKwhUsageCurrent = estimatedKWHUsage === filters.usage;

  const handleHomeFilter = (e) => {
    setHomeFilter(e.target.value);

    if (isTxu) {
      tracking.elementClicked({
        webElement: {
          elementType: 'RADIOBUTTON',
          location: 'HOME SIZE FILTER',
          name: 'FILTER DROPDOWNS',
          position: '0',
          text: e.target.value === 'kwh' ? 'kWh usage' : 'square footage'
        },
        actionOutcome: 'FILTER LIST'
      });
    }
  };

  const handleBlur = () => {
    const isKhwFilter = homeFilter === 'kwh';

    tracking.fieldInputted({
      userInputField: {
        fieldLabel: isKhwFilter ? 'EXACT USAGE' : 'EXACT SIZE',
        fieldName: isKhwFilter ? 'EXACT USAGE GRID FILTER' : 'EXACT SIZE SQFT FILTER',
        fieldType: isKhwFilter ? 'EXACT USAGE KWH' : 'EXACT SIZE SQFT',
        fieldValue: isKhwFilter ? `${parseInt(exactKwhUsage, 10)} kWh` : `${parseInt(exactSqFtUsage, 10)} sq. ft`
      }
    });
  };

  const handleExactKwhUsage = (e) => {
    setExactKwhUsage(e.target.value);

    setFilters({
      ...filters,
      usage: parseInt(e.target.value, 10)
    });
  };

  const handleExactSqFtUsage = (e) => {
    setExactSqFtUsage(e.target.value);
  };

  const handleExactKwhUsageSubmit = (e) => {
    e.preventDefault();

    setExactKwhUsageLazy(true);

    if (exactKwhUsageError) {
      return;
    }

    setHomeKwhUsage('');
    setUsage('kwh');

    setFilters({
      ...filters,
      usage: parseInt(exactKwhUsage, 10)
    });

    tracking.fieldInputted({
      userInputField: {
        fieldLabel: 'EXACT USAGE',
        fieldName: 'EXACT USAGE GRID FILTER',
        fieldType: 'EXACT USAGE KWH',
        fieldValue: `${parseInt(exactKwhUsage, 10)} kWh`
      }
    });
  };

  const handleExactSqFtUsageSubmit = (e) => {
    e.preventDefault();

    setExactSqFtUsageLazy(true);

    if (exactSqFtUsageError) {
      return;
    }

    setHomeSqFtUsage('');
    setUsage('sq. ft');

    setFilters({
      ...filters,
      usage: parseInt(exactSqFtUsage, 10)
    });

    tracking.fieldInputted({
      userInputField: {
        fieldLabel: 'EXACT SIZE',
        fieldName: 'EXACT SIZE SQFT FILTER',
        fieldType: 'EXACT SIZE SQFT',
        fieldValue: `${parseInt(exactSqFtUsage, 10)} sq. ft`
      }
    });
  };

  const handleHomeKwhUsage = (e) => {
    // reset the exact kwh state
    if (exactKwhUsageLazy) {
      setExactKwhUsageLazy(false);
      setExactKwhUsage('');
    }

    setHomeKwhUsage(e.target.value);
    setFilters({
      ...filters,
      usage: parseInt(e.target.value, 10)
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'BUTTON',
        location: 'GRID HERO',
        name: 'FILTER DROPDOWNS',
        position: '0',
        text: e.target.value
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const handleHomeSqFtUsage = (e) => {
    // reset the exact sq ft state
    if (exactSqFtUsageLazy) {
      setExactSqFtUsageLazy(false);
      setExactSqFtUsage('');
    }

    setHomeSqFtUsage(e.target.value);
    setFilters({
      ...filters,
      usage: parseInt(e.target.value, 10)
    });
  };

  // reset the filters if the users navigates to a new pane
  useEffect(() => {
    if (homeFilter === 'kwh') {
      setHomeSqFtUsage('');
      setExactSqFtUsage('');
      setExactSqFtUsageLazy(false);
      // setUsage('kwh');

      setFilters({
        ...filters,
        usage: 1000
      });
    }

    if (homeFilter === 'square') {
      setHomeKwhUsage('1000');
      setExactKwhUsage('');
      setExactKwhUsageLazy(false);
      // setUsage('sqft');

      setFilters({
        ...filters,
        usage: 1000
      });
    }
  }, [homeFilter]);

  useEffect(() => {
    setHomeFilter('kwh');
    setHomeKwhUsage('1000');
    setExactKwhUsage('');
    setExactSqFtUsage('');
  }, [resetTrigger]);

  useEffect(() => {
    // Prefill custom kwh input when usage is available
    const { usage } = filters;

    if (usage > 0) {
      setExactKwhUsage(usage);
    }
  }, [filters.usage]);

  useEffect(() => {
    // Make sure options are not selected when custom kwh is used with coreLogic
    if (coreLogicBool && estimatedKWHUsage) {
      setHomeKwhUsage('0');
    }
  }, []);

  return (
    <div className="grid-v2-home-size">
      <p className="grid-v2-home-size__content">
        Estimated monthly charges may vary in higher usage seasons like winter and summer.
      </p>

      {coreLogicBool && clKwhUsageCurrent && (
        <div className="grid-v2__prefill-filter">
          <img className="grid-v2__prefill-filter-icon" src={iconCheck} alt="Check" />
          <p className="grid-v2__prefill-filter-copy">
            We’ve done the work for you! Providing your address helped us decide your home size.
          </p>
        </div>
      )}

      {isTxu && (
        <>
          <p className="grid-v2-home-size__radio-text">Filter by</p>
          <div className="u-display-flex">
            <Radio
              checked={homeFilter === 'kwh'}
              className="grid-v2-home-size__usage"
              id={`grid-v2-kwh-usage-${uniqueId}`}
              label="kWh usage"
              name={`grid-v2-filter-type-${uniqueId}`}
              onChange={handleHomeFilter}
              value="kwh"
            />
            <Radio
              checked={homeFilter === 'square'}
              className="grid-v2-home-size__usage"
              id={`grid-v2-sq-ft-usage-${uniqueId}`}
              label="Square footage"
              name={`grid-v2-filter-type-${uniqueId}`}
              onChange={handleHomeFilter}
              value="square"
            />
          </div>
        </>
      )}

      {homeFilter === 'kwh' && (
        <>
          <div className="u-display-flex">
            {!isTxu && (
              <RadioButton
                checked={homeKwhUsage === '500'}
                id={`grid-v2-kwh-apartment-${uniqueId}`}
                label="Apartment"
                name={`grid-v2-home-size-kwh-${uniqueId}`}
                onChange={handleHomeKwhUsage}
                value="500"
              >
                <br />
                <span className="grid-v2-home-size__sq-ft">
                  500 kWh
                </span>
              </RadioButton>
            )}
            <RadioButton
              checked={homeKwhUsage === '1000'}
              id={`grid-v2-kwh-small-home-${uniqueId}`}
              label="Small home"
              name={`grid-v2-home-size-kwh-${uniqueId}`}
              onChange={handleHomeKwhUsage}
              value="1000"
            >
              <br />
              <span className="grid-v2-home-size__sq-ft">1000 kWh</span>
            </RadioButton>
            <RadioButton
              checked={homeKwhUsage === '2000'}
              id={`grid-v2-kwh-large-home-${uniqueId}`}
              label="Large home"
              name={`grid-v2-home-size-kwh-${uniqueId}`}
              onChange={handleHomeKwhUsage}
              value="2000"
            >
              <br />
              <span className="grid-v2-home-size__sq-ft">2000 kWh</span>
            </RadioButton>
          </div>
          <HomeInput
            errorMessage={exactKwhUsageError}
            hasError={exactKwhUsageLazy && !!exactKwhUsageError}
            measurement="kWh"
            onChange={handleExactKwhUsage}
            onBlur={handleBlur}
            placeholder="Enter exact usage"
            onSubmit={handleExactKwhUsageSubmit}
            value={exactKwhUsage}
            filters={filters}
          />
        </>
      )}
      {homeFilter === 'square' && (
        <>
          <div className="u-display-flex">
            {!isTxu && (
              <RadioButton
                checked={homeSqFtUsage === '750'}
                id={`grid-v2-sq-ft-apartment-${uniqueId}`}
                label="Apartment"
                name={`grid-v2-home-size-square-${uniqueId}`}
                onChange={handleHomeSqFtUsage}
                value="750"
              >
                <br />
                <span className="grid-v2-home-size__sq-ft">
                  500-999
                  <br />
                  sq. ft.
                </span>
              </RadioButton>
            )}
            <RadioButton
              checked={homeSqFtUsage === '1000'}
              id={`grid-v2-sq-ft-small-home-${uniqueId}`}
              label="Small home"
              name={`grid-v2-home-size-square-${uniqueId}`}
              onChange={handleHomeSqFtUsage}
              value="1000"
            >
              <br />
              <span className="grid-v2-home-size__sq-ft">
                {isTxu ? (
                  <span>1,000-1,999 sq. ft</span>
                ) : (
                  <span>
                    1,000-1,999
                    <br />
                    sq. ft.
                  </span>
                )}
              </span>
            </RadioButton>
            <RadioButton
              checked={homeSqFtUsage === '2000'}
              id={`grid-v2-sq-ft-large-home-${uniqueId}`}
              label="Large home"
              name={`grid-v2-home-size-square-${uniqueId}`}
              onChange={handleHomeSqFtUsage}
              value="2000"
            >
              <br />
              <span className="grid-v2-home-size__sq-ft">
                {isTxu ? (
                  <span>2000+ sq. ft</span>
                ) : (
                  <span>
                    2000+
                    <br />
                    sq. ft.
                  </span>
                )}
              </span>
            </RadioButton>
          </div>
          <HomeInput
            errorMessage={exactSqFtUsageError}
            hasError={exactSqFtUsageLazy && !!exactSqFtUsageError}
            measurement="sq. ft."
            onChange={handleExactSqFtUsage}
            onBlur={handleBlur}
            onSubmit={handleExactSqFtUsageSubmit}
            placeholder="Enter exact sq. footage"
            value={exactSqFtUsage}
            filters={filters}
          />
        </>
      )}
    </div>
  );
};

export default HomeSize;
