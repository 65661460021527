/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';

import { usePreamp } from '../../preamp';
import CardDetailsTrigger from '../../CardDetailsTrigger';
import PricePerKwh from '../sub-components/PricePerKwh';
import MonthlyBillEstimate from '../sub-components/MonthlyBillEstimate';
import SupplierRatings from '../sub-components/SupplierRatings';
import Cta from '../sub-components/Cta';
import GridBadges from '../../GridBadges';
import SatisfactionGuaranteeBadge from '../../SatisfactionGuaranteeBadge';

const getSatisfactionGuaranteeBadge = (badgesList = []) => {
  if (!badgesList || (badgesList && badgesList?.length === 0)) {
    return null;
  }

  const list = badgesList.filter((item) => item.name.includes('satisfaction'));

  if (list.length === 0) {
    return null;
  }

  return list[0];
};

function BasicCard({
  ESIID,
  plan,
  site,
  onOrderOnline,
  disableCartLinks
}) {
  const {
    termText,
    type,
    name,
    supplier,
    badges,
  } = plan;

  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';

  const hasBadges = badges?.length > 0;
  const satisfactionGuaranteeBadgeActive = usePreamp('Satisfaction Guarantee Badge');
  const satisfactionGuaranteeBadge = getSatisfactionGuaranteeBadge(badges);

  return (
    <article className="card">
      {(satisfactionGuaranteeBadge && satisfactionGuaranteeBadgeActive) && <SatisfactionGuaranteeBadge site={site} badge={satisfactionGuaranteeBadge} />}
      <div
        className="card__section
          card__section--name
          u-display-flex@md
          u-justify-content-space-between@md
          u-align-items-center@md
          ep-card--hide"
      >
        <div className="name">
          <strong>{name}</strong>
          {[Sites.SOE].includes(site) && supplier?.name ? <span>{` - ${supplier.name}`}</span> : null }
        </div>
        <div className="term u-display-none u-display-block@md">
          <strong>{`Term: ${termText} | ${type}`}</strong>
        </div>
      </div>
      <div
        className="card__section
          u-position-relative
          u-display-flex
          u-justify-content-space-between
          u-flex-wrap-wrap
          u-justify-content-flex-start@md
          u-align-items-center@md"
      >
        <PricePerKwh
          plan={plan}
          site={site}
          className="card__section-item"
        />
        <MonthlyBillEstimate
          plan={plan}
          className="card__section-item"
        />
        <SupplierRatings
          plan={plan}
          site={site}
          enableRatings
          className="card__section-item"
          type={type}
        />
        <Cta
          ESIID={SOEesiid}
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
          className="card__section-item"
        />
      </div>

      {/* PLAN DETAILS SECTION */}
      <div className="card__checkbox-row">
        {hasBadges && (
          <GridBadges
            badges={badges}
            site={site}
          />
        )}
        <div className="details-container">
          <CardDetailsTrigger
            plan={plan}
            site={site}
          />
          <div className="term term--ratings term--badge">
            <strong>{`Term: ${termText} | ${type}`}</strong>
          </div>
        </div>
      </div>
    </article>
  );
}

BasicCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  disableCartLinks: PropTypes.bool
};

BasicCard.defaultProps = {
  disableCartLinks: false
};

export default BasicCard;
