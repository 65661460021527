import React from 'react';
import { Sites, RatingSites } from '@rvpower/constants';
import { compareTermText } from '../js/project/mappers';
import planFilterFn from '../js/project/plan-filter';

// React Components
import Checkboxes from './Checkboxes';

function CheckboxesSections(props) {
  const {
    filters, providers, setFilters, rates, terms, plans, allPlans, hideUsage, site, scrollToTopOfList, isNTX = () => {}
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ratingsEnabled = RatingSites.includes(site);

  const addFilter = (filterType) => (filterValue) => {
    if (scrollToTopOfList) {
      scrollToTopOfList();
    }

    setFilters({
      ...filters,
      [filterType]: [...filters[filterType], filterValue]
    });
  };

  const removeFilter = (filterType) => (filterValue) => {
    if (scrollToTopOfList) {
      scrollToTopOfList();
    }

    setFilters({
      ...filters,
      [filterType]: filters[filterType].filter((f) => f !== filterValue)
    });
  };

  const providersCheckboxes = providers.map((p) => ({
    name: p.name,
    value: p.id,
    label: p.name,
    checked: filters.providers.includes(p.id),
    number: plans.filter((plan) => plan.supplier.id === p.id).length
  }));

  const termsCheckboxes = terms.sort(compareTermText).map((term) => ({
    name: term,
    value: term,
    label: term.charAt(0).toUpperCase() + term.slice(1).toLowerCase(),
    checked: filters.terms.includes(term),
    number: plans.filter((plan) => plan.termText === term).length
  }));

  const ratesCheckboxes = rates.map((rate) => ({
    name: rate,
    value: rate,
    label: rate,
    checked: filters.rateTypes.includes(rate),
    number: plans.filter((plan) => plan.type === rate).length
  }));

  const ratingsCheckboxes = [3, 4, 5].map((rating) => ({
    name: `ratings-${rating}`,
    value: rating,
    label: `${rating} stars`,
    checked: filters.ratings.includes(rating),
    number: allPlans
      // first, filter plans without rating filter
      .filter(planFilterFn(filters, false))
      // then, filter the number of specific rating plans
      .filter((plan) => (plan.supplier.ratings?.overallScore || 0) >= rating).length
  }));

  let checkboxSections = [
    {
      sectionLabel: 'Features',
      addFilter: addFilter('features'),
      removeFilter: removeFilter('features'),
      checkboxes: [
        {
          name: 'feature-green-energy',
          value: 'GreenEnergy',
          label: 'Green energy',
          checked: filters.features.includes('GreenEnergy'),
          number: plans.filter((plan) => plan.features.includes('GreenEnergy')).length
        },
        {
          name: 'feature-free-usage',
          value: 'FreeUsagePeriod',
          label: 'Free usage period',
          checked: filters.features.includes('FreeUsagePeriod'),
          number: plans.filter((plan) => plan.features.includes('FreeUsagePeriod')).length
        },
        {
          name: 'feature-no-credit-check',
          value: 'NoCreditCheck',
          label: 'All credit approved',
          checked: filters.features.includes('NoCreditCheck'),
          number: plans.filter((plan) => plan.features.includes('NoCreditCheck')).length
        }
      ]
    },
    {
      sectionLabel: 'Providers',
      checkboxes: providersCheckboxes,
      addFilter: addFilter('providers'),
      removeFilter: removeFilter('providers'),
    },
    {
      sectionLabel: 'Minimum rating',
      addFilter: addFilter('ratings'),
      removeFilter: removeFilter('ratings'),
      checkboxes: ratingsCheckboxes,
      sectionNumber: 3,
    },
    {
      sectionLabel: 'Terms',
      checkboxes: termsCheckboxes,
      addFilter: addFilter('terms'),
      removeFilter: removeFilter('terms'),
    },
    {
      sectionLabel: 'Rate type',
      checkboxes: ratesCheckboxes,
      addFilter: addFilter('rateTypes'),
      removeFilter: removeFilter('rateTypes'),
    }
  ];

  if (site === Sites.TXU) {
    const sectionsToHide = ['features', 'providers', 'minimum rating'];
    // eslint-disable-next-line max-len
    checkboxSections = checkboxSections.filter((section) => !sectionsToHide.includes(section.sectionLabel.toLowerCase()));
  }

  if (isNTX) {
    const sectionsToHide = ['features', 'minimum rating'];
    // eslint-disable-next-line max-len
    checkboxSections = checkboxSections.filter((section) => !sectionsToHide.includes(section.sectionLabel.toLowerCase()));
  }

  if (!ratingsEnabled) {
    checkboxSections = checkboxSections.filter(({ sectionLabel }) => sectionLabel !== 'Minimum Rating');
  }

  return (
    <div className={`checkboxes-section ${hideUsage ? 'kwh-hidden' : ''}`}>
      {checkboxSections.map((section) => (
        <Checkboxes
          key={section.sectionLabel}
          section={section}
          filters={filters}
          isNTX={isNTX}
        />
      ))}
    </div>
  );
}

export default CheckboxesSections;
