import { Sites } from '@rvpower/constants';

const providerLinks = {
  [Sites.SOE]: {
    'Champion Energy': '/providers/champion-energy/',
    'Chariot Energy': '/providers/chariot-energy/',
    Cirro: '/providers/cirro-energy/',
    Constellation: '/providers/constellation-energy/',
    'Direct Energy': '/providers/direct-energy/',
    'Discount Power': '/providers/discount-power/',
    'Express Energy': '/providers/express-energy/',
    'First Choice Power': '/providers/first-choice-power/',
    '4Change Energy': '/providers/4change-energy/',
    'Frontier Utilities': '/providers/frontier-utilities/',
    'Gexa Energy': '/providers/gexa-energy/',
    'Green Mountain': '/providers/green-mountain/',
    'Payless Power': '/providers/payless-power/',
    'Pulse Power': '/providers/pulse-power/',
    Reliant: '/providers/reliant-energy/',
    'TriEagle Energy': '/providers/trieagle-energy/',
    'TXU Energy': '/providers/txu-energy/',
    'Rhythm Energy': '/providers/rhythm-energy/',
    'Flagship Power': '/providers/flagship-power/',
  },
  [Sites.CHOOSE]: {
    'Champion Energy': '/providers/champion-energy/',
    'Chariot Energy': '/providers/chariot-energy/',
    Cirro: '/providers/cirro-energy/',
    Constellation: '/providers/constellation/',
    'Direct Energy': '/providers/direct-energy/',
    'Discount Power': '/providers/discount-power/',
    'Express Energy': '/providers/express-energy/',
    'First Choice Power': '/providers/first-choice-power/',
    '4Change Energy': '/providers/4change-energy/',
    'Frontier Utilities': '/providers/frontier-utilities/',
    'Gexa Energy': '/providers/gexa-energy/',
    'Green Mountain': '/providers/green-mountain/',
    'Payless Power': '/providers/payless-power/',
    'Pulse Power': '/providers/pulse-power/',
    Reliant: '/providers/reliant-energy/',
    'TriEagle Energy': '/providers/trieagle/',
    'TXU Energy': '/providers/txu-energy/',
    'Rhythm Energy': '/providers/rhythm/',
    'Flagship Power': '/providers/',
  },
  [Sites.NEW_TXER]: {
    'Champion Energy': '/providers/#champion-energy',
    'Chariot Energy': '/providers/#chariot-energy',
    Cirro: '/providers/#cirro',
    Constellation: '/providers/#constellation',
    'Direct Energy': '/providers/#direct-energy',
    'Discount Power': '/providers/#discount-power',
    'Express Energy': '/providers/#express-energy',
    'First Choice Power': '/providers/#first-choice-power',
    '4Change Energy': '/providers/#4change-energy',
    'Frontier Utilities': '/providers/#frontier-utilities',
    'Gexa Energy': '/providers/#gexa-energy',
    'Green Mountain': '/providers/#green-mountain',
    'Payless Power': '/providers/#payless-power',
    'Pulse Power': '/providers/#pulse-power',
    Reliant: '/providers/#reliant',
    'TriEagle Energy': '/providers/#trieagle-energy',
    'TXU Energy': '/providers/#txu-energy',
    'Rhythm Energy': '/providers/',
    'Flagship Power': '/providers/',
  },
  [Sites.NEW_CTXP]: {
    'Champion Energy': '/electricity-providers/#champion-energy',
    'Chariot Energy': '/electricity-providers/chariot-energy',
    Cirro: '/electricity-providers/cirro-energy',
    Constellation: '/electricity-providers/constellation-energy',
    'Direct Energy': '/electricity-providers/direct-energy',
    'Discount Power': '/electricity-providers/discount-power',
    'Express Energy': '/electricity-providers/express-energy',
    'First Choice Power': '/electricity-providers/first-choice-power',
    '4Change Energy': '/electricity-providers/4change-energy',
    'Frontier Utilities': '/electricity-providers/frontier-utilities',
    'Gexa Energy': '/electricity-providers/gexa-energy',
    'Green Mountain': '/electricity-providers/green-mountain-energy',
    'Payless Power': '/electricity-providers/payless-power',
    'Pulse Power': '/electricity-providers/pulse-power',
    Reliant: '/electricity-providers/reliant-energy',
    'TriEagle Energy': '/electricity-providers/trieagle-energy',
    'TXU Energy': '/electricity-providers/txu-energy',
    'Rhythm Energy': '/electricity-providers/',
    'Flagship Power': '/electricity-providers/flagship-power',
  }
};

const getProviderLink = (site, supplier) => providerLinks[site]?.[supplier];

export default getProviderLink;
