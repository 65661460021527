import React, { useState } from 'react';
import PropTypes from 'prop-types';

import tracking from '../../js/project/tagular/tracking';

import iconPlus from '../../../images/icon-plus.svg';
import iconMinus from '../../../images/icon-minus.svg';

function ProductDetailAccordion(props) {
  const {
    title,
    subtitle,
    icon,
    children
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickTrigger = () => {
    tracking.elementClicked({
      webElement: {
        location: title,
        elementType: 'Button',
        text: `${isOpen ? 'Less' : 'More'} details`
      },
      actionOutcome: `${isOpen ? 'Collapse' : 'Expand'} details`
    });

    setIsOpen(!isOpen);
  };

  return (
    <div className={`pdp-accordion ${isOpen ? 'pdp-accordion--open' : ''}`}>
      <div className="pdp-accordion__header">
        <div className="pdp-accordion__header-top">
          <div className="pdp-accordion__title-container">
            <img className="pdp-accordion__icon" src={icon} alt="Plan Icon" />
            <span className="pdp-accordion__title">{title}</span>
          </div>
          <button
            className="pdp-accordion__trigger"
            type="button"
            onClick={handleClickTrigger}
          >
            {isOpen ? 'Less details' : 'More details'}
            <img src={isOpen ? iconMinus : iconPlus} alt="Trigger Icon" />
          </button>
        </div>
        {
          !isOpen && (
            <div className="pdp-accordion__header-bottom">
              {subtitle}
            </div>
          )
        }
      </div>
      {
        isOpen && (
          <div className="pdp-accordion__body">
            {children}
          </div>
        )
      }
    </div>
  );
}

export default ProductDetailAccordion;

ProductDetailAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.shape({}).isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
