import React from 'react';
import { PropTypes } from 'prop-types';
import Tippy from '@tippy.js/react';
import generateClasses from '../utils';
import infoBubble from '../../../svg/info-bubble.svg';

export default function MonthlyBillEstimate({ plan, className }) {
  const { price, usage, unitOfMeasure: { description } } = plan;

  const classString = generateClasses(
    className,
    'estimate u-display-none u-display-flex@lg u-align-items-center u-justify-content-center'
  );

  return (
    <div
      className={classString}
    >
      <strong>$</strong>
      <strong>{Math.round(price * usage)}</strong>
      <div className="estimate__details">
        monthly bill
        <p>estimate for</p>
        <p>
          {`${usage} ${description || 'kWh'}*`}
          <Tippy
            content="Includes recurring charges excluding government fees and taxes.
            Confirm this estimate with Plan Documents. Bills vary according to actual usage."
            className="disclaimer"
            arrow
            placement="top-start"
          >
            <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
          </Tippy>
        </p>
      </div>
    </div>
  );
}

MonthlyBillEstimate.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired
};
