import React, { useRef } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Sites } from '@rvpower/constants';
import Tippy from '@tippy.js/react';
import Ratings from '../../../Ratings';
import RatingsUnavailToolTip from '../../RatingsUnavailToolTip';
import xIcon from '../../../../svg/x.svg';
import SvgPhone from '../../../SvgPhone';
import arrow from '../../../../../images/arrow.png';

export default function DragPlanCards(props) {
  const {
    comparedPlans,
    setComparedPlans,
    site,
    closeModal,
    ratingsEnabled,
    generateFee,
    onOrderOnline = () => { },
    cartCta
  } = props;

  const draggableCard = useRef(null);

  const removeCard = (e, index) => {
    e.preventDefault();
    const cardsList = Object.assign([], comparedPlans);
    const element = e.target.parentElement;

    if (comparedPlans.length === 1) {
      closeModal();
      cardsList.splice(index, 1);
      setComparedPlans([...cardsList]);
    }

    element.classList.add('modal-delete-card-animation');
    setTimeout(() => {
      element.classList.remove('modal-delete-card-animation');
      cardsList.splice(index, 1);

      setComparedPlans([...cardsList]);
    }, 500);
  };

  // render draggable items
  const renderPlans = (plan, index) => {
    const {
      name,
      id,
      supplier,
      estimate,
      termText,
      cartActive,
      cartUrl,
      prices,
      type,
      renewablePercentage,
      usage,
      roundedEstimate,
      roundedPricePerKwh
    } = plan;

    const isSoe = site === Sites.SOE;
    const { disablePhoneNumbers = true } = supplier?.controls;
    const planPrice = roundedPricePerKwh || prices[usage];
    const { ratings, logo } = supplier;
    const supplierName = supplier.name;
    const indexString = index.toString();
    const renewableEnergyRounded = (renewablePercentage * 100).toFixed(0);
    const showRatings = ratingsEnabled && ratings;
    const ratingUnavailable = ratingsEnabled && !ratings;
    const planEstimate = roundedEstimate || estimate;

    const phoneColorConfig = {
      [Sites.CTXP]: '#ffffff',
      [Sites.NEW_CTXP]: '#173957',
      [Sites.TXER]: '#0B3970',
      [Sites.NEW_TXER]: '#0B3970',
      [Sites.CHOOSE]: '#954A9C',
      [Sites.SOE]: '#446DB5',
      [Sites.TXU]: '#ffffff'
    };
    const phoneColor = phoneColorConfig[site];
    const ellipsedPlanName = name?.length > 28;

    const fuseNumber = () => {
      const fuse = document.querySelector('.phone')?.innerText;
      return (fuse && (
        <div className="plan__fuse">
          <SvgPhone
            site={site}
            Sites={Sites}
            phoneColor={phoneColor}
            gridComparisonModal
          />
          <p
            className="plan__fuse-number"
          >
            <span
              className="phone phone--modal"
            >
              <a href={`tel: ${fuse}`}>
                {fuse}
              </a>
            </span>
          </p>
        </div>
      ));
    };

    const ellipseToolTip = () => (
      <span className="ellipse-tool-tip">
        <Tippy
          content={name}
          className="ellipse"
          arrow
          placement="top-end"
        >
          <p
            className="plan__name"
          >
            {name}
          </p>
        </Tippy>
      </span>
    );

    return (
      <Draggable
        key={index}
        draggableId={indexString}
        index={index}
      >
        {(provided, { isDragging }) => (
          <div
            className="modal-droppable__plan"
            ref={provided.innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
          >
            <div
              className={
                `modal-droppable__plan--background-color
                ${isDragging ? 'is-active' : ''}
                ${ratingsEnabled ? '' : 'ratings-disabled'}`
              }
            />
            <button
              aria-label={`remove ${name} plan`}
              type="button"
              onClick={(e) => removeCard(e, index)}
              className="modal-droppable__close-button"
              ref={(el) => {
                if (el) {
                  el.style.setProperty('background-image', `url("${xIcon}")`, 'important');
                }
              }}
            />
            <div
              key={id}
              className={`modal-droppable__content ${ratingsEnabled ? '' : 'ratings-disabled'}`}
            >
              <div className="plan__header">
                <div>
                  {ellipsedPlanName && (
                    <>
                      {ellipseToolTip()}
                    </>
                  )}
                  {!ellipsedPlanName && (
                    <p
                      className="plan__name"
                    >
                      { name }
                    </p>
                  )}
                  <p
                    className="plan__provider"
                  >
                    {supplierName}
                  </p>
                </div>
                <img
                  className="plan__logo"
                  src={logo}
                  alt="plan logo"
                />
              </div>
              {ratingsEnabled && (
                <div
                  className="plan__ratings"
                >
                  {showRatings && (
                    <Ratings
                      ratings={ratings}
                      boundary="scrollParent"
                      tippyClass="ratings-tippy--card"
                      supplierName={supplierName}
                      site={site}
                    />
                  )}
                  {ratingUnavailable && (
                    <>
                      <RatingsUnavailToolTip />
                    </>
                  )}
                </div>
              )}
              <p
                className="plan__rate-type"
              >
                {type}
              </p>
              <p
                className="plan__average-kwh"
              >
                {planPrice}
                <sup
                  className="price-symbol"
                >
                  ¢
                </sup>
                <span
                  className="per-kwh-copy"
                >
                  PER KWH
                </span>
              </p>
              <p
                className="plan__estimated-bill"
              >
                {`$${planEstimate}`}
              </p>
              <p
                className="plan__length"
              >
                {termText}
              </p>
              <p
                className="plan__renewable-energy"
              >
                {`${renewableEnergyRounded}%`}
              </p>
              <p
                className="plan__fee"
              >
                {generateFee(plan)}
              </p>
              <div
                className="plan__cta-group"
              >
                {!disablePhoneNumbers && (fuseNumber())}
                {cartActive && (
                  <a
                    href={cartUrl}
                    className="plan__cta-button grid-button"
                    onClick={(e) => onOrderOnline(e, cartUrl, plan)}
                  >
                    {cartCta || 'ORDER ONLINE'}

                    {isSoe && (
                      <img src={arrow} className="button--arrow-img" alt="arrow" />
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div>
      <Droppable droppableId="dp2" direction="horizontal">
        { (provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={provided.innerRef} {...provided.droppableProps} className="modal-droppable__wrapper">
            <div ref={draggableCard} className="modal-droppable__container">
              {
                comparedPlans.map((e, index) => renderPlans(e, index))
              }
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
}
