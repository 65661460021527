import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Tippy from '@tippy.js/react';
import { siteCta } from '@rvpower/constants';
import { isMobile } from 'react-device-detect';
import CarouselModal from './carousel/CarouselModal';
import { usePreamp } from '../../preamp';
import DragPlansContext from './draggable/DragPlansContext';
import xIcon from '../../../svg/x--black.svg';
import infoBubble from '../../../svg/info-bubble.svg';

function GctModal(props) {
  const {
    triggerModal,
    setTriggerModal,
    setComparedPlans,
    comparedPlans,
    ratingsEnabled,
    site,
    usage,
    generateFee
  } = props;

  const modalContent = useRef(null);

  // 'Cart CTA' for marketplace
  const preampCartCta = usePreamp('Cart CTA');
  const defaultCtaCopy = siteCta(site, isMobile);
  const cartCta = preampCartCta || defaultCtaCopy;

  const [displayedCardIndex, setDisplayedCardIndex] = useState(0);

  const closeModal = () => {
    const { current } = modalContent;
    current
      .parentElement
      .parentElement
      .classList
      .remove('is-active');

    setTimeout(() => {
      setTriggerModal(false);
    }, 1000);
  };

  const removePlan = (index) => (e) => {
    e.preventDefault();
    const cardsList = Object.assign([], comparedPlans);
    setDisplayedCardIndex(0);

    if (comparedPlans.length === 1) { closeModal(); }
    cardsList.splice(index, 1);
    setComparedPlans([...cardsList]);
  };

  const tooltip = () => (
    <span className="tooltip-wrapper">
      <Tippy
        content="Includes recurring charges excluding government fees and taxes.
            Confirm this estimate with Plan Documents. Bills vary according to actual usage."
        className="disclaimer"
        arrow
        placement="top-start"
      >
        <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
      </Tippy>
    </span>
  );

  const gridElement = '#js-grid';
  const fieldLabels = [
    {
      heading: 'RATING',
      classPrefix: 'rating'
    },
    {
      heading: 'Rate Type',
      classPrefix: 'rate-type'
    },
    {
      heading: 'Average kWh',
      subheading: `at ${usage}KWH`,
      classPrefix: 'avrge-kwh'
    },
    {
      heading: 'Estimated Bill',
      subheading: `monthly bill estimated for ${usage} kWh*`,
      classPrefix: 'estimated-bill'
    },
    {
      heading: 'Length of plan',
      classPrefix: 'length-of-plan'
    },
    {
      heading: '% of renewable energy',
      classPrefix: 'renewable-energy'
    },
    {
      heading: 'Early Termination fee',
      classPrefix: 'fee'
    }
  ];

  if (!ratingsEnabled) {
    fieldLabels.splice(0, 1);
  }

  Modal.setAppElement(gridElement);

  const addTransition = () => {
    if (triggerModal) {
      setTimeout(() => {
        const { current } = modalContent;
        current
          .parentElement
          .parentElement
          .classList
          .add('is-active');
      }, 100);
    }
  };

  useEffect(() => {
    const bodyEl = document.querySelector('body');
    if (triggerModal) {
      bodyEl.classList.add('gct-modal');
    } else {
      bodyEl.classList.remove('gct-modal');
    }
    addTransition();
  }, [triggerModal]);

  const renderFieldRows = ({ heading, subheading, classPrefix }, index) => (
    <div
      className={classPrefix ? `row row__${classPrefix}` : 'row'}
      key={index}
    >
      {!subheading && (
      <p
        className="row__heading"
      >
        {
              heading
            }
      </p>
      )}
      {subheading && (
      <div
        className="row__group"
      >
        <p
          className="row__heading"
        >
          {
                heading
              }
        </p>
        <p
          className="row__subheading"
        >
          {subheading}
        </p>
      </div>
      )}
    </div>
  );

  return (
    <Modal
      isOpen={triggerModal}
      onRequestClose={() => {}}
      overlayClassName="gct-modal__overlay"
      className="gct-modal__wrapper"
      parentSelector={() => (document?.querySelector('#js-grid') ? document.querySelector('#js-grid') : null)}
    >
      <div ref={modalContent} className="gct-modal__content">
        <button
          type="button"
          onClick={closeModal}
          className="gct-modal__close-button"
        >
          <img
            src={xIcon}
            alt="close button"
          />
        </button>
        <h2
          className="gct-title"
        >
          Compare plans
        </h2>
        {/**
          Mobile Breakpoint
        * */}
        <div className="gct__modal--mobile">
          <CarouselModal
            ratingsEnabled={ratingsEnabled}
            displayedCardIndex={displayedCardIndex}
            removePlan={removePlan}
            site={site}
            comparedPlans={comparedPlans}
            usage={usage}
            tooltip={tooltip}
            generateFee={generateFee}
            cartCta={cartCta}
          />
        </div>
        {/**
          Desktop Breakpoint
        * */}
        <div className="gct__modal--desktop">
          <div
            className={`gct-cards__wrapper ${ratingsEnabled ? '' : 'ratings-disabled'}`}
          >
            {tooltip()}
            <div
              className={`gct-rows ${ratingsEnabled ? '' : 'ratings-disabled'}`}
            >
              {
                fieldLabels.map((e, index) => renderFieldRows(e, index))
              }
            </div>
            <div
              className="gct-draggable-cards-wrapper"
            >
              <DragPlansContext
                setComparedPlans={setComparedPlans}
                comparedPlans={comparedPlans}
                closeModal={closeModal}
                ratingsEnabled={ratingsEnabled}
                generateFee={generateFee}
                site={site}
                cartCta={cartCta}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default GctModal;
