import React from 'react';

import satisfactionIcon from '../../../images/satisfaction.svg';
import protectionIcon from '../../../images/price-protection.svg';
import supportIcon from '../../../images/support.svg';
import curve from '../../../images/term-top-curve-bg-tablet.svg';

const TxUTerms = () => (
  <>
    <div className="txu-terms-curve">
      <img src={curve} alt="curve" />
    </div>
    <div className="txu-terms">
      <h3 className="txu-terms-heading">All of our term plans come with</h3>

      <ul className="txu-terms-list">
        <li className="txu-terms-list-item">
          <img src={protectionIcon} alt="price protection" />
          <p className="txu-terms-list-item-heading">Price Protect Promise</p>
          <p className="txu-terms-list-item-text">
            Lock in consistent energy rates, so you can rest easy all year long. *
          </p>
        </li>
        <li className="txu-terms-list-item">
          <img src={satisfactionIcon} alt="satisfaction" />
          <p className="txu-terms-list-item-heading">Total Satisfaction Guarantee</p>
          <p className="txu-terms-list-item-text">
            <span>Try us for 60 days with no string attached. If your plan&apos;s not the right fit</span>
            <span>, we&apos;ll help you find one that is — or you can switch for free.**</span>
          </p>
        </li>
        <li className="txu-terms-list-item">
          <img src={supportIcon} alt="Customer support" />
          <p className="txu-terms-list-item-heading">24/7 Customer Support</p>
          <p className="txu-terms-list-item-text">
            We&apos;re here to help anytime you need us, day or night.
          </p>
        </li>
      </ul>
    </div>
  </>
);

export default TxUTerms;
