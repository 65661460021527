import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import PlanDetails from './grid-v2/PlanDetails';
import closeIcon from '../svg/close.svg';
import buildClassList from '../js/project/buildClassList';
import NTXPlanDetails from './grid-v2/NTXPlanDetails';

if (document.querySelector('#js-grid')) {
  Modal.setAppElement('#js-grid');
}

export default function PlanDetailsModal({
  detailsOpen,
  plan,
  site,
  handleDetailsToggle,
  isNTX = false,
  className,
}) {
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const openModal = () => {
    setmodalIsOpen(true);
  };

  const closeModal = () => {
    setmodalIsOpen(false);
    handleDetailsToggle();
  };

  useEffect(() => {
    if (detailsOpen) {
      openModal();
    }
  }, [detailsOpen]);

  const titleClassName = buildClassList(
    'plan-details-modal__title',
    isNTX && 'ntx-plan-details-modal__title',
  );
  const subtitleClassName = buildClassList(
    'plan-details-modal__sub-title',
    isNTX && 'ntx-plan-details-modal__sub-title',
  );
  const headerClassName = buildClassList(
    'plan-details-modal__header',
    isNTX && 'ntx-plan-details-modal__header',
  );

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="grid-modal-overlay"
        className={`grid-modal-content plan-details-modal ${isNTX && 'ntx-plan-details-modal'} ${className}`}
      >
        <button className="plan-details-modal__close-icon" onClick={closeModal} type="button">
          <img src={closeIcon} alt="Close" />
        </button>
        <div className={headerClassName}>
          <h3 className={titleClassName}>{plan?.name}</h3>
          <p className={subtitleClassName}>{plan?.supplier?.name}</p>
        </div>
        {plan && (
          isNTX ? (
            <NTXPlanDetails
              plan={plan}
              site={site}
              featured
            />
          ) : (
            <PlanDetails
              plan={plan}
              site={site}
              featured
            />
          )
        )}
      </Modal>
    </>
  );
}

PlanDetailsModal.propTypes = {
  detailsOpen: PropTypes.bool.isRequired,
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  handleDetailsToggle: PropTypes.func.isRequired,
  isNTX: PropTypes.bool,
  className: PropTypes.string,
};

PlanDetailsModal.defaultProps = {
  className: '',
  isNTX: false,
};
