import React from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';

import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import tracking from '../../js/project/tagular/tracking';

import iconLocationPin from '../../../images/icon-location-pin.svg';
import iconSafety from '../../../images/icon-safety.svg';
import iconArrowWhite from '../../../images/long-arrow-right.svg';
import iconChevronLeft from '../../../images/icon-chevron-left.svg';

function ProductDetailHero(props) {
  const { plan, zipCode, site } = props;

  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;

  const isNEWCTXP = site === Sites.NEW_CTXP;

  const generateCtaHeadlineCopy = () => {
    const { ctaHeadlineCopy } = pdpTestActive;
    const defaultCopy = (
      <>
        <strong>Good choice! </strong>
        {`Secure this low rate with ${plan.supplier.name} before prices change.`}
      </>
    );

    return pdpTestActive && ctaHeadlineCopy ? ctaHeadlineCopy : defaultCopy;
  };

  const generateCtaButtonCopy = () => {
    const { ctaButtonCopy } = pdpTestActive;
    const defaultCopy = 'Order now';

    return pdpTestActive && ctaButtonCopy ? ctaButtonCopy : defaultCopy;
  };

  const handleClickBack = (text) => {
    const newUrl = window.location.href.replace(window.location.search, `?zipCode=${zipCode}`);

    tracking.elementClicked({
      webElement: {
        location: 'PDP',
        elementType: 'Button',
        text
      },
      actionOutcome: 'Return to grid'
    });

    setTimeout(() => {
      // Allow time for tagular event to fire
      window.location = newUrl;
    }, 150);
  };

  const handleClickCTA = () => {
    tracking.elementClicked({
      webElement: {
        location: 'PDP Hero',
        elementType: 'Button',
        text: 'Order now'
      },
      actionOutcome: 'Enter cart'
    });

    setTimeout(() => {
      // Allow time for tagular event to fire
      window.location = plan.cartUrl;
    }, 500);
  };

  return (
    <div className="pdp-hero">
      <div className="pdp-hero__container">
        <button
          className="pdp-hero__button-back"
          type="button"
          onClick={() => handleClickBack('Back to plans')}
        >
          <img src={iconChevronLeft} alt="Back" />
          Back to plans
        </button>
        <div className="pdp-hero__inner">
          <div className="pdp-hero__plan">
            <div className="pdp-hero__plan-header">
              <img
                src={plan?.supplier?.logo}
                className="pdp-hero__plan-logo"
                alt="Supplier Logo"
              />
              <span className="pdp-hero__plan-name">{plan?.name}</span>
            </div>
            <div className="pdp-hero__plan-content pdp-hero__plan-content--mobile">
              <div>
                {`${plan?.price}¢ per kWh`}
                <span>{`(at ${plan.usage} kWh)`}</span>
              </div>
              <div>{plan?.termText}</div>
              <div>
                {`${plan?.type} rate`}
              </div>
            </div>
            <div className="pdp-hero__plan-content pdp-hero__plan-content--desktop">
              <div>
                <span>Your usage</span>
                <p>{`${plan.usage} kWh`}</p>
              </div>
              <div>
                <span>Price per kWh</span>
                <p>{`${plan?.price}¢`}</p>
              </div>
              <div>
                <span>Term length</span>
                <p>{plan?.termText}</p>
              </div>
              <div>
                <span>Rate type</span>
                <p>{plan?.type}</p>
              </div>
            </div>
            <div className="pdp-hero__zip">
              <img src={iconLocationPin} alt="Zip Code" />
              <span>Available at</span>
              <button
                type="button"
                onClick={() => handleClickBack('Update zip')}
              >
                {zipCode}
              </button>
            </div>
          </div>
          <div className="pdp-hero__cta-banner">
            <div className="pdp-hero__cta-banner-inner">
              <p className="pdp-hero__cta-banner-copy">
                {generateCtaHeadlineCopy()}
              </p>
              <button
                className="pdp-hero__cta-banner-link"
                type="button"
                onClick={handleClickCTA}
              >
                {generateCtaButtonCopy()}
                {!isNEWCTXP && (
                  <img src={iconArrowWhite} alt="Order now" />
                )}
              </button>
            </div>
            <p className="pdp-hero__cta-banner-label">
              <img src={iconSafety} alt="Secure checkout" />
              Secure checkout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailHero;

ProductDetailHero.propTypes = {
  plan: PropTypes.shape().isRequired,
  zipCode: PropTypes.string.isRequired,
};
