import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function UsageSlider(props) {
  const {
    updateDisplay,
    updateFilter,
    initialUsage,
    setShouldClearTextInput,
    kwh
  } = props;
  const valueToUsage = {
    1: 500,
    2: 750,
    3: 1000,
    4: 1250,
    5: 1500,
    6: 1750,
    7: 2000
  };

  const usageToValue = {};
  Object.keys(valueToUsage).forEach((k) => { usageToValue[valueToUsage[k]] = parseInt(k, 10); });

  const minValue = 1;
  const maxValue = 7;
  const [currentValue, setCurrentValue] = useState(usageToValue[initialUsage]);

  const updateSlider = (newValue) => {
    setShouldClearTextInput(true);
    setCurrentValue(newValue);
    updateDisplay(valueToUsage[newValue]);
  };

  const updateFilterWithKwh = (newValue) => {
    updateFilter(valueToUsage[newValue]);
  };

  const findClosestMarkValue = (goal) => Object.values(valueToUsage)
    .reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));

  useEffect(() => {
    setCurrentValue(usageToValue[findClosestMarkValue(kwh)]);
  }, [kwh]);
  useEffect(() => {
    setCurrentValue(usageToValue[initialUsage]);
  }, []);

  // Generates marks using min and max values
  const marks = {};
  for (let index = minValue; index <= maxValue; index += 1) {
    marks[index] = { style: {}, label: null };
  }

  const getHandleClass = () => {
    if (kwh < 500) {
      return 'rc-slider--minus';
    }
    if (kwh > 2000) {
      return 'rc-slider--plus';
    }
    return '';
  };

  return (
    <Slider
      className={`${getHandleClass()}`}
      tabIndex={currentValue}
      trackStyle={{ backgroundColor: '#dadde1' }}
      min={minValue}
      max={maxValue}
      value={currentValue}
      onChange={updateSlider}
      onAfterChange={updateFilterWithKwh}
      marks={marks}
      ariaLabelForHandle="kWh usage slider"
    />
  );
}
