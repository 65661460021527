import React from 'react';
import { isMobile } from 'react-device-detect';

export default function TxuPhoneCTAs(props) {
  const {
    phoneMobile,
    permalease,
    formattedPermalease,
    dataFuseName,
    txuPhoneCTA
  } = props;

  const OptionOneCTA = txuPhoneCTA === 'Option 1 - Grid phone CTA';
  const OptionThreeCTA = txuPhoneCTA === 'Option 3 - Grid phone CTA';

  const preampFormatNumber = (str) => {
    const cleaned = (str).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return null;
  };

  const preampNumFormat = preampFormatNumber(formattedPermalease);

  let ctaMessage = '';
  const ctaConditional = () => {
    if (isMobile && OptionOneCTA) {
      ctaMessage = preampNumFormat;
    } else if (isMobile && OptionThreeCTA) {
      ctaMessage = 'Call an Expert';
    } else {
      ctaMessage = formattedPermalease;
    }
    return ctaMessage;
  };

  let fuseMessage = '';
  const fuseConditional = () => {
    if (isMobile && OptionOneCTA) {
      fuseMessage = '(###) ###-####';
    } else if (isMobile && OptionThreeCTA) {
      fuseMessage = 'Call an Expert';
    } else {
      fuseMessage = '###-###-####';
    }
    return fuseMessage;
  };

  return (
    <>
      <a
        className={`phone u-display-none@xl ${phoneMobile && 'svg-phone--mobile-copy'}`}
        href={`tel:${permalease}`}
        data-fuse
        data-fuse-format={fuseConditional()}
        data-fuse-name={dataFuseName}
      >
        {ctaConditional()}
      </a>
    </>
  );
}
