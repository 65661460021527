import React from 'react';

export default function ButtonResponseWrapper(props) {
  const {
    answerState,
    setAnswerState,
    continueAttempt,
    errorConfig,
    setErrorConfig,
    errorKey,
    title
  } = props;

  const selected = answerState !== null;

  const hasError = !selected && continueAttempt;

  const handleClick = (val) => {
    setAnswerState(val);
    setErrorConfig({ ...errorConfig, [errorKey]: true });
  };

  return (
    <div className={`entry-modal__radio-wrapper ${hasError ? 'entry-modal__radio-wrapper--errored' : ''}`}>
      {title && (
        <p className="entry-modal__title">
          {title}
        </p>
      )}
      <button
        className={`entry-modal__radio ${answerState ? 'entry-modal__radio--selected' : ''}`}
        onClick={() => handleClick(true)}
        type="button"
      >
        Yes
      </button>
      <button
        className={`entry-modal__radio entry-modal__radio--no
        ${!answerState && selected ? 'entry-modal__radio--selected' : ''}`}
        onClick={() => handleClick(false)}
        type="button"
      >
        No
      </button>
    </div>
  );
}
