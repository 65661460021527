import React, {
  useState, useEffect, useContext, createContext
} from 'react';

const MonarchContext = createContext({
  rules: {},
});

export function MonarchProvider({ children }) {
  const [rules, setRules] = useState({});

  useEffect(() => {
    window.cohesion('monarch:done', (err, res) => {
      if (err) {
        console.error('Monarch Error', err);
      } else {
        setRules((currentRules) => ({ ...currentRules, ...res }));
      }
    });
  }, []);

  return (
    <MonarchContext.Provider value={{ rules }}>
      {children}
    </MonarchContext.Provider>
  );
}

export function useMonarch() {
  const context = useContext(MonarchContext);

  if (context === undefined) {
    throw new Error('useMonarch must be used within a MonarchProvider');
  }
  return context;
}
