import React from 'react';
import PropTypes from 'prop-types';
import productDisclosure from '../../js/project/product-disclaimers';
import TxuPDFLinks from './PDFLinks';
import close from '../../../images/close.svg';
import TXUWhatsIncluded from '../grid-v2/TXUWhatsIncluded';
import TXUPlanDisclaimers from '../grid-v2/TXUPlanDisclaimers';
import getIncentivePerSize from '../../js/project/incentivesUtils';
import { useMonarch } from '../monarch';

export default function TxuOverlayContent({
  activeClass,
  plan,
  showPlanDetails
}) {
  const {
    secondaryDescription,
    name,
    incentives,
  } = plan;
  const { rules } = useMonarch();
  const showIncentives = rules?.source?.incentives?.value?.showIncentives;
  const incentive = getIncentivePerSize(incentives);
  const planDisclosure = productDisclosure(name);

  return (
    <div
      className={
        activeClass ? 'overlay active-overlay txu-redesign' : 'overlay'
      }
    >
      <div className="overlay__heading">
        {name}
        <button
          type="button"
          onClick={(e) => {
            showPlanDetails(e);
          }}
          className="close"
        >
          <img
            src={close}
            alt="close icon"
          />
        </button>
      </div>
      <div
        className="overlay__html-content"
      >
        <TXUWhatsIncluded secondaryDescription={secondaryDescription} />
      </div>
      <div
        className="overlay__html-disclosure"
      >
        <TXUPlanDisclaimers>
          <>
            {planDisclosure && (
              <p dangerouslySetInnerHTML={{ __html: planDisclosure }} />
            )}
            {showIncentives && incentive?.disclaimer && (
              <p dangerouslySetInnerHTML={{ __html: `†${incentive?.disclaimer}` }} />
            )}
          </>
        </TXUPlanDisclaimers>
      </div>
      <ul className="overlay__pdf-list txu-redesign u-display-flex u-justify-content-space-between">
        <TxuPDFLinks plan={plan} isFeaturedCard />
      </ul>
    </div>
  );
}

TxuOverlayContent.propTypes = {
  activeClass: PropTypes.bool.isRequired,
  plan: PropTypes.shape({}).isRequired
};
