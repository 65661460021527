import React from 'react';

import Accordion from '../grid-faqs/Accordion';

function ProductDetailSectionFAQ(props) {
  const { site, useChevronItem = false } = props;

  return (
    <div className="pdp-section-faq">
      <div className="pdp-section-faq__inner">
        <Accordion site={site} pdpTestActive isOnPDP useChevronItem={useChevronItem} />
      </div>
    </div>
  );
}

export default ProductDetailSectionFAQ;
