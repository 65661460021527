import { useEffect, useState } from 'react';

/**
 * Custom hook that tells you whether a given media query is active.
 */

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (!query) return;

    const mediaQuery = window.matchMedia(query);

    // iOS may not be compatible with matchMedia function
    const compatibilityCheck = mediaQuery.media === 'not all' ? null : mediaQuery.matches;

    setMatches(compatibilityCheck);

    let enableCall = true;

    const handler = (event) => {
      if (!enableCall) return;
      enableCall = false;
      setMatches(event.matches);
      /* eslint-disable-next-line no-return-assign */
      setTimeout(() => (enableCall = true), 1000);
    };

    mediaQuery.addEventListener('change', handler);

    /* eslint-disable-next-line consistent-return */
    return () => mediaQuery.removeEventListener('change', handler);
  }, [query]);
  return matches;
};
export default useMediaQuery;
