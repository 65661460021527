import React from 'react';
import PropTypes from 'prop-types';

function ProductDetailBenefitCard(props) {
  const { benefit } = props;

  return (
    <div className="pdp-benefit-card">
      <div className="pdp-benefit-card__header">
        <img className="pdp-benefit-card__icon" src={benefit.icon} alt="Icon" />
        <span className="pdp-benefit-card__title">{benefit.title}</span>
      </div>
      <div className="pdp-benefit-card__body">
        {benefit.content}
      </div>
    </div>
  );
}

export default ProductDetailBenefitCard;

ProductDetailBenefitCard.propTypes = {
  benefit: PropTypes.shape({}).isRequired,
};
