import {
  productClicked,
  productListFiltered,
  productListViewed,
  productSearched,
  productViewed,
} from './tagular/ecommerce.v1';
import {
  formContinued,
  formBacked,
  formSubmitted,
  formErrored,
} from './tagular/ods.pipeline.v1';
import productPriceViewed from './tagular/energy';

const ZipSearched = (zip) => {
  productSearched(zip);
};

/**
   * Product List Viewed
Fire this event when a visitor views a product list or category.

This event supports the following semantic properties:

Property Type Description
list_id String Product list being viewed
category String Product category being viewed
products Array Products displayed in the product list
products.$.product_id String Product id displayed on the list
products.$.sku String Sku of the product being viewed
products.$.category String Product category being viewed
products.$.name String Name of the product being viewed
products.$.brand String Brand associated with the product
products.$.variant String Variant of the product (e.g. Black)
products.$.price Number Price ($) of the product being viewed
products.$.quantity Number Quantity of a product
products.$.coupon String Coupon code associated with a product (e.g MAY_DEALS_3)
products.$.position Number Position in the product list (ex. 3)
products.$.url String URL of the product page
products.$.image_url String Image url of the product
   */
const ProductListViewed = (products = [], zip = []) => {
  productListViewed(products, zip);
};

/**
   * Product List Filtered
Fire this event when a visitor filters a product list or category.

This event supports the following semantic properties:

Property Type Description
list_id String Product list being viewed
category String Product category being viewed
filters Array Product filters that the customer is using
filters.$.type String Id of the filter type that the customer is using
filters.$.value String Id of the selection that the customer chose
sorts Array Product sorting that the customer is using
sorts.$.type String Id of the sort type that the customer is using
sorts.$.value String Id of the selection type the the customer is using (ascending, descending)
products Array Products displayed in the product list
products.$.product_id String Product id displayed on the list
products.$.sku String Sku of the product being viewed
products.$.category String Product category being viewed
products.$.name String Name of the product being viewed
products.$.brand String Brand associated with the product
products.$.variant String Variant of the product (e.g. Black)
products.$.price Number Price ($) of the product being viewed
products.$.quantity Number Quantity of a product
products.$.coupon String Coupon code associated with a product (e.g MAY_DEALS_3)
products.$.position Number Position in the product list (ex. 3)
products.$.url String URL of the product page
products.$.image_url String Image url of the product
   */
const ProductListFiltered = (products = [], filters = [], sort = '') => {
  productListFiltered(products, filters, sort);
};

/**
 *
 * Fire this event when a visitor clicks a product.
 *
 * @param {Object} product - A product
 * @param {string} product.product_id - Database id of the product being viewed
 * @param {string} product.sku - Sku of the product being viewed
 * @param {string} product.name - Name of the product being viewed
 * @param {string} product.brand - Brand associated with the product
 * @param {string} product.variant - Variant of the product (e.g. Black)
 * @param {string} product.price - Price of the product being viewed
 * @param {integer} product.quantity - Quantity of a product
 * @param {string} product.coupon - Coupon code associated with a product (e.g MAY_DEALS_3)
 * @param {integer} product.position - Position in the product list (ex. 3)
 */
const ProductClicked = ({
  brand,
  location,
  name,
  position,
  price,
  productId,
  quantity,
  sku,
  variant,
  actionOutcome = 'Entered Cart'
}) => {
  productClicked({
    productId,
    sku,
    name,
    brand,
    variant,
    price,
    quantity,
    location,
    position
  }, actionOutcome);
};

/**
 *
 * Fire this event when a visitor views a product.
 *
 * @param {Object} product - A product
 * @param {string} product.product_id - Database id of the product being viewed
 * @param {string} product.sku - Sku of the product being viewed
 * @param {string} product.category - Product category being viewed
 * @param {string} product.name - Name of the product being viewed
 * @param {string} product.brand - Brand associated with the product
 * @param {string} product.variant - Variant of the product (e.g. Black)
 * @param {string} product.price - Price of the product being viewed
 * @param {integer} product.quantity - Quantity of a product
 * @param {string} product.coupon - Coupon code associated with a product (e.g MAY_DEALS_3)
 * @param {integer} product.position - Position in the product list (ex. 3)
 */
const ProductViewed = ({
  brand,
  category,
  location,
  name,
  position,
  price,
  productId,
  quantity,
  sku,
  variant,
}) => {
  productViewed({
    productId,
    sku,
    category,
    name,
    brand,
    variant,
    price,
    quantity,
    location,
    position
  });
};

/**
 *
 * Fire this event when a visitor views a product's pricing.
 *
 * @param {Object} product - A product
 * @param {string} product.product_id - Database id of the product being viewed
 * @param {integer} product.pointInTimeId - pointInTimeId of the product being viewed
 * @param {string} product.lifetimeId - lifetimeId of the product being viewed
 * @param {string} product.variationId - variationId of the product being viewed
 * @param {string} product.fiveHundredKwh - fiveHundredKwh price of the product being viewed
 * @param {string} product.oneThousandKwh - oneThousandKwh price of the product being viewed
 * @param {string} product.twoThousandKwh - twoThousandKwh price of the product being viewed
 */
const ProductPriceViewed = ({
  pointInTimeId,
  productId,
  lifetimeId,
  variationId,
  fiveHundredKwh,
  oneThousandKwh,
  twoThousandKwh
}) => {
  productPriceViewed({
    pointInTimeId,
    productId,
    lifetimeId,
    variationId,
    fiveHundredKwh,
    oneThousandKwh,
    twoThousandKwh
  });
};

export {
  ProductClicked,
  ProductListViewed,
  ProductListFiltered,
  ProductViewed,
  ZipSearched,
  formContinued,
  formBacked,
  formSubmitted,
  formErrored,
  ProductPriceViewed,
};
