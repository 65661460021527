import React, {
  createContext, useContext, useState
} from 'react';

const PreampContext = createContext({});

const findAssetName = (placementToFind, context, isEditable = false) => {
  const asset = context.find((placement) => placement.placementName === placementToFind);
  if (isEditable) {
    return asset?.html;
  }
  return asset?.assetName;
};

const findAssetVariables = (placementToFind, context) => {
  const asset = context.find((placement) => placement.placementName === placementToFind);

  if (asset?.metadata) {
    return asset.metadata;
  }

  return asset?.assetName;
};

export function usePreamp(name = '', editable = false) {
  const ctx = useContext(PreampContext);
  const assetName = findAssetName(name, ctx) || false;
  const isEditableAsset = assetName && assetName.match(/(\[editable\])/);

  if (isEditableAsset) {
    return findAssetName(name, ctx, !!isEditableAsset.length);
  }

  if (editable) {
    return findAssetVariables(name, ctx);
  }

  return findAssetName(name, ctx) || false;
}

export default function PreampProvider({ children }) {
  const [preampPlacements, setPreampPlacements] = useState([]);

  const cohesionCheck = setInterval(() => {
    if (window.cohesion) {
      window.cohesion('preamp:decisionsReceived', (decision) => {
        setPreampPlacements(decision.placements);
      });
      clearInterval(cohesionCheck);
    }
  }, 10);

  return (
    <PreampContext.Provider value={preampPlacements}>
      {children}
    </PreampContext.Provider>
  );
}
