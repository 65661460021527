/* eslint-disable max-len */
import React from 'react';
import atlasLogoSet from '../../images/atlasLogoSet.png';

function AtlasCard(props) {
  const { currentIndex, insertionIndex, active } = props;

  if (!active || currentIndex !== insertionIndex) {
    return null;
  }

  return (
    <article className="card atlas-card">
      <div
        className="card__section
          u-display-flex
          u-justify-content-space-between
          u-flex-direction-column
          u-flex-wrap-wrap
          u-justify-content-center@md
          u-align-items-center@md"
      >
        <div className="logo-section">
          <img src={atlasLogoSet} alt="atlas logo" />
        </div>
        <div className="header-text">
          Unsure of what plan type best meets your needs?
        </div>
        <div className="description-text">
          ChooseTexasPower and AtlasHome work together to select the top plan for your home, your usage, and your needs.
        </div>
        <a className="button-section" href="https://www.AtlasHome.com/?utm_source=CTXP">
          <div className="atlas-button">
            Find Your Best Plan on Atlas!
          </div>
        </a>
      </div>
    </article>
  );
}

export default AtlasCard;
