import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import { ProductViewed } from '@rvpower/track';
import { gridUrl } from '@rvpower/constants';
import { usePreamp } from '../preamp';
import useBreakpoints from '../../js/hooks/useBreakPoints';
import tracking from '../../js/project/tagular/tracking';

// Components
import PlanCardsWidgetPlanCard from './PlanCardsWidgetPlanCard';
import PlanDetails from './PlanDetails';
import VisibleTracker from '../VisibleTracker';

import iconArrowDown from '../../../images/long-arrow-right-black.svg';
import soeLogo from '../../../images/soe-logo.svg';
import closeIcon from '../../svg/close.svg';
import InlineZipcodeEntry from '../InlineZipcodeEntry';
import ErrorBanner from './ErrorBanner';

function PlanCardsWidgetWrapper({
  plans = [],
  site,
  fuseData,
  onSubmit,
  zipCode,
  noUtitlities,
  externalGrid = false,
}) {
  const funcs = useRef([]);
  const registerIsVisbileCheck = (f) => funcs.current.push(f);
  const dispatchIsVisibleChecks = () => funcs.current.forEach((f) => f());

  const headlinePreampData = usePreamp('Rates Outside of Grid - Headline', true);
  const [carouselButton, setCarouselButton] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { isXl } = useBreakpoints();

  const handleDropdownToggle = (dropdownId, isDropdownActive) => {
    setActiveDropdown((prevActiveDropdown) => (prevActiveDropdown === dropdownId ? null : dropdownId));

    tracking.elementClicked({
      actionOutcome: isDropdownActive ? 'Collapse Details' : 'Expand Details',
      webElement: {
        location: 'ROG',
        elementType: 'Button',
        text: isDropdownActive ? 'Less Details' : 'More Details',
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const containerObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect.height && window) {
        window.postMessage(
          { type: 'affiliate-resize', height: entry.contentRect.height },
          '*'
        );
      }
    });
  });

  useEffect(() => {
    const widgetContainer = document.querySelector('#js-grid .grid');
    if (widgetContainer) containerObserver.observe(widgetContainer);

    return () => {
      containerObserver.unobserve(widgetContainer);
    };
  }, []);

  const customDotStyles = (dotIndex) => (carouselButton === dotIndex && 'plans-widget-wrapper__custom-dot--active');

  const responsiveVerticalCards = {
    desktop: {
      breakpoint: { max: 30000, min: 1025 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 530 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobileLarge: {
      breakpoint: { max: 529, min: 375 },
      items: 1,
      paritialVisibilityGutter: 100
    },
    mobile: {
      breakpoint: { max: 374, min: 0 },
      items: 1,
      paritialVisibilityGutter: 50
    },
  };

  const handleAllPlansClicked = () => {
    tracking.elementClicked({
      actionOutcome: 'Load all plans',
      webElement: {
        location: 'ROG',
        elementType: 'Hyperlink',
        text: 'All Plans',
      },
    });

    setTimeout(() => {
      const url = gridUrl(site, zipCode);

      if (url) {
        window.parent.postMessage(
          { type: 'widget-cart-redirect', url },
          '*'
        );
      }
    }, 500);
  };

  const cardsList = plans.map((plan, index) => {
    const productInfo = {
      brand: plan?.supplier?.name,
      category: 'Electricity',
      location: '',
      name: plan?.name,
      position: index + 1,
      price: plan?.price || 0,
      productId: plan?.id,
      quantity: 1,
      sku: plan?.utility?.name,
      variant: plan?.term?.length,
    };

    return (
      <VisibleTracker
        onVisible={() => ProductViewed(productInfo)}
        registerIsVisbileCheck={registerIsVisbileCheck}
        key={plan.id}
      >
        <PlanCardsWidgetPlanCard
          key={plan.id}
          site={site}
          fuseData={fuseData}
          plan={plan}
          dropdownId={index}
          activeDropdown={activeDropdown}
          handleDropdownToggle={handleDropdownToggle}
          zipCode={zipCode}
          externalGrid={externalGrid}
        />
      </VisibleTracker>
    );
  });

  const handleOnZipCodeSubmit = (zip) => {
    if (onSubmit) {
      onSubmit(zip);
    }
  };

  useEffect(() => {
    window.parent.postMessage(
      { type: 'widget-details-toggle', open: activeDropdown !== null },
      '*'
    );
  }, [activeDropdown]);

  const widgetWrapperClasses = externalGrid
    ? 'plans-widget-wrapper plans-widget-wrapper__external-grid'
    : 'plans-widget-wrapper';

  return (
    <div className={widgetWrapperClasses}>
      {modalIsOpen ? (
        <div className="plans-widget-modal-overlay">
          <div className="plans-widget-modal">
            <div className="plans-widget-modal__header">
              <h3 className="plans-widget-modal__headline">Advertising disclosure</h3>
              <button className="plans-widget-modal__close-icon" onClick={closeModal} type="button">
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
            <div className="plans-widget-modal__content">
              {/* eslint-disable-next-line max-len */}
              <p className="plans-widget-modal__copy">This advertising widget is powered by SaveOnEnergy and contains advertisements that SaveOnEnergy may be paid for in different ways. You will not be charged for engaging with this advertisement. While we strive to provide a wide range of offers, this advertising widget does not include information about every product or service that may be available to you. We make reasonable efforts to ensure that information in the featured advertisements is up to date, each advertiser featured in this widget is responsible for the accuracy and availability of its offer details. It is possible that your actual offer terms from an advertiser may be different than the offer terms in this advertising widget and the advertised offers may be subject to additional terms and conditions of the advertiser which will be presented to you prior to making a purchase. All information is presented without any warranty or guarantee to you.</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="plans-widget-wrapper__headline">
            {headlinePreampData?.headlineText || 'Shop electricity plans in your area'}
          </p>
          <div className="plans-widget-wrapper__zip-entry">
            <span>
              Showing plans for
            </span>
            {' '}
            <InlineZipcodeEntry value={zipCode} onSubmit={handleOnZipCodeSubmit} />
          </div>
          {noUtitlities && (
            <ErrorBanner phone={fuseData?.permalease} isOpenInitial />
          )}
          {isXl ? (
            <div className="plans-widget-wrapper__plans-wrapper">
              <div className="plans-widget-wrapper__inner-plans-wrapper">
                {cardsList}
              </div>
              {activeDropdown !== null && (
                <PlanDetails
                  desktop
                  site={site}
                  fuseData={fuseData}
                  plan={plans?.[activeDropdown]}
                  activeDropdown={activeDropdown}
                  carouselButton={carouselButton}
                  handleDropdownToggle={handleDropdownToggle}
                />
              )}
            </div>
          ) : (
            <div className="plans-widget-wrapper__plans-wrapper isSmall">
              <Carousel
                afterChange={(previousSlide, { currentSlide }) => {
                  setActiveDropdown(null);
                  setCarouselButton(currentSlide);
                  dispatchIsVisibleChecks(currentSlide);
                }}
                arrows={false}
                swipeable
                partialVisbile
                showDots
                responsive={responsiveVerticalCards}
                dotListClass="plans-widget-wrapper__carousel-dot-list"
                containerClass="plans-widget-wrapper__carousel-list"
                itemClass="plans-widget-wrapper__carousel-item"
              >
                {cardsList}
              </Carousel>
              {activeDropdown !== null && (
                <PlanDetails
                  site={site}
                  fuseData={fuseData}
                  plan={plans?.[activeDropdown]}
                  activeDropdown={activeDropdown}
                  carouselButton={carouselButton}
                  handleDropdownToggle={handleDropdownToggle}
                />
              )}
              {/* Creating custom carousel dots for tablet to match plan details designs */}
              <div className="plans-widget-wrapper__custom-dot-list">
                <div className={`plans-widget-wrapper__custom-dot ${customDotStyles(0)}`} />
                <div className={`plans-widget-wrapper__custom-dot ${customDotStyles(1)}`} />
              </div>
            </div>
          )}
          <p className="plans-widget-wrapper__copy">Want more options? Explore all electricity plans in your area.</p>
          <button
            onClick={handleAllPlansClicked}
            className="plans-widget-wrapper__grid-link"
            type="button"
          >
            All plans
            <img src={iconArrowDown} alt="arrow-right" />
          </button>
          {externalGrid && (
            <div className="plans-widget-wrapper__footer">
              <div className="plans-widget-wrapper__logo-container">
                <span>Powered by</span>
                <img
                  className="plans-widget-wrapper__logo"
                  src={soeLogo}
                  alt="soe-logo"
                />
              </div>
              <div className="plans-widget-wrapper__links-container">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="plans-widget-wrapper__link"
                  href="https://www.saveonenergy.com/privacy/"
                >
                  Privacy Policy
                </a>
                <span>|</span>
                <button
                  className="plans-widget-wrapper__link"
                  type="button"
                  onClick={() => setModalIsOpen(true)}
                >
                  Advertiser Disclosure
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PlanCardsWidgetWrapper;
