import React from 'react';

import perk1Icon from '../../../images/planperk1.svg';
import perk2Icon from '../../../images/planperk2.svg';
import perk3Icon from '../../../images/planperk3.svg';

const TXUPlanPerks = () => (
  <div className="txu-plan-perks">
    <h3>All of our term plans come with</h3>
    <ul>
      <li>
        <img src={perk1Icon} alt="Price protect icon" />
        Price protect promise
        <sup id="price-protect-sup">*</sup>
      </li>
      <li>
        <img src={perk2Icon} alt="Price protect icon" />
        Total Satisfaction guarantee
        <sup id="total-satisfaction-sup">**</sup>
      </li>
      <li>
        <img src={perk3Icon} alt="Price protect icon" />
        24/7 Customer Support
      </li>
    </ul>
  </div>
);

export default TXUPlanPerks;
