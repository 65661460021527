import React from 'react';
import Select from 'react-select';
import tracking from '../js/project/tagular/tracking';

function Sort(props) {
  const { sort, setSort, sortOptions } = props;
  const defaultOption = sortOptions.find((option) => option.value === sort);

  const handleChange = (option) => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Dropdown',
        text: option.label
      },
      actionOutcome: 'Submit new sort'
    });

    setSort(option.value);
  };

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Dropdown',
        text: 'Sort'
      },
      actionOutcome: 'Sort dropdown opened'
    });
  };

  return (
    <div className="u-display-flex u-align-items-center">
      <strong className="sort__label" id="sortLabel">Sort</strong>
      <Select
        value={defaultOption}
        isSearchable={false}
        onChange={handleChange}
        onMenuOpen={handleClick}
        options={sortOptions}
        className="sort"
        classNamePrefix="sort"
        aria-labelledby="sortLabel"
      />
    </div>
  );
}

export default Sort;
