import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { DefaultFilters } from '@rvpower/constants';
import useBreakpoints from '../../../js/hooks/useBreakPoints';
import buildClassList from '../../../js/project/buildClassList';
import tracking from '../../../js/project/tagular/tracking';

import { formatLabel, pillIsActive, sortPills } from './PillsFunctions';

import Pill from './Pill';
import MenuIcon from './MenuIcon';
import PillsDropdownObject from './PillsDropdownObject';
import PillChildren from './PillChildren';
import TippyInstance from './TippyInstance';
import PillAllFilters from './PillAllFilters';
import HmcButton from '../../help-me-choose/HmcButton';
import HmcModal from '../../help-me-choose/HmcModal';
import ZipSearch from '../ZipSearch';

const Pills = ({
  allPlans,
  className,
  filters,
  isNTX,
  isOpen,
  plans,
  providers,
  rates,
  setFilters,
  setFiltersActiveSection,
  setIsOpen,
  setUsage,
  setSort,
  setSelectedUtility,
  terms,
  utility,
  utilities,
  usage,
  site,
  splashDeleteClass,
  isSplashPage,
  showHelpMeChooseCTA,
  hmcData,
  updateHmcData,
  getPlansCount,
  zipCode,
  monarchSimplifiedFilter
}) => {
  const pillsDropdown = PillsDropdownObject({
    allPlans,
    className,
    filters,
    isNTX,
    plans,
    providers,
    rates,
    setFilters,
    setUsage,
    terms,
    site,
    setSelectedUtility,
    utilities,
    utility
  });

  // Removing Utility because that is not a valid filter for TX
  if (!isNTX) delete pillsDropdown.Utility;
  // Removing Usage because its not a valid filter for NTX
  const newFilters = { ...filters };
  if (isNTX) {
    delete newFilters.usage;
    delete DefaultFilters.usage;
  }
  const isTXU = site === 'shop.txu.com';
  const ntxPillsDropdown = ['Utility', 'Term length', 'Provider', 'Features'];
  const pillsContainer = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [pillsOrder, setPillsOrder] = useState(isNTX ? ntxPillsDropdown : Object.keys(pillsDropdown));
  const [isTippyOpen, setIsTippyOpen] = useState(false);
  const [instance, setInstance] = useState({});
  const [usageInteracted, setUsageInteracted] = useState(false);
  const [hmcModalOpen, setHmcModalOpen] = useState(false);

  const {
    isSm,
    isMd,
  } = useBreakpoints();
  const hasDefaultFilters = isEqual(newFilters, DefaultFilters);

  const classList = buildClassList(
    'grid-v2-pills',
    isSticky && 'grid-v2-pills--sticky',
    isNTX && 'ntx',
    splashDeleteClass,
    className,
    monarchSimplifiedFilter && 'monarch-simplified-filter'
  );

  const handleSticky = () => {
    const options = {
      threshold: [1],
      rootMargin: '190px'
    };

    const onIntersect = (entries) => {
      entries.forEach((entry) => {
        setIsSticky(!entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(onIntersect, options);

    if (pillsContainer.current) {
      observer.observe(document.body);
    }
  };

  const handleDropdownClick = (label, index, opened) => {
    tracking.elementClicked({
      webElement: {
        elementType: 'DROPDOWN',
        location: 'GRID HERO',
        name: 'FILTER DROPDOWNS',
        position: index.toString(),
        text: label
      },
      actionOutcome: opened
    });
  };

  const handlePillsMobileClick = (e, value, label, index) => {
    const clickedClose = e.target.classList.contains('grid-v2-pills__pill-icon-close');

    handleDropdownClick(label, index, 'EXPAND');

    if (!isOpen && (isSm) && !clickedClose) {
      setIsOpen(true);
      setFiltersActiveSection(value);
    }
  };

  useEffect(() => {
    setUsageInteracted(filters.usage !== 1000);

    if (!isTippyOpen) {
      const sortFunction = sortPills(pillsOrder, pillsDropdown);
      setPillsOrder(sortFunction);
    }

    setTimeout(() => handleSticky(), 150);
  }, [isTippyOpen, filters]);

  // splashFilters was made with respect to the preamp "feature-button" class
  // found in the CDK preamp asset Simplified Filter that is used for the splash page.
  // The order of the splashFilters array is important for the preamp to work correctly
  const splashFilters = ['Home size', 'Price', 'Features', 'Term length', 'Provider', 'Rating', 'Rate type'];
  const simplifiedFilters = ['Home size', 'Price', 'Provider', 'Rating', 'Term length', 'Rate type'];

  let filtersToDisplay;
  if (isSplashPage) {
    filtersToDisplay = splashFilters;
  } else if (monarchSimplifiedFilter) {
    filtersToDisplay = simplifiedFilters;
  } else {
    filtersToDisplay = pillsOrder;
  }

  const hmcModalHandler = (modalToggle) => {
    const soeNav = document.querySelector('#soe-nav-loader');
    if (modalToggle) {
      if (soeNav) {
        document.querySelector('.soe-nav-and-footer div').classList.remove('z-50');
        document.querySelector('.soe-nav-and-footer nav').classList.remove('z-50');
      }
    } else if (!modalToggle) {
      if (soeNav) {
        setTimeout(() => {
          document.querySelector('.soe-nav-and-footer div').classList.add('z-50');
          document.querySelector('.soe-nav-and-footer nav').classList.add('z-50');
        }, 175);
      }
    }

    setHmcModalOpen(modalToggle);
  };

  const hmcAnswered = hmcData !== null && hmcData?.estimatedUsage !== null;

  return (
    <div className={classList} ref={pillsContainer}>
      <div className="grid-v2-pills__container">
        {monarchSimplifiedFilter && (
          <ZipSearch
            plansCount={getPlansCount(site)}
            zipCode={zipCode}
            site={site}
            isNTX={isNTX}
            isSplashPage={isSplashPage}
          />
        )}
        <div className={buildClassList(
          showHelpMeChooseCTA && ('grid-v2-pills__hmc-wrapper'), 'grid-v2-pills__wrapper'
        )}
        >
          {isSm && !isNTX && !monarchSimplifiedFilter && (
            <>
              {showHelpMeChooseCTA && !hmcAnswered && hasDefaultFilters ? (
                <Pill
                  icon={<MenuIcon className="help-me-choose__filter-icon" />}
                  label="Filter"
                  onClick={() => setIsOpen(true)}
                />
              ) : (
                <Pill
                  onClick={() => setIsOpen(true)}
                >
                  <MenuIcon />
                </Pill>
              )}
            </>
          )}
          {showHelpMeChooseCTA && !hmcAnswered && hasDefaultFilters && (
            <HmcButton
              modalOpen={hmcModalOpen}
              modalHandler={hmcModalHandler}
              screenWidth="--mobile"
            />
          )}
          {showHelpMeChooseCTA && hasDefaultFilters && (
            <HmcButton
              modalOpen={hmcModalOpen}
              modalHandler={hmcModalHandler}
              screenWidth="--tablet"
            />
          )}
          {isMd && !isNTX && (
            <TippyInstance
              content={
                  (
                    <PillAllFilters
                      allPlans={allPlans}
                      filters={filters}
                      isNTX={isNTX}
                      plans={plans}
                      providers={providers}
                      rates={rates}
                      setFilters={setFilters}
                      setUsage={setUsage}
                      terms={terms}
                      site={site}
                    />
                  )
                }
              resetFilters={() => setFilters({ ...DefaultFilters })}
              resetText="Clear all"
              setInstance={setInstance}
              setIsTippyOpen={setIsTippyOpen}
              title="All filters"
            >
              <button
                type="button"
                  // eslint-disable-next-line max-len
                className={`grid-v2-pills__pill grid-v2-pills__pill-filter-button ${showHelpMeChooseCTA && hasDefaultFilters && 'grid-v2-pills__pill-filter-button--hmc'}`}
              >
                <MenuIcon />
              </button>
            </TippyInstance>
          )}
          {!isSm && !isMd && (
            <TippyInstance
              content={
                  (
                    <PillAllFilters
                      allPlans={allPlans}
                      filters={filters}
                      isNTX={isNTX}
                      plans={plans}
                      providers={providers}
                      rates={rates}
                      setFilters={setFilters}
                      setUsage={setUsage}
                      setSelectedUtility={setSelectedUtility}
                      utilities={utilities}
                      utility={utility}
                      terms={terms}
                      site={site}
                    />
                  )
                }
              resetFilters={() => setFilters({ ...DefaultFilters })}
              resetText="Clear all"
              setInstance={setInstance}
              setIsTippyOpen={setIsTippyOpen}
              title="All filters"
            >
              <button
                type="button"
                className={`
                  grid-v2-pills__pill-filter-button
                  ${showHelpMeChooseCTA && hasDefaultFilters && 'grid-v2-pills__pill-filter-button--hmc'}
                `}
              >
                <MenuIcon
                  className="grid-v2-pills__pill-filter-button-icon"
                />
                <span
                  className={`
                    grid-v2-pills__pill-filter-button-span
                    ${isTXU ? 'grid-v2-pills__pill-filter-button-span-txu' : ''}
                  `}
                >
                    All filters
                </span>
              </button>
            </TippyInstance>
          )}

          {(
            showHelpMeChooseCTA ? (
              !hasDefaultFilters || !isSm
            ) : (
              !showHelpMeChooseCTA || !isSm
            )
          ) && (filtersToDisplay.map((pill, index) => {
            const currentDropdown = pillsDropdown[pill];

            //  For NTX we want to show the simplified version of filters so we only want one pill on smaller viewports
            if (isNTX && (isSm || isMd)) {
              if (pill !== 'Utility') return null;
            }

            const {
              component,
              filterType,
              trackingName,
              hasIcon,
              icon,
              label,
              number,
              placement,
              resetFilters,
              resetText
            } = currentDropdown;

            const formattedLabel = formatLabel(filters, label, currentDropdown, providers, usage, utility);
            const isUsageFilter = label === 'Home size';
            const isUtilityFilter = label === 'Utility';
            const isActiveUsageCheck = isUsageFilter
              ? pillIsActive(currentDropdown, filters) && usageInteracted
              : pillIsActive(currentDropdown, filters);
            let usageCheckNumber = number;
            if (isUsageFilter) {
              usageCheckNumber = usageInteracted ? number : 0;
            }
            if (isUtilityFilter) {
              usageCheckNumber = 0;
            }

            const handleReset = () => {
              resetFilters();

              tracking.elementClicked({
                webElement: {
                  elementType: 'LINK',
                  location: `${trackingName} FILTER`,
                  name: 'FILTER DROPDOWNS',
                  position: index.toString(),
                  text: 'RESET'
                },
                actionOutcome: 'RESET FILTER LIST'
              });
            };

            const handleCloseButtonClick = () => {
              if (!isSm) instance.hide();
              resetFilters();
            };

            if (!isSm) {
              return (
                <>
                  <TippyInstance
                    key={label}
                    content={component}
                    resetFilters={() => handleReset()}
                    resetText={resetText || 'Reset'}
                    placement={placement}
                    setInstance={setInstance}
                    setIsTippyOpen={setIsTippyOpen}
                    title={label}
                    index={index}
                  >
                    <Pill
                      icon={icon && number > 0 ? icon : <></>}
                      isActive={isActiveUsageCheck}
                      label={formattedLabel}
                      onClick={() => handleDropdownClick(formattedLabel, index, 'EXPAND')}
                    >
                      <PillChildren
                        hasPlus={number > 0 && hasIcon}
                        number={usageCheckNumber}
                        onClick={handleCloseButtonClick}
                      />
                    </Pill>
                  </TippyInstance>
                </>
              );
            }

            return (
              <Pill
                key={label}
                icon={icon && number > 0 ? icon : <></>}
                isActive={isActiveUsageCheck}
                label={formattedLabel}
                onClick={(event) => handlePillsMobileClick(event, filterType, label, index)}
              >
                <PillChildren
                  hasPlus={number > 0 && hasIcon}
                  number={usageCheckNumber}
                  onClick={handleCloseButtonClick}
                />
              </Pill>
            );
          }))}

          {monarchSimplifiedFilter && isSm && (
            <Pill
              key="All filters"
              icon={<></>}
              label="All filters"
              onClick={() => setIsOpen(true)}
            >
              <PillChildren
                hasPlus={false}
                number={0}
                onClick={() => setIsOpen(true)}
              />
            </Pill>
          )}

          {showHelpMeChooseCTA && hasDefaultFilters && (
            <HmcButton
              modalOpen={hmcModalOpen}
              modalHandler={hmcModalHandler}
              screenWidth="--desktop"
            />
          )}

          {isSm && isNTX && (
            <Pill
              key="All filters"
              icon={<></>}
              label="All filters"
              onClick={() => setIsOpen(true)}
            >
              <PillChildren
                hasPlus={false}
                number={0}
                onClick={() => setIsOpen(true)}
              />
            </Pill>
          )}

          {(isMd && isNTX && (
            <span className={`${isNTX ? 'grid-v2-tippy-ntx' : ''}`}>
              <TippyInstance
                content={
                  (
                    <PillAllFilters
                      allPlans={allPlans}
                      filters={filters}
                      isNTX={isNTX}
                      plans={plans}
                      providers={providers}
                      rates={rates}
                      setFilters={setFilters}
                      setUsage={setUsage}
                      setSelectedUtility={setSelectedUtility}
                      utilities={utilities}
                      utility={utility}
                      terms={terms}
                      site={site}
                    />
                  )
                }
                resetFilters={() => setFilters({ ...DefaultFilters })}
                resetText="Clear all"
                setInstance={setInstance}
                setIsTippyOpen={setIsTippyOpen}
                title="All filters"
              >
                <Pill
                  key="All filters"
                  icon={<></>}
                  label="All filters"
                  onClick={setIsTippyOpen}
                >
                  <PillChildren
                    hasPlus={false}
                    number={0}
                    onClick={setIsTippyOpen}
                  />
                </Pill>
              </TippyInstance>
            </span>
          ))}
        </div>

        {!hasDefaultFilters && (
          <div className="grid-v2-pills__clear-all-wrapper">
            <button
              className="grid-v2-pills__clear-all-button"
              onClick={() => setFilters({ ...DefaultFilters })}
              type="button"
            >
              Clear All Filters
            </button>
          </div>
        )}
      </div>
      {showHelpMeChooseCTA && (
        <HmcModal
          modalOpen={hmcModalOpen}
          modalHandler={hmcModalHandler}
          updateHmcData={updateHmcData}
          setSort={setSort}
        />
      )}
    </div>
  );
};

export default Pills;

Pills.propTypes = {
  allPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  filters: PropTypes.shape({}).isRequired,
  isNTX: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rates: PropTypes.arrayOf(PropTypes.oneOf(['Fixed', 'Variable', 'Indexed'])).isRequired,
  setFilters: PropTypes.func.isRequired,
  setFiltersActiveSection: PropTypes.func.isRequired,
  setUsage: PropTypes.func.isRequired,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  usage: PropTypes.string.isRequired,
  site: PropTypes.string
};

Pills.defaultProps = {
  className: '',
  site: ''
};
