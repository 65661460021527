import { Sites } from '@rvpower/constants';
import React from 'react';

// SVGs
import apartmentIcon from '../svg/apartment.svg';
import smallHouseIcon from '../svg/small-house.svg';
import largeHouseIcon from '../svg/large-house.svg';

import apartmentIconSoe from '../svg/apartment-soe.svg';
import smallHouseIconSoe from '../svg/small-house-soe.svg';
import largeHouseIconSoe from '../svg/large-house-soe.svg';

import apartmentIconTxu from '../svg/apartment-txu.svg';
import smallHouseIconTxu from '../svg/small-house-txu.svg';
import largeHouseIconTxu from '../svg/large-house-txu.svg';

const iconPaths = {
  [Sites.SOE]: {
    apartment: apartmentIconSoe,
    smallHouse: smallHouseIconSoe,
    largeHouse: largeHouseIconSoe
  },
  [Sites.TXU]: {
    apartment: apartmentIconTxu,
    smallHouse: smallHouseIconTxu,
    largeHouse: largeHouseIconTxu
  },
  default: {
    apartment: apartmentIcon,
    smallHouse: smallHouseIcon,
    largeHouse: largeHouseIcon
  }
};

const ranges = {
  apartment: {
    min: 0,
    max: 750
  },
  smallHouse: {
    min: 751,
    max: 1500
  },
  largeHouse: {
    min: 1501,
    max: 9999
  }
};

const UsageInfo = ({
  className, iconPath, iconClassName = '', kwh, title
}) => (
  <div className={className}>
    <img className={`slider__image svg ${iconClassName}`} src={iconPath} alt={title} />
    <span className="slider__home-type">{title}</span>
    <strong className="slider__image--text">
      {`${kwh} kWh`}
    </strong>
  </div>
);

function UsageDisplay(props) {
  const { kwh, site } = props;

  const findRange = (homeType) => {
    const { min, max } = ranges[homeType];
    return (kwh >= min && kwh <= max);
  };

  const sliderItemClasses = `slider__label
                             u-flex-direction-column
                             u-align-items-center
                             u-display-flex
                             u-justify-content-flex-end`;

  const iconPath = iconPaths?.[site] || iconPaths?.default;

  return (
    <>
      <UsageInfo
        title="Apartment"
        iconClassName="svg--apartment"
        iconPath={iconPath?.apartment}
        kwh={kwh}
        className={
          `${sliderItemClasses} ${findRange('apartment') ? 'u-display-flex' : 'u-display-none'}`
        }
      />
      <UsageInfo
        title="Small House"
        iconClassName="svg--small-house"
        iconPath={iconPath?.smallHouse}
        kwh={kwh}
        className={
          `${sliderItemClasses} ${findRange('smallHouse') ? 'u-display-flex' : 'u-display-none'}`
        }
      />
      <UsageInfo
        title="Large House"
        iconClassName="svg--large-house"
        iconPath={iconPath?.largeHouse}
        kwh={kwh}
        className={
          `${sliderItemClasses} ${findRange('largeHouse') ? 'u-display-flex' : 'u-display-none'}`
        }
      />
    </>
  );
}

export default UsageDisplay;
