import React from 'react';
import { Sites } from '@rvpower/constants';
import TechCards from './TechCards';

import waveDivider from '../../../images/unserve/wave-divider.svg';
import soeShopping from '../../../images/unserve/soe-shopping.svg';
import chooseShopping from '../../../images/unserve/choose-shopping.svg';

const UnserveSmartTech = (props) => {
  const { site } = props;

  const isSOE = site === Sites.SOE;

  const backgroundImages = [
    {
      site: 'saveonenergy.com',
      src: soeShopping,
      alt: 'Save On Energy Smart Shopping Background',
      className: 'soe',
    },
    {
      site: 'chooseenergy.com',
      src: chooseShopping,
      alt: 'Choose Energy Smart Shopping Background',
      className: 'choose',
    }
  ];

  const siteSolarBackground = backgroundImages.filter((image) => image.site === site);

  return (
    <div id="smart-tech" className="unserve-tech__wrapper">
      <div className="unserve-tech">
        <div className="unserve-tech__header">
          <h2 className="unserve-tech__header-title">Shop smart tech for your home</h2>
          <p className="unserve-tech__header-subtext">
            Save money and optimize your home&rsquo;s energy usage with Emporia smart home products.
          </p>
        </div>
        <TechCards site={site} />
        {siteSolarBackground.map((image) => (
          <img
            key={image.alt}
            src={image.src}
            alt={image.alt}
            className={`unserve-tech__background-shopping-${image.className}`}
          />
        ))}
      </div>
      {isSOE && (
      <div className="unserve-tech__background">
        <img src={waveDivider} alt="Wave divider" className="unserve-tech__background-wave" />
      </div>
      )}
    </div>
  );
};

export default UnserveSmartTech;
