/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import FeaturedCardLabel from '../sub-components/FeatureCardLabel';

import Badge from '../../Badge';
import TxuPrice from '../../txu-grid/TxuPrice';
import TxuOverlayContent from '../../txu-grid/OverLayContentTxu';
import phone from '../../../../images/phone.svg';
import useFuseNumber from '../../../js/hooks/useFuseNumber';
import { useMonarch } from '../../monarch';

const TXUFeaturedCardVariant = ({
  featuredCard,
  onOrderOnline,
  plan,
  planDetailsToggle,
  showPlanDetails,
  variant,
  TXUBadge,
  incentiveActive,
  termText,
  usage,
  name,
  badges,
  cartUrl,
  description,
  type,
  site,
}) => {
  const { fuseData } = useFuseNumber();
  const { dataFuseFormat, dataFuseName, permalease } = fuseData?.featuredCardsFuseData || fuseData;
  const { rules } = useMonarch();
  const isCart3 = rules?.source?.cartVersion?.value?.cartVersion === '3.0';
  const updatedCartUrl = !isCart3 ? cartUrl : cartUrl.replace('cart', 'shop');


  function featuredCardUrl(cartUrlLink, usageAmount) {
    return cartUrlLink.replace(/rateType=[0-9]*/, `rateType=${usageAmount}`);
  }

  return (
    <article className={`featured-card txu-redesign ${variant}`} ref={featuredCard}>
      <FeaturedCardLabel plan={plan} />
      <div className="txu-card-details">
        <p className="txu-name">{name}</p>
      </div>

      <div className="txu-card-numbers">
        <TxuPrice
          plan={plan}
          isFeaturedCard
          className="card__section-item"
        />

        <div className="txu-card-term">
          <span className="overlay__heading--light">
            {`Per kWh at ${usage}`}
          </span>
          <span className="divider" />
          {' '}
          <strong>
            {` ${termText} - ${type}`}
          </strong>
        </div>
      </div>

      <div className="txu-cta-area">
        <a
          className="check-availability"
          href={featuredCardUrl(updatedCartUrl, usage, site)}
          onClick={(e) => onOrderOnline(e, featuredCardUrl(updatedCartUrl, usage, site), plan)}
        >
          Check availability
        </a>

        <a
          href={`tel:${permalease}`}
          className="txu-number"
          data-fuse
          data-fuse-format={dataFuseFormat}
          data-fuse-name={dataFuseName}
        >
          <img src={phone} alt="phone" />
          <span
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {permalease}
          </span>
        </a>
      </div>

      {TXUBadge && badges?.length ? (
        <Badge type={badges[0].name}>{badges[0].attributeValue}</Badge>
      ) : null}

      <p className="txu-description">{description}</p>

      <div className="txu-card-footer">
        <button
          type="button"
          className="txu-plan-details"
          onClick={(e) => {
            showPlanDetails(e);
          }}
        >
          <u>See Plan Details</u>
        </button>

        <a
          href={`tel:${permalease}`}
          className="txu-number"
          data-fuse
          data-fuse-format={dataFuseFormat}
          data-fuse-name={dataFuseName}
        >
          <img src={phone} alt="phone" />
          <span
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {permalease}
          </span>
        </a>
      </div>

      <TxuOverlayContent
        site={site}
        plan={plan}
        type={type}
        activeClass={planDetailsToggle}
        incentiveActive={incentiveActive}
        showPlanDetails={showPlanDetails}
      />
    </article>
  );
};

TXUFeaturedCardVariant.propTypes = {
  variant: PropTypes.string.isRequired,
  featuredCard: PropTypes.shape({}).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  TXUBadge: PropTypes.string.isRequired
};

export default TXUFeaturedCardVariant;
