import React from 'react';
import SplashZipSearch from './SplashZipSearch';
import SplashTrustPilot from './SplashTrustPilot';

const SplashHero = (props) => {
  const {
    zipCode,
  } = props;

  return (
    <div className="splash-hero-container">
      <div className="splash-content-wrapper">
        <div className="hero-content">
          <h1 className="hero-content__heading">Compare rates and start saving</h1>
          <p className="hero-content__subheading">Enter your ZIP code to explore electricity plans in your area</p>
          <SplashZipSearch
            zipCode={zipCode}
          />
        </div>
        <SplashTrustPilot />
      </div>
    </div>
  );
};

export default SplashHero;
