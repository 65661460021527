/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from 'react';

import { devEnv, prodEnv } from '../../js/config/config';

const SolarLeadForm = () => {
  const elfLoaded = useRef(false);
  const initialRequestTime = useRef();
  const metricEventFired = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const scriptId = document.getElementById('elf-script');

      const env = process.env.APP_ENV;
      const envScripts = env === 'production' ? prodEnv : devEnv;

      if (!scriptId) {
        const script = document.createElement('script');
        script.src = envScripts.ELF_URL || '';
        script.id = 'elf-script';
        document.body.appendChild(script);
      }

      const elfInterval = setInterval(() => {
        if (
          window.ELF_LOADED
          && elfLoaded.current
          && window._Cohesion.ready
          && window._Cohesion.instanceId !== undefined
        ) {
          clearInterval(elfInterval);
        }

        if (
          window.ELF_LOADED
          && !elfLoaded.current
          && window._Cohesion.ready
          && window._Cohesion.instanceId !== undefined
        ) {
          const encodedUrl = encodeURIComponent(window.location.href);
          const parentInstanceId = window._Cohesion?.instanceId;

          window.postMessage(
            {
              triggerId: parentInstanceId,
              name: 'SHOW_EMBEDDED_IN_PAGE_FORM',
              formUrl: `${envScripts.BRUNO_URL}&lead_src=${encodedUrl}&parentInstanceId=${parentInstanceId}`,
              context: {},
            },
            '*',
          );

          elfLoaded.current = true;
          clearInterval(elfInterval);
        }
      }, 100);

      window.addEventListener(
        'message',
        (e) => {
          const message = e.data;

          const widget = document.getElementById('elf-iframe');

          // APP READY is bruno being ready - not elf
          if (message.type === 'solar-resize' && widget !== null) {
            widget.setAttribute('height', e.data.height);
          }

          if (message.name === 'APP_READY') {
            const metricInterval = setInterval(() => {
              if (initialRequestTime.current !== null && !metricEventFired.current) {
                metricEventFired.current = true;
                clearInterval(metricInterval);
              }
            }, 50);
          }

          if (message.name === 'urlRedirect') {
            window.location.href = message.url;
          }
        },
        false,
      );
    }
  }, []);

  return (
    <div
      className="unserve-solar__slf"
      id="elf-mounting-node"
    />
  );
};

export default SolarLeadForm;
