import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePreamp } from '../../../preamp';
import { useMonarch } from '../../../monarch';

import buildClassList from '../../../../js/project/buildClassList';

const HomeInput = ({
  errorMessage,
  hasError,
  measurement,
  onChange,
  onSubmit,
  onBlur,
  placeholder,
  value,
  filters,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const { rules } = useMonarch();
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;
  const estimatedKWHUsage = Number(localStorage.getItem('estimatedKWHUsage'));
  const prefillKwh = estimatedKWHUsage === filters.usage;

  const wrapperClassList = buildClassList(
    'grid-v2-home-input__wrapper',
    isFocused ? 'grid-v2-home-input__wrapper--focused' : '',
    hasError ? 'grid-v2-home-input__wrapper--error' : '',
    (coreLogicPreamp || monarchCoreLogic) && prefillKwh ? 'grid-v2__prefill-kwh-input' : '',
  );

  const handleBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  return (
    <form
      className="grid-v2-home-input"
      onSubmit={onSubmit}
    >
      <div className={wrapperClassList}>
        <div className="grid-v2-home-input__type">Your home</div>
        <div className="grid-v2-home-input__container">
          <input
            className="grid-v2-home-input__input"
            type="number"
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onChange={onChange}
            value={value}
          />
          {measurement}
        </div>
      </div>
      {hasError && <div className="grid-v2-home-input__error">{errorMessage}</div>}
    </form>
  );
};

export default HomeInput;

HomeInput.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  measurement: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  filters: PropTypes.shape({}).isRequired,
};

HomeInput.defaultProps = {
  errorMessage: 'Please enter a numbered amount.',
  hasError: false,
  placeholder: 'Please enter usage'
};
