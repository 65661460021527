import React from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';

import starIcon from '../../images/icon-star.svg';
import infoSoeIcon from '../../images/icon-info-soe.svg';

const icons = {
  [Sites.SOE]: {
    src: infoSoeIcon,
    alt: 'Info Icon'
  },
  default: {
    src: starIcon,
    alt: 'Star Icon'
  }
};

function RateBanner({ description, site }) {
  const { src, alt } = icons[site] || icons.default;

  return (
    <section className="rate-banner">
      <img src={src} alt={alt} className="rate-banner__icon" />
      <p className="rate-banner__text">{description}</p>
    </section>
  );
}

export default RateBanner;

RateBanner.propType = {
  site: PropTypes.oneOf(Object.values(Sites)),
  description: PropTypes.string
};

RateBanner.defaultProps = {
  description: "We don't like hidden fees. Our rates include all monthly supplier and utility charges, except taxes.",
};
