import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import iSvg from '../../svg/i.svg';
import FuseNumbers from '../FuseNumbers';

smoothscroll.polyfill();

const beam = (eventName, data) => {
  if (window.tagular) {
    window.tagular('beam', eventName, {
      '@type': `redventures.usertracking.v3.${eventName}`,
      ...data,
    });
  }
};

const tagularBeam = () => {
  const payload = {
    webElement: {
      location: 'Grid',
      elementType: 'Link',
      text: 'FAQ'
    }
  };
  beam('ElementClicked', payload);
};

function FaqsBanner(props) {
  const {
    currentIndex,
    insertionIndex,
    active,
    position,
    site
  } = props;

  const middle = position === 'middle';
  const bottom = position === 'bottom';

  function scrollToBottom() {
    tagularBeam();
    window.location.href = '#accordion__anchor';
  }

  if (!active || currentIndex !== insertionIndex) {
    return null;
  }

  return (
    <div className={bottom ? 'faqs-banner bottom' : 'faqs-banner'}>
      <img
        src={iSvg}
        alt="Info"
        className="svg"
      />
      {middle && (
        <span className="faq-banner__copy">
          Have a question about enrolling?&nbsp;
          <button
            className="faq-banner__scroll-link"
            onClick={() => scrollToBottom()}
            type="button"
          >
            Check out our FAQs
          </button>
        </span>
      )}
      {bottom && (
        <span className="faq-banner__copy">
         Have other questions? Contact one of our experts at&nbsp;
          <FuseNumbers site={site} faqBanner />
        </span>
      )}
    </div>
  );
}

export default FaqsBanner;
