import React from 'react';

import beam from '../../js/project/tagular/beam';
import AttentionBanner from './AttentionBanner';

const HmcButton = (props) => {
  const {
    modalOpen,
    modalHandler,
    screenWidth,
  } = props;

  const hmcButtonClickHandler = () => {
    const buttonClickedPayload = {
      webContext: {
        sessionId: window?._Cohesion.sessionId,
      },
      webElement: {
        location: 'Grid',
        elementType: 'Button',
        text: 'Help me choose'
      },
      actionOutcome: 'Help me choose opened'
    };
    beam.userTrackingV3('ElementClicked', buttonClickedPayload);

    const hmcStartedPayload = {
      webContext: {
        sessionId: window?._Cohesion.sessionId,
      },
      formContext: {
        formName: 'Help Me Choose',
        formType: 'Questionnaire',
      },
      stepContext: {
        stepName: 'Enter Form',
        stepNumber: 0,
      }
    };
    beam.userTrackingV3('FormStarted', hmcStartedPayload);

    modalHandler(!modalOpen);
  };

  const bannerClosedPayload = {
    webContext: {
      sessionId: window?._Cohesion.sessionId,
    },
    webElement: {
      location: 'Grid',
    },
    actionOutcome: 'Closed Help Me Choose TT'
  };

  return (
    <div className={`hmc-wrapper-inner hmc-wrapper-inner${screenWidth}`}>
      <div className="hmc-button-container">
        <button
          type="button"
          onClick={hmcButtonClickHandler}
          className="hmc-button"
        >
        Help me choose
        </button>
      </div>
      <AttentionBanner
        bannerClosedPayload={bannerClosedPayload}
      />
    </div>
  );
};

export default HmcButton;
