/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { FeaturedCardLabels } from '@rvpower/constants';
import { useMonarch } from '../monarch';

// SVGs
import apartmentIcon from '../../svg/featuredCardLabelIcons/apartmentIcon.svg';
import smallHouseIcon from '../../svg/featuredCardLabelIcons/smallHouseIcon.svg';
import largeHouseIcon from '../../svg/featuredCardLabelIcons/largeHouseIcon.svg';
import creditIcon from '../../svg/featuredCardLabelIcons/creditIcon.svg';
import billCreditsIcon from '../../svg/featuredCardLabelIcons/billCreditsIcon.svg';
import freenightsIcon from '../../svg/featuredCardLabelIcons/freeNightsIcon.svg';
import freeweekendsIcon from '../../svg/featuredCardLabelIcons/freeWeekendsIcon.svg';
import greatvalueIcon from '../../svg/featuredCardLabelIcons/trophyIcon.svg';
import moverSpecial from '../../svg/featuredCardLabelIcons/moverSpecialIcon.svg';
import nodepositIcon from '../../svg/featuredCardLabelIcons/noDepositIcon.svg';
import nosuprisesIcon from '../../svg/featuredCardLabelIcons/noSurprisesIcon.svg';
import earthIcon from '../../svg/featuredCardLabelIcons/earthIcon.svg';
import seasonalIcon from '../../svg/featuredCardLabelIcons/snowflakeIcon.svg';
import instantApproval from '../../svg/featuredCardLabelIcons/instantApprovalIcon.svg';
import highCustomerSatisfaction from '../../svg/featuredCardLabelIcons/highCustomerSatisfactionIcon.svg';
import popularProvider from '../../svg/featuredCardLabelIcons/popularProviderIcon.svg';
import lowEnergyCharge from '../../svg/featuredCardLabelIcons/lowEnergyChargeIcon.svg';
import popularPlan from '../../svg/featuredCardLabelIcons/popularPlanIcon.svg';
import competitiveRate from '../../svg/featuredCardLabelIcons/competitiveRateIcon.svg';
import acProtection from '../../svg/featuredCardLabelIcons/ACProtectionIcon.svg';
import flatEnergyRate from '../../svg/featuredCardLabelIcons/flatEnergyRateIcon.svg';
import longerPriceGuarantee from '../../svg/featuredCardLabelIcons/longerPriceGuaranteeIcon.svg';
import longerPriceSecurity from '../../svg/featuredCardLabelIcons/longerPriceSecurityIcon.svg';
import noGimmicks from '../../svg/featuredCardLabelIcons/noGimmicksIcon.svg';
import renewableLeaf from '../../svg/featuredCardLabelIcons/greenEnergyLeafIcon.svg';
import renewableRecycle from '../../svg/featuredCardLabelIcons/renewableRecycleIcon.svg';
import popularForMoving from '../../svg/featuredCardLabelIcons/popularForMovingIcon.svg';
import popularForSwitching from '../../svg/featuredCardLabelIcons/popularForSwitchingIcon.svg';
import workFromHome from '../../svg/featuredCardLabelIcons/wfhIcon.svg';
import automaticSavings from '../../svg/featuredCardLabelIcons/automaticSavingsIcon.svg';
import onlineRewards from '../../svg/featuredCardLabelIcons/onlineRewardsIcon.svg';
import cashBack from '../../svg/featuredCardLabelIcons/cashBackIcon.svg';
import visaGiftCard from '../../svg/featuredCardLabelIcons/visaGiftCardIcon.svg';
import smartHomeEquipmentIcon from '../../svg/featuredCardLabelIcons/bluetoothIcon.svg';
import exclusiveOfferIcon from '../../svg/featuredCardLabelIcons/exclusiveOfferIcon.svg';
import alarm from '../../svg/featuredCardLabelIcons/alarm.svg';
import electricBolt from '../../svg/featuredCardLabelIcons/electricBolt.svg';
import localFireDepartment from '../../svg/featuredCardLabelIcons/localFireDepartment.svg';

function FeaturedCardLabel(props) {
  const { isNTX, plan, index } = props;
  const { labelId } = plan;

  const { rules } = useMonarch();

  // Override labels from Monarch
  const monarchOverrideLabels = isNTX
    ? rules?.source?.ntxGridFeaturedConfig?.value?.data?.planLabelsOverride
    : rules?.source?.gridFeaturedConfig?.value?.data?.planLabelsOverride;

  // Override labels for all TX utilities
  const allTxUtilities = monarchOverrideLabels?.allTxUtilities;
  const allTxLabels = [allTxUtilities?.card1Label, allTxUtilities?.card2Label, allTxUtilities?.card3Label];

  // Override labels for all NTX utilities
  const allNtxUtilities = monarchOverrideLabels?.allNtxUtilities;
  const allNtxLabels = [allNtxUtilities?.card1Label, allNtxUtilities?.card2Label, allNtxUtilities?.card3Label];

  // Select labels based on TX or NTX
  const allUtilityLabels = isNTX ? allNtxLabels : allTxLabels;

  // Get utility specific labels
  // These will take precedence over the allTxUtilities and allNtxUtilities
  const planUtilityId = plan.utility.id;
  const monarchUtility = monarchOverrideLabels?.utilities[planUtilityId];
  const singleUtilityLabels = [monarchUtility?.card1Label, monarchUtility?.card2Label, monarchUtility?.card3Label];

  const gridSvgOptions = {
    1: apartmentIcon,
    2: smallHouseIcon,
    3: largeHouseIcon,
    4: creditIcon,
    5: nodepositIcon,
    6: earthIcon,
    7: popularPlan,
    8: greatvalueIcon,
    9: nosuprisesIcon,
    10: freenightsIcon,
    11: freeweekendsIcon,
    12: billCreditsIcon,
    13: seasonalIcon,
    14: moverSpecial,
    15: freeweekendsIcon,
    16: automaticSavings,
    17: onlineRewards,
    18: cashBack,
    19: visaGiftCard,
    20: cashBack,
    21: smartHomeEquipmentIcon,
    22: workFromHome,
    23: instantApproval,
    24: highCustomerSatisfaction,
    25: popularProvider,
    26: lowEnergyCharge,
    27: popularPlan,
    28: renewableLeaf,
    29: competitiveRate,
    30: acProtection,
    31: flatEnergyRate,
    32: longerPriceGuarantee,
    33: longerPriceSecurity,
    34: noGimmicks,
    35: renewableLeaf,
    36: renewableRecycle,
    37: popularForMoving,
    38: popularForSwitching,
    39: exclusiveOfferIcon,
    40: exclusiveOfferIcon,
    45: alarm,
    46: alarm,
    47: electricBolt,
    48: electricBolt,
    49: localFireDepartment,
    50: localFireDepartment,
  };

  let labelIcon = nosuprisesIcon;
  let labelText = FeaturedCardLabels[8].label;
  const exclusiveClass = labelId === 40 ? 'featured-plan-card-v2__exclusive' : '';
  const gradientClass = labelId === 46 || labelId === 48 || labelId === 50 ? 'featured-plan-card-v2--gradient' : '';
  // Adding this because there is filter on the standard lable that is making them look a grayish white color
  // In order to make sure the colors of the new icons added are not affected by the filter, we are adding this class
  // eslint-disable-next-line max-len
  const overrideFilter = labelId === 45 || labelId === 47 || labelId === 49 ? 'featured-plan-card-v2--override-filter' : '';

  if (labelId && labelId !== '') {
    labelIcon = gridSvgOptions[labelId];
    labelText = FeaturedCardLabels[labelId - 1].label;
  }

  // Overriding labelText to allow us to use different label names
  if (labelId === 35 || labelId === 36) {
    labelText = '100% Renewable';
  }

  if (labelId === 39 || labelId === 40) {
    labelText = 'Exclusive plan';
  }

  const FCBaseClass = 'featured-plan-card-v2-vertical';
  const ntxLabelClass = isNTX ? 'ntx-featured-plan-card__label' : '';
  const ntxIconClass = isNTX ? 'ntx-featured-plan-card__icon' : '';
  const ntxTextClass = isNTX ? 'ntx-featured-plan-card__text' : '';

  return (
    <div
      className={`${FCBaseClass}-label ${exclusiveClass} ${gradientClass} ${overrideFilter} ${ntxLabelClass}`}
    >
      <img
        className={`${FCBaseClass}-label__icon ${ntxIconClass}`}
        src={labelIcon}
        alt={labelText}
      />
      <span className={`${FCBaseClass}-label__text ${ntxTextClass}`}>
        {singleUtilityLabels?.[index] || allUtilityLabels?.[index] || labelText}
      </span>
    </div>
  );
}

export default FeaturedCardLabel;

FeaturedCardLabel.propTypes = {
  plan: PropTypes.shape().isRequired,
};
