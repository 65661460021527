/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import tracking from '../../js/project/tagular/tracking';
import solarPanels from '../../svg/solar-panels.svg';
import arcadiaLogo from '../../svg/arcadia-logo.svg';
import VisibleTracker from '../VisibleTracker';

const CommunitySolarBanner = ({
  title,
  subtitle,
  ctaText,
}) => {
  // eslint-disable-next-line max-len
  const ctaLink = 'https://www.arcadia.com/community-solar/lp-chooseenergy?utm_source=chooseenergy&utm_medium=marketplace&promo=chooseenergy0';

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID - Community Solar Banner',
        elementType: 'Button',
        text: ctaText,
      },
      actionOutcome: 'Navigate to Arcadia landing page'
    });
  };

  const elementViewedPayload = {
    webElement: {
      elementType: 'Community Solar Banner',
      location: 'NTX Grid',
    }
  };

  return (
    <VisibleTracker onVisible={() => tracking.elementViewed(elementViewedPayload)}>
      <div className="community_solar_banner">
        <div className="community_solar_banner__container">
          <div className="community_solar_banner__content">
            <div className="community_solar_banner__content-wrapper">
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
              <p dangerouslySetInnerHTML={{ __html: subtitle }} />
            </div>
            <div className="community_solar_banner__cta-wrapper">
              <div className="community_solar_banner__cta-inner-wrapper">
                <a
                  id="solar-cta-button"
                  href={ctaLink}
                  onClick={handleClick}
                  target="_blank"
                >
                  {ctaText}
                </a>
                <div className="community_solar_banner__logo-wrapper">
                  <p>with&nbsp;</p>
                  <img
                    alt="Arcadia logo"
                    className="community_solar_banner__partner-logo"
                    src={arcadiaLogo}
                  />
                </div>
              </div>
              <img
                alt="Solar panels"
                src={solarPanels}
              />
            </div>
          </div>
        </div>
      </div>
    </VisibleTracker>
  );
};

export default CommunitySolarBanner;
