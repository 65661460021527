import React from 'react';
import PropTypes from 'prop-types';

import buildClassList from '../../../js/project/buildClassList';

import Close from '../../../svg/close.svg';
import ClosePill from '../../../svg/close-pill.svg';

const CloseIcon = ({ className, type }) => {
  const classList = buildClassList('grid-v2-pills__pill-icon-close', className);

  return (
    <img
      className={classList}
      src={type === 'close-pill' ? ClosePill : Close}
      alt="Remove Filter Menu Item"
    />
  );
};

export default CloseIcon;

CloseIcon.propTypes = {
  className: PropTypes.string
};

CloseIcon.defaultProps = {
  className: ''
};
