import React from 'react';
import { Sites } from '@rvpower/constants';

// components
import UnserveHero from './UnserveHero';
import SolarLeadForm from './SolarLeadForm';
import EmailCapture from './EmailCapture';
import UnserveSmartTech from './UnserveSmartTech';

// images
import soeSolarBackground from '../../../images/unserve/soe-slf-bg.svg';
import chooseSolarBackground from '../../../images/unserve/choose-slf-bg.svg';
import smallSprinkles from '../../../images/unserve/sprinkles-small.svg';
import mediumSprinkles from '../../../images/unserve/sprinkles-medium.svg';

const UnservePage = (props) => {
  const {
    zipCode,
    site,
    plansCount
  } = props;

  const isSOE = site === Sites.SOE;

  const backgroundImages = [
    {
      site: 'saveonenergy.com',
      src: soeSolarBackground,
      alt: 'Save On Energy Solar Background',
      className: 'soe-background',
    },
    {
      site: 'chooseenergy.com',
      src: chooseSolarBackground,
      alt: 'Choose Energy Solar Background',
      className: 'choose-background',
    }
  ];

  const siteSolarBackground = backgroundImages.filter((image) => image.site === site);

  return (
    <div className="unserve">
      <div className="unserve-hero-slf">
        <UnserveHero
          zipCode={zipCode}
          site={site}
          plansCount={plansCount}
        />
        <div id="slf" className="unserve-solar">
          <div className="unserve-solar__wrapper">
            {siteSolarBackground.map((image) => (
              <img
                key={image.alt}
                src={image.src}
                alt={image.alt}
                className={`unserve-solar__${image.className}`}
              />
            ))}
            <SolarLeadForm />
          </div>
          {isSOE && (
          <div className="unserve-solar__sprinkles-wrapper">
            <img src={smallSprinkles} alt="sprinkles" className="unserve-solar__small-sprinkles" />
            <img src={mediumSprinkles} alt="sprinkles" className="unserve-solar__medium-sprinkles" />
          </div>
          )}
        </div>
      </div>
      <UnserveSmartTech
        site={site}
      />
      {isSOE && (<EmailCapture />)}
    </div>
  );
};

export default UnservePage;
