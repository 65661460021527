import React from 'react';
import Tippy from '@tippy.js/react';
import { Sites } from '@rvpower/constants';
import URLSearchParams from '@ungap/url-search-params';
import productDisclosure from '../js/project/product-disclaimers';
import getPerMonthVerbage from '../js/project/termination-fee';

// React Components
import CardDetailsTable from './CardDetailsTable';
import PDFLinks from './PDFLinks';

// SVGs
import infoBubble from '../svg/info-bubble.svg';

function CardDetails(props) {
  const {
    plan,
    site,
    electricPhoenixActive,
    incentiveActive
  } = props;

  const {
    description,
    secondaryDescription,
    fee,
    name
  } = plan;

  const perMonthVerbage = getPerMonthVerbage(fee?.monthly);
  const planDisclosure = productDisclosure(name);

  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;
  const isTXU = site === Sites.TXU;
  const isSOE = site === Sites.SOE;

  const mobileDescription = (isTXU && secondaryDescription) ? secondaryDescription : description;

  return (
    <div className="card-details u-display-flex@lg u-flex-wrap-wrap@lg">
      {mobileDescription && (
        <div
          className="description description--secondary"
          dangerouslySetInnerHTML={{ __html: mobileDescription }}
        />
      )}
      {/* eslint-disable-next-line max-len */}
      <div className="card__section card__section--docs card__section--inner u-display-flex u-justify-content-space-between">
        <div className="documents u-flex-1">
          <strong>Plan documents</strong>
          <Tippy
            content="EFL: Electricity Facts Label TOS: Terms of Service YRAC: Your Rights as a Customer"
            className={isSOE ? 'disclaimer' : 'document'}
            arrow
            placement="top-start"
          >
            <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
          </Tippy>
          <ul className="documents__list u-display-flex u-flex-direction-column u-justify-content-space-between">
            <PDFLinks plan={plan} />
          </ul>
        </div>
        {fee
          && (
            <div
              className={`fee u-text-align-right u-flex-1 u-display-none@lg
              ${isNTX ? 'fee--ntx' : ''}`}
            >
              <div className="fee__amount">
                <strong>{`Early termination fee${perMonthVerbage}: $${fee.amount}`}</strong>
              </div>
              <div>
                (Note: This fee will
                <strong> not be charged </strong>
                if you end your contract early because you are moving out.)
              </div>
            </div>
          )}
      </div>
      {isTXU && planDisclosure
            && (
              <div
                className="description--disclosure-html--mobile card__section card__section--docs card__section--inner"
                dangerouslySetInnerHTML={{ __html: planDisclosure }}
              />
            )}
      {!isNTX && (
        <div
          className={`card__section card__section--inner card__section--table u-flex-1@lg
          ${isTXU ? '' : 'mobile-term-text-open'}`}
        >
          <CardDetailsTable
            plan={plan}
            site={site}
            electricPhoenixActive={electricPhoenixActive}
          />
        </div>
      )}
      <div className="card__section card__section--inner card__section--inner-text">
        {description
          && (
            <div className="description u-display-none u-display-block@lg">
              {description}
            </div>
          )}
        {fee
          && (
            <div className="fee u-display-none u-display-block@lg">
              <div className="fee__amount">
                <strong>{`Early termination fee${perMonthVerbage}: $${fee.amount}`}</strong>
              </div>
              <div>
                (Note: This fee will
                <strong> not be charged </strong>
                if you end your contract early because you are moving out.)
              </div>
            </div>
          )}
        {isTXU && planDisclosure
          && (
            <div
              className="description--disclosure-html"
              dangerouslySetInnerHTML={{ __html: planDisclosure }}
            />
          )}
      </div>
      {isTXU && incentiveActive && (
      <div className="card__section card__section--incentive-disclaimer">
        <div className="incentive__disclaimer">
          {/* eslint-disable-next-line max-len */}
          * Redeem your bonus at the website or phone number within 90 days of the first day of electricity service on your new plan with TXU Energy. Once redeemed, you’ll receive your prepaid Mastercard within 2 – 4 weeks after your first bill on your new plan is paid in full. Your account must be in good standing and active on this plan at that time. Mastercard® prepaid card is valid for six months after issuance.
        </div>
      </div>
      )}
    </div>
  );
}

export default CardDetails;
