import { Sites } from '@rvpower/constants';
import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// SVGs
import apartmentIcon from '../svg/apartment.svg';
import smallHouseIcon from '../svg/small-house.svg';
import largeHouseIcon from '../svg/large-house.svg';

import apartmentIconSoe from '../svg/apartment-soe.svg';
import smallHouseIconSoe from '../svg/small-house-soe.svg';
import largeHouseIconSoe from '../svg/large-house-soe.svg';

import apartmentIconTxu from '../svg/apartment-txu.svg';
import smallHouseIconTxu from '../svg/small-house-txu.svg';
import largeHouseIconTxu from '../svg/large-house-txu.svg';

const iconPaths = {
  [Sites.SOE]: {
    apartment: apartmentIconSoe,
    smallHouse: smallHouseIconSoe,
    largeHouse: largeHouseIconSoe
  },
  [Sites.TXU]: {
    apartment: apartmentIconTxu,
    smallHouse: smallHouseIconTxu,
    largeHouse: largeHouseIconTxu
  },
  default: {
    apartment: apartmentIcon,
    smallHouse: smallHouseIcon,
    largeHouse: largeHouseIcon
  }
};

const valueToUsage = { 1: 500, 2: 1000, 3: 2000 };
const usageToValue = {};
Object.keys(valueToUsage).forEach((k) => { usageToValue[valueToUsage[k]] = parseInt(k, 10); });

const UsageInfo = ({
  className, iconClassName, iconPath, title, kwhDescription
}) => (
  <div className={className}>
    <img className={`slider__image svg ${iconClassName}`} src={iconPath} alt={title} />
    <span>{title}</span>
    <strong>{kwhDescription}</strong>
  </div>
);

function Usage({
  site, filters, setFilters, scrollToTopOfList = () => {}
}) {
  const { usage } = filters;

  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(usageToValue[usage]);
  }, [usage]);

  const minValue = 1;
  const maxValue = 3;

  const updateValue = (filterValue) => {
    setValue(filterValue);
  };

  const updateFilter = (filterValue) => {
    scrollToTopOfList();
    setFilters({
      ...filters,
      usage: valueToUsage[filterValue]
    });
  };

  const sliderItemClasses = `slider__label
                             u-flex-direction-column
                             u-align-items-center
                             u-display-flex
                             u-justify-content-flex-end`;

  const iconPath = iconPaths?.[site] || iconPaths?.default;

  return (
    <div className="slider">
      <div className="slider__description">
        Shop price based on your home size and usage
      </div>
      <div className="slider__container">
        <UsageInfo
          title="Apartment"
          iconClassName="svg--apartment"
          kwh="500 kWh"
          iconPath={iconPath?.apartment}
          className={
            `${sliderItemClasses} ${value === 1 ? 'u-display-flex' : 'u-display-none'}`
          }
        />
        <UsageInfo
          title="Small House"
          iconClassName="svg--small-house"
          kwh="1000 kWh"
          iconPath={iconPath?.smallHouse}
          className={
            `${sliderItemClasses} ${value === 2 ? 'u-display-flex' : 'u-display-none'}`
          }
        />
        <UsageInfo
          title="Large House"
          iconClassName="svg--large-house"
          kwh="2000 kWh"
          iconPath={iconPath?.largeHouse}
          className={
            `${sliderItemClasses} ${value === 3 ? 'u-display-flex' : 'u-display-none'}`
          }
        />
        <Slider
          dots
          min={minValue}
          max={maxValue}
          value={value}
          onChange={updateValue}
          onAfterChange={updateFilter}
          className="slider__size"
          ariaLabelForHandle="home size and usage slider"
        />
      </div>
    </div>
  );
}

export default Usage;
