import { termTextToType } from '@rvpower/constants';

const params = new URLSearchParams(window.location.search);
const isNTX = params.get('isNTX') === 'true';

const supplierOrder = [
  'Payless Power',
  'Reliant',
  'Green Mountain',
  'Cirro',
  'Discount Power',
  'First Choice Power',
  'Express Energy',
  'Pulse Power',
  'TriEagle Energy',
  'TXU Energy',
  'Frontier Utilities',
  '4Change Energy',
  'Gexa Energy',
  'Direct Energy',
  'Constellation',
];

const termOrder = {
  Prepaid: -1,
  MonthToMonth: 1
};

const compareTerms = (a, b) => {
  const aLength = (termOrder[a.type] || a.length);
  const bLength = (termOrder[b.type] || b.length);

  return aLength - bLength;
};

const compareTermText = (a, b) => compareTerms(termTextToType(a), termTextToType(b));

const compareRatings = (a, b) => {
  const aRating = a.supplier.ratings ? a.supplier.ratings.overallScore : 0;
  const bRating = b.supplier.ratings ? b.supplier.ratings.overallScore : 0;

  return bRating - aRating;
};

const compareSuppliers = (a, b) => {
  const aSupplier = supplierOrder.indexOf(a.supplier.name);
  const bSupplier = supplierOrder.indexOf(b.supplier.name);

  return bSupplier - aSupplier;
};

const comparePrice = (a, b) => {
  if (isNTX) {
    const aEnergyCharge = a.rates.find((p) => p.type === 'EnergyCharge');
    const bEnergyCharge = b.rates.find((p) => p.type === 'EnergyCharge');

    return aEnergyCharge.price - bEnergyCharge.price;
  }

  return a.price - b.price;
};

const sortingFunctions = {
  'rate-low': (a, b) => comparePrice(a, b) || compareRatings(a, b) || compareSuppliers(a, b),
  'rate-high': (a, b) => comparePrice(b, a) || compareRatings(a, b) || compareSuppliers(a, b),
  'terms-longer': (a, b) => compareTerms(b.term, a.term) || a.price - b.price || compareSuppliers(a, b),
  'terms-shorter': (a, b) => compareTerms(a.term, b.term) || a.price - b.price || compareSuppliers(a, b),
  'provider-a': (a, b) => a.supplier.name.localeCompare(b.supplier.name) || a.price - b.price,
  'provider-z': (a, b) => b.supplier.name.localeCompare(a.supplier.name) || a.price - b.price,
  'bill-low': (a, b) => a.electricPhoenixData.averageCost - b.electricPhoenixData.averageCost,
  'bill-high': (a, b) => b.electricPhoenixData.averageCost - a.electricPhoenixData.averageCost,
  // SOE New Grid Methods, these are different because they're factoring
  'monthly-bill-low': (a, b) => a.estimate - b.estimate,
  'monthly-bill-high': (a, b) => b.estimate - a.estimate,
};

const usageMapping = {
  2000: 'TwoThousandKwh',
  1000: 'OneThousandKwh',
  500: 'FiveHundredKwh'
};

export {
  sortingFunctions, compareTermText, usageMapping
};
