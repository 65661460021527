import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';
import { usePreamp } from './preamp';
import SvgPhone from './SvgPhone';
import TxuPhoneCTAs from './txu-phone-ctas/TxuPhoneCTAs';
import buildClassList from '../js/project/buildClassList';

export const FuseContext = createContext({});

const formatConfig = {
  [Sites.CTXP]: '1-###-###-####',
  [Sites.NEW_CTXP]: '1-###-###-####',
  [Sites.NEW_TXER]: '1(###) ###-####',
  [Sites.CHOOSE]: '1 (###) ###-####',
  [Sites.TXU]: '###-###-####',
  [Sites.SOE]: '1-###-###-####',
  default: '1-###-###-####'
};

const phoneColorConfig = {
  [Sites.CTXP]: '#ffffff',
  [Sites.NEW_CTXP]: '#ffffff',
  [Sites.TXER]: '#ffffff',
  [Sites.NEW_TXER]: '#ffffff',
  [Sites.CHOOSE]: '#2b2b2b',
  [Sites.SOE]: '#ffffff',
  [Sites.TXU]: '#ffffff'
};

const preampFuseDesktopCopy = {
  'Grid Desktop - Call + Phone': 'Call:',
  'Desktop Grid - Call': 'Call:',
  'Grid Desktop - Call Now + Phone': 'Call Now:',
  'Desktop Grid - Call Now': 'Call Now:',
  'Grid Desktop - Call Us + Phone': 'Call Us:',
  'Desktop Grid - Call Us': 'Call Us:',
  'Grid Desktop - Expert Help + Phone': 'Expert Help:',
  'Desktop Grid - Expert Help': 'Expert Help:',
  'Grid Desktop - Talk To Us + Phone': 'Talk To Us:',
  'Desktop Grid - Talk To Us': 'Talk To Us:'
};

const fcSimplifiedCopy = 'Call to order';

const txuPhone = '844-359-4993';

const formatPhoneNumber = (number, site, ignoreSites = [Sites.TXU]) => {
  const cfg = formatConfig[site];

  if (!cfg) {
    return number;
  }

  const numberModified = (ignoreSites.includes(site)) ? number : number.substring(1);
  const slashesRemoved = numberModified.replace(/-/g, '');
  const format = Array.from(cfg);
  const numberArray = Array.from(slashesRemoved);

  format.forEach((digit, i) => {
    if (digit === '#') {
      [format[i]] = numberArray;
      numberArray.shift();
    }
  });
  const newString = format.toString();
  return newString.replace(/,/g, '');
};

export function FuseProvider(props) {
  const {
    children, fuseData, isOpen, isTxu, disablePhoneNumbers
  } = props;

  const context = {
    fuseData, isOpen, isTxu, disablePhoneNumbers
  };

  return (
    <FuseContext.Provider value={context}>
      {children}
    </FuseContext.Provider>
  );
}

export default function FuseNumbers(props) {
  const {
    className,
    faqBanner,
    featuredCard,
    phoneColor,
    site,
    newGridEnabled,
    newGridSimplified,
    showCartCTA = false,
  } = props;

  const {
    fuseData, isOpen, isTxu, disablePhoneNumbers
  } = useContext(FuseContext);
  const phoneMobile = usePreamp('Grid - Mobile Phone CTA');
  const phoneDesktop = usePreamp('Grid - Desktop Phone CTA');
  const txuPhoneCTA = usePreamp('Grid - Phone CTA');

  const { dataFuseName, permalease } = featuredCard && fuseData.featuredCardsFuseData
    ? fuseData.featuredCardsFuseData
    : fuseData;

  const isCHOOSE = site === Sites.CHOOSE;

  const dataFuseFormat = formatConfig[site] || formatConfig.default;
  const formattedPermalease = formatPhoneNumber(permalease, site);
  const OptionTwoCTA = txuPhoneCTA === 'Option 2 - Grid phone CTA';

  const phoneCopyDesktop = preampFuseDesktopCopy[phoneDesktop];
  const phoneCopyMobile = phoneMobile && `${phoneMobile.split('Mobile Grid - ')[1]}`;
  const phoneAndIcon = phoneCopyMobile === 'Icon + Phone' || phoneCopyMobile === 'Icon + Number';

  if (newGridEnabled) {
    formatConfig[Sites.SOE] = '(###) ###-####';
    if (showCartCTA) {
      phoneColorConfig[Sites.SOE] = '#0071cd';
    }
  }

  const mobilePhoneClassName = buildClassList(
    'phone',
    newGridEnabled && 'u-display-none@lg',
    !newGridEnabled && 'u-display-none@xl',
    (newGridEnabled && !phoneMobile) && 'phone--with-icon',
    phoneMobile && !isCHOOSE && 'svg-phone--mobile-copy',
  );

  const mobilePhoneWithPreampClassName = buildClassList(
    'phone-copy-mobile',
    newGridEnabled && 'phone--with-icon phone--mobile-click-through',
  );

  const desktopPhoneClassName = buildClassList(
    'phone u-display-none',
    newGridEnabled && 'u-display-flex@lg phone--with-icon',
    !newGridEnabled && 'u-display-block@xl',
  );

  useEffect(() => {
    if (window.fuse && isOpen) {
      window.fuse('placeNumbers');
    }
  }, [isOpen]);

  if ((!isOpen || disablePhoneNumbers) && !isTxu) {
    return null;
  }

  const makeACall = () => {
    window.location = `tel:${permalease}`;
  };

  // This is just for choose
  if (isCHOOSE) {
    return (
      <>
        <button
          type="button"
          onClick={makeACall}
          className={className}
        >
          <span
            className={`${OptionTwoCTA ? '' : 'u-display-none u-display-block@lg'}
            ${phoneDesktop ? ' svg-phone--copy' : ''}`}
          >
            <SvgPhone
              site={site}
              Sites={Sites}
              phoneColor={phoneColor}
            />
          </span>

          <a
            // eslint-disable-next-line max-len
            className={mobilePhoneClassName}
            href={`tel:${permalease}`}
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {formattedPermalease}
          </a>

          <span
            className={desktopPhoneClassName}
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {formattedPermalease}
          </span>
        </button>
      </>
    );
  }

  return (
    <>
      {faqBanner ? (
        <a
          className="phone"
          href={`tel:${permalease}`}
          data-fuse
          data-fuse-format={dataFuseFormat}
          data-fuse-name={dataFuseName}
        >
          {formattedPermalease}
        </a>
      ) : (
        <button type="submit" className={className}>
          <span
            className={`${OptionTwoCTA ? '' : 'u-display-none u-display-block@lg'}
            ${phoneDesktop ? ' svg-phone--copy' : ''}`}
          >
            {phoneCopyDesktop
              || (
                <SvgPhone
                  site={site}
                  Sites={Sites}
                  phoneColor={phoneColor}
                />
              )}
          </span>
          {isTxu && disablePhoneNumbers ? (
            <>
              <a className="phone u-display-none@xl" href={`tel:${txuPhone}`}>
                {txuPhone}
              </a>
              <span className="phone u-display-none u-display-block@xl">
                {txuPhone}
              </span>
            </>
          ) : (
            <>
              {txuPhoneCTA && (
                <TxuPhoneCTAs
                  site={site}
                  phoneMobile={phoneMobile}
                  permalease={permalease}
                  formatConfig={formatConfig}
                  formattedPermalease={formattedPermalease}
                  dataFuseName={dataFuseName}
                  txuPhoneCTA={txuPhoneCTA}
                />
              )}
              {!txuPhoneCTA && (
                <a
                  // eslint-disable-next-line max-len
                  className={mobilePhoneClassName}
                  href={`tel:${permalease}`}
                  data-fuse
                  data-fuse-format={dataFuseFormat}
                  data-fuse-name={dataFuseName}
                >
                  {newGridSimplified ? fcSimplifiedCopy : formattedPermalease}
                </a>
              )}
              {phoneMobile && (
                <div className="cta-copy-preamp cta-copy-preamp--mobile">
                  <span className={mobilePhoneWithPreampClassName}>
                    {phoneAndIcon ? formattedPermalease : phoneCopyMobile}
                  </span>
                </div>
              )}
              <span
                className={desktopPhoneClassName}
                data-fuse
                data-fuse-format={dataFuseFormat}
                data-fuse-name={dataFuseName}
              >
                {formattedPermalease}
              </span>
            </>
          )}
        </button>
      )}
    </>
  );
}

FuseNumbers.propTypes = {
  className: PropTypes.string,
  faqBanner: PropTypes.bool,
  featuredCard: PropTypes.bool,
  phoneColor: PropTypes.string,
  site: PropTypes.string,
};

FuseNumbers.defaultProps = {
  className: '',
  faqBanner: false,
  featuredCard: false,
  phoneColor: '',
  site: ''
};

export function FuseNumbersEP(props) {
  const {
    featuredCard,
    className,
    site,
  } = props;

  const {
    fuseData, isOpen, disablePhoneNumbers
  } = useContext(FuseContext);
  // eslint-disable-next-line max-len
  const { dataFuseName, permalease } = featuredCard && fuseData.featuredCardsFuseData ? fuseData.featuredCardsFuseData : fuseData;

  const formattedPermalease = formatPhoneNumber(permalease, site);
  const dataFuseFormat = formatConfig[site] || formatConfig.default;

  useEffect(() => {
    if (window.fuse && isOpen) {
      window.fuse('placeNumbers');
    }
  }, [isOpen]);

  if (!isOpen || disablePhoneNumbers) {
    return null;
  }

  return (
    <>
      <a className="ep-phone-wrapper" href={`tel:${permalease}`}>
        <button type="submit" className={className}>
          <span className="ep-phone-cta">Call now:</span>
          <span
            className="phone"
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {formattedPermalease}
          </span>
        </button>
      </a>
    </>
  );
}

FuseNumbersEP.propTypes = {
  className: PropTypes.string,
  featuredCard: PropTypes.bool,
  site: PropTypes.string,
};

FuseNumbersEP.defaultProps = {
  className: '',
  featuredCard: false,
  site: ''
};

export function CustomFuseText(props) {
  const {
    text,
  } = props;

  const {
    fuseData,
  } = useContext(FuseContext);

  const { dataFuseName, permalease } = fuseData;

  return (
    <>
      <a
        href={`tel:${permalease}`}
        data-fuse
        data-fuse-format={text}
        data-fuse-name={dataFuseName}
      >
        {text}
      </a>
    </>
  );
}

CustomFuseText.propTypes = {
  text: PropTypes.string,
};

CustomFuseText.defaultProps = {
  text: '',
};

export function CustomFuseLink({ fuseData, site }) {
  const { isOpen, disablePhoneNumbers } = useContext(FuseContext);
  const { fuseName = '', permalease = '' } = fuseData;
  const dataFuseFormat = formatConfig[site] || formatConfig.default;
  const formattedPermalease = formatPhoneNumber(permalease, site);

  useEffect(() => {
    if (window.fuse && isOpen) {
      window.fuse('placeNumbers');
    }
  }, [isOpen]);

  if (!fuseName || !permalease || !isOpen || disablePhoneNumbers) {
    return null;
  }

  return (
    <a
      className="phone"
      href={`tel:${permalease}`}
      data-fuse
      data-fuse-format={dataFuseFormat}
      data-fuse-name={fuseName}
    >
      {formattedPermalease}
    </a>
  );
}

CustomFuseLink.propTypes = {
  fuseData: PropTypes.shape({
    dataFuseName: PropTypes.string,
    permalease: PropTypes.string,
  }),
  site: PropTypes.string,
};

CustomFuseLink.defaultProps = {
  fuseData: {},
  site: ''
};
