/* eslint-disable max-len */
import React from 'react';

import iconPhoneFilled from '../../images/icon-phone-filled.svg';
import phone from '../../images/phone.svg';

function SvgPhone(props) {
  const {
    site,
    Sites,
    phoneColor,
    gridComparisonModal
  } = props;

  if (gridComparisonModal) {
    return (
      <svg className="svg--phone" width="15px" height="22px" viewBox="0 0 15 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>7CF4FC4B-DF8B-436F-BB2A-7050E9F9F741@3x</title>
        <desc>Created with sketchtool.</desc>
        <g id="Desktop" stroke="none" strokeWidth="1">
          <g id="Choose-Color-and-Graphics" transform="translate(-127.000000, -531.000000)" fill={phoneColor}>
            <path d="M142.305886,533.357137 C142.65718,533.450816 142.949925,533.638173 143.184121,533.919208 C143.418317,534.200243 143.535415,534.528117 143.535415,534.902831 C143.535415,537.760022 142.821117,540.394727 141.392521,542.806947 C140.010765,545.172326 138.148907,547.034184 135.806947,548.392521 C133.371308,549.821117 130.736603,550.535415 127.902831,550.535415 C127.528117,550.535415 127.200243,550.418317 126.919208,550.184121 C126.638173,549.949925 126.450816,549.65718 126.357137,549.305886 L125.584291,545.968593 C125.490612,545.593879 125.531597,545.230875 125.707244,544.879581 C125.882891,544.528287 126.158071,544.282381 126.532784,544.141864 L130.080854,542.59617 C130.408728,542.455653 130.748313,542.432233 131.099607,542.525911 C131.450901,542.61959 131.743646,542.806947 131.977842,543.087982 L133.101983,544.493158 C134.99897,543.462696 136.462696,541.99897 137.493158,540.101983 L136.087982,538.977842 C135.806947,538.743646 135.61959,538.450901 135.525911,538.099607 C135.432233,537.748313 135.455653,537.408728 135.59617,537.080854 L137.141864,533.532784 C137.282381,533.158071 137.528287,532.882891 137.879581,532.707244 C138.230875,532.531597 138.593879,532.490612 138.968593,532.584291 L142.305886,533.357137 Z M128.413156,548.565569 C130.813797,548.565569 133.046169,547.91653 135.110271,546.618453 C137.107066,545.344415 138.700015,543.637684 139.889117,541.498261 C141.100656,539.286723 141.706425,536.894895 141.706425,534.32278 L138.643925,533.565569 L137.230463,537.13528 L139.518925,539.118453 C138.756104,540.921338 137.875496,542.351626 136.877098,543.409319 C135.8787,544.467011 134.549373,545.404511 132.889117,546.221819 L131.038156,543.769895 L127.706425,545.284319 L128.413156,548.565569 Z" id="Phone" transform="translate(134.535415, 541.535415) scale(-1, 1) rotate(-20.000000) translate(-134.535415, -541.535415) " />
          </g>
        </g>
      </svg>
    );
  }

  if (site === Sites.NEW_TXER) {
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>p@2x</title>
        <g id="Element-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Elements-and-Spacing" transform="translate(-100.000000, -4885.000000)" fill={phoneColor || '#484B51'} fillRule="nonzero">
            <g id="Icons" transform="translate(100.000000, 4785.000000)">
              <path d="M14.5004537,115.999995 C14.8297376,115.999995 15.1571294,115.739589 15.2312259,115.418759 L15.9812241,112.168767 C15.991417,112.123387 15.9996872,112.048833 15.9996872,112.002324 C15.9996872,111.727636 15.7952809,111.415808 15.5433895,111.30625 L12.0433978,109.806254 C11.9656392,109.773112 11.8338951,109.746226 11.7493616,109.746226 C11.5529205,109.746226 11.2926367,109.86973 11.1683999,110.021891 L9.61841579,111.915624 C7.54124179,110.936256 5.06059584,108.45561 4.08122806,106.378467 L5.97499186,104.828452 C6.12736576,104.70452 6.25105321,104.444511 6.25105321,104.24807 C6.25105321,104.163323 6.22398418,104.031335 6.19059803,103.953454 L4.69060161,100.453462 C4.58150153,100.203341 4.27147419,100.000308 3.99858666,100.000308 C3.95180332,100.000308 3.87685233,100.008731 3.83122866,100.019107 L0.581236407,100.769105 C0.260405873,100.843171 0,101.170563 0,101.499847 L0,101.50003 C0,109.515636 6.49687172,115.999995 14.4999654,115.999995 L14.5004537,115.999995 Z" id="p" />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  if (site === Sites.SOE) {
    return <img src={iconPhoneFilled} alt="Phone Icon Filled" />;
  }

  if (site === Sites.TXU) {
    return <img src={phone} alt="Phone Icon" />;
  }

  return (
    <svg className="svg--phone" width="15px" height="22px" viewBox="0 0 15 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>7CF4FC4B-DF8B-436F-BB2A-7050E9F9F741@3x</title>
      <desc>Created with sketchtool.</desc>
      <g id="Desktop" stroke="none" strokeWidth="1">
        <g id="Choose-Color-and-Graphics" transform="translate(-127.000000, -531.000000)" fill="#954a9c">
          <path d="M142.305886,533.357137 C142.65718,533.450816 142.949925,533.638173 143.184121,533.919208 C143.418317,534.200243 143.535415,534.528117 143.535415,534.902831 C143.535415,537.760022 142.821117,540.394727 141.392521,542.806947 C140.010765,545.172326 138.148907,547.034184 135.806947,548.392521 C133.371308,549.821117 130.736603,550.535415 127.902831,550.535415 C127.528117,550.535415 127.200243,550.418317 126.919208,550.184121 C126.638173,549.949925 126.450816,549.65718 126.357137,549.305886 L125.584291,545.968593 C125.490612,545.593879 125.531597,545.230875 125.707244,544.879581 C125.882891,544.528287 126.158071,544.282381 126.532784,544.141864 L130.080854,542.59617 C130.408728,542.455653 130.748313,542.432233 131.099607,542.525911 C131.450901,542.61959 131.743646,542.806947 131.977842,543.087982 L133.101983,544.493158 C134.99897,543.462696 136.462696,541.99897 137.493158,540.101983 L136.087982,538.977842 C135.806947,538.743646 135.61959,538.450901 135.525911,538.099607 C135.432233,537.748313 135.455653,537.408728 135.59617,537.080854 L137.141864,533.532784 C137.282381,533.158071 137.528287,532.882891 137.879581,532.707244 C138.230875,532.531597 138.593879,532.490612 138.968593,532.584291 L142.305886,533.357137 Z M128.413156,548.565569 C130.813797,548.565569 133.046169,547.91653 135.110271,546.618453 C137.107066,545.344415 138.700015,543.637684 139.889117,541.498261 C141.100656,539.286723 141.706425,536.894895 141.706425,534.32278 L138.643925,533.565569 L137.230463,537.13528 L139.518925,539.118453 C138.756104,540.921338 137.875496,542.351626 136.877098,543.409319 C135.8787,544.467011 134.549373,545.404511 132.889117,546.221819 L131.038156,543.769895 L127.706425,545.284319 L128.413156,548.565569 Z" id="Phone" transform="translate(134.535415, 541.535415) scale(-1, 1) rotate(-20.000000) translate(-134.535415, -541.535415) " />
        </g>
      </g>
    </svg>
  );
}

export default SvgPhone;
