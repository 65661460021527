import React from 'react';

function StarRatings(props) {
  const { rating, totalStars, newGridEnabled } = props;

  const [whole, decimal] = rating.split('.');

  const starRatings = [];

  for (let i = 0; i < whole; i += 1) {
    starRatings.push('fullStar');
  }

  if (decimal === '5') {
    starRatings.push('halfStar');
  }

  const emptyCount = decimal === '5' ? (totalStars - (parseInt(whole, 10) + 1)) : totalStars - parseInt(whole, 10);

  for (let i = 0; i < emptyCount; i += 1) {
    starRatings.push('emptyStar');
  }
  return (
    <div className="ratings__stars">
      {starRatings.map((starRating) => (
        <img
          key={Math.random()}
          className="ratings__star"
          alt="rating"
          // eslint-disable-next-line global-require,import/no-dynamic-require
          src={require(`../../images/${starRating}${newGridEnabled ? '-soe' : ''}.svg`)}
        />
      ))}
    </div>
  );
}

export default StarRatings;
