import React, { useRef, useState } from 'react';
import Toggle from 'react-toggle';
import Carousel from 'react-multi-carousel';
import { ProductViewed, ProductClicked } from '@rvpower/track';
import { Sites } from '@rvpower/constants';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { getPlan } from '../../js/project/tracking-helper';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import { usageMapping } from '../../js/project/mappers';
import useBreakpoints from '../../js/hooks/useBreakPoints';
import PlanDetails from './PlanDetails';
import NTXPlanDetails from './NTXPlanDetails';
import TemplateFeaturedCard from '../cards/templates/TemplateFeaturedCard';
import VisibleTracker from '../VisibleTracker';
import GridTrustBuildingBanner from '../grid-trust-building-banner/GridTrustBuildingBanner';
import Spinner from '../Spinner';
import Error from '../Error';
import buildClassList from '../../js/project/buildClassList';
import tracking from '../../js/project/tagular/tracking';
import close from '../../svg/close.svg';
import PlanDetailsModal from '../PlanDetailsModal';
import SplashInlineZip from '../splash-page/SplashInlineZip';

function SOEFeaturedCards(props) {
  const {
    featuredPlans,
    site,
    shouldAbortLoadingPlans,
    gridComparisonToolActive,
    comparedPlans,
    setComparedPlans,
    disableCartLinks,
    electricPhoenixToggleActive,
    toggleEP,
    newGridEnabled = false,
    usage,
    utility,
    isNTX = false,
    isSplashPage,
    zipEntered,
    zipCode,
  } = props;

  const { isSm } = useBreakpoints();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedFeaturedPlans, setSelectedFeaturedPlans] = useState([]);

  const funcs = useRef([]);
  const registerIsVisibileCheck = (f) => funcs.current.push(f);
  const dispatchIsVisibleChecks = () => funcs.current.forEach((f) => f());

  const gridTrustBuildingBanner = usePreamp('Trust Building - MP Grid');
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const ntxP2CSavingsAcrossPrice = rules?.source?.ntxP2CSavingsAcrossPrice?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;

  const simplifiedFCsActive = site === Sites.SOE;

  const responsiveVerticalCards = {
    desktop: {
      breakpoint: { max: 30000, min: 1025 },
      items: featuredPlans.length < 3 ? featuredPlans.length : 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: featuredPlans.length < 2 ? featuredPlans.length : 2,
      paritialVisibilityGutter: isNTX ? 70 : 50
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 100
    },
    smallMobile: {
      breakpoint: { max: 374, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: isNTX ? 40 : 50
    }
  };

  // Fix/change carousel before adding this preamp test to main code
  const simplifiedVerticalCards = {
    desktop: {
      breakpoint: { max: 30000, min: 1025 },
      items: featuredPlans.length < 3 ? featuredPlans.length : 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: featuredPlans.length < 2 ? featuredPlans.length : 2,
      paritialVisibilityGutter: 50
    },
    smallTablet: {
      breakpoint: { max: 599, min: 520 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 180
    },
    mobile: {
      breakpoint: { max: 520, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 150
    },
    smobile: {
      breakpoint: { max: 460, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 110
    },
    smMobile: {
      breakpoint: { max: 420, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 85
    },
    smaMobile: {
      breakpoint: { max: 399, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 60
    },
    smallMobile: {
      breakpoint: { max: 374, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 35
    },
    smallerMobile: {
      breakpoint: { max: 340, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: 0
    },
    smallestMobile: {
      breakpoint: { max: 300, min: 0 },
      items: featuredPlans.length < 1 ? featuredPlans.length : 1,
      paritialVisibilityGutter: -30
    }
  };

  const query = gql`
    query plans($id: ID!, $usage: [Int]!) {
      utility(id: $id) {
        id
        plans(usage: $usage) {
          pricePerKwh
          id
        }
      }
    }
  `;

  const hasSponsoredPlan = featuredPlans.find((plan) => plan.distinction === 'sponsored');

  const {
    data = {},
    error,
    loading,
  } = useQuery(query, {
    errorPolicy: 'all',
    variables: {
      id: utility,
      usage
    },
    fetchPolicy: 'cache-and-network',
    skip: Boolean(usageMapping[usage])
  });

  if (error && !data) {
    return (
      <Error />
    );
  }

  if (loading) {
    return (
      <>
        <Spinner />
        <div className="column-content-placeholder" />
      </>
    );
  }

  if (shouldAbortLoadingPlans(featuredPlans) === true) {
    return null;
  }


  const handleOpenDetails = (detailsOpened) => {
    setDetailsOpen(detailsOpened);

    // Lock mobile viewport to prevent background from scrolling
    if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');

    tracking.elementClicked({
      webElement: {
        location: 'FEATURED',
        elementType: 'Button',
        text: 'MORE DETAILS'
      },
      actionOutcome: `Plan details ${detailsOpen ? 'closed' : 'expanded'}`
    });
  };

  /** Callback function passed into the featured cards and is
   * triggered when the plan details are toggled */
  const handleDetailsToggle = (selectedPlan, detailsOpened) => {
    let newDetailsOpened = detailsOpened;
    let newSelectedPlans = selectedFeaturedPlans;
    const matchedPlanIndex = newSelectedPlans.indexOf(selectedPlan.id);
    const match = newSelectedPlans.length ? matchedPlanIndex > -1 : false;

    if (detailsOpened) {
      // Add plan to selected featured plans
      newSelectedPlans.push(selectedPlan.id);
    } else if (!detailsOpened && match) {
      // Remove plan from selected featured plans
      newSelectedPlans.splice(matchedPlanIndex, 1);

      if (newSelectedPlans.length) {
        newDetailsOpened = true;
      }
    } else if (!detailsOpened && !match) {
      newSelectedPlans = [selectedPlan.id];
      newDetailsOpened = true;
    }

    setSelectedFeaturedPlans([...newSelectedPlans]);
    handleOpenDetails(newDetailsOpened);
  };

  const featuredCardsClasses = buildClassList(
    'featured-cards__cards featured-plan-cards-v2__cards u-position-relative',
    hasSponsoredPlan && 'featured-plan-cards-v2__sponsored-container'
  );

  const featuredCardsMobileClasses = buildClassList(
    'featured-cards__plan-card-v2s',
    'featured-cards__plan-card-v2s--vertical-mobile',
    simplifiedFCsActive && 'featured-cards__plan-card-v2--simplified',
    isNTX && 'ntx-featured-cards--mobile',
    isNTX && featuredPlans.length < 3 && 'ntx-two-cards'
  );

  const featuredCardsDesktopClasses = buildClassList(
    'featured-cards__plan-card-v2s',
    'featured-cards__plan-card-v2s--vertical-desktop',
    simplifiedFCsActive && 'featured-cards__plan-card-v2--simplified',
  );

  const { plans: kwhPlans = [] } = data?.utility || {};
  let plansWithPrices = featuredPlans.map((featuredPlan) => {
    // if there are not usage rates, refer to plan data
    let matchedPlanPrice = 0;

    if (typeof featuredPlan?.unitOfMeasure === 'string' && featuredPlan?.unitOfMeasure !== '') {
      const [currency, description] = featuredPlan.unitOfMeasure.split('/');
      // eslint-disable-next-line no-param-reassign
      featuredPlan.unitOfMeasure = { currency, description };
    }

    if (kwhPlans.length) {
      matchedPlanPrice = kwhPlans.find((kwhPlan) => kwhPlan.id === featuredPlan.id)?.pricePerKwh[0];
    }

    if (!matchedPlanPrice) {
      matchedPlanPrice = featuredPlan.rates.find((r) => r.type === usageMapping[usage])?.price;
    }

    return {
      ...featuredPlan,
      price: matchedPlanPrice || 0,
    };
  });

  // Check NTX plans for duplicates and return new plan list
  function removeDuplicates() {
    const jsonObject = plansWithPrices.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    return Array.from(uniqueSet).map(JSON.parse);
  }
  if (isNTX) plansWithPrices = removeDuplicates();

  const featuredCardsTemplate = plansWithPrices.map((plan, i) => {
    const productInfo = getPlan(plan, i + 1, 'FEATURED');
    const pdpProductInfo = { ...productInfo, actionOutcome: 'Entered PDP' };

    const ntxFeatureClick = (e, cartUrl, planObject) => {
      e.preventDefault();
      window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
      ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);
      window.open(cartUrl, '_blank');
    };

    const onOrderOnline = (e, cartUrl, planObject) => {
      e.preventDefault();

      const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;
      window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
      ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);

      setTimeout(() => {
        window.location = pdpTestActive ? pdpUrl : cartUrl;
      }, 150);
    };
    // eslint-disable-next-line max-len
    const selected = selectedFeaturedPlans.filter(((selectedFeaturedPlan) => selectedFeaturedPlan === plan.id)).length > 0;
    return (
      <VisibleTracker
        onVisible={() => ProductViewed(productInfo)}
        registerIsVisibileCheck={registerIsVisibileCheck}
        key={plan.id}
      >
        <TemplateFeaturedCard
          key={plan.name}
          index={i}
          plan={plan}
          onOrderOnline={isNTX ? ntxFeatureClick : onOrderOnline}
          site={site}
          gridComparisonToolActive={gridComparisonToolActive}
          comparedPlans={comparedPlans}
          setComparedPlans={setComparedPlans}
          disableCartLinks={disableCartLinks}
          newGridEnabled={newGridEnabled}
          handleDetailsToggle={handleDetailsToggle}
          detailsOpen={detailsOpen}
          selected={selected}
          isNTX={isNTX}
          ntxP2CSavingsAcrossPrice={ntxP2CSavingsAcrossPrice}
        />
      </VisibleTracker>
    );
  });

  const latestSelectedPlan = selectedFeaturedPlans[selectedFeaturedPlans.length - 1];
  const displayedFeaturedPlan = plansWithPrices.find(((planWithPrice) => planWithPrice.id === latestSelectedPlan));
  const sectionClasses = buildClassList(
    'featured-cards featured-plan-cards-v2',
    simplifiedFCsActive && 'featured-plan-cards-v2--simplified',
    isNTX && featuredPlans.length < 2 && 'u-display-none'
  );
  return (
    <section className={sectionClasses}>
      <div className={simplifiedFCsActive ? 'grid-v2__column grid-v2__column--title' : 'grid-v2__column'}>
        {isSplashPage && !zipEntered ? (
          <div className="grid-v2__column splash-inline-zip">
            <span className="splash-inline-zip__title">
              Showing plans for&nbsp;
            </span>
            <SplashInlineZip
              zipCode={zipCode}
            />
          </div>
        ) : (
          <h1 className="featured-plan-cards-v2__title">
            {simplifiedFCsActive ? 'Top plans in your area' : 'Your recommended plans'}
          </h1>
        )}
        {(!simplifiedFCsActive && (!isSplashPage || zipEntered)) && (
        <div className="featured-plan-cards-v2__subtitle-container">
          <p className="featured-plan-cards-v2__subtitle">
            To provide you with the best results, we use your location to recommend plans that fit your needs.
          </p>
        </div>
        )}
      </div>
      <div className="grid-v2__column grid-v2__column-carousel">
        {electricPhoenixToggleActive && (
          <div className="ep-toggle">
            <div className="ep-toggle__text">
              Customize results for savings
            </div>
            <Toggle
              onChange={toggleEP}
              className="ep-toggle__slider"
              aria-label="Toggle"
            />
          </div>
        )}
        <div className={featuredCardsClasses}>
          <div className={featuredCardsMobileClasses}>
            <Carousel
              arrows={false}
              swipeable={!detailsOpen}
              partialVisbile={!detailsOpen}
              responsive={simplifiedFCsActive
                ? simplifiedVerticalCards
                : responsiveVerticalCards}
              showDots
              afterChange={dispatchIsVisibleChecks}
              itemClass="vertical-cards-carousel-item"
            >
              {featuredCardsTemplate}
            </Carousel>
          </div>
          <div className={featuredCardsDesktopClasses}>
            {featuredCardsTemplate}
          </div>

          {/* Details container */}
          { detailsOpen && displayedFeaturedPlan && isSm && (
            // eslint-disable-next-line max-len
            <div className="featured-plan-card-v2__details featured-plan-cards-v2__details-vertical">
              <div className={`featured-plan-card-v2__details-header ${isNTX && 'ntx'}`}>
                {/* eslint-disable-next-line */}
                <img
                  className="featured-plan-card-v2__details-close"
                  src={close}
                  alt="Close"
                  onClick={() => handleDetailsToggle(displayedFeaturedPlan, false)}
                />
                <div className="featured-plan-card-v2__details-title">{displayedFeaturedPlan.name}</div>
                <div className="featured-plan-card-v2__details-subtitle">{displayedFeaturedPlan.supplier.name}</div>
              </div>
              <div className="featured-plan-card-v2__details-content">
                {
                isNTX ? (
                  <NTXPlanDetails
                    plan={displayedFeaturedPlan}
                    site={site}
                    featured
                  />
                ) : (
                  <PlanDetails
                    plan={displayedFeaturedPlan}
                    site={site}
                    featured
                  />
                )
              }
              </div>
            </div>
          )}

          { gridTrustBuildingBanner && !isNTX(
            <GridTrustBuildingBanner site={site} assetName={gridTrustBuildingBanner} />
          )}

          {!isSm && (
            <PlanDetailsModal
              detailsOpen={detailsOpen}
              plan={displayedFeaturedPlan}
              site={site}
              handleDetailsToggle={() => handleDetailsToggle(displayedFeaturedPlan, false)}
              isNTX={isNTX}
            />
          )}

        </div>
      </div>
    </section>
  );
}

export default SOEFeaturedCards;
