/* eslint-disable no-param-reassign, max-len, consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import infoBubble from '../svg/info-bubble.svg';
import { usePreamp } from './preamp';

// Badges
import badgeRenewable from '../../images/badge-100-percent-renewable.svg';
import badgeRenewableSoe from '../../images/badge-100-percent-renewable-soe.svg';
import badgeARatingChoose from '../../images/badge-bbb-a-choose.svg';
import badgeARatingCtxp from '../../images/badge-bbb-a-ctxp.svg';
import badgeARatingTxer from '../../images/badge-bbb-a-txer.svg';
import badgeARatingSoe from '../../images/badge-bbb-a-soe.svg';
import badgeAPlusRatingChoose from '../../images/badge-bbb-a-plus-choose.svg';
import badgeAPlusRatingCtxp from '../../images/badge-bbb-a-plus-ctxp.svg';
import badgeAPlusRatingTxer from '../../images/badge-bbb-a-plus-txer.svg';
import badgeAPlusRatingSoe from '../../images/badge-bbb-a-plus-soe.svg';
import satisfactionGuaranteeChoose from '../../images/badge-satisfaction-guarantee-choose.svg';
import satisfactionGuaranteeCtxp from '../../images/badge-satisfaction-guarantee-ctxp.svg';
import satisfactionGuaranteeTxer from '../../images/badge-satisfaction-guarantee-txer.svg';
import satisfactionGuaranteeSoe from '../../images/badge-satisfaction-guarantee-soe.svg';
import badgeGas from '../../images/icon-flame.svg';
import badgeElectric from '../../images/icon-bolt.svg';
import badgeLowCost from '../../images/icon-coin-blue.svg';

function GridBadges({
  badges,
  site,
  featuredCard,
  isNTX = false,
}) {
  const satisfactionGuaranteeBadgeActive = usePreamp('Satisfaction Guarantee Badge');
  const ratingOptions = {
    'bbb-a-plus-rating': {
      'chooseenergy.com': {
        icon: badgeAPlusRatingChoose,
        background: '#EADBEB'
      },
      'new.choosetexaspower.org': {
        icon: badgeAPlusRatingCtxp,
        background: '#FFECEC'
      },
      'saveonenergy.com': {
        icon: badgeAPlusRatingSoe,
        background: '#E8F2F6',
        color: '#253640'
      },
      'new.texaselectricrates.com': {
        icon: badgeAPlusRatingTxer,
        background: '#E6EFFB'
      },
      text: 'BBB | Rated "A+"',
    },
    'bbb-a-rating': {
      'chooseenergy.com': {
        icon: badgeARatingChoose,
        background: '#EADBEB'
      },
      'new.choosetexaspower.org': {
        icon: badgeARatingCtxp,
        background: '#FFECEC'
      },
      'saveonenergy.com': {
        icon: badgeARatingSoe,
        background: '#E8F2F6',
        color: '#253640'
      },
      'new.texaselectricrates.com': {
        icon: badgeARatingTxer,
        background: '#E6EFFB'
      },
      text: 'BBB | Rated "A"',
    },
  };

  const satisfactionGuaranteeOptions = {
    'chooseenergy.com': {
      icon: satisfactionGuaranteeChoose,
      background: '#D8DBEC'
    },
    'new.choosetexaspower.org': {
      icon: satisfactionGuaranteeCtxp,
      background: '#ECF5FF'
    },
    'saveonenergy.com': {
      icon: satisfactionGuaranteeSoe,
      background: '#F9EBE4'
    },
    'new.texaselectricrates.com': {
      icon: satisfactionGuaranteeTxer,
      background: '#DEF6FF'
    },
    text: 'Satisfaction Guarantee'
  };

  const renewableOptions = {
    'saveonenergy.com': {
      icon: badgeRenewableSoe,
      background: '#E7F9F1',
      color: '#253640'
    },
    default: {
      icon: badgeRenewable,
      background: '#D3E6D1',
      color: '#424242'
    }
  };

  // NTX plan badges
  const ntxOptions = {
    'natural-gas': {
      text: 'Natural gas',
      icon: badgeGas,
      border: '#954A9C'
    },
    electric: {
      text: 'Electric',
      icon: badgeElectric,
      border: '#954A9C'
    },
    renewable: {
      text: 'Renewable',
      icon: badgeRenewable,
      border: '#248118'
    },
    'low-cost-choice': {
      text: 'Low-cost choice',
      icon: badgeLowCost,
      border: '#3B4DA0'
    }
  };

  const badgesArray = [...badges];
  const filteredBadges = satisfactionGuaranteeBadgeActive ? badgesArray.filter((badge) => !badge.name.includes('satisfaction')) : badgesArray;

  filteredBadges.forEach((badge) => {
    if (isNTX) {
      badge.icon = ntxOptions?.[badge.name]?.icon;
      badge.background = '#fff';
      badge.border = ntxOptions?.[badge.name]?.border;
      badge.color = '#3f423e';
      badge.text = ntxOptions?.[badge.name]?.text;

      if (badge.name.includes('gas') || badge.name.includes('electric')) {
        badge.order = 1;
      } else if (badge.name.includes('renewable')) {
        badge.order = 2;
      } else {
        badge.order = 3;
      }
    }

    if (!isNTX) {
      if (badge.name.includes('renewable')) {
        badge.icon = renewableOptions?.[site]?.icon || renewableOptions.default.icon;
        badge.background = renewableOptions?.[site]?.background || renewableOptions.default.background;
        badge.color = renewableOptions?.[site]?.color || renewableOptions.default.color;
        badge.text = '100% Renewable';
        badge.order = 1;
      } else if (badge.name.includes('satisfaction')) {
        badge.icon = satisfactionGuaranteeOptions?.[site]?.icon;
        badge.background = satisfactionGuaranteeOptions?.[site]?.background;
        badge.text = satisfactionGuaranteeOptions?.text;
        badge.order = 3;
      } else {
        badge.icon = ratingOptions?.[badge.name]?.[site]?.icon;
        badge.background = ratingOptions?.[badge.name]?.[site]?.background;
        badge.color = ratingOptions?.[badge.name]?.[site]?.color;
        badge.text = ratingOptions?.[badge.name]?.text;
        badge.order = 2;
      }
    }
  });

  if (filteredBadges.length === 0) {
    return null;
  }

  return (
    <div className={`badge-container
      ${featuredCard ? 'badge-container--featured' : ''}
      ${isNTX && 'badge-container__ntx'} `}
    >
      {filteredBadges.map((badge) => {
        if (!badge.icon) return;

        const divStyle = {
          backgroundColor: badge.background,
          borderColor: badge.border ? badge.border : {},
          ...(badge.color ? { color: badge.color } : {})
        };

        return (
          <div
            className={`badge-container__badge badge-container__badge--${badge.order}
            ${isNTX && 'badge-container__badge-ntx'}`}
            style={divStyle}
            key={badge.id}
          >
            <img className="badge-container__icon" src={badge.icon} alt={badge.name} />
            <span>{ badge.text }</span>
            {badge.tooltip && (
              <Tippy
                content={badge.tooltip}
                arrow
                placement="top-start"
                className="disclaimer"
              >
                <img className="svg svg--info-bubble badge-container__tooltip" src={infoBubble} alt="Info" />
              </Tippy>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default GridBadges;
GridBadges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  site: PropTypes.string.isRequired,
  featuredCard: PropTypes.bool,
};

GridBadges.defaultProps = {
  featuredCard: false
};
