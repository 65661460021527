import { useState } from 'react';

const useModal = (initialState = false) => {
  const [modalIsOpen, setmodalIsOpen] = useState(initialState);

  const toggleModal = () => {
    setmodalIsOpen(!modalIsOpen);
  };

  return [modalIsOpen, toggleModal];
};

export default useModal;
