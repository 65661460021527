import React from 'react';
import siteName from '../js/project/siteName';

/* eslint-disable max-len */
export default function Spinner() {
  const circles = [];
  const isSoeSite = siteName('saveonenergy');
  const isTxuSite = siteName('txu');
  const defaultSpinner = !isSoeSite && !isTxuSite;
  for (let i = 1; i <= 12; i += 1) {
    circles.push(<div key={i} className={`sk-circle${i} sk-circle`} />);
  }

  return (
    <div className="circle-container u-display-flex u-justify-content-center u-align-items-center">
      {isSoeSite && (
        <div className="circle-container__wrapper">
          <div className="circle-container__spinner" />
        </div>
      )}
      {isTxuSite && (
        <div className="showbox">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
            </svg>
          </div>
        </div>
      )}
      {defaultSpinner && (
        <div className="sk-fading-circle">
          {circles}
        </div>
      )}
    </div>
  );
}
