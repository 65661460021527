import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

// There were some backend changes that uses rogUtilities
// instead of utilities in order to account for NTX and TXU branded
// Whenever we migrate to use the new widget, we will use rogUtilities
const utilitiesQuery = gql`
  query Utilities($zipCode: String!, $site: String!, $useRogUtilities: Boolean!) {
    utilities(zipCode: $zipCode) @skip(if: $useRogUtilities) {
      id
      name
    }
    rogUtilities(zipCode: $zipCode) @include(if: $useRogUtilities) {
      id
      name
    }
    outsideGridFeaturedConfigs(site: $site){
      id
      name
      site
      utilities {
        utilityId
        featuredPlans{
          planId
          labelId
          usageId
        }
      }
    }
    isCallCenterOpen
    siteSettings(site: $site) {
      disableCartLinks
      disablePhoneNumbers
      maintenanceMode
    }
  }
`;

const useOutsideGridUtilities = (zipCode, site) => useLazyQuery(utilitiesQuery, {
  variables: { zipCode, site, useRogUtilities: false },
});

const useOutsideGridUtilitiesV2 = (zipCode, site) => useLazyQuery(utilitiesQuery, {
  variables: { zipCode, site, useRogUtilities: true },
  fetchPolicy: 'network-only',
});

export { useOutsideGridUtilities, useOutsideGridUtilitiesV2 };
