/* eslint-disable max-len */
import React from 'react';

// SVGs
import chooseReducedLogo from '../svg/choose-reduced-logo.svg';
import energyPriceComparePeople from '../svg/energy-price-compare.svg';
import energyPriceCompareLightbulb from '../svg/energy-price-compare-lightbulb.svg';

import useBreakpoints from '../js/hooks/useBreakPoints';

const ComparePriceBanner = ({ title, content, protip }) => {
  const { isSm } = useBreakpoints();
  const energyPriceCompare = isSm ? energyPriceCompareLightbulb : energyPriceComparePeople;

  return (
    <div className="compare_price_banner">
      <div className="compare_price_banner__info">
        <img className="compare_price_banner__picture" src={energyPriceCompare} alt="Energy Price to compare" />
        <div className="compare_price_banner__content">
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <p dangerouslySetInnerHTML={{ __html: content }} />
          <div className="compare_price_banner__tip">
            <img alt="ChooseEnergy" src={chooseReducedLogo} />
            <span dangerouslySetInnerHTML={{ __html: protip }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePriceBanner;
