import React, { useEffect, useState } from 'react';

const CircleLoading = () => {
  const [activeItem, setActiveItem] = useState(0);
  const LOADING_ITEMS_QTY = 4;

  useEffect(() => {
    const loading = setInterval(
      () => setActiveItem((item) => (item < LOADING_ITEMS_QTY - 1 ? item + 1 : 0)),
      350
    );
    return () => {
      clearInterval(loading);
    };
  }, []);

  return (
    <div className="circle-loading-container">
      {Array.from(Array(LOADING_ITEMS_QTY).keys()).map((i) => (
        <div
          className={`circle-loading-circle ${
            i === activeItem && 'circle-loading-circle--active'
          }`}
          key={i}
        />
      ))}
    </div>
  );
};

export default CircleLoading;
