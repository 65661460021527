import React, { useState } from 'react';

const GctToolbarCard = ({
  item, i, comparedPlans, setComparedPlans, usage
}) => {
  const {
    name, id, supplier, roundedPricePerKwh, prices
  } = item;

  const price = roundedPricePerKwh || prices[usage];
  const { logo } = supplier;
  const [isActive, setIsActive] = useState(true);

  const removeCard = (e, index) => {
    e.preventDefault();
    setIsActive(false);
    const plans = [...comparedPlans];
    plans.splice(index, 1);
    setComparedPlans(plans);
  };

  return (
    <div
      className={`gct-toolbar__card${isActive ? ' is-active' : ''}`}
      key={id}
    >
      <div className="gct-toolbar__content-container">
        <img
          className="gct-toolbar__logo"
          src={logo}
          alt={name}
        />
        <p className="gct-toolbar__content-wrapper">
          <span className="gct-toolbar__plan-name">
            { name }
          </span>
          <span className="gct-toolbar__price">
            { price }
            <sup className="gct-toolbar__price-symbol">¢</sup>
            {' '}
            per kWh
          </span>
          <span className="gct-toolbar__rate">{`at ${usage} kWh`}</span>
        </p>
      </div>
      <button
        className="gct-toolbar__close-button"
        type="button"
        onClick={(e) => removeCard(e, i)}
        aria-label="close button"
      />
    </div>
  );
};

export default GctToolbarCard;
