import React, { useState, useEffect } from 'react';
import beam from '../../js/project/tagular/beam';

import lightningIcon from '../../../images/lightning-icon.svg';
import closeIcon from '../../../images/close-icon.svg';

const AttentionBanner = (props) => {
  const {
    bannerClosedPayload = null,
    monarchCreditFilter = {
      active: false,
      bannerTitle: 'Need some help deciding?',
      bannerDescription: 'Let\'s find the perfect energy plan for you',
    },
    isTippyOpen = false,
    creditFilterToggle,
    mobileFiltersOpen,
  } = props;

  const [bannerOpen, setBannerOpen] = useState(true);
  const [bannerAnimate, setBannerAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setBannerAnimate(true);
    }, 1500);
  }, []);

  const closeBanner = () => {
    if (bannerClosedPayload) {
      beam.userTrackingV3('ElementClicked', bannerClosedPayload);
    }

    setBannerOpen(false);
  };

  useEffect(() => {
    if (isTippyOpen || mobileFiltersOpen) {
      setBannerOpen(false);
    }
  }, [isTippyOpen, mobileFiltersOpen]);

  const bannerInstanceSizing = creditFilterToggle ? 'credit-filter-banner--open' : 'hmc-button-banner--active';

  return (
    bannerOpen && (
      <div className={`hmc-button-banner ${bannerAnimate ? bannerInstanceSizing : ''}`}>
        <div className="hmc-button-banner__inner-left">
          <img
            src={lightningIcon}
            alt="lightning icon"
            className="hmc-button-banner__lightning-icon"
          />
          <div className={`
            hmc-button-banner__text-wrapper
            ${bannerAnimate ? 'hmc-button-banner__text-wrapper--active' : ''}
          `}
          >
            <strong className="hmc-button-banner__title">{monarchCreditFilter.bannerTitle}</strong>
            <p className="hmc-button-banner__subtext">{monarchCreditFilter.bannerDescription}</p>
          </div>
        </div>
        <div className="hmc-button-banner__inner-right">
          <button
            type="button"
            className="hmc-button-banner__close-button"
            onClick={() => closeBanner()}
          >
            <img
              src={closeIcon}
              alt="close icon"
              className="hmc-button-banner__close-icon"
            />
          </button>
        </div>
      </div>
    )
  );
};

export default AttentionBanner;
