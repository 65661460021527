import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default function UtilityDropdown(props) {
  const {
    setSelectedUtility,
    utilities,
    utility,
  } = props;

  const options = utilities?.map((util) => ({ value: util.id, label: util.name }));
  const defaultValue = options?.find((option) => option?.value === utility?.id);

  return (
    <div className="utility-dropdown__section">
      <strong className="utility-dropdown__label">Utility</strong>
      <Select
        defaultValue={defaultValue}
        isSearchable={false}
        onChange={(option) => setSelectedUtility(option.value)}
        options={options}
        className="utility-dropdown"
        classNamePrefix="utility-dropdown"
      />
    </div>
  );
}

UtilityDropdown.propTypes = {
  utilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  utility: PropTypes.shape().isRequired,
  setSelectedUtility: PropTypes.func.isRequired,
};
