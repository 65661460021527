import React, { useState, useEffect } from 'react';
import editIcon from '../../../images/edit-icon-ctxp.svg';

const SplashInlineZip = (props) => {
  const {
    zipCode,
  } = props;

  const [localZipCode, setLocalZipCode] = useState(zipCode);

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  const updateZip = () => {
    if (window.location.href.includes('sp')) {
      window.location.href = `/compare-offers/sp/?zipCode=${localZipCode}&m=`;
    }
  };

  const submit = (e) => {
    e.preventDefault();

    localStorage.setItem('splashZipEntered', 'true');
    updateZip();
  };

  // Updates the zip in the middle of the grid page after the browser back button is clicked
  useEffect(() => {
    if (zipCode !== localZipCode) {
      setLocalZipCode(zipCode);
      updateZip();
    }
  }, []);

  return (
    <form className="splash-inline-zip__form" onSubmit={submit}>
      <input
        className="splash-inline-zip__input"
        id="zip-code"
        value={localZipCode}
        onChange={(e) => handleChange(e)}
        type="text"
        inputMode="numeric"
        maxLength="5"
        pattern="[0-9]{5}"
        title="Please enter a valid zip code"
        required
        aria-label="zip code search"
      />
      <img className="splash-inline-zip__edit-icon" src={editIcon} alt="edit icon" />
    </form>
  );
};

export default SplashInlineZip;
