import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { Sites } from '@rvpower/constants';
import RatingsUnavailToolTip from '../../RatingsUnavailToolTip';
import Ratings from '../../../Ratings';
import SvgPhone from '../../../SvgPhone';

function CarouselModal(props) {
  const {
    ratingsEnabled,
    displayedCardIndex,
    removePlan,
    comparedPlans,
    site,
    usage,
    tooltip,
    generateFee,
    onOrderOnline = () => { },
    cartCta
  } = props;

  const phoneColorConfig = {
    [Sites.CTXP]: '#ffffff',
    [Sites.NEW_CTXP]: '#173957',
    [Sites.TXER]: '#0B3970',
    [Sites.NEW_TXER]: '#0B3970',
    [Sites.CHOOSE]: '#954A9C',
    [Sites.SOE]: '#446DB5',
    [Sites.TXU]: '#ffffff'
  };

  const phoneColor = phoneColorConfig[site];

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1
    },
    smallMobile: {
      breakpoint: { max: 374, min: 0 },
      items: 1
    }
  };

  const swipeableLimit = (nextSlide) => {
    const count = comparedPlans.length;
    if (nextSlide > count - 1) {
      const gctModal = document.querySelector('.gct__modal--mobile');
      const carouselBtn = gctModal.querySelector('.react-multi-carousel-dot button');

      setTimeout(() => {
        carouselBtn.click();
      }, 500);
    }
  };

  useEffect(() => {
    const gctCarousel = document.querySelector('.gct__modal--mobile');
    const carouselButton = gctCarousel.querySelector('.react-multi-carousel-dot button');
    if (carouselButton) {
      carouselButton.click();
    }
  }, [comparedPlans]);

  const fuseNumber = (optionalClass = '') => {
    const fuse = document.querySelector('.phone')?.innerText;
    return (fuse
      && (
      <div className={`gct__phone-cta${optionalClass}`}>
        <SvgPhone
          site={site}
          Sites={Sites}
          phoneColor={phoneColor}
          gridComparisonModal
        />
        <span
          className="phone phone--modal"
        >
          <a href={`tel: ${fuse}`}>
            {fuse}
          </a>
        </span>
      </div>
      )
    );
  };

  return (
    <Carousel
      responsive={responsive}
      showDots
      beforeChange={(nextSlide) => {
        swipeableLimit(nextSlide);
      }}
    >
      {comparedPlans.map((plan, i) => {
        const { cartActive, cartUrl, supplier } = plan;
        const { disablePhoneNumbers = true } = supplier?.controls;
        const { ratings, logo } = supplier;
        const ratingUnavailable = ratingsEnabled && !ratings;

        return (
          <>
            <div className="gct__header-control">
              <span className="gct__plan-counter">
                {i + 1}
                /
                {`${comparedPlans.length} `}
                plans
              </span>
              <button
                className="gct__remove-plan-button"
                type="button"
                onClick={removePlan(displayedCardIndex)}
              >
                Remove this plan
              </button>
            </div>
            <div className="gct__container">
              <div className="gct__supplier-info">
                <div className="gct__title">{`${plan.name}`}</div>
                <div className="gct__subtitle">{`${supplier.name}`}</div>
                <img className="gct__logo" src={logo} alt={supplier.name} />
                <div className="gct__cta-wrapper">
                  {!disablePhoneNumbers && (fuseNumber(' gct__phone-cta--icon'))}
                  {cartActive && (
                    <a
                      href={cartUrl}
                      className="gct__order-online"
                      onClick={(e) => onOrderOnline(e, cartUrl, plan)}
                    >
                      {cartCta || 'ORDER ONLINE'}
                    </a>
                  )}
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text gct__text--ratings">Rating</div>
                { ratingsEnabled && plan?.supplier?.ratings
                && (
                  <Ratings
                    ratings={plan?.supplier?.ratings}
                    boundary="scrollParent"
                    tippyClass="ratings-tippy--card"
                    supplierName={plan?.supplier?.name}
                    site={site}
                  />
                )}
                {ratingUnavailable && (
                  <>
                    <RatingsUnavailToolTip />
                  </>
                )}
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">Rate Type</div>
                <div className="gct__text-bold">
                  {`${plan.type}`}
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">
                  Average price
                  {' '}
                  {`at ${usage} KWH`}
                  {tooltip()}
                </div>
                <div className="gct__text-bold">
                  {plan.roundedPricePerKwh || plan.prices[plan.usage]}
                  <span className="cent-symbol">¢</span>
                  {' '}
                  PER KWH
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">
                  Estimated bill
                </div>
                <div className="gct__text-bold">
                  $
                  {plan.roundedEstimate || plan.estimate}
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">
                  Length of plan
                </div>
                <div className="gct__text-bold">
                  {plan?.termText}
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">% of renewable energy</div>
                <div className="gct__text-bold">
                  {(plan?.renewablePercentage * 100).toFixed(0)}
                  %
                </div>
              </div>
              <div className="gct__supplier-info">
                <div className="gct__text">
                  Early termination fee
                </div>
                <div className="gct__text-bold">
                  {generateFee(plan)}
                </div>
              </div>
              <div className="gct__supplier-info">
                {!disablePhoneNumbers && (fuseNumber())}
                {cartActive && (
                  <a
                    href={cartUrl}
                    className="gct__order-online"
                    onClick={(e) => onOrderOnline(e, cartUrl, plan)}
                  >
                    {cartCta || 'ORDER ONLINE'}
                  </a>
                )}
              </div>
            </div>
          </>
        );
      })}
    </Carousel>
  );
}

export default CarouselModal;
