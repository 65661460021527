const ProcessErrors = {
  TXUNoSwitchHold: 'TXU_NO_SWITCH_HOLD',
  TXUFraudAlert: 'TXU_FRAUD_ALERT',
  TXUMoveInToCurrentPremise: 'TXU_MOVE_IN_TO_CURRENT_PREMISE',
  TXUSecurityHoldForDifferentCustomer: 'TXU_SECURITY_HOLD',
  TXUCurrentCustomer: 'TXU_CURRENT_CUSTOMER',
  TXUEquifaxError: 'TXU_EQUIFAX_ERROR',
  TXUPriorDebt: 'TXU_PRIOR_DEBT',
  TXUMissingSessionID: 'TXU_MISSING_SESSION_ID',
  TXUMissingCustomerNumber: 'TXU_MISSING_CUSTOMER_NUMBER',
  TXUPendingTransaction: 'TXU_PENDING_TRANSACTION',

  CreditCard: 'INVALID_CREDIT_CARD',

  FailedCreditCheck: 'FAILED_CREDIT_CHECK',
  FailedQualification: 'FAILED_QUALIFICATION',
  FailedSEON: 'SEON_FAILED',
  FailedIDDW: 'IDDW_FAILED',
  FailedNegativeList: 'NEGATIVE_LIST_FAILED',
};

module.exports = {
  ProcessErrors
};
