import React from 'react';
import gearIcon from '../svg/gear-icon.svg';

function ComparisonBanner() {
  return (
    <section className="gct-banner">
      <div className="gct-banner__ribbon"><span>NEW</span></div>
      <img className="svg svg--gear-icon" src={gearIcon} alt="Gear Icon" />
      <h3>Tips for comparing plans:</h3>
      <ul>
        <li>
          View plans side by side with the
          <strong>
          &nbsp;Click to Compare&nbsp;
          </strong>
          checkbox. Choose up to three plans to compare estimated bill, early termination fee, and more.
        </li>
        <li>
          To see which plans you&apos;ve already selected, click the number in the toolbar at the bottom of your screen.
        </li>
      </ul>
    </section>
  );
}

export default ComparisonBanner;
