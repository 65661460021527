import React from 'react';

import tracking from '../../js/project/tagular/tracking';
import getProviderLink from '../../js/project/provider-links';
import iconCheck from '../../../images/icon-check-default.svg';
import iconLeaf from '../../../images/icon-leaf-default.svg';
import iconStar from '../../../images/icon-badge-star-default.svg';
import iconThumbsUp from '../../../images/icon-thumbs-up.svg';
import iconLeafNTX from '../../../images/icon-leaf-green-ntx.svg';
import iconDollarSign from '../../../images/icon-dollar-sign.svg';

function BadgeBox(props) {
  const {
    type,
    plan,
    badge,
    site,
    isNTX = false
  } = props;
  const providerLink = getProviderLink(site, plan.supplier.name);
  const { ratings } = plan?.supplier;
  const badgeMap = {
    satisfaction: {
      type: 'Satisfaction Guarantee',
      title: badge?.attributeValue ? `${badge?.attributeValue}-day satisfaction guarantee` : 'Satisfaction Guarantee',
      icon: iconCheck,
      copy: {
        __html: badge?.tooltip,
      }
    },
    renewable: {
      type: 'Renewable Energy',
      title: badge?.attributeValue ? `${badge?.attributeValue}% renewable energy source` : 'Renewable energy source',
      icon: isNTX ? iconLeafNTX : iconLeaf,
      copy: {
        __html: badge?.tooltip,
      }
    },
    bbb: {
      type: 'Better Business Bureau',
      title: 'A+ Better Business Bureau Rating',
      icon: iconThumbsUp,
      copy: {
        __html: 'The Better Business Bureau (BBB) rates businesses on how likely they are to interact with customers.'
      }
    },
    rating: {
      type: 'Rating',
      title: `We rate this provider ${ratings?.overallScore}/5`,
      icon: iconStar,
    },
    'low-cost-choice': {
      type: 'Low-cost choice',
      title: 'Low-cost choice',
      icon: iconDollarSign,
      copy: {
        __html: badge?.tooltip,
      }
    },
  };
  const themeStyles = `badge-box--${type}`;

  const trackDocumentClicked = () => {
    tracking.elementClicked({
      actionOutcome: 'Redirects to partner page',
      webElement: {
        location: 'Rating Modal',
        elementType: 'Link',
        text: 'Learn more'
      },
      outboundUrl: `${window.location.host}${providerLink}`
    });
  };

  return (
    <div className={`badge-box ${themeStyles}`}>
      <div className={`badge-box__header ${isNTX && 'ntx'}`}>
        <img className={`badge-box__icon ${isNTX && 'ntx'}`} src={badgeMap[type].icon} alt="Badge Icon" />
        <p className={`badge-box__title ${isNTX && 'ntx'}`}>{badgeMap[type]?.title}</p>
      </div>
      <div className="badge-box__content">
        {isNTX && type === 'low-cost-choice' && (
          <div className="badge-box__content-copy">
            <p className="badge-box__content-copy">
              The supply charge for this plan is lower than the current utility rate,
              without considering additional fees or taxes, making it a more affordable option.
            </p>
          </div>
        )}
        {isNTX && type === 'renewable' && (
          <div className="badge-box__content-copy">
            <p className="badge-box__content-copy">
              This plan uses renewable energy sources like wind and
              solar to reduce the use of conventional fossil fuels.
            </p>
          </div>
        )}
        {type === 'rating' ? (
          <div className="badge-box__content-copy">
            <div>
              <p className="badge-box__content-copy">
                We grade providers in three categories for their overall rating:
              </p>
              <div className="badge-box__rating-item">
                <p className="badge-box__content-copy">Customer service</p>
                <strong>
                  {ratings?.customerService ? `${ratings?.customerService}/5` : '--'}
                </strong>
              </div>
              <div className="badge-box__rating-item">
                <p className="badge-box__content-copy">Online accessibility</p>
                <strong>
                  {ratings?.onlineAccessibility ? `${ratings?.onlineAccessibility}/5` : '--'}
                </strong>
              </div>
              <div className="badge-box__rating-item">
                <p className="badge-box__content-copy">Plan Options</p>
                <strong>
                  {ratings?.planOptions ? `${ratings?.planOptions}/5` : '--'}
                </strong>
              </div>
              <a
                className="badge-box__provider-link"
                href={providerLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackDocumentClicked()}
              >
                Learn more
              </a>
            </div>
          </div>
        ) : (
          <div className="badge-box__content-copy" dangerouslySetInnerHTML={badgeMap[type]?.copy} />
        )}
      </div>
    </div>
  );
}

export default BadgeBox;
