import React from 'react';
import PropTypes from 'prop-types';

import FuseNumbers from '../../FuseNumbers';

const NoPlanResults = ({ site, isNTX }) => {
  const subText = isNTX
    ? `There aren't any plans that match your selected filters. 
    Update the filters to view available plans in your area.`
    : `There aren't any plans that match your selected filters. 
    Update the filters or change your location to view available plans in your area.`;

  return (
    <div className="grid-v2-no-plan-results">
      <div className="grid-v2-no-plan-results__wrapper">
        <h2
          className="grid-v2-no-plan-results__heading"
        >
          No plan results found
        </h2>
        <p className="grid-v2-no-plan-results__text">
          {subText}
        </p>
        <div className={`${isNTX ? 'u-display-none' : ''}`}>
          <p className="grid-v2-no-plan-results__text grid-v2-no-plan-results__text--margin-top-30">
            {/* eslint-disable-next-line */}
            {`If you still can't find what you need, call our experts at`}
          </p>
          <FuseNumbers
            className="grid-v2-no-plan-results__fuse-number"
            site={site}
          />
        </div>
      </div>
    </div>
  );
};

export default NoPlanResults;

NoPlanResults.propTypes = {
  site: PropTypes.string.isRequired
};
