import { Sites, gridUrl } from '@rvpower/constants';

export function getComparisonPartnerId() {
  const re = /comparisonPartnerId=([0-9]{4})/i;
  const match = window.location.search.match(re);
  return match && match.length > 1 ? match[1] : '';
}

export function getZip(site) {
  const re = /zipCode=([0-9]{5})/i;
  const match = window.location.search.match(re);

  if (match && match.length > 1) {
    return match[1];
  }

  if (site === Sites.CHOOSE) {
    const parts = window.location.pathname.split('/').filter(Boolean);
    return parts.length > 0 ? parts[parts.length - 1] : '';
  }

  if (site === Sites.ALLCONNECT) {
    return localStorage.getItem('geoZip') || '';
  }

  return '';
}

export function getZipModal() {
  const re = /zm=([0-9]{1})/i;
  const match = window.location.search.match(re);

  return match && match.length > 1 ? Boolean(Number(match[1])) : false;
}

const texasRegex = /7([5-9]\d{3}|33\d{2})|885\d{2}/;
const zipcodeEndpoint = 'https://api.saveonenergy.cloud/zipcode';
export default function getStateByZip(zipCode, retryCount = 3) {
  return fetch(`${zipcodeEndpoint}/${zipCode}`, {
    method: 'GET',
  }).then((results) => results.json()).then(
    (data) => data.state
  ).catch(() => {
    if (retryCount === 1) {
      if (texasRegex.test(zipCode)) return 'TX';
      return null;
    }

    return getStateByZip(zipCode, retryCount - 1);
  });
}

export async function updateZip(site, zipCode) {
  if (site === Sites.ALLCONNECT) {
    localStorage.setItem('geoZip', zipCode);
    window.location.reload();
    return;
  }

  let url = '';
  await getStateByZip(zipCode, 3).then((state) => {
    if (state && state !== 'TX') {
      url = `${process.env.CHOOSE_URL}/shop/residential/electricity/${state}/${zipCode}?isNTX=true`;
    }

    // we do not have texas plans in the isNTX experience so by removing it we will request
    // a new source token and then it would proceed as usual
    if (state && state === 'TX' && site === Sites.CHOOSE) {
      url = `${process.env.CHOOSE_URL}/shop/residential/electricity/${state}/${zipCode}`;
    }
  });

  if (url) {
    window.location.href = url;
  } else {
    const paramArray = window.location.search.slice(1).split('&').map((paramString) => {
      if (paramString.includes('zipcode')) {
        return `zipcode=${zipCode}`;
      }
      if (paramString.includes('zipCode')) {
        return `zipCode=${zipCode}`;
      }
      return paramString;
    });
    const newUrl = gridUrl(site, zipCode);
    window.location.href = `${newUrl}&${paramArray.join('&')}`;
  }
}
