import React from 'react';
import { Sites } from '@rvpower/constants';
import { usePreamp } from './preamp';
import { useMonarch } from './monarch';

function Disclaimer(props) {
  const { site, electricPhoenixActive, ntxUtilityName } = props;
  const topOne = usePreamp('Grid-Guarantee-Banner-Top') === 'Guarantee Banner Top - Copy #1';
  const topTwo = usePreamp('Grid-Guarantee-Banner-Top') === 'Guarantee Banner Top - Copy #2';
  const topThree = usePreamp('Grid-Guarantee-Banner-Top') === 'Guarantee Banner Top - Copy #3';

  const middleOne = usePreamp('Grid-Guarantee-Banner-Middle') === 'Guarantee Banner Middle - Copy #1';
  const middleTwo = usePreamp('Grid-Guarantee-Banner-Middle') === 'Guarantee Banner Middle - Copy #2';
  const middleThree = usePreamp('Grid-Guarantee-Banner-Middle') === 'Guarantee Banner Middle - Copy #3';

  const disclaimerTest = usePreamp('Grid-disclaimer');
  const incentiveTest = usePreamp('Offer Incentives');

  const messageOne = topOne || middleOne;
  const messageTwo = topTwo || middleTwo;
  const messageThree = topThree || middleThree;

  const customMessage = (messageOne || messageTwo || messageThree);
  const { rules } = useMonarch();
  const isTXU = site === Sites.TXU;
  const isCHOOSE = site === Sites.CHOOSE;
  const ntxP2CSavingsAcrossPrice = rules?.source?.ntxP2CSavingsAcrossPrice?.value;

  function determineNonTXUDisclaimer(ep) {
    if (ep) {
      return (
        <p>
          { /* eslint-disable max-len */ }
          * Please refer to each plan&apos;s Electricity Facts Label and Terms of Service before purchase. Estimates include recurring charges except government fees and taxes. Actual savings and rates may vary. Projected savings assumes same usage on every plan. Projected savings is based on the following sources:
          <br />
          <br />
          Energy Information Administration (EIA) data from Texas Electricity Profiles 2018-2020, and 2015 Residential Energy Consumption Survey (RECS); User-input usage and pricing
          { /* eslint-enable max-len */ }
        </p>
      );
    }
    return (
      <p>
        * Estimates include recurring charges excluding government fees and taxes.
        Estimates are based on information provided by energy providers and your usage estimates.
        Study information from the plan’s Electricity Facts Label and Terms of Service to confirm these estimates.
        Rates may vary according to your actual electricity usage.
      </p>
    );
  }

  function determineTXUDisclaimer() {
    if (disclaimerTest) {
      return (
        <p>
          { /* eslint-disable max-len */ }
          * Price Protection Promise applies to residential term contracted customers on fixed rate products. Your price will not change during the term of this plan except to reflect actual changes in TDU charges; changes to the Electric Reliability Council of Texas or Texas Regional Entity administrative fees; or changes resulting from federal, state or local laws that impose new or modified fees or costs that are beyond our control.
          <br />
          <br />
          ** Estimates include recurring charges excluding government fees, taxes, and non-recurring charges. Estimates are based on information provided by TXU Energy and your usage estimates. Study information from the plan&apos;s Electricity Facts Label and Terms of Service to confirm these estimates. Rates may vary according to your actual electricity usage.
        </p>
      );
    }
    return (
      <>
        <p>
          { /* * Here should be the Disclaimer text in the Grid just below cards */ }
        </p>
        {incentiveTest
        && (
          <p>* Redeem your bonus at the website or phone number within 90 days of the first day of electricity service on your new plan with TXU Energy. Once redeemed, you’ll receive your prepaid Mastercard within 2 – 4 weeks after your first bill on your new plan is paid in full. Your account must be in good standing and active on this plan at that time. Mastercard® prepaid card is valid for six months after issuance.</p>
        )}
        {!customMessage
        && (
          <p>
            * Total Satisfaction Guarantee - New residential customers only.
            Cancellation fee applies if you cancel after 60 days.
            You remain responsible for any billed and unbilled charges.
          </p>
        )}
        {customMessage
        && (
          <>
            {messageTwo && (
              <p>
                {/* eslint-disable-next-line */}
                †If your current provider charges a cancellation fee, just send us your final bill and we’ll cover the fee up to $150 with a credit on your TXU Energy bill.
              </p>
            )}
            {messageOne && (
              <p>
                {/* eslint-disable-next-line */}
                †Just call us and if you change plans, we’ll waive any early cancellation fees. New plan must have a term equal to balance of existing term and no less than 12 months. Cancellation fee applies if you switch providers. You remain responsible for any billed and unbilled charges. Rental property customers only.
              </p>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <section className="legal-disclaimer">
      {isTXU
        ? determineTXUDisclaimer()
        : determineNonTXUDisclaimer(electricPhoenixActive)}
      {ntxP2CSavingsAcrossPrice && isCHOOSE && (
        <p>
          {`**Estimated savings are calculated by comparing ${ntxUtilityName}'s
          Residential Price to Compare (PTC) and the current lowest rates
          available for our brand to demonstrate an estimated percentage
          difference. The rates and potential savings mentioned are subject to
          variation based on location and usage patterns, and do not include
          distribution fees, taxes, or other state-regulated charges. The
          provided savings figures are intended as informative guidance and are
          not guaranteed, as pricing fluctuations or regional variations may
          occur.`}
        </p>
      )}
    </section>
  );
}

export default Disclaimer;
