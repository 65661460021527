import React from 'react';
import Tippy from '@tippy.js/react';
import { Sites } from '@rvpower/constants';

// SVGs
import infoBubble from '../svg/info-bubble.svg';

function CardDetailsTable(props) {
  const { plan, site, electricPhoenixActive } = props;
  const {
    prices,
    estimates,
    usage,
    unitOfMeasure: { currency, description }
  } = plan;

  const tableHeaderClassName = (highlighted) => (
    `cell u-display-flex u-align-items-center ${highlighted ? 'cell--highlighted' : ''}`
  );

  const tableElClassName = (highlighted) => (
    `cell u-display-flex u-align-items-center ${highlighted ? 'cell--highlighted' : ''}`
  );

  const usagesToDisplay = [500, 1000, 2000];

  const headers = usagesToDisplay.map((u) => (
    <th
      key={u}
      className={`${tableHeaderClassName(usage === u && !electricPhoenixActive)}`}
    >
      {`${u} ${description || 'kWh'}`}
    </th>
  ));

  const estimatedCharges = usagesToDisplay.map((u) => (
    <td key={u} className={`${tableElClassName(usage === u && !electricPhoenixActive)}`}>
      <span>$</span>
      {estimates[u]}
    </td>
  ));

  const averagePrices = usagesToDisplay.map((u) => (
    <td key={u} className={`${tableElClassName(usage === u && !electricPhoenixActive)}`}>
      {prices[u] ? (prices[u] * 100).toFixed(1) : ''}
      <span>{currency || '¢'}</span>
    </td>
  ));

  const isTxu = site === Sites.TXU;

  return (
    <table>
      <tbody className="table u-display-flex u-flex-direction-column">
        <tr className="row row--first u-display-flex u-flex-direction-row">
          <th className="cell cell--first-column u-display-flex u-align-items-center">Monthly usage</th>
          {headers}
        </tr>
        {!isTxu && (
          <tr className="row u-display-flex u-flex-direction-row">
            <td className="cell cell--first-column u-display-flex u-align-items-center">
              Estimated charges*
              <Tippy
                content="Includes recurring charges excluding government fees and taxes.
                  Confirm this estimate with Plan Documents. Bills vary according to actual usage."
                className="disclaimer"
                arrow
                placement="top-start"
              >
                <img className="svg svg--info-bubble" src={infoBubble} alt="Info" />
              </Tippy>
            </td>
            {estimatedCharges}
          </tr>
        )}
        <tr className="row u-display-flex u-flex-direction-row u-justify-content-space-between">
          <td className="cell cell--first-column u-display-flex u-align-items-center">
            Average price per
            {' '}
            {description || 'kWh'}
          </td>
          {averagePrices}
        </tr>
      </tbody>
    </table>
  );
}

export default CardDetailsTable;
