/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import useBreakpoints from '../../../js/hooks/useBreakPoints';
import tracking from '../../../js/project/tagular/tracking';

import checkmarkIcon from '../../../../images/checkmark-green-circle.svg';
import arrowIcon from '../../../../images/arrow-long-right.svg';
import soeBrandLogo from '../../../../images/soe-logo-transparent.svg';
import plug from '../../../../images/plug-icon.svg';
import solarPanel from '../../../../images/solar-panel-icon-green.svg';
import VisibleTracker from '../../VisibleTracker';

const SolarCardGeneric1 = ({
  title,
  checkmark1,
  checkmark2,
  checkmark3,
  ctaText,
  costWithSolar,
  costWithoutSolar,
  trackElementViewed,
}) => {
  const ctaLink = 'https://www.saveonenergy.com/solar-energy/solar-estimator/';
  const { isSm } = useBreakpoints();

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID - Solar Card Generic 1',
        elementType: 'Button',
        text: 'Get started',
      },
      actionOutcome: 'Navigate to solar lead form'
    });
  };

  return (
    <VisibleTracker onVisible={() => trackElementViewed('Solar card generic option 1')}>
      <div className="solar-generic-1__wrapper">
        <div className="solar-generic-1__inner-wrapper">
          <div className="solar-generic-1__content">
            <div className="solar-generic-1__content__title">{title}</div>
            <div className="solar-generic-1__content__checkmark-list">
              <div className="solar-generic-1__content__checkmark-list__item">
                <img
                  alt="checkmark icon"
                  className="solar-generic-1__content__checkmark-list__icon"
                  src={checkmarkIcon}
                />
                <div className="solar-generic-1__content__checkmark-item">{checkmark1}</div>
              </div>
              <div className="solar-generic-1__content__checkmark-list__item">
                <img
                  alt="checkmark icon"
                  className="solar-generic-1__content__checkmark-list__icon"
                  src={checkmarkIcon}
                />
                <div className="solar-generic-1__content__checkmark-item">{checkmark2}</div>
              </div>
              <div className="solar-generic-1__content__checkmark-list__item">
                <img
                  alt="checkmark icon"
                  className="solar-generic-1__content__checkmark-list__icon"
                  src={checkmarkIcon}
                />
                <div className="solar-generic-1__content__checkmark-item">{checkmark3}</div>
              </div>
            </div>
            <a
              className="solar-generic__cta-button"
              href={ctaLink}
              onClick={handleClick}
              target="_blank"
            >
              {ctaText}
              <img
                alt="get started arrow icon"
                className="solar-generic__cta-button__icon"
                src={arrowIcon}
              />
            </a>
            {isSm && (
            <div className="solar-generic__content__logo-wrapper">
              <div>Powered by</div>
              <img
                alt="SOE logo"
                className="solar-generic__brand-logo"
                src={soeBrandLogo}
              />
            </div>
            )}
          </div>
          <div className="solar-generic-1__average-cost-panel-wrapper">
            {!isSm && (
            <div className="solar-generic__content__logo-wrapper">
              <div>Powered by</div>
              <img alt="SOE logo" className="solar-generic__brand-logo" src={soeBrandLogo} />
            </div>
            )}
            <div className="solar-generic-1__average-cost-panel">
              <div className="solar-generic-1__average-cost-panel__container">
                <h2 className="solar-generic-1__average-cost-panel__header">Average 20-year energy cost</h2>
                <div className="solar-generic-1__average-cost-panel__data-wrapper">
                  <div className="solar-generic-1__average-cost-panel__data">
                    <div className="solar-generic-1__average-cost-panel__data__label">With solar</div>
                    <div className="solar-generic-1__average-cost-panel__value-wrapper">
                      <img
                        alt="solar panel icon"
                        className="solar-generic-1__average-cost-panel__icon"
                        src={solarPanel}
                      />
                      <div className="solar-generic-1__average-cost-panel__data__value--solar">{costWithSolar}</div>
                    </div>
                  </div>
                  <div className="solar-generic-1__average-cost-panel__data">
                    <div className="solar-generic-1__average-cost-panel__data__label">Without solar</div>
                    <div className="solar-generic-1__average-cost-panel__value-wrapper">
                      <img alt="plug icon" className="solar-generic-1__average-cost-panel__icon" src={plug} />
                      <div className="solar-generic-1__average-cost-panel__data__value">{costWithoutSolar}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VisibleTracker>
  );
};

export default SolarCardGeneric1;
