import React, { useState } from 'react';

// React Components
import Checkbox from './Checkbox';
import ProviderCheckbox from './ProviderCheckbox';

// SVGs
import plus from '../svg/plus.svg';
import minus from '../svg/minus.svg';
import doubleArrow from '../svg/double-arrow.svg';
import doubleArrowUp from '../svg/double-arrow-up.svg';

function Checkboxes(props) {
  const { filters, section, isNTX } = props;
  const {
    checkboxes, sectionLabel, addFilter, removeFilter
  } = section;

  let initialState;
  if (sectionLabel === 'Features' || sectionLabel === 'Providers') {
    initialState = false;
  } else {
    initialState = true;
  }

  const [isHidden, setIsHidden] = useState(initialState);

  const [isCheckboxHidden, setIsCheckboxHidden] = useState(initialState);

  return (
    <div className="checkboxes">
      <button
        className="checkboxes__label u-display-flex u-align-items-center"
        type="submit"
        onClick={() => setIsHidden(!isHidden)}
      >
        <img
          className={`svg svg--plus ${isHidden ? 'u-display-inline-block' : 'u-display-none'}`}
          src={plus}
          alt="Plus"
        />
        <img
          className={`svg svg--minus ${isHidden ? 'u-display-none' : 'u-display-inline-block'}`}
          src={minus}
          alt="Minus"
        />
        <strong className="checkboxes__label--text">{sectionLabel}</strong>
        <span className="checkbox__number">{`(${checkboxes?.length || 0})`}</span>
      </button>
      {(!isHidden && sectionLabel !== 'Providers')
        && checkboxes.map((checkbox) => (
          <Checkbox
            key={checkbox.name}
            checkbox={checkbox}
            filters={filters}
            addItem={addFilter}
            removeItem={removeFilter}
          />
        ))}
      {(!isHidden && sectionLabel === 'Providers')
        && checkboxes.map((checkbox) => (
          <ProviderCheckbox
            key={checkbox.name}
            checkbox={checkbox}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            isCheckboxHidden={isCheckboxHidden}
            setIsCheckboxHidden={setIsCheckboxHidden}
            isNTX={isNTX}
          />
        ))}
      {(!isHidden && sectionLabel === 'Providers' && !isNTX)
        && (
          <button
            className="button--more u-display-flex u-align-items-center"
            type="submit"
            onClick={() => setIsCheckboxHidden(!isCheckboxHidden)}
          >
            {!isCheckboxHidden
              ? (
                <>
                  <span>View more</span>
                  <img className="svg svg--double-arrow" src={doubleArrow} alt="Arrow" />
                </>
              ) : (
                <>
                  <span>View Less</span>
                  <img className="svg svg--double-arrow" src={doubleArrowUp} alt="Arrow" />
                </>
              )}
          </button>
        )}
    </div>
  );
}

export default Checkboxes;
