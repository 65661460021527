import React, { useRef, useState, useEffect } from 'react';
import cloneDeep from 'clone-deep';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Sites } from '@rvpower/constants';
import { ProductViewed, ProductClicked, ProductPriceViewed } from '@rvpower/track';
import {
  Transition,
  TransitionGroup,
} from 'react-transition-group';
import { getPlan } from '../../js/project/tracking-helper';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import {
  usageMapping,
  sortingFunctions,
} from '../../js/project/mappers';

// React Components
// import Card from '../Card';
import TemplateCard from '../cards/templates/TemplateCard';
import FaqsBanner from '../grid-faqs/FaqsBanner';
import VisibleTracker from '../VisibleTracker';
import Spinner from '../Spinner';
import Error from '../Error';
import GuaranteeBanner from '../GuaranteeBanner';
import Pagination from '../pagination/Pagination';
import DisplayPlans from '../pagination/DisplayPlans';
import SeasonalMessaging from '../grid-v2/SeasonalMessaging';

// settings for card transitions
// const duration = 300;
// const delayMultiplier = 200;

// const transitionStyles = {
//   entering: { opacity: 1 },
//   entered: { opacity: 1 },
//   exiting: { opacity: 0 },
//   exited: { opacity: 0 },
// };

// const defaultTransitionStyle = (i) => {
//   const delay = i < 5 ? i * delayMultiplier : 5 * delayMultiplier;
//   return {
//     transition: `opacity ${duration}ms ease-in-out ${delay}ms`,
//     opacity: 0,
//   };
// };

function CardsKwh(props) {
  const {
    ESIID,
    electricPhoenixActive,
    electricPhoenixToggleActive,
    setEpPrimaryPlan,
    epPrimaryPlan,
    electricPhoenixInput,
    shouldAbortLoadingPlans,
    plans = [],
    site,
    usage,
    epData,
    sort,
    filters,
    utility,
    comparedPlans,
    enableCardAnimation,
    setEnableCardAnimation,
    setComparedPlans,
    gridComparisonToolActive,
    setKwhPlansWithPrices,
    kWhAmountUpdated,
    faqsBannerActive,
    disableCartLinks,
    isNTX,
    newGridEnabled = false,
    seasonalBannerData = {},
    setPaginationUpdated,
    hmcData,
    hasDefaultFilters,
  } = props;

  const isTxu = site === Sites.TXU;
  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';
  const guaranteeBannerBottom = usePreamp('Grid-Guarantee-Banner-Bottom');
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const { rules } = useMonarch();
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;
  const {
    seasonalTitle = null,
    seasonalDescription = null
  } = seasonalBannerData || {};
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;
  const ntxP2CSavingsAcrossPrice = rules?.source?.ntxP2CSavingsAcrossPrice?.value;
  const coreLogicBool = monarchCoreLogic || coreLogicPreamp;
  const productsViewed = useRef({});

  const paginationIncrement = 10;
  const [paginationActive, setPaginationActive] = useState(true);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const paginationToggle = () => {
    setPaginationActive(!paginationActive);
  };

  useEffect(() => {
    if (gridComparisonToolActive) {
      setEnableCardAnimation(true);
    }
  }, [comparedPlans]);

  useEffect(() => {
    if (gridComparisonToolActive) {
      setEnableCardAnimation(false);
    }
    setPaginationStartIndex(0);
  }, [filters]);

  useEffect(() => {
    if (setPaginationUpdated) {
      setPaginationUpdated(paginationStartIndex);
    }
  }, [paginationStartIndex]);

  const productViewed = (p) => {
    const productInfo = cloneDeep(p);
    if (electricPhoenixActive && productInfo.electricPhoenixData) {
      const newPlanAverageCost = Math.round(productInfo.electricPhoenixData.averageCost);
      const currentPlanAverageCost = Math.round(epData.averageCost);
      const averageSavings = currentPlanAverageCost - newPlanAverageCost;

      productInfo.price = averageSavings;
      productInfo.location = 'Savings grid';
    }

    if (!productsViewed.current[productInfo.productId]) {
      productsViewed.current[productInfo.productId] = true;
      ProductViewed(productInfo);
      ProductPriceViewed(productInfo);
    }
  };

  const query = gql`
    query plans($id: ID!, $usage: [Int]!) {
      utility(id: $id) {
        id
        plans(usage: $usage) {
          pricePerKwh
          id
        }
      }
    }
  `;

  const {
    data = {},
    error,
    loading,
  } = useQuery(query, {
    errorPolicy: 'all',
    variables: {
      id: utility,
      usage
    },
    fetchPolicy: 'cache-and-network',
    skip: Boolean(usageMapping[usage])
  });

  if (error && !data) {
    return (
      <Error />
    );
  }

  if (loading) {
    return (
      <>
        <Spinner />
        <div className="column-content-placeholder" />
      </>
    );
  }

  function customOrder(order = []) {
    if (order.length === 0) {
      return () => -1;
    }

    return (a, b) => {
      if (order.indexOf(a.id) > order.indexOf(b.id)) {
        return 1;
      }
      return -1;
    };
  }

  const { plans: kwhPlans = [] } = data?.utility || {};

  let plansWithPrices = plans.map((plan) => {
    // if there are not usage rates, refer to plan data
    let matchedPlanPrice = 0;

    if (plan.unitOfMeasure && typeof plan.unitOfMeasure === 'string' && plan.unitOfMeasure !== '') {
      const [currency, description] = plan.unitOfMeasure.split('/');
      // eslint-disable-next-line no-param-reassign
      plan.unitOfMeasure = { currency, description };
    }

    if (kwhPlans.length) {
      matchedPlanPrice = kwhPlans.find((kwhPlan) => kwhPlan.id === plan.id)?.pricePerKwh[0];
    }

    if (!matchedPlanPrice) {
      matchedPlanPrice = plan.rates.find((r) => r.type === usageMapping[usage])?.price;
    }

    return {
      ...plan,
      price: matchedPlanPrice || 0
    };
  });

  if (coreLogicBool) {
    plansWithPrices = plansWithPrices.filter((plan) => plan.price > 0);
  }

  const sortedPlansKwh = () => {
    if (sort === 'recommended') {
      return coreLogicBool ? plansWithPrices : plansWithPrices.sort(customOrder(filters.customPlanOrder));
    }
    return plansWithPrices.sort(sortingFunctions[sort]);
  };

  if (isNTX) {
    plansWithPrices = plansWithPrices.filter((plan) => plan.rates.find((p) => p.type === 'EnergyCharge'));
  }

  const sortedPlans = sortedPlansKwh();

  if (shouldAbortLoadingPlans(plans) === true) {
    return null;
  }

  if (kWhAmountUpdated) {
    setKwhPlansWithPrices(sortedPlans);
  }

  const hash = btoa(JSON.stringify({ filters, sort, usage }));

  const faqInsertionIndex = paginationActive ? paginationStartIndex + 3 : 3;
  const seasonalMessagingInsertionIndex = () => {
    if (!paginationActive) {
      return 6;
    }

    if ([Sites.NEW_CTXP, Sites.NEW_TXER, Sites.CHOOSE, Sites.SOE].includes(site)) {
      return paginationStartIndex + 3;
    }

    return paginationStartIndex + 6;
  };

  if (electricPhoenixActive && epPrimaryPlan && sortedPlans.length && epPrimaryPlan.id !== sortedPlans[0].id) {
    setEpPrimaryPlan(sortedPlans[0]);
  }

  const paginationRenderCheck = (i) => {
    if (paginationActive
      && (i < paginationStartIndex || i > paginationStartIndex + paginationIncrement - 1)) {
      return false;
    }
    return true;
  };

  return (
    <section className="cards">
      <div className="cards__cards">
        {!newGridEnabled && (
          <DisplayPlans
            paginationStartIndex={paginationStartIndex}
            paginationIncrement={paginationIncrement}
            sortedPlans={sortedPlans.length}
            paginationActive={paginationActive}
            paginationToggle={paginationToggle}
          />
        )}
        {
          !enableCardAnimation && (
            <TransitionGroup exit={false}>
              {plansWithPrices.map((plan, i) => {
                if (!paginationRenderCheck(i)) return null;

                const productInfo = getPlan(plan, i + 1, 'GRID');
                if (electricPhoenixActive) {
                  productInfo.location = 'EP Grid';
                }

                const pdpProductInfo = { ...productInfo, actionOutcome: 'Entered PDP' };

                // Added a separate onClick function for NTX because gtm keeps overriding flow for NTX
                const ntxOnClick = (e, cartUrl, planObject) => {
                  e.preventDefault();

                  window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
                  ProductClicked(productInfo);
                  window.open(cartUrl, '_blank');
                };

                const onOrderOnline = (e, cartUrl, planObject) => {
                  e.preventDefault();

                  const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;
                  window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
                  ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);

                  if (isNTX) {
                    window.open(cartUrl, '_blank');
                    return;
                  }

                  setTimeout(() => {
                    window.location = pdpTestActive ? pdpUrl : cartUrl;
                  }, 150);
                };

                return (
                  <Transition
                    // the key needs to be different on each render so the transition happens on each render
                    key={plan.id + hash}
                    timeout={500}
                    mountOnEnter={false}
                  >
                    {() => (
                      <>
                        {!isNTX && !newGridEnabled && (
                          <FaqsBanner
                            active={faqsBannerActive}
                            currentIndex={i}
                            insertionIndex={faqInsertionIndex}
                            position="middle"
                          />
                        )}
                        {!isNTX && newGridEnabled && (
                          <SeasonalMessaging
                            active={seasonalTitle && seasonalDescription}
                            title={seasonalTitle}
                            description={seasonalDescription}
                            currentIndex={i}
                            insertionIndex={seasonalMessagingInsertionIndex()}
                            site={site}
                          />
                        )}
                        <VisibleTracker onVisible={() => productViewed(productInfo)}>
                          <TemplateCard
                            ESIID={SOEesiid}
                            plan={plan}
                            planIndex={i}
                            site={site}
                            onOrderOnline={isNTX ? ntxOnClick : onOrderOnline}
                            electricPhoenixActive={electricPhoenixActive}
                            electricPhoenixToggleActive={electricPhoenixToggleActive}
                            electricPhoenixInput={electricPhoenixInput}
                            epData={epData}
                            comparedPlans={comparedPlans}
                            setComparedPlans={setComparedPlans}
                            gridComparisonToolActive={gridComparisonToolActive}
                            disableCartLinks={disableCartLinks}
                            isNTX={isNTX}
                            ntxP2CSavingsAcrossPrice={ntxP2CSavingsAcrossPrice}
                            newGridEnabled={newGridEnabled}
                            hmcData={hmcData}
                            hasDefaultFilters={hasDefaultFilters}
                            sort={sort}
                          />
                        </VisibleTracker>
                      </>
                    )}
                  </Transition>
                );
              })}
            </TransitionGroup>
          )
        }
        {/* // not using transition group for new grid */}
        {enableCardAnimation && plansWithPrices.map((plan, i) => {
          if (!paginationRenderCheck(i)) return null;

          const productInfo = getPlan(plan, i + 1, 'GRID');
          const pdpProductInfo = { ...productInfo, actionOutcome: 'Entered PDP' };

          const onOrderOnline = (e, cartUrl, planObject) => {
            e.preventDefault();

            const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;
            window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
            ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);

            setTimeout(() => {
              window.location = pdpTestActive ? pdpUrl : cartUrl;
            }, 150);
          };

          return (
            <>
              <FaqsBanner
                active={faqsBannerActive}
                currentIndex={i}
                insertionIndex={faqInsertionIndex}
                position="middle"
              />
              <VisibleTracker
                key={plan.id + hash}
                onVisible={() => productViewed(productInfo)}
              >
                <TemplateCard
                  plan={plan}
                  planIndex={i}
                  site={site}
                  onOrderOnline={onOrderOnline}
                  electricPhoenixActive={electricPhoenixActive}
                  electricPhoenixToggleActive={electricPhoenixToggleActive}
                  electricPhoenixInput={electricPhoenixInput}
                  epData={epData}
                  comparedPlans={comparedPlans}
                  setComparedPlans={setComparedPlans}
                  gridComparisonToolActive={gridComparisonToolActive}
                  disableCartLinks={disableCartLinks}
                  isNTX={isNTX}
                  newGridEnabled={newGridEnabled}
                  hmcData={hmcData}
                  hasDefaultFilters={hasDefaultFilters}
                  sort={sort}
                />
              </VisibleTracker>
            </>
          );
        })}
        { isTxu && guaranteeBannerBottom && (
          <GuaranteeBanner location="bottom" />
        )}
        <DisplayPlans
          paginationStartIndex={paginationStartIndex}
          paginationIncrement={paginationIncrement}
          sortedPlans={sortedPlans.length}
          paginationActive={paginationActive}
          paginationToggle={paginationToggle}
          isAtBottom
        />
        <Pagination
          paginationStartIndex={paginationStartIndex}
          setPaginationStartIndex={setPaginationStartIndex}
          paginationIncrement={paginationIncrement}
          sortedPlansLength={sortedPlans.filter((plan) => plan.price).length}
          setPaginationActive={setPaginationActive}
          paginationActive={paginationActive}
          paginationToggle={paginationToggle}
        />
      </div>
    </section>
  );
}

export default CardsKwh;
