import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

// Icons
import closeIcon from '../../../images/close.svg';
import CustomTooltip from '../CustomTooltip';

export default function ZipEntryModal(props) {
  const [zipCode, setZipCode] = useState('');
  const [validZipCode, setValidZipCode] = useState(false);
  const [newAddress, setNewAddress] = useState();
  const [anApartment, setAnApartment] = useState();
  const [zipModalConfig, setZipModalConfig] = useState({});

  const { active, handleModalToggle } = props;

  useEffect(() => {
    if (validZipCode) {
      document.querySelectorAll('.zip-address-container [type="radio"]').forEach((element) => {
        element.addEventListener('invalid', (event) => {
          event.preventDefault();
        });
      });
    }
  }, [validZipCode]);

  const invalidFieldHandler = (e) => {
    if (e.target.type !== 'radio') {
      e.preventDefault();
    }

    if (zipModalConfig.visible) {
      return;
    }

    const { modalId, modalTitle } = e.target.dataset;

    setZipModalConfig({
      elementId: modalId,
      text: modalTitle,
      visible: true
    });
  };

  const resetModalTooltip = () => {
    setZipModalConfig({
      elementId: null,
      text: '',
      visible: false
    });
  };

  const handleEvent = (callbackFn, callbackValue) => {
    if (typeof callbackFn === 'function' && callbackValue !== null) {
      callbackFn(() => callbackValue);
    }
    resetModalTooltip();
  };

  const generateEvents = (callbackFn, callbackValue) => ({
    onClick: () => handleEvent(callbackFn, callbackValue),
    onKeyPress: (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleEvent(callbackFn, callbackValue);
      }
    }
  });

  const closeModal = () => {
    handleModalToggle();
    setValidZipCode(false);
    setZipCode('');
    setNewAddress();
    setAnApartment();
    resetModalTooltip();
  };

  const submitMoverSwitcher = (e) => {
    e.preventDefault();
    setValidZipCode(true);
  };

  const handleNavigation = (e) => {
    e.preventDefault();
    // Construct the URL based on the state values
    const moveValue = newAddress ? 'movey' : 'moven';
    const apartmentValue = anApartment ? 'apartmenty' : 'apartmentn';
    const currentURL = `${window.location.origin}${window.location.pathname}`;
    window.location.href = `${currentURL}?zipCode=${zipCode}&m=${moveValue}&a=${apartmentValue}`;
  };

  return (
    <Modal
      isOpen={active}
      className="zip-entry-modal"
      style={{
        overlay: {
          background: 'rgba(37, 54, 64, 0.85)'
        },
      }}
    >
      <button
        onClick={closeModal}
        className="zip-entry-modal__close"
        type="button"
      >
        <img src={closeIcon} alt="close button" />
      </button>

      {validZipCode ? (
        <form className="zip-address-container" onSubmit={handleNavigation}>
          <div className="zip-option-container">
            <p>Are you moving to a new address?</p>
            <div className="zip-radio-container zip-radio-container-address">
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...generateEvents(setNewAddress, true)}
              >
                <input
                  type="radio"
                  name="newAddress"
                  id="newAddressYes"
                  checked={newAddress}
                  value={newAddress}
                  required
                  data-modal-id="newAddress"
                  data-modal-title="Please select one of these options."
                  onInvalid={invalidFieldHandler}
                />
                {' '}
                <label htmlFor="newAddressYes">Yes</label>
              </span>
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...generateEvents(setNewAddress, false)}
              >
                <input
                  type="radio"
                  name="newAddress"
                  id="newAddressNo"
                  checked={newAddress === false}
                  value={newAddress}
                  onChange={() => {
                    resetModalTooltip();
                  }}
                />
                {' '}
                <label htmlFor="newAddressNo">No</label>
              </span>
              <CustomTooltip
                centered
                id="newAddress"
                config={zipModalConfig}
              />
            </div>
          </div>
          <div className="zip-option-container">
            <p>Are you moving to an apartment?</p>
            <div className="zip-radio-container zip-radio-container-apt">
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...generateEvents(setAnApartment, true)}
              >
                <input
                  type="radio"
                  name="newAApartment"
                  id="newAApartmentYes"
                  value={anApartment}
                  checked={anApartment}
                  data-modal-id="newAApartment"
                  data-modal-title="Please select one of these options."
                  onInvalid={invalidFieldHandler}
                  required
                />
                {' '}
                <label htmlFor="newAApartmentYes">Yes</label>
              </span>
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...generateEvents(setAnApartment, false)}
              >
                <input
                  type="radio"
                  name="newAApartment"
                  id="newAApartmentNo"
                  checked={anApartment === false}
                  value={anApartment}
                  onChange={() => {
                    resetModalTooltip();
                  }}
                />
                {' '}
                <label htmlFor="newAApartmentNo">No</label>
              </span>
              <CustomTooltip
                centered
                id="newAApartment"
                config={zipModalConfig}
              />
            </div>
          </div>
          <button type="submit">Find Plans</button>
        </form>
      ) : (
        <>
          <p className="zip-entry-modal__headline">
            Enter your ZIP Code to see plans
          </p>
          <form
            className="zip-code-container"
            onSubmit={(e) => submitMoverSwitcher(e)}
          >
            <div className="custom-tooltip-wrapper">
              <input
                maxLength="5"
                required
                type="text"
                pattern="[0-9]{5}"
                placeholder="ZIP Code"
                data-modal-id="zip-code-input"
                data-modal-title="Please input the ZIP code."
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value);
                  resetModalTooltip();
                }}
                onInvalid={invalidFieldHandler}
              />
              <CustomTooltip
                id="zip-code-input"
                config={zipModalConfig}
              />
            </div>
            <button type="submit">Continue</button>
          </form>
        </>
      )}
    </Modal>
  );
}

ZipEntryModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired
};
