/* eslint-disable max-len */
import React, { useState } from 'react';
import tracking from '../js/project/tagular/tracking';

const icons = {
  package: 'tip__icon--package',
  arrowsSwitcher: 'tip__icon--arrows-switcher'
};

function Tip(props) {
  const {
    section,
    hasCollapsibles,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const showTip = !isCollapsed || !hasCollapsibles;

  const showHideTip = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Dropdown',
        text: section.headline
      },
      actionOutcome: isCollapsed ? 'EXPAND' : 'COLLAPSE'
    });

    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className="tip"
      key={section.param}
    >
      {hasCollapsibles
        ? (
          <button
            type="button"
            onClick={showHideTip}
          >
            <h3 className={`tip__headline tip__headline--show-arrow ${!isCollapsed ? 'tip__headline--open' : ''} ${icons[section.icon] || ''}`}>
              {section.headline}
            </h3>
          </button>
        )
        : (
          <h3 className="tip__headline">
            {section.headline}
          </h3>
        )}
      {showTip && (
        <div className="tip__container">
          {section.tips.map((tip, j) => (
            <p
              key={tip}
              className="tip__text"
              dangerouslySetInnerHTML={{ __html: `<span class="tip__list-number">${j + 1}</span> <span class="tip__item">${tip}</span>` }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Tip;
