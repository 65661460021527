import React from 'react';
import Tippy from '@tippy.js/react';

function CheckboxTooltip() {
  return (
    <Tippy
      content="A maximum of 3 plans can be compared at once. Please deselect a plan to continue."
      className="tippy-tooltip gct__checkbox-tippy"
      arrow
      placement="top-start"
    >
      <span className="gct__checkbox-tippy" />
    </Tippy>
  );
}

export default CheckboxTooltip;
