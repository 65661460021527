/**
 * Handle error
 * @param {Error} err
 */
export default function logError(err) {
  if (window.newrelic) {
    window.newrelic.noticeError(err);
  } else {
    console.warn('newrelic not defined. unable to log errors to new relic.');
  }
}
