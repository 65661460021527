import React, { useState } from 'react';
import ZipEntryModal from './ZipEntryModal';
import location from '../../../images/location.svg';

export default function TXUEditLocation() {
  const [zipModalToggle, setZipModalToggle] = useState(false);

  const handleModalToggle = () => {
    setZipModalToggle(!zipModalToggle);
  };

  return (
    <>
      <div className="simplified-location">
        <button
          onClick={() => {
            handleModalToggle();
          }}
          className="edit-location"
          type="button"
        >
          <img src={location} alt="location" />
          <span>Edit location</span>
        </button>
      </div>
      <ZipEntryModal
        active={zipModalToggle}
        handleModalToggle={handleModalToggle}
      />
    </>
  );
}
