import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import tracking from '../../../js/project/tagular/tracking';

import Radio from '../radio';
import Label from './Label';

const Sort = ({
  options,
  sort,
  setSort,
  isNTX,
  isTxu
}) => {
  const handleOnChange = (e) => {
    setSort(e.target.value);

    tracking.elementClicked({
      webElement: {
        elementType: 'DROPDOWN',
        location: 'GRID',
        name: 'SORT FILTERS',
        position: '4',
        text: e.target.value
      },
      actionOutcome: 'Submit new sort'
    });
  };

  const ntxSortOptions = [
    'recommended',
    'rate-low',
    'rate-high',
    'terms-longer',
    'terms-shorter',
    'provider-a',
    'provider-z'
  ];

  const ntxSort = ([key]) => ntxSortOptions.includes(key);

  const txuSortOptions = [
    'recommended',
    'rate-low',
    'rate-high',
    'terms-longer',
    'terms-shorter',
  ];

  const txuSort = ([key]) => txuSortOptions.includes(key);

  const getSortOptions = () => {
    if (isNTX) {
      return Object.entries(options).filter(ntxSort);
    }

    if (isTxu) {
      return Object.entries(options).filter(txuSort);
    }

    return Object.entries(options);
  };

  const sortOptions = getSortOptions();

  return (
    <div className="grid-v2-sort">
      <h4 className="grid-v2-sort__heading">Sort by</h4>
      {sortOptions.map(([key, value]) => (
        <Radio
          checked={sort === key}
          className="grid-v2-sort__radio"
          key={uuidv4()}
          id={`grid-v2-${key}`}
          label={<Label label={value} />}
          name="grid-v2-sort"
          value={key}
          onChange={handleOnChange}
        />
      ))}
    </div>
  );
};

export default Sort;

Sort.propTypes = {
  options: PropTypes.shape({
    recommended: PropTypes.string.isRequired,
    'rate-low': PropTypes.string.isRequired,
    'rate-high': PropTypes.string.isRequired,
    'monthly-bill-low': PropTypes.string.isRequired,
    'monthly-bill-high': PropTypes.string.isRequired,
    'terms-longer': PropTypes.string.isRequired,
    'terms-shorter': PropTypes.string.isRequired,
    'provider-a': PropTypes.string.isRequired,
    'provider-z': PropTypes.string.isRequired
  }).isRequired,
  sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
};

Sort.defaultProps = {
  sort: null // default value type
};
