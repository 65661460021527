import React from 'react';
import PropTypes from 'prop-types';

const Feature = ({
  addFilter,
  checkbox,
  removeFilter
}) => {
  const {
    checked,
    icon,
    label,
    name,
    value
  } = checkbox;

  const check = () => {
    if (!checked) {
      addFilter(value);
    } else {
      removeFilter(value);
    }
  };

  return (
    <div className="grid-v2-feature" onClick={check} aria-hidden="true">
      <input
        className="grid-v2-feature__input"
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        readOnly
      />
      <div className="grid-v2-feature__wrapper">
        <img
          className="grid-v2-feature__icon"
          src={icon}
          alt={label}
        />
        {label}
      </div>
    </div>
  );
};

export default Feature;

Feature.propTypes = {
  addFilter: PropTypes.func.isRequired,
  checkbox: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  }).isRequired,
  removeFilter: PropTypes.func.isRequired,
};
