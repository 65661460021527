import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import arrowLeft from './pagination-arrow-left';
import arrowRight from './pagination-arrow-right';

function Pagination(props) {
  const {
    paginationStartIndex,
    setPaginationStartIndex,
    paginationIncrement,
    sortedPlansLength,
    paginationActive,
    paginationToggle
  } = props;

  let numberOfPageLinks = Math.floor(sortedPlansLength / paginationIncrement);
  if (sortedPlansLength % paginationIncrement) {
    numberOfPageLinks += 1;
  }

  const currentPageIndex = Math.floor(paginationStartIndex / paginationIncrement);

  const changePage = (pageIndexNumber) => () => {
    setPaginationStartIndex(paginationIncrement * pageIndexNumber);
    document.querySelector('.zip-search__form').scrollIntoView();
  };

  const generatePageLinks = () => {
    const pageLinkArray = [];
    for (let i = 0; i < numberOfPageLinks; i += 1) {
      pageLinkArray.push(
        <button
          key={uuidv4()}
          type="button"
          onClick={changePage(i)}
          className={currentPageIndex === i
            ? 'current-page page-number'
            : 'page-number'}
        >
          {i + 1}
        </button>
      );
    }
    return pageLinkArray;
  };

  return (
    <>
      <div className={`pagination-container ${paginationActive ? '' : 'pagination-hidden'}`}>
        {(paginationActive && currentPageIndex !== 0)
        && arrowLeft('#376DBB', changePage(currentPageIndex - 1), 'arrow-left')}
        {paginationActive && generatePageLinks()}
        {(paginationActive && numberOfPageLinks - 1 !== currentPageIndex)
        && arrowRight('#376DBB', changePage(currentPageIndex + 1), 'arrow-right')}
      </div>
      {paginationActive && <span onClick={paginationToggle} className="pagination-toggle__button">Show all plans</span>}
    </>
  );
}

export default Pagination;
