import React from 'react';
import PropTypes from 'prop-types';
import { useMonarch } from '../../monarch';
import { usePlanPlanContext } from '../../../js/contexts/PlanContext';
import getIncentivePerSize from '../../../js/project/incentivesUtils';

const IncentivesRibbon = ({ variant }) => {
  const { rules } = useMonarch();
  const showIncentives = rules?.source?.incentives?.value?.showIncentives;
  const { plan } = usePlanPlanContext();
  const { incentives } = plan || {};
  const incentive = getIncentivePerSize(incentives);

  if (!showIncentives || !incentive?.showIncentive) {
    return null;
  }

  return (
    <div className={`incentives__ribbon ${variant}`}>
      {incentive.offerText}
      <sup>†</sup>
    </div>
  );
};

IncentivesRibbon.propTypes = {
  variant: PropTypes.oneOf(['default', 'list']),
};

IncentivesRibbon.defaultProps = {
  variant: 'default',
};

export default IncentivesRibbon;
