import { useRef, useEffect } from 'react';

/**
* Attaches a click event listener to the DOM to determine if the user
* has clicked outside of the bounds of the specified reference element.
* Runs a callback function if so.
* Allows for additional classes to be passed to ignore other elements
* with those classes.
*
* @param {MutableRefObject} refEle
* @param {Function} callback
* @param {Array} ignoreClasses
*
* @returns {Function}
*/
const useClickOutside = (refEle, callback, ignoreClasses) => {
  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(() => {
    const handleClickOutside = (e) => {
      const ignoredClasses = ignoreClasses
        && ignoreClasses.find((classString) => e.target.classList.contains(classString));
      if (!ignoredClasses && !(refEle?.current?.contains(e.target)) && callbackRef.current) {
        callbackRef.current(e);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [callbackRef, refEle, ignoreClasses]);
};

export default useClickOutside;
