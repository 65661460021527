import React, { useState, useRef, useEffect } from 'react';
import { updateZip } from '../../js/project/get-query-params';
import tracking from '../../js/project/tagular/tracking';

const ZipSearch = (props) => {
  const {
    plansCount,
    zipCode,
    site,
    isNTX = false,
    isSplashPage,
  } = props;
  const [buttonName, setButtonName] = useState('Edit');
  const [localZipCode, setLocalZipCode] = useState(zipCode);
  const [isFocused, setIsFocused] = useState(false);

  const inputEl = useRef(null);

  const focusZip = () => {
    setButtonName('Go');
    inputEl.current.select();
    setIsFocused(true);
  };

  const submit = (e) => {
    e.preventDefault();
    if (buttonName === 'Edit') {
      focusZip();
    } else {
      tracking.elementClicked({
        webElement: {
          location: 'GRID',
          elementType: 'Button',
          text: 'Go'
        },
        actionOutcome: 'Submit new ZIP'
      });

      const newZip = inputEl.current.value;
      setLocalZipCode(newZip);
      updateZip(site, newZip);
    }
  };

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  // Updates the zip in the middle of the grid page after the browser back button is clicked
  useEffect(() => {
    if (zipCode !== localZipCode) {
      setLocalZipCode(zipCode);
      updateZip(site, zipCode);
    }
  }, []);

  // Fires event once the zip has all 5 digits
  useEffect(() => {
    if (localZipCode.toString().length === 5) {
      tracking.fieldInputted({
        userInputField: {
          fieldLabel: 'ZIP',
          fieldName: 'ZIP EDIT GRID',
          fieldType: 'Zip Entry',
          fieldValue: `Object 0: ${zipCode}, Object 1: ${localZipCode}`
        }
      });
    }
  }, [localZipCode]);

  return (
    <section className="zip-search">
      <form className={`zip-search__form ${isNTX ? 'ntx' : ''}`} onSubmit={submit}>
        <div className={`zip-search__text-wrapper${isNTX ? '--ntx' : ''} ${isSplashPage ? 'splash-zip-search' : ''}`}>
          We found
          <strong>{` ${plansCount} plans `}</strong>
          {'for '}
          <div className={`zip-search__input-submit-container ${isNTX ? 'ntx' : ''}`}>
            <div className="zip-search__input-container">
              <input
                id="zip-code"
                className={`zip-search__input ${isSplashPage ? 'splash-zip-search__input' : ''}`}
                ref={inputEl}
                value={localZipCode}
                onChange={(e) => handleChange(e)}
                onClick={focusZip}
                onBlur={() => handleBlur()}
                type="text"
                inputMode="numeric"
                maxLength="5"
                pattern="[0-9]{5}"
                title="Please enter a valid zip code"
                required
                aria-label="zip code search"
              />
            </div>
            {' '}
            <button
              id="zip-search-submit-button"
              className={`zip-search__button ${isFocused ? 'zip-search__button--focused' : ''}`}
              type="submit"
            >
              {buttonName}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ZipSearch;
