/* eslint-disable global-require */
/* eslint-disable max-len */
import React from 'react';
import beam from '../../js/project/tagular/beam';

import arrow from '../../../images/unserve/arrow.svg';

const TechCards = ({ site }) => {
  const siteName = site;
  const techIcons = [];
  switch (siteName) {
    case 'chooseenergy.com':
      techIcons.push(
        require('../../../images/unserve/smart-tech-icons/choose-smart-plug.svg'),
        require('../../../images/unserve/smart-tech-icons/choose-energy-monitors.svg'),
        require('../../../images/unserve/smart-tech-icons/choose-ev-charger.svg'),
      );
      break;

    default:
      techIcons.push(
        require('../../../images/unserve/smart-tech-icons/soe-smart-plug.svg'),
        require('../../../images/unserve/smart-tech-icons/soe-energy-monitors.svg'),
        require('../../../images/unserve/smart-tech-icons/soe-ev-charger.svg'),
      );
      break;
  }

  const cardData = [
    {
      icon: techIcons[0],
      alt: 'Smart Plugs Icon',
      option: 'Shop smart plugs',
      description: 'Control devices on your phone',
    },
    {
      icon: techIcons[1],
      alt: 'Energy Monitors Icon',
      option: 'Shop energy monitors',
      description: 'Monitor real-time energy usage',
    },
    {
      icon: techIcons[2],
      alt: 'EV Chargers Icon',
      option: 'Shop EV chargers',
      description: 'Charge your Electric Vehicle',
    },
  ];

  const cardLink = 'https://shop.emporiaenergy.com/collections/saveonenergy/';

  const clickHandler = () => {
    const payload = {
      webElement:
        {
          elementType: 'Button',
          location: 'Emporia CTA',
        },
      actionOutcome: 'SubmitLead'
    };

    beam.userTrackingV3('ElementClicked', payload);
  };

  return (
    <div className="unserve-tech__card">
      {cardData.map((data) => (
        <a
          href={cardLink}
          rel="noopener noreferrer"
          target="_blank"
          className="unserve-tech__card-link"
          onClick={clickHandler}
          key={data.option}
        >
          <img src={data.icon} alt={data.alt} className="unserve-tech__card-icon" />
          <div className="unserve-tech__card-content">
            <div className="unserve-tech__card-content-text">
              <h3 className="unserve-tech__card-content-option">{data.option}</h3>
              <p className="unserve-tech__card-content-description">{data.description}</p>
            </div>
            <img src={arrow} alt="Arrow Button" className="unserve-tech__card-content-arrow" />
          </div>
        </a>
      ))}
    </div>
  );
};

export default TechCards;
